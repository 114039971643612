import React, {createRef} from 'react';
import {Stepper, Step, StepLabel, Paper} from '@material-ui/core';
import './registrationProcess.css';
import {
    addScrollbar,
    contentAreaLargerThanDisplayArea,
    removeScrollbar,
    hideCircularProgress,
    updateScrollbarAtWindowResize
} from '../utils/Utils';

enum CurrentStep {
    First = 'first',
    Second = 'second',
    Third = 'third'
}

/**
 * @param {{}} props
 * @param {string} props.textObject
 * @param {Function} props.setDisabled
 * @returns {React.ReactElement}
 */
export default function RegistrationSimpleStepper(props: {
    textObject: {[index: string]: string};
}): React.ReactElement {
    const ref = createRef<HTMLElement>();

    React.useEffect(() => {
        const element = ref.current;

        if (element) {
            const needScrollbar = contentAreaLargerThanDisplayArea(element);

            if (needScrollbar) {
                addScrollbar(element);
            } else {
                removeScrollbar(element);
            }

            element.scrollTo(0, 0);
            hideCircularProgress();
        }
    });

    React.useEffect(() => {
        if (ref.current) {
            updateScrollbarAtWindowResize(ref.current);
        }
    }, []);

    return (
        <Paper
            ref={ref}
            square
            elevation={3}
            className="page-content-box paper-font-size"
        >
            <div className="register-stepper-container">
                <h3>{props.textObject.title}</h3>
                <div className="simple-stepper-introduction">{props.textObject.introduction}</div>
                <Stepper activeStep={3} orientation="vertical" className="simple-stepper">
                    {Object.values(CurrentStep).map((key, idx) => {
                        return (
                            <Step className="simple-stepper-icon" key={key}>
                                <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            active:
                                                key === CurrentStep.First
                                                    ? 'phase1-color'
                                                    : key === CurrentStep.Second
                                                    ? 'phase2-color'
                                                    : 'phase3-color',
                                            completed:
                                                key === CurrentStep.First
                                                    ? 'phase1-color'
                                                    : key === CurrentStep.Second
                                                    ? 'phase2-color'
                                                    : 'phase3-color'
                                        }
                                    }}
                                >
                                    <div className="simple-stepper-title">
                                        <div>{props.textObject[`${key}Title`]}</div>
                                    </div>
                                    <div
                                        className={`simple-stepper-content ${
                                            idx === Object.keys(CurrentStep).length - 1
                                                ? 'last-step'
                                                : ''
                                        }`}
                                        dangerouslySetInnerHTML={{
                                            __html: props.textObject[`${key}`]
                                        }}
                                    />
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
        </Paper>
    );
}
