import React from 'react';

/**
 *
 * Displays Login page
 * @returns {React.ReactElement}
 */
export default function Loading(): React.ReactElement {
    return <h3>Loading</h3>;
}
