import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireS: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://uk-erlangen.de/freeda/questionnaire/S_en',
    name: 'S',
    status: 'active',
    item: [
        {
            linkId: 'S0',
            prefix: 'S0',
            text:
                'In the next section, we ask you to answer questions about your overall well-being. Please answer the following questions by selecting the desired answer item that best describes your experience within the last 7 days. ',
            type: 'display'
        },
        {
            linkId: 'patCareMedPractit',
            prefix: 'S1',
            text:
                'In the last 7 days, how well would you rate the care provided by your primary care physician during the pandemic period?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '0',
                        display: 'very poor'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '10',
                        display: 'very good'
                    }
                }
            ]
        },
        {
            linkId: 'patCareClinic',
            prefix: 'S2',
            text:
                'In the last 7 days, how well do you rate current hospital care and availability?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '0',
                        display: 'very poor'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '10',
                        display: 'very good'
                    }
                }
            ]
        },
        {
            linkId: 'cancerCareSituat',
            prefix: 'S3',
            text: 'In the last 7 days, how good do you think the current cancer care situation is?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '0',
                        display: 'very poor'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_en',
                        version: '1.0',
                        code: '10',
                        display: 'very good'
                    }
                }
            ]
        },
        {
            linkId: 'privateStressLoad',
            prefix: 'S4',
            text: 'In the last 7 days, how high do you rate your personal stress level privately?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '0',
                        display: 'very low'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '10',
                        display: 'very high'
                    }
                }
            ]
        },
        {
            linkId: 'atWorkStressLoad',
            prefix: 'S5',
            text:
                'In the last 7 days, how high do you rate your personal stress level professionally?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '0',
                        display: 'very low'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_en',
                        version: '1.0',
                        code: '10',
                        display: 'very high'
                    }
                }
            ]
        },
        {
            linkId: 'fearInfection',
            prefix: 'S6',
            text:
                'In the last 7 days, in general, how afraid are you of infection from SARS-CoV-2 (current coronavirus pandemic)?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'infectDelayTherapy',
            prefix: 'S7',
            text:
                'In the last 7 days, how worried are you that getting COVID-19 will result in a postponement or delay of your (chemo) therapy?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'infAndTherapyDeath',
            prefix: 'S8',
            text:
                'In the last 7 days, how afraid are you that getting COVID-19 and additional (chemo) therapy or palliative therapy will make your health worse (up to and including death)?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'infectInvestigation',
            prefix: 'S9',
            text:
                'In the last 7 days, how worried are you that additional examinations (cardiac echo, CT scan, mammogram, etc.) will increase your risk of contracting SARS-CoV-2?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'insecImageMeasure',
            prefix: 'S10',
            text:
                'In the last 7 days, how distressed are you that, because of the pandemic, imaging studies of your cancer will have to be cancelled to assess your therapy?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'insecur2ndryTherap',
            prefix: 'S11_1',
            text:
                'During the last 7 days: Regarding other drug therapies, how much do you feel insecure about this (examples: flu vaccine, pneumococcal vaccine, or other)?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'insecTherap_txt',
            prefix: 'S11_2',
            text: 'What exactly is causing you to feel insecure? Please describe.',
            type: 'string',
            required: false,
            maxLength: 1000,
            enableWhen: [
                {
                    question: 'insecur2ndryTherap',
                    operator: '>',
                    answerCoding: {
                        code: '0'
                    }
                }
            ]
        },
        {
            linkId: 'insecIsolFamily',
            prefix: 'S12',
            text:
                'In the last 7 days, how upset are you by a possible quarantine measure in terms of isolation from your family?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'worryLoneliness',
            prefix: 'S13',
            text:
                'In the last 7 days, how worried are you about loneliness in your current situation?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'vaccPriority',
            prefix: 'S14',
            text:
                'In the last 7 days, how much of a sense of purpose and opportunity do you see in the COVID vaccine and therefore want to be vaccinated?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                        version: '1.0',
                        code: '10',
                        display: 'very much'
                    }
                }
            ]
        },
        {
            linkId: 'S00',
            prefix: 'S00',
            text:
                'In the following, you will be asked about current complaints. These can be side effects of cancer therapy or symptoms due to an infection, but also complaints caused by other triggers. Please answer the following questions by selecting the answer that best describes your experience within the last 7 days.',
            type: 'display'
        },
        {
            linkId: 'S15_0',
            prefix: 'S15_0',
            text: 'PRO-CTCAE™Symptom Term: Taste changes',
            type: 'group',
            item: [
                {
                    linkId: 'tasteDisorder',
                    prefix: 'S15_1',
                    text:
                        'In the last 7 days, what was the SEVERITY of your PROBLEMS WITH TASTING FOOD OR DRINK at their WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S16_0',
            prefix: 'S16_0',
            text: 'PRO-CTCAE™Symptom Term: Shortness of breath',
            type: 'group',
            item: [
                {
                    linkId: 'shortnessOfBreath',
                    prefix: 'S16_1',
                    text:
                        'In the last 7 days, what was the SEVERITY of your SHORTNESS OF BREATH at its WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S17_0',
            prefix: 'S17_0',
            text: 'CPRO-CTCAE™Symptom Term: Concentration',
            type: 'group',
            item: [
                {
                    linkId: 'concProbl',
                    prefix: 'S17_1',
                    text:
                        'In the last 7 days, what was the SEVERITY of your PROBLEMS WITH CONCENTRATION at their WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                },
                {
                    linkId: 'concProblDailyAct',
                    prefix: 'S17_2',
                    text:
                        'In the last 7 days, how much did PROBLEMS WITH CONCENTRATION INTERFERE with your usual or daily activities?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S18_0',
            prefix: 'S18_0',
            text: 'PRO-CTCAE™Symptom Term: Muscle pain',
            type: 'group',
            item: [
                {
                    linkId: 'musclePainFreq',
                    prefix: 'S18_1',
                    text: 'In the last 7 days, how OFTEN did you have ACHING MUSCLES?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                },
                {
                    linkId: 'musclePain',
                    prefix: 'S18_2',
                    text:
                        'In the last 7 days, what was the SEVERITY of your ACHING MUSCLES at their WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                },
                {
                    linkId: 'muscPainDailyAct',
                    prefix: 'S18_3',
                    text:
                        'In the last 7 days, how much did ACHING MUSCLES INTERFERE with your usual or daily activities?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S19_0',
            prefix: 'S19_0',
            text: 'PRO-CTCAE™Symptom Term: Insomnia',
            type: 'group',
            item: [
                {
                    linkId: 'sleepProbl',
                    prefix: 'S19_1',
                    text:
                        'In the last 7 days, what was the SEVERITY of your INSOMNIA (INCLUDING DIFFICULTY FALLING ASLEEP, STAYING ASLEEP, OR WAKING UP EARLY) at its WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                },
                {
                    linkId: 'sleepProbDailyAct',
                    prefix: 'S19_2',
                    text:
                        'In the last 7 days, how much did INSOMNIA (INCLUDING DIFFICULTY FALLING ASLEEP, STAYING ASLEEP, OR WAKING UP EARLY) INTERFERE with your usual or daily activities?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S20_0',
            prefix: 'S20_0',
            text: 'PRO-CTCAE™Symptom Term: Fatigue',
            type: 'group',
            item: [
                {
                    linkId: 'fatigueExhaust',
                    prefix: 'S20_1',
                    text:
                        'In the last 7 days, what was the SEVERITY of your FATIGUE, TIREDNESS, OR LACK OF ENERGY at its WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                },
                {
                    linkId: 'fatigueExhDailyAct',
                    prefix: 'S20_2',
                    text:
                        'In the last 7 days, how much did FATIGUE, TIREDNESS, OR LACK OF ENERGY INTERFERE with your usual or daily activities?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S21_0',
            prefix: 'S21_0',
            text: 'PRO-CTCAE™Symptom Term: Anxious',
            type: 'group',
            item: [
                {
                    linkId: 'fearFreq',
                    prefix: 'S21_1',
                    text: 'In the last 7 days, how OFTEN did you feel ANXIETY?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '0',
                                display: 'never'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '1',
                                display: 'rarely'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '2',
                                display: 'occasionally'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '3',
                                display: 'frequently'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '4',
                                display: 'almost constantly'
                            }
                        }
                    ]
                },
                {
                    linkId: 'fear',
                    prefix: 'S21_2',
                    text: 'In the last 7 days, what was the SEVERITY of your ANXIETY at its WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                },
                {
                    linkId: 'fearDailyAct',
                    prefix: 'S21_3',
                    text:
                        'In the last 7 days, how much did ANXIETY INTERFERE with your usual or daily activities?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S22_0',
            prefix: 'S22_0',
            text: 'PRO-CTCAE™Symptom Term: Discouraged',
            type: 'group',
            item: [
                {
                    linkId: 'notCheerUpFreq',
                    prefix: 'S22_1',
                    text:
                        'In the last 7 days, how OFTEN did you FEEL THAT NOTHING COULD CHEER YOU UP?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '0',
                                display: 'never'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '1',
                                display: 'rarely'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '2',
                                display: 'occasionally'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '3',
                                display: 'frequently'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '4',
                                display: 'almost constantly'
                            }
                        }
                    ]
                },
                {
                    linkId: 'notCheerUp',
                    prefix: 'S22_2',
                    text:
                        'In the last 7 days, what was the SEVERITY of your FEELINGS THAT NOTHING COULD CHEER YOU UP at their WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                },
                {
                    linkId: 'notCheerUpDailyAct',
                    prefix: 'S22_3',
                    text:
                        'In the last 7 days, how much did FEELING THAT NOTHING COULD CHEER YOU UP INTERFERE with your usual or daily activities?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S23_0',
            prefix: 'S23_0',
            text: 'PRO-CTCAE™Symptom Term: Sad',
            type: 'group',
            item: [
                {
                    linkId: 'sadFreq',
                    prefix: 'S23_1',
                    text: 'In the last 7 days, how OFTEN did you have SAD OR UNHAPPY FEELINGS?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '0',
                                display: 'never'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '1',
                                display: 'rarely'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '2',
                                display: 'occasionally'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '3',
                                display: 'frequently'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_en',
                                version: '1.0',
                                code: '4',
                                display: 'almost constantly'
                            }
                        }
                    ]
                },
                {
                    linkId: 'sad',
                    prefix: 'S23_2',
                    text:
                        'In the last 7 days, what was the SEVERITY of your SAD OR UNHAPPY FEELINGS at their WORST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                },
                {
                    linkId: 'sadDailyAct',
                    prefix: 'S23_3',
                    text:
                        'In the last 7 days, how much did SAD OR UNHAPPY FEELINGS INTERFERE with your usual or daily activities?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '0',
                                display: 'none'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '1',
                                display: 'mild'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '2',
                                display: 'moderate'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '3',
                                display: 'severe'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_en',
                                version: '1.0',
                                code: '4',
                                display: 'very severe'
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
