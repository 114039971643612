import React from 'react';
import {Button, FormControlLabel, Select, Switch, IconButton} from '@material-ui/core';
import i18n, {effectiveLanguage} from '../../i18n';
import './AppSettings.css';
import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import MenuItem from '@material-ui/core/MenuItem';
// the material-ui-flags library does not contain declarations files
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import {IconFlagDE, IconFlagUS} from 'material-ui-flags';
import MenuButton from '../menu/MenuButton';
import {useSettings} from '../modelHelper/SettingsHelper';

/**
 * @param {{}} props
 * @param {PropertyTree} props.settings
 * @param {PropertyTree} props.rootPropertyTree
 * @returns {React.ReactElement}
 */
export default function AppSettings(props: {
    settings: PropertyTree;
    rootPropertyTree: PropertyTree;
}): React.ReactElement {
    async function handleChange(language: string): Promise<void> {
        await i18n.changeLanguage(language);
    }

    const [autoScroll, setAutoScroll] = useSettings(
        props.rootPropertyTree,
        'covidQuestionnaire.autoScroll',
        'true'
    );
    const [closeMenuOnItemSelect, setCloseMenuOnItemSelect] = useSettings(
        props.rootPropertyTree,
        'navigationDrawer.menuItems.closeMenuOnItemSelect',
        'false'
    );
    const [saveQuestionnaireProgress, setSaveQuestionnaireProgress] = useSettings(
        props.rootPropertyTree,
        'covidQuestionnaire.saveQuestionnaireProgress',
        'true'
    );

    const infoBoxesSettings: any[] = [];
    infoBoxesSettings.push(useSettings(props.rootPropertyTree, 'homeScreen.hideInfoBox', 'false'));
    infoBoxesSettings.push(useSettings(props.rootPropertyTree, 'journal.hideInfoBox', 'false'));
    infoBoxesSettings.push(useSettings(props.rootPropertyTree, 'news.hideInfoBox', 'false'));
    infoBoxesSettings.push(
        useSettings(props.rootPropertyTree, 'covidQuestionnaire.hideInfoBox', 'false')
    );

    function setStateOfInfoBoxes(value: string): void {
        for (let i = 0; i < infoBoxesSettings.length; i++) {
            infoBoxesSettings[i][1](value);
        }
    }

    return (
        <>
            <div className="page-container app-settings-container">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:settings.appSettings')}</h2>
                </div>
                <div className="language-option">
                    <div className="language-label">{i18n.t('common:settings.language')}</div>
                    <div className="language-dropdown flag-list">
                        <Select value={effectiveLanguage()} disableUnderline className="hide-arrow">
                            <MenuItem className="select-item" value={'de'}>
                                <IconButton onClick={() => handleChange('de')}>
                                    <IconFlagDE />
                                </IconButton>
                            </MenuItem>
                            <MenuItem className="select-item" value={'en'}>
                                <IconButton onClick={() => handleChange('en')}>
                                    <IconFlagUS />
                                </IconButton>
                            </MenuItem>
                        </Select>
                    </div>
                </div>
                <FormControlLabel
                    className={window.innerWidth < 770 ? 'hide' : 'item-app-settings'}
                    onChange={async () => {
                        if (closeMenuOnItemSelect === 'true') {
                            await setCloseMenuOnItemSelect('false');
                        } else {
                            await setCloseMenuOnItemSelect('true');
                        }
                    }}
                    control={<Switch checked={closeMenuOnItemSelect === 'true'} color="primary" />}
                    label={i18n.t('common:settings.hideMenuOnSelectItem')}
                    labelPlacement="end"
                />
                <FormControlLabel
                    className="item-app-settings"
                    onChange={async () => {
                        if (autoScroll === 'true') {
                            await setAutoScroll('false');
                        } else {
                            await setAutoScroll('true');
                        }
                    }}
                    control={<Switch color="primary" checked={autoScroll === 'true'} />}
                    label={i18n.t('common:settings.autoNavigation')}
                    labelPlacement="end"
                />
                <FormControlLabel
                    className="item-app-settings"
                    onChange={async () => {
                        if (saveQuestionnaireProgress === 'true') {
                            await setSaveQuestionnaireProgress('false');
                        } else {
                            await setSaveQuestionnaireProgress('true');
                        }
                    }}
                    control={
                        <Switch color="primary" checked={saveQuestionnaireProgress === 'true'} />
                    }
                    label={i18n.t('common:settings.saveQuestionnaireProgress')}
                    labelPlacement="end"
                />
                <div className="enable-info-screens-container">
                    <Button
                        className="enable-info-screens-button"
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                            setStateOfInfoBoxes('false');
                        }}
                    >
                        {i18n.t('common:settings.displayInfoScreens')}
                    </Button>
                </div>
            </div>
        </>
    );
}
