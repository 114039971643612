import React from 'react';
import QRCode from 'qrcode.react';
import {Button, ListItem, ListItemIcon} from '@material-ui/core';
import Clipboard from 'react-clipboard.js';
import {Prompt, Redirect, useHistory, useLocation} from 'react-router-dom';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import i18n from '../../i18n';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AnyObject} from '@OneCoreTypes';
import Paper from '@material-ui/core/Paper';
import {Icon} from '../icon/Icon';
import InfoMessage, {MessageType} from '../errors/InfoMessage';
import ConnectionsModel, {PairingInformation} from 'one.models/lib/models/ConnectionsModel';
import MenuButton from '../menu/MenuButton';
import {Alert} from '@material-ui/lab';
import {FreedaAccessGroups} from '../../model/FreedaAccessRightsManager';
import {AccessModel} from 'one.models/lib/models';
import {acceptUrl} from '../modelHelper/ConnectionsHelper';

/**
 * The instance that has received the invitation has to explicitly
 * connect to the instance that has generated the invitation.
 *
 * In case of an connection with a personal cloud device the instance
 * that has received the invitation has to first create a new instance
 * with the email received in the QR-code and after that has to initiate
 * the connection.
 * The email for the anonymous user is also sent in the invitation.
 * @param {OneInstanceModel} oneInstanceModel
 * @param {ConnectionsModel} connectionsModel
 * @param {PairingInformation} pairingInformation
 * @param {(value: string) => void} setError
 */
function connectUsingReceivedPairingInformation(
    oneInstanceModel: OneInstanceModel,
    connectionsModel: ConnectionsModel,
    pairingInformation: PairingInformation,
    setError: (value: string) => void
): void {
    const secret = oneInstanceModel.getSecret();

    if (pairingInformation.takeOver && pairingInformation.takeOverDetails) {
        oneInstanceModel
            .createNewInstanceWithReceivedEmail(
                pairingInformation.takeOverDetails.email,
                true,
                pairingInformation.takeOverDetails.anonymousEmail
            )
            .then(() => {
                connectToOtherInstanceUsingPairingInformation(
                    connectionsModel,
                    pairingInformation,
                    secret,
                    setError
                );
            })
            .catch((err: Error) => {
                setError(err.message);
            });
    } else {
        connectToOtherInstanceUsingPairingInformation(
            connectionsModel,
            pairingInformation,
            secret,
            setError
        );
    }
}

/**
 * Initiates the connection with the instance that has generated the invite.
 * @param {ConnectionsModel} connectionsModel
 * @param {PairingInformation} pairingInformation
 * @param {string} secret
 * @param {(value: string) => void} setError
 */
function connectToOtherInstanceUsingPairingInformation(
    connectionsModel: ConnectionsModel,
    pairingInformation: PairingInformation,
    secret: string,
    setError: (value: string) => void
): void {
    connectionsModel
        .connectUsingPairingInformation(pairingInformation, secret)
        .catch((e: Error) => {
            setError(e.message);
        });
}

/**
 * When the state of the connection changes update the UI.
 *
 * @param {ConnectionsModel} connectionsModel
 * @param {PairingInformation} pairingInformation
 * @returns {boolean}
 */
function useConnectionEstablishment(
    connectionsModel: ConnectionsModel,
    pairingInformation: PairingInformation
): boolean {
    const [connectionEstablished, setConnectionEstablished] = React.useState<boolean>(false);

    React.useEffect(() => {
        function connectionFinished(authenticationTocken: string): void {
            if (authenticationTocken === pairingInformation.authenticationTag) {
                setConnectionEstablished(true);
            }
        }
        connectionsModel.on('one_time_auth_success', connectionFinished);

        return () => {
            connectionsModel.removeListener('one_time_auth_success', connectionFinished);
        };
    }, [connectionsModel, pairingInformation]);

    return connectionEstablished;
}

/**
 * @param {{}} props
 * @param {ConnectionsModel} props.connectionsModel
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {AccessModel} props.accessModel
 * @returns {React.ReactElement}
 */
export default function CreateAcceptInvites(props: {
    connectionsModel: ConnectionsModel;
    oneInstanceModel: OneInstanceModel;
    accessModel: AccessModel;
}): React.ReactElement {
    const location = useLocation();
    const takeOver = location.pathname.includes('personalCloud');

    return (
        <>
            {location.search.includes('invited=true') ? (
                <AcceptInvite
                    connectionsModel={props.connectionsModel}
                    connectInformation={JSON.parse(decodeURIComponent(location.hash.substring(1)))}
                    oneInstanceModel={props.oneInstanceModel}
                    takeOver={takeOver}
                    accessModel={props.accessModel}
                />
            ) : (
                <DisplayInvite
                    connectionsModel={props.connectionsModel}
                    oneInstanceModel={props.oneInstanceModel}
                    takeOver={takeOver}
                />
            )}
        </>
    );
}

function DisplayInvite(props: {
    connectionsModel: ConnectionsModel;
    oneInstanceModel: OneInstanceModel;
    takeOver: boolean;
}): React.ReactElement {
    const [displayError, setDisplayError] = React.useState(false);
    const [generateNewInvite, setGenerateNewInvite] = React.useState(true);
    const [counter, setCounter] = React.useState(
        props.connectionsModel.authTokenExpirationDuration
    );
    const [displayTimeout, setDisplayTimeout] = React.useState('00:00');
    const [pairingInformation, setPairingInformation] = React.useState<PairingInformation>(
        {} as PairingInformation
    );
    const [inviteExpired, setInviteExpired] = React.useState(false);
    const [displayPopup, setDisplayPopup] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const location = useLocation();

    React.useEffect(() => {
        return () => props.connectionsModel.invalidateCurrentInvitation(pairingInformation);
    }, []);

    /**
     * If the counter is greater than 0, then decrease it at every second and compute
     * the displayed value by converting from milliseconds to minutes and seconds.
     *
     * If the counter has reached 0 then display the expiration warning in the page.
     */
    React.useEffect(() => {
        function computeDisplayedTimeoutValue(): void {
            const minutes = Math.floor(counter / 60000);
            const seconds = (counter % 60000) / 1000;
            setDisplayTimeout(`${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`)
        }

        let counter_id: NodeJS.Timeout;

        if (counter > 0) {
            counter_id = setTimeout(() => setCounter(counter - 1000), 1000);
        } else {
            setInviteExpired(true);
        }
        computeDisplayedTimeoutValue();

        return () => {
            clearInterval(counter_id);
            clearTimeout(counter_id);
        };
    }, [counter, inviteExpired, displayTimeout]);

    /**
     * If the user tries to navigate out from the generated invitation page a popup will appear
     * to inform him that the invitation expires if he leaves
     */
    React.useEffect(() => {
        setDisplayPopup(true);
    }, [location.pathname]);

    /**
     * When the generate new invite state is enabled, invalidate the current displayed invite,
     * generate a new invite, remove the prevoiusly counter and reinitialise the counter with
     * the expiration value of the current displayed invite.
     */
    React.useEffect(() => {
        function fetchNewPairingInformation(): void {
            props.connectionsModel
                .generatePairingInformation(props.takeOver)
                .then((pi: PairingInformation) => {
                    setPairingInformation(pi);
                })
                .catch((e: Error) => {
                    setErrorMessage(e.message);
                });
        }

        if (generateNewInvite) {
            // when creating a new invitation the old one becomes invalid
            props.connectionsModel.invalidateCurrentInvitation(pairingInformation);
            fetchNewPairingInformation();
            setGenerateNewInvite(false);
            setInviteExpired(false);

            // reset the counter to the initial value
            setCounter(props.connectionsModel.authTokenExpirationDuration);
        }
    }, [props.connectionsModel, props.takeOver, generateNewInvite, counter]);

    const encryptedInformationString = JSON.stringify(pairingInformation);
    const encodedInformation = encodeURIComponent(encryptedInformationString);
    const urlForAccept = acceptUrl(encodedInformation, props.takeOver);
    const connectionEstablished = useConnectionEstablishment(
        props.connectionsModel,
        pairingInformation
    );

    const qrCodePageType = props.takeOver
        ? 'PersonalCloud'
        : props.oneInstanceModel.patientTypeState().includes('partner')
        ? 'Partner'
        : 'Patient';

    if (connectionEstablished) {
        props.oneInstanceModel.unregister();
        return <Redirect to="/connections" />;
    }

    if (errorMessage) {
        setDisplayError(true);
    }

    return (
        <>
            <React.Fragment>
                <Prompt
                    when={displayPopup}
                    message={i18n.t('errors:connection.leaveInvitationPageWarning')}
                />
            </React.Fragment>
            <div className="page-container">
                {inviteExpired ? (
                    <Paper
                        square
                        elevation={3}
                        className="stick-message-top message-font-size warning-color"
                    >
                        <Alert severity="warning">{i18n.t('connections:invitationExpired')}</Alert>
                    </Paper>
                ) : (
                    <></>
                )}
                {displayError ? (
                    <InfoMessage
                        errorMessage={errorMessage}
                        displayMessage={displayError}
                        setDisplayMessage={setDisplayError}
                        messageType={MessageType.Error}
                    />
                ) : (
                    <></>
                )}
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t(`connections:qr${qrCodePageType}.title`)}</h2>
                </div>
                <Paper square elevation={3} className="page-content-box">
                    <div className="qr-code">
                        <Clipboard component="div" data-clipboard-text={urlForAccept}>
                            <QRCode value={urlForAccept} size={200} />
                        </Clipboard>
                    </div>
                    <div className="qr-code-details">
                        <div className="refresh-countdown">
                            <div className="counter">{displayTimeout}</div>
                            <Button
                                onClick={() => {
                                    setGenerateNewInvite(true);
                                }}
                            >
                                <Icon name="Refresh" />
                            </Button>
                        </div>
                        <div className="paper-font-size">
                            <br />
                            {i18n.t(`connections:qr${qrCodePageType}.details`)}
                            <br />
                            {i18n.t(`connections:qr${qrCodePageType}.copy`)}
                        </div>
                        <Clipboard component="div" data-clipboard-text={urlForAccept}>
                            <ListItem key="fileCopy" button className="copyIcon">
                                <ListItemIcon>
                                    <Icon name="FileCopy" />
                                </ListItemIcon>
                            </ListItem>
                        </Clipboard>
                    </div>
                </Paper>
            </div>
        </>
    );
}

function AcceptInvite(props: {
    connectionsModel: ConnectionsModel;
    connectInformation: AnyObject;
    oneInstanceModel: OneInstanceModel;
    accessModel: AccessModel;
    takeOver: boolean;
}): React.ReactElement {
    const history = useHistory();

    const [errorMessage, setErrorMessage] = React.useState('');
    const [connectionType, setConnectionType] = React.useState('');

    /**
     * Automatically accept the invite without using the invite button and set
     * the connection type based on the URL invite
     */
    React.useEffect(() => {
        history.location.pathname.includes('/personalCloud')
            ? setConnectionType('IoM')
            : setConnectionType('partner');
        connectToOtherInstance();
    }, []);

    /**
     * If a new connection is fetched and the invitation between the two worked and they will be redirected to connections page
     */
    React.useEffect(() => {
        function navigateToConnections(): void {
            props.oneInstanceModel.unregister();
            history.push('/connections');
        }

        async function fetchConnections(): Promise<void> {
            const allConnections = props.connectionsModel.connectionsInfo();

            if (!allConnections || allConnections.length < 1) {
                return;
            }

            const clinicGroup = (
                await props.accessModel.getAccessGroupByName(FreedaAccessGroups.clinic)
            ).obj.person;

            const lastConnected = allConnections.reverse()[0];

            // check against the replicant which is already connected as a partner in allConnections
            if (lastConnected.isConnected && !clinicGroup.includes(lastConnected.targetPersonId)) {
                if (!errorMessage) {
                    navigateToConnections();
                }
            }
        }

        props.connectionsModel.on('connectionsChange', fetchConnections);
        fetchConnections();

        return () => {
            props.connectionsModel.removeListener('connectionsChange', fetchConnections);
        };
    }, [props.connectionsModel]);

    /**
     * If an error is caught appBrokeOnInvite is set into localStorage for the error page
     * to appear and user is automatically logged out with the type of connection
     */
    React.useEffect(() => {
        if (errorMessage) {
            localStorage.setItem('appBrokeOnInvite', connectionType);
            window.location.reload();
        }
    }, [errorMessage]);

    /**
     * If connection succeeded loading is set to false and
     * if there is no error message will redirect to connection page
     */

    function connectToOtherInstance(): void {
        const pairingInformation = {
            authenticationTag: props.connectInformation.authenticationTag,
            publicKeyLocal: props.connectInformation.publicKeyLocal,
            takeOver: props.connectInformation.takeOver,
            url: props.connectInformation.url,
            takeOverDetails: props.connectInformation.takeOverDetails
        };

        connectUsingReceivedPairingInformation(
            props.oneInstanceModel,
            props.connectionsModel,
            pairingInformation,
            setErrorMessage
        );
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
        </>
    );
}
