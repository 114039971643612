import React, {useEffect, useState} from 'react';
import './Journal.css';
import '../../Primary.css';
import JournalModel, {EventListEntry, EventType} from 'one.models/lib/models/JournalModel';
import {useEventList, getCurrentEventInformation} from '../modelHelper/JournalHelper';
import {Divider, ListItem, ListItemIcon, ListItemText, CircularProgress} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import * as dateFns from 'date-fns';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import {ObjectData} from 'one.models/lib/models/ChannelManager';
import CovidWorkflowModel from '../../model/CovidWorkflowModel';
import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import {useSettings} from '../modelHelper/SettingsHelper';
import {DiaryEntry} from 'one.models/lib/models/DiaryModel';
import MenuButton from '../menu/MenuButton';
import {formatDate, hideCircularProgress} from '../utils/Utils';
import {QuestionnaireResponses} from '@OneCoreTypes';

/*
 type definition of the layout of elements of settings  menu
 */
export type EventTypes = {
    type: EventType;
    name: string;
    icon?: React.ReactElement;
};

/**
 * This component builds and returns the events list view.
 * @param {{}} props -View properties
 * @param {JournalModel} props.journalModel
 * @param {EventTypes} props.eventTypes
 * @param {boolean} props.showFilterButtons
 * @param {CovidWorkflowModel} props.covidWorkflowModel
 * @param {PropertyTree} props.settings
 * @param {boolean} props.isPartnerApp - Set to true if view should be rendered for partner app.
 * @returns {React.ReactElement} - Entire event list view
 */
export default function Journal(props: {
    journalModel: JournalModel;
    eventTypes: EventTypes[];
    covidWorkflowModel: CovidWorkflowModel;
    settings: PropertyTree;
    isPartnerApp: boolean;
}): React.ReactElement {
    const history = useHistory();

    // component specific setting for info boxes
    const [hideInfoBox, setHideInfoBox] = useSettings(props.settings, 'hideInfoBox', 'false');

    const allEventsList = useEventList(props.journalModel);
    const [currentEventsList, setCurrentEventsList] = useState(allEventsList);
    const [viewEmptyJournal, setViewEmptyJournal] = useState(true);
    useEffect(() => {
        setCurrentEventsList(allEventsList);

        if (allEventsList.length) {
            hideCircularProgress();
        }
    }, [allEventsList]);

    /**
     * This method builds the body in which events are listed. The events are grouped by day and each one of them contains the following components:
     * - the time when the event was created
     * - the specific icon for each type
     * - a brief description of the event
     *
     * @param {EventListEntry[]} eventsList - The list of events
     * @param {EventTypes[]} eventTypes - The list of event types
     * @returns {React.ReactElement} - The body of events list
     */
    function buildJournal(
        eventsList: EventListEntry[],
        eventTypes: EventTypes[]
    ): React.ReactElement {
        const displayedEventsList = [];

        if (eventsList.length) {
            let date = eventsList[eventsList.length - 1].data.creationTime;

            for (let i = 0; i < eventsList.length; i++) {
                const currentEventInfo = getCurrentEventInformation(eventsList[i], eventTypes);

                if (!dateFns.isSameDay(eventsList[i].data.creationTime, date) || i === 0) {
                    date = eventsList[i].data.creationTime;

                    // to not draw the divider for the first date
                    if (i !== 0) {
                        displayedEventsList.push(<Divider key={'divider_' + i.toString()} />);
                    }

                    displayedEventsList.push(
                        <div key={'time_' + i.toString()}>
                            <ListItem button key={date.toString()} className="journal-event-date">
                                <ListItemText>{formatDate(date)}</ListItemText>
                            </ListItem>
                        </div>
                    );
                }

                // TODO: Add a brief description for each event
                displayedEventsList.push(
                    <ListItem
                        button
                        className="journal-entry"
                        key={'event_' + i.toString()}
                        onClick={() => {
                            switch (true) {
                                case currentEventInfo.type.includes(
                                    i18n.t('common:settings.ConsentFile')
                                ):
                                    history.push('participantInformation');
                                    break;
                                case currentEventInfo.type.includes(
                                    i18n.t('common:questionnaire.I')
                                ):
                                case currentEventInfo.type.includes(
                                    i18n.t('common:menu.questionnaire')
                                ):
                                    history.push(
                                        'covidQuestionnaireView/view?response=' +
                                            (eventsList[i].data as ObjectData<
                                                QuestionnaireResponses
                                            >).id
                                    );
                                    break;
                                case currentEventInfo.type.includes(
                                    i18n.t('common:settings.DropoutFile')
                                ):
                                    history.push('/dropoutFileView');
                                    break;
                                case currentEventInfo.type.includes(
                                    i18n.t('common:eventTypes.diaryEntry')
                                ):
                                    history.push(
                                        '/diary/view/' +
                                            (eventsList[i].data as ObjectData<DiaryEntry>).id
                                    );
                                    break;
                            }
                        }}
                    >
                        <ListItemText className="eventTime">
                            {currentEventInfo.hour}
                            {':'}
                            {currentEventInfo.minutes}
                        </ListItemText>
                        <ListItemIcon>{currentEventInfo.icon}</ListItemIcon>
                        <ListItemText>{currentEventInfo.type}</ListItemText>
                    </ListItem>
                );
            }
        } else {
            displayedEventsList.push(
                <div key={'emptyJournal'}>
                    {viewEmptyJournal && (
                        <Paper square elevation={3} className="stick-message-top">
                            <Alert
                                onClose={() => setViewEmptyJournal(false)}
                                className="patient-info"
                                severity="info"
                                key="alert"
                            >
                                <AlertTitle className="message-title">
                                    {i18n.t('errors:titleInfo')}
                                </AlertTitle>
                                {i18n.t('common:journal.emptyJournal')}
                            </Alert>
                        </Paper>
                    )}
                </div>
            );
        }

        return <>{displayedEventsList}</>;
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container hide">
                {hideInfoBox === 'false' && (
                    <Paper square elevation={3} className="stick-message-top">
                        <Alert
                            onClose={async () => {
                                await setHideInfoBox('true');
                            }}
                            className="patient-info"
                            severity="info"
                            key="alert"
                        >
                            <AlertTitle className="message-title">
                                {i18n.t('errors:titleInfo')}
                            </AlertTitle>
                            {props.isPartnerApp
                                ? i18n.t('common:journal.journalInfoPartner')
                                : i18n.t('common:journal.journalInfo')}
                        </Alert>
                    </Paper>
                )}
                <div>
                    <div className="menu-button-header">
                        <MenuButton />
                        <h2 className="headline"> {i18n.t('common:menu.journal')}</h2>
                    </div>
                    {currentEventsList.length !== 0 && (
                        <Paper square elevation={3} className="page-content-box">
                            {buildJournal(currentEventsList, props.eventTypes)}
                        </Paper>
                    )}
                </div>
            </div>
        </>
    );
}
