import React from 'react';
import OneInstanceModel, {AuthenticationState} from 'one.models/lib/models/OneInstanceModel';

/**
 * Provides the current authentication state from the OneIntsnaceModel
 *
 * @param {OneInstanceModel} oneInstanceModel - The one instance model
 * @returns {AuthenticationState} the current authentication state
 */
export function useAuthenticationState(oneInstanceModel: OneInstanceModel): AuthenticationState {
    const [authenticationState, setAuthenticationState] = React.useState();

    React.useEffect(() => {
        function fetchCurrentAuthenticationState(): void {
            setAuthenticationState(oneInstanceModel.authenticationState());
        }

        oneInstanceModel.on('authstate_changed', fetchCurrentAuthenticationState);
        fetchCurrentAuthenticationState();

        return () => {
            oneInstanceModel.removeListener('authstate_changed', fetchCurrentAuthenticationState);
        };
    }, [oneInstanceModel]);

    return authenticationState;
}

/**
 * Provides the current registration state from the OneInstanceModel
 *
 * @param {OneInstanceModel} oneInstanceModel
 * @returns {boolean}
 */
export function useRegistrationState(oneInstanceModel: OneInstanceModel): boolean {
    const [registrationState, setRegistrationState] = React.useState();

    React.useEffect(() => {
        function fetchCurrentRegistrationState(): void {
            setRegistrationState(oneInstanceModel.registrationState());
        }

        oneInstanceModel.on('registration_state_changed', fetchCurrentRegistrationState);
        fetchCurrentRegistrationState();

        return () => {
            oneInstanceModel.removeListener(
                'registration_state_changed',
                fetchCurrentRegistrationState
            );
        };
    }, [oneInstanceModel]);

    return registrationState;
}
