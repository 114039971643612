import {QuestionType} from 'one.models/lib/models/QuestionTypes';

const QuestionnaireEQ5D3LDE = {
    identifier: 'EQ5D3L_DE',
    item: [
        {
            questionIdentifier: 'mobility',
            question: 'Ihre Mobilität HEUTE',
            questionType: QuestionType.String
        },
        {
            questionIdentifier: 'selfCare',
            question: 'Ihre Selbstpflege HEUTE',
            questionType: QuestionType.String
        },
        {
            questionIdentifier: 'usualActivities',
            question: 'Ihre üblichen Aktivitäten HEUTE',
            questionType: QuestionType.String
        },
        {
            questionIdentifier: 'pain',
            question: 'Ihre Schmerzen / Beschwerden HEUTE',
            questionType: QuestionType.String
        },
        {
            questionIdentifier: 'anxiety',
            question: 'Ihre Angst / Depression HEUTE',
            questionType: QuestionType.String
        },
        {
            questionIdentifier: 'healthState',
            question: 'YOUR HEALTH TODAY',
            questionType: QuestionType.String
        }
    ]
};

export default QuestionnaireEQ5D3LDE;
