import * as dateFns from 'date-fns';
import i18n from '../../i18n';
import React from 'react';

/**
 * Check if the content is larger than the window size.
 *
 * This functions calculates the window height without the footer and
 * checks if the height of the element received as parameter is larger
 * than the computed content height.
 *
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function contentAreaLargerThanDisplayArea(element: HTMLElement): boolean {
    // Since the recovery view was changed we need to handle it as an individual case.
    // We have to ensure that the button and the text are positioned properly.
    // In order to achieve that we have to subtract from the height of the visible .md content
    // the height of the button and the height of the text.
    const recoveryText = document.getElementsByClassName('recovery-text')[0];
    const recoveryButton = document.getElementsByClassName('recovery-button')[0];

    let textAndButtonHeight = 0;

    if (recoveryText) {
        textAndButtonHeight = recoveryText.clientHeight;
    }

    if (recoveryButton) {
        const recoveryButtonStyle = window.getComputedStyle(recoveryButton);
        const paddingTop = parseInt(recoveryButtonStyle.getPropertyValue('padding-top'), 10);
        const paddingBottom = parseInt(recoveryButtonStyle.getPropertyValue('padding-bottom'), 10);
        textAndButtonHeight += recoveryButton.clientHeight + paddingTop + paddingBottom;
    }

    const pageHeightWithoutFooter =
        window.innerHeight -
        document.getElementsByTagName('footer')[0].clientHeight -
        textAndButtonHeight;

    if (document.getElementsByClassName('register-stepper-container')[0] === undefined) {
        return (
            pageHeightWithoutFooter < element.children[0].scrollHeight ||
            element.children[0].clientHeight < element.children[0].scrollHeight
        );
    } else {
        if (
            document.getElementsByClassName('register-stepper-buttons-container')[0] ===
                undefined ||
            document.getElementsByClassName('registration-stepper')[0] === undefined
        ) {
            return false;
        }

        return (
            pageHeightWithoutFooter -
                document.getElementsByClassName('register-stepper-buttons-container')[0]
                    .clientHeight -
                document.getElementsByClassName('registration-stepper')[0].clientHeight <
            element.children[0].scrollHeight
        );
    }
}

/**
 * Add css class for scrollbar for the parent of the element received as argument.
 *
 * @param {HTMLElement} element
 */
export function addScrollbar(element: HTMLElement): void {
    if (element.parentElement) {
        element.parentElement.classList.add('is-scrollable');
    }
}

/**
 * Remove css class for scrollbar for the parent of the element received as argument.
 *
 * @param {HTMLElement} element
 */
export function removeScrollbar(element: HTMLElement): void {
    if (element.parentElement) {
        element.parentElement.classList.remove('is-scrollable');
    }
}

/**
 * Checks if scroll has reached the bottom.
 *
 * @param {HTMLElement} element
 */
export function verifyIfBottomWasReached(element: HTMLElement): boolean {
    /**
     * +1 because Math.round will round the scrollTop down
     */
    return (
        Math.round(element.children[0].scrollHeight) -
            Math.round(element.children[0].scrollTop + 1) <=
        Math.round(element.children[0].clientHeight)
    );
}

/**
 * Check if an standalone application is used
 *
 * @returns {boolean}
 */
export function isStandalone(): boolean {
    // ts-ignore needed because window.navigator.standalone is available only for safari
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isInStandalone = window.navigator.standalone;

    return !!isInStandalone;
}

/**
 * Creates the dynamic manifest, setting the start_url at current window location. (Used for STH)
 *
 * @returns {void}
 */
export function generateDynamicManifest(): void {
    const dynamicManifest = {
        name: 'freeda',
        short_name: 'freeda',
        scope: window.location.origin,
        start_url:
            window.location.origin +
            window.location.pathname +
            window.location.search +
            window.location.hash,
        display: 'standalone',
        theme_color: '#000000',
        background_color: '#ffffff',
        icons: [
            {
                src: '../../../resources/one.svg',
                sizes: '64x64 32x32 24x24 16x16',
                type: 'image/svg'
            },
            {
                src: '../../../resources/one.svg',
                type: 'image/svg',
                sizes: '192x192'
            },
            {
                src: '../../../resources/one.svg',
                type: 'image/svg',
                sizes: '512x512'
            }
        ]
    };
    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    const htmlTag = document.querySelector('#dynamic-manifest');

    if (htmlTag) {
        htmlTag.setAttribute('href', manifestURL);
    }
}

/**
 * Display the loading wheel in the page.
 *
 * The loading wheel should always have the circular-progress-container class
 * and the page content should have the page-container class (or for registration
 * pages the registration-stepper-wrapper class is also considered).
 *
 * When the loading wheel is displayed the page content is hidden.
 */
export function displayCircularProgress(): void {
    const pageContainer = document.getElementsByClassName('page-container')[0];
    const registrationContainer = document.getElementsByClassName(
        'registration-stepper-wrapper'
    )[0];

    if (pageContainer) {
        pageContainer.classList.add('hide');
    }

    /* The stepper-wrapper has a different class from the "parent-container" class,
    because it must be present in the DOM. Its size is needed to add/remove the scrollbar.*/
    if (registrationContainer) {
        registrationContainer.classList.add('not-visible');
    }

    if (pageContainer || registrationContainer) {
        const circularProgressBar = document.getElementsByClassName(
            'circular-progress-container'
        )[0];

        if (circularProgressBar && circularProgressBar.classList.contains('hide')) {
            circularProgressBar.classList.remove('hide');
        }
    }
}

/**
 * Hide the loading wheel.
 *
 * The loading wheel should always have the circular-progress-container class
 * and the page content should have the page-container class (or for registration
 * pages the registration-stepper-wrapper class is also considered).
 *
 * When the page content is displayed the loading wheel is hidden.
 */
export function hideCircularProgress(): void {
    const circularProgressBar = document.getElementsByClassName('circular-progress-container')[0];

    if (circularProgressBar) {
        circularProgressBar.classList.add('hide');

        const pageContainer = document.getElementsByClassName('page-container')[0];
        const registrationContainer = document.getElementsByClassName(
            'registration-stepper-wrapper'
        )[0];

        if (pageContainer && pageContainer.classList.contains('hide')) {
            pageContainer.classList.remove('hide');
        }

        /* The stepper-wrapper has a different class from the "parent-container" class,
        because it must be present in the DOM. Its size is needed to add/remove the scrollbar.*/
        if (registrationContainer && registrationContainer.classList.contains('not-visible')) {
            registrationContainer.classList.remove('not-visible');
        }
    }
}

/**
 *  Listen for window resize event and check if the scrollbar is needed
 *  after resize or it the scrollbar should be removed after resize.
 *
 *  @param {HTMLElement} element
 */
export function updateScrollbarAtWindowResize(element: HTMLElement): void {
    window.onresize = () => {
        if (contentAreaLargerThanDisplayArea(element)) {
            addScrollbar(element);
        } else {
            removeScrollbar(element);
        }
    };
}

/**
 * Global internationalized formatter for a date.
 * @param {Date} date - the date that is formatted.
 * @returns {string} - formatted date.
 */
export function formatDate(date: Date | number | undefined): string {
    if (date) {
        return (
            dateFns.format(date, 'dd') +
            ' ' +
            i18n.t(`common:months.${dateFns.format(date, 'MMMM')}`) +
            ' ' +
            dateFns.format(date, 'yyyy')
        );
    }

    return '';
}

/**
 * Custom hook for getting the size of the window.
 * @returns {number[]} - width and height of the window.
 */
export function useWindowSize(): number[] {
    const [size, setSize] = React.useState([0, 0]);
    React.useLayoutEffect(() => {
        function updateSize(): void {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
