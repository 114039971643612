import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import OneInstanceModel, {LogoutMode} from 'one.models/lib/models/OneInstanceModel';
import Loading from '../loading/Loading';

/**
 * logs out the user
 * @param {{}} props
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {Function} props.setNavigationDrawer
 * @returns {React.ReactElement}
 */
export default function Logout(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawer: Function;
}): React.ReactElement {
    const history = useHistory();

    useEffect(() => {
        props.setNavigationDrawer(false);
        props.oneInstanceModel
            .logout(LogoutMode.KeepData)
            .then(() => {
                history.push('/');
            })
            .catch(err => console.error(err));
    }, []);

    return <Loading />;
}
