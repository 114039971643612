import InstancesModel from 'one.models/lib/models/InstancesModel';
import {Instance, SHA256IdHash} from '@OneCoreTypes';
import i18n from '../../i18n';
import React from 'react';

/**
 * Extract the current anonymous instance id.
 *
 * @param {InstancesModel} instancesModel
 * @param {Function} setErrorCallback
 * @returns {{ mainInstanceId: SHA256IdHash<Instance> | undefined,
 *             anonymousInstanceId: SHA256IdHash<Instance> | undefined}}
 */
export function useCurrentAnonInstanceId(
    instancesModel: InstancesModel,
    setErrorCallback: (value: string) => void
): {
    mainInstanceId: SHA256IdHash<Instance> | undefined;
    anonymousInstanceId: SHA256IdHash<Instance> | undefined;
} {
    const [anonymousInstanceId, setAnonymousInstanceId] = React.useState<SHA256IdHash<Instance>>();
    const [mainInstanceId, setMainInstanceId] = React.useState<SHA256IdHash<Instance>>();

    React.useEffect(() => {
        instancesModel
            .localInstancesInfo()
            .then(infos => {
                if (infos.length !== 2) {
                    setErrorCallback(i18n.t('errors:unknownError'));
                }

                infos.forEach(instanceInfo => {
                    if (instanceInfo.isMain) {
                        setMainInstanceId(instanceInfo.instanceId);
                    } else {
                        setAnonymousInstanceId(instanceInfo.instanceId);
                    }
                });
            })
            .catch(err => setErrorCallback(err));
    });

    return {
        mainInstanceId: mainInstanceId,
        anonymousInstanceId: anonymousInstanceId
    };
}
