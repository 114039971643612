import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import './AutocompleteQuestionnaires.css';
import '../../Primary.css';
import {
    Box,
    Button,
    createStyles,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Slider,
    Theme,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';
import '../journal/Journal.css';
import Paper from '@material-ui/core/Paper';
import BugReportIcon from '@material-ui/icons/BugReport';
import QuestionnaireResponseBuilder, {
    QuestionnaireResponseBuilderItem
} from '../questionnaireCovid/QuestionnaireResponseBuilder';
import {AccessModel, ChannelManager, QuestionnaireModel} from 'one.models/lib/models';
import {Person, SHA256IdHash, VersionedObjectResult} from '@OneCoreTypes';
import {QuestionnaireValue} from 'one.models/lib/models/QuestionnaireModel';
import {createRandomString} from 'one.core/lib/system/crypto-helpers';
import {
    createSingleObjectThroughImpurePlan,
    createSingleObjectThroughPurePlan,
    getObjectByIdHash,
    SET_ACCESS_MODE,
    VERSION_UPDATES
} from 'one.core/lib/storage';
import {calculateIdHashOfObj} from 'one.core/lib/util/object';
import {FreedaAccessGroups} from '../../model/FreedaAccessRightsManager';
import MenuButton from '../menu/MenuButton';
import i18n from '../../i18n';

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 10,
            borderRadius: 5
        },
        colorPrimary: {
            backgroundColor: '#ffc677'
        },
        bar: {
            borderRadius: 5,
            backgroundColor: '#ffffff'
        }
    })
)(LinearProgress);

const questionnairesMarks = [
    {
        value: 1,
        label: 'I/VB/VS'
    },
    {
        value: 3,
        label: 'T,S,IM'
    },
    {
        value: 4,
        label: 'T,S,IM,I'
    },
    {
        value: 5,
        label: 'B,BI,BT,S,IM'
    },
    {
        value: 6,
        label: 'B,BI,BT,S,IM,I'
    }
];

/**
 * @param props
 * @param props.questionnaireModel
 * @param props.channelManager
 * @param props.accessModel
 * @class
 */
export default function AutocompleteQuestionnaires(props: {
    questionnaireModel: QuestionnaireModel;
    channelManager: ChannelManager;
    accessModel: AccessModel;
}): React.ReactElement {
    const [questionnairesPerPersonCount, setQuestionnairesPerPersonCount] = useState(1);
    const [personsCount, setPersonsCount] = useState(1);

    const [isGenerating, setIsGenerating] = useState(false);
    const [completedQuestionnaires, setCompletedQuestionnaires] = useState(0);
    const [logs, setLogs] = useState<string[]>([]);
    const logsEndRef = useRef<null | HTMLDivElement>(null);

    /**
     * generates a random date between 1970 - 2021
     * @param start
     * @param end - //'02/13/2013', '01/01/2000'
     */
    function randomDate(
        start: string = '01/01/1970',
        end: string = new Date().toLocaleDateString()
    ): string {
        function randomValueBetween(min: number, max: number): number {
            return Math.random() * (max - min) + min;
        }

        const date1: number = new Date(start || '01-01-1970').getTime();
        const date2: number = new Date(end || new Date().toLocaleDateString()).getTime();

        if (date1 > date2) {
            return new Date(randomValueBetween(date2, date1)).toISOString().slice(0, 10);
        } else {
            return new Date(randomValueBetween(date1, date2)).toISOString().slice(0, 10);
        }
    }

    /**
     * This generates a random question's answer by a given {@link QuestionnaireResponseBuilderItem}
     * @param question
     */
    function generateRandomQuestionAnswer(
        question: QuestionnaireResponseBuilderItem
    ): QuestionnaireValue[] {
        switch (question.question.type) {
            case 'display':
            case 'group':
                return [{valueString: Math.random().toString(36).substring(7)}];
            case 'question':
                throw new Error('Case not implemented in generateRandomQuestionAnswer()');
            case 'boolean':
                return [{valueBoolean: false}];
            case 'decimal':
                if (question.question.maxLength) {
                    return [
                        {
                            valueInteger: (Math.random() * question.question.maxLength).toString()
                        }
                    ];
                }
                throw new Error(
                    'question.question.maxLength got undefined in generateRandomQuestionAnswer()'
                );
            case 'integer':
                if (question.question.maxLength) {
                    return [
                        {
                            valueInteger: Math.floor(
                                Math.random() * question.question.maxLength
                            ).toString()
                        }
                    ];
                }
                throw new Error(
                    'question.question.maxLength got undefined in generateRandomQuestionAnswer()'
                );
            case 'choice':
                if (question.question.answerOption) {
                    return [
                        question.question.answerOption[
                            Math.floor(question.question.answerOption.length * Math.random()) | 0
                        ]
                    ];
                }
                break;
            case 'date':
                return [{valueDate: randomDate()}];
            case 'dateTime':
            case 'time':
            case 'string':
            case 'text':
            case 'url':
            case 'open-choice':
                // eslint-disable-next-line no-case-declarations
                const answerCodings = [];

                if (question.question.answerOption) {
                    for (const answerCoding of question.question.answerOption.values()) {
                        if (answerCoding.valueCoding) {
                            answerCodings.push(answerCoding);
                        }
                    }
                }

                if (answerCodings.length > 0) {
                    const toBeOrNotToBe = [true, false];

                    /** in case there is only the unknown option **/
                    if (Math.floor(toBeOrNotToBe.length * Math.random())) {
                        return [
                            answerCodings[Math.floor(answerCodings.length * Math.random()) | 0]
                        ];
                    }
                }

                return [{valueString: Math.random().toString(36).substring(7)}];
            case 'attachment':
                throw new Error('Case not implemented in generateRandomQuestionAnswer()');
            case 'reference':
                throw new Error('Case not implemented in generateRandomQuestionAnswer()');
            case 'quantity':
                throw new Error('Case not implemented in generateRandomQuestionAnswer()');
            case 'slider':
                return [
                    {
                        valueCoding: {
                            system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_en',
                            version: '1.0',
                            code: Math.floor(Math.random() * 10).toString()
                        }
                    }
                ];
        }
        throw new Error('Unknown case in generateRandomQuestionAnswer()');
    }

    /**
     * This creates a random person and his questionnaire response channel. After that, it
     * sets access to his channel for the clinic.
     */
    async function generatePersonEntity(): Promise<SHA256IdHash<Person>> {
        let t0 = performance.now();

        /** create the person object by a random email **/
        const email = await createRandomString(64);
        const person: VersionedObjectResult<Person> = (await createSingleObjectThroughImpurePlan(
            {
                module: '@one/identity',
                versionMapPolicy: {'*': VERSION_UPDATES.NONE_IF_LATEST}
            },
            {
                $type$: 'Person',
                email: email
            }
        )) as VersionedObjectResult<Person>;
        let t1 = performance.now();

        setLogs(oldArray => [
            ...oldArray,
            `${getLogTime()} - Person ${person.idHash} has been created.(${Math.round(t1 - t0)}ms)`
        ]);
        t0 = performance.now();

        /** create the channel for the particular person id **/
        await createSingleObjectThroughPurePlan(
            {module: '@module/channelCreate'},
            'questionnaireResponse',
            person.idHash
        );
        t1 = performance.now();

        setLogs(oldArray => [
            ...oldArray,
            `${getLogTime()} - Questionnaire response channel for ${
                person.idHash
            } has been created.(${Math.round(t1 - t0)}ms)`
        ]);
        t0 = performance.now();

        /** get the clinic instance contact **/
        const clinicContacts = await props.accessModel.getAccessGroupPersons(
            FreedaAccessGroups.clinic
        );
        /** create access to his QuestionnaireResponses Channel for the clinic **/
        const setAccessParam = {
            id: await calculateIdHashOfObj({
                $type$: 'ChannelInfo',
                id: 'questionnaireResponse',
                owner: person.idHash
            }),
            person: clinicContacts,
            group: [],
            mode: SET_ACCESS_MODE.REPLACE
        };
        await getObjectByIdHash(setAccessParam.id); // To check whether a channel with this id exists
        await createSingleObjectThroughPurePlan({module: '@one/access'}, [setAccessParam]);
        t1 = performance.now();

        setLogs(oldArray => [
            ...oldArray,
            `${getLogTime()} - Access for questionnaire response channel for ${
                person.idHash
            } has been created for the clinic.(${Math.round(t1 - t0)}ms)`
        ]);

        return person.idHash;
    }

    /**
     * Generate completed questionnaires based of the values set
     */
    async function generateQuestionnaire(): Promise<void> {
        const t0 = performance.now();

        /** started to generate completed questionnaires (shows the progress bar) **/
        setIsGenerating(true);

        let personIndex = 0;
        let completedQuestionnairesPerPerson = 0;
        const questionnaireCount = questionnairesPerPersonCount * personsCount;
        /** get the person number by knowing how many completed questionnaires per person would be **/
        const personIds = [];
        let generatedQuestionnaires = [];

        /** generate random persons **/
        for (let count = 0; count < personsCount; count++) {
            // eslint-disable-next-line no-await-in-loop
            personIds.push(await generatePersonEntity());
        }

        let personId = personIds[personIndex];

        /** start creating questionnaires **/
        for (let count = 0; count < questionnaireCount; count++) {
            personId = personIds[personIndex];

            const tt0 = performance.now();

            /** select random questionnaire and create {@link QuestionnaireResponseBuilder} **/
            const questionnairesTypes = getQuestionnaireTypesFromSliderValue(
                questionnairesPerPersonCount
            );
            const questionnairesToBeCompleted = [];

            for (const q of questionnairesTypes) {
                // eslint-disable-next-line no-await-in-loop
                questionnairesToBeCompleted.push(
                    await props.questionnaireModel.questionnaireByName(q)
                );
            }

            const randomQuestionnaire =
                questionnairesToBeCompleted[completedQuestionnairesPerPerson];
            const questionnaireBuilder = new QuestionnaireResponseBuilder(randomQuestionnaire);

            /** for every question in the questionnaire **/
            for (const question of questionnaireBuilder.questionIterator({flatIteration: true})) {
                /** give a random generated answer **/
                const answer = generateRandomQuestionAnswer(question);
                question.setAnswer(answer);
            }

            if (questionnaireBuilder.validate()) {
                questionnaireBuilder.setStatus('completed');

                const questionnaireResponse = questionnaireBuilder.buildResponse();
                generatedQuestionnaires.push(questionnaireResponse);
                completedQuestionnairesPerPerson++;

                const tt1 = performance.now();
                const questionnaireName = randomQuestionnaire.name
                    ? randomQuestionnaire.name
                    : 'UNKNOWN_NAME';
                const questionnaireLanguage = randomQuestionnaire.language
                    ? randomQuestionnaire.language
                    : 'UNKNOWN_LANG';
                setLogs(oldArray => [
                    ...oldArray,
                    `${getLogTime()} - Questionnaire${questionnaireName}_${questionnaireLanguage} has been auto-completed.(${Math.round(
                        tt1 - tt0
                    )}ms)`
                ]);
            }

            /** if the questionnaires completion reached the set value **/
            if (completedQuestionnairesPerPerson === questionnairesPerPersonCount) {
                /** in case if the value was set only to one questionnaire per person, use postResponse **/
                if (questionnairesPerPersonCount === 1) {
                    // eslint-disable-next-line no-await-in-loop
                    await props.questionnaireModel.postResponse(
                        generatedQuestionnaires[0],
                        undefined,
                        randomQuestionnaire.name,
                        /** if the personIndex reached the end, add the rest of the questionnaire to the last person's channel **/
                        personId
                    );
                } else {
                    // eslint-disable-next-line no-await-in-loop
                    await props.questionnaireModel.postResponseCollection(
                        generatedQuestionnaires,
                        undefined,
                        questionnairesTypes.join(','),
                        /** if the personIndex reached the end, add the rest of the questionnaire to the last person's channel **/
                        personId
                    );
                }

                const tt1 = performance.now();
                setLogs(oldArray => [
                    ...oldArray,
                    `${getLogTime()} - Questionnaires${questionnairesTypes.toString()} has been added to person ${personId} QuestionnaireResponses Channel.(${Math.round(
                        tt1 - tt0
                    )}ms)`
                ]);
                /** if it reached howManyQuestionnairesPerPerson, move to the next person **/
                personIndex++;
                /** reset the values, it's a different person **/
                completedQuestionnairesPerPerson = 0;
                generatedQuestionnaires = [];
            }
            setCompletedQuestionnaires(count);
        }

        const t1 = performance.now();

        setLogs(oldArray => [
            ...oldArray,
            `${getLogTime()} - Task finalized in ${getETA(
                Math.round(t1 - t0) / 1000
            )} with success! It has been created ${personsCount} persons with ${questionnairesPerPersonCount} questionnaires each and a total number of ${
                personsCount * questionnairesPerPersonCount
            } questionnaires.`
        ]);
        setCompletedQuestionnaires(0);
        setQuestionnairesPerPersonCount(1);
        setPersonsCount(1);
        setIsGenerating(false);
    }

    function LinearProgressWithLabel(progressProps: {value: number}): React.ReactElement {
        return (
            <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                    <BorderLinearProgress variant="determinate" {...progressProps} />
                </Box>
                <Box minWidth={35}>
                    <Typography
                        className="autocomplete-questionnaire-text-color"
                        variant="subtitle1"
                    >{`${Math.round(progressProps.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    function getLogTime(): string {
        return new Date().toLocaleString().split(',')[1].replace(' ', '');
    }

    function scrollToBottom(): void {
        if (logsEndRef.current) {
            logsEndRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'end'
            });
        }
    }

    function getQuestionnaireTypesFromSliderValue(value: number): string[] {
        const label = questionnairesMarks.findIndex(
            (mark: {label: string; value: number}) => mark.value === value
        );

        if (label === 0) {
            const questionnairesTypes = questionnairesMarks[label].label.split('/');
            return [questionnairesTypes[Math.floor(questionnairesTypes.length * Math.random())]];
        }

        return questionnairesMarks[label].label.split(',');
    }

    function getETA(timeInSeconds?: number): string {
        timeInSeconds = timeInSeconds
            ? timeInSeconds
            : Math.round(personsCount * questionnairesPerPersonCount * 0.4);

        const minutes = Math.floor(timeInSeconds / 60);
        const remainingSeconds = Math.round(timeInSeconds - minutes * 60);
        return `${minutes} ${
            minutes > 1
                ? i18n.t('common:autocompleteQuestionnaires.minutes')
                : i18n.t('common:autocompleteQuestionnaires.minute')
        } ${i18n.t('common:autocompleteQuestionnaires.and')} ${remainingSeconds} ${
            remainingSeconds > 1
                ? i18n.t('common:autocompleteQuestionnaires.seconds')
                : i18n.t('common:autocompleteQuestionnaires.second')
        }`;
    }

    useEffect(() => {
        scrollToBottom();
    }, [logs]);

    return (
        <div className="page-container">
            <div className="menu-button-header">
                <MenuButton />
                <h2 className="headline">
                    {' '}
                    {i18n.t('common:settings.generateCompletedQuestionnaire')}
                </h2>
            </div>
            <Paper
                square
                elevation={3}
                className="page-content-box autocomplete-questionnaire-page-content"
            >
                <Tooltip
                    className="autocomplete-questionnaire-tooltip-container"
                    title={i18n.t('common:autocompleteQuestionnaires.description').toString()}
                    aria-label="add"
                >
                    <div>
                        <BugReportIcon className="autocomplete-questionnaire-icon" />
                        <Typography className="autocomplete-questionnaire-text-color" variant="h5">
                            {i18n.t('common:autocompleteQuestionnaires.contentInfo')}
                        </Typography>
                    </div>
                </Tooltip>
                <Typography className="autocomplete-questionnaire-text-color" variant="subtitle1">
                    {isGenerating
                        ? i18n.t('common:autocompleteQuestionnaires.generatingQuestionnaires')
                        : i18n.t('common:autocompleteQuestionnaires.howManyPersons')}
                </Typography>
                {isGenerating ? (
                    <>
                        <LinearProgressWithLabel
                            value={
                                (completedQuestionnaires /
                                    (questionnairesPerPersonCount * personsCount)) *
                                100
                            }
                        />
                        <Button
                            onClick={() => setLogs([])}
                            className="autocomplete-questionnaire-generate-button"
                            variant="contained"
                            disableElevation
                        >
                            {i18n.t('common:autocompleteQuestionnaires.clearLogs')}
                        </Button>
                    </>
                ) : (
                    <>
                        <Slider
                            className="autocomplete-questionnaire-slider"
                            defaultValue={1}
                            onChange={(
                                _: ChangeEvent<Record<string, never>>,
                                value: number | number[]
                            ) => setPersonsCount(value as number)}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            min={1}
                            max={200}
                        />
                        <Typography
                            className="autocomplete-questionnaire-text-color"
                            variant="subtitle1"
                        >
                            {i18n.t('common:autocompleteQuestionnaires.howManyQuestionnaires')}
                        </Typography>
                        <Slider
                            className="autocomplete-questionnaire-slider "
                            defaultValue={1}
                            onChange={(
                                _: ChangeEvent<Record<string, never>>,
                                value: number | number[]
                            ) => setQuestionnairesPerPersonCount(value as number)}
                            marks={questionnairesMarks}
                            aria-labelledby="discrete-slider-restrict"
                            step={null}
                            valueLabelDisplay="off"
                            max={6}
                            min={1}
                        />
                        <Typography
                            className="autocomplete-questionnaire-text-color"
                            variant="subtitle2"
                        >
                            {i18n.t('common:autocompleteQuestionnaires.qTypes')}{' '}
                            {i18n.t(
                                `common:questionnaire.${getQuestionnaireTypesFromSliderValue(
                                    questionnairesPerPersonCount
                                ).join(',')}`
                            )}
                        </Typography>
                        <Typography
                            className="autocomplete-questionnaire-text-color"
                            variant="subtitle2"
                        >
                            {i18n.t('common:autocompleteQuestionnaires.totalNrQ')}{' '}
                            {questionnairesPerPersonCount * personsCount}
                        </Typography>
                        <Typography
                            className="autocomplete-questionnaire-text-color"
                            variant="subtitle2"
                        >
                            ETA: {getETA()}.
                        </Typography>
                        <Button
                            onClick={generateQuestionnaire}
                            className="autocomplete-questionnaire-generate-button"
                            variant="contained"
                            disableElevation
                        >
                            {i18n.t('common:autocompleteQuestionnaires.generate')}
                        </Button>
                        {logs.length > 0 ? (
                            <Button
                                onClick={() => setLogs([])}
                                className="autocomplete-questionnaire-generate-button autocomplete-questionnaire-button"
                                variant="contained"
                                disableElevation
                            >
                                {i18n.t('common:autocompleteQuestionnaires.clearLogs')}
                            </Button>
                        ) : null}
                    </>
                )}
            </Paper>
            {logs.length > 0 ? (
                <Paper
                    square
                    elevation={3}
                    className="autocomplete-page-content-logs autocomplete-questionnaire-logs-container"
                >
                    <div ref={logsEndRef}>
                        <List dense={true}>
                            {logs.map((log: string, index: number) => (
                                <ListItem
                                    key={index}
                                    className={
                                        index % 2 === 0
                                            ? 'autocomplete-page-content-logs-style-colored'
                                            : 'autocomplete-page-content-logs-style-uncolored'
                                    }
                                >
                                    <ListItemText primary={log} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Paper>
            ) : null}
        </div>
    );
}
