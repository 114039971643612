import React from 'react';
import {useHistory} from 'react-router';
import {Fab} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

/**
 * Renders the global back button
 * @param {{}} props
 * @param {boolean} props.isHidden
 * @returns {React.ReactElement}
 */
export default function BackButton(props: {
    isHidden?: boolean;
}): React.ReactElement {
    const history = useHistory();

    return (
        <>
            {!props.isHidden && (
                <div className="button-menu">
                    <Fab
                        size="small"
                        color="primary"
                        className="fab-wrapper"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <ArrowBackIcon />
                    </Fab>
                </div>
            )}
        </>
    );
}
