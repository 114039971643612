import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireI: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/I_de',
    name: 'I',
    status: 'active',
    item: [
        {
            linkId: 'cuInfYNU',
            prefix: 'I1',
            text: 'Sind Sie aktuell aufgrund der SARS-CoV-2-Infektion in stationärer Behandlung?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'I2_0',
            prefix: 'I2_0',
            text: 'Auf welcher Art von Station werden Sie behandelt? (Mehrfachnennungen möglich)',
            type: 'group',
            enableWhen: [
                {
                    question: 'cuInfYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'intensiveCUYNU',
                    prefix: 'I2_1',
                    text: 'Intensivstation',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'isolInfCUYNU',
                    prefix: 'I2_2',
                    text: 'Isolierstation oder Infektionsstation',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherCU_txt',
                    prefix: 'I2_3',
                    text: 'Sonstige?',
                    required: false,
                    type: 'string',
                    maxLength: 1000
                }
            ]
        },
        {
            linkId: 'symptomesYNU',
            prefix: 'I3',
            text: 'Leiden Sie aktuell unter Beschwerden?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'I4_0',
            prefix: 'I4_0',
            text: 'Unter welchen der folgenden Beschwerden leiden Sie? (Mehrfachnennung möglich)',
            type: 'group',
            enableWhen: [
                {
                    question: 'symptomesYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'fever',
                    prefix: 'I4_1',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/fever_de',
                                version: '1.0',
                                code: '1',
                                display: 'Fieber (über 38,5 °C)'
                            }
                        }
                    ]
                },
                {
                    linkId: 'headache',
                    prefix: 'I4_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/headache_de',
                                version: '1.0',
                                code: '1',
                                display: 'Kopf- bzw. Gliederschmerzen'
                            }
                        }
                    ]
                },
                {
                    linkId: 'musclePain',
                    prefix: 'I4_3',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/muscle_de',
                                version: '1.0',
                                code: '1',
                                display: 'Muskel- und Gelenkschmerzen'
                            }
                        }
                    ]
                },
                {
                    linkId: 'shortnessOfBreath',
                    prefix: 'I4_4',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/breath_de',
                                version: '1.0',
                                code: '1',
                                display: 'Kurzatmigkeit/Atemlosigkeit'
                            }
                        }
                    ]
                },
                {
                    linkId: 'dryCough',
                    prefix: 'I4_5',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DryCough_de',
                                version: '1.0',
                                code: '1',
                                display: 'Trockener Husten'
                            }
                        }
                    ]
                },
                {
                    linkId: 'cough',
                    prefix: 'I4_6',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/cough_de',
                                version: '1.0',
                                code: '1',
                                display: 'Husten (mit Auswurf)'
                            }
                        }
                    ]
                },
                {
                    linkId: 'tasteDisorder',
                    prefix: 'I4_7',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/taste_de',
                                version: '1.0',
                                code: '1',
                                display: 'Geruchs- bzw. Geschmacksstörungen'
                            }
                        }
                    ]
                },
                {
                    linkId: 'nausea',
                    prefix: 'I4_8',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/nausea_de',
                                version: '1.0',
                                code: '1',
                                display: 'Übelkeit'
                            }
                        }
                    ]
                },
                {
                    linkId: 'vomiting',
                    prefix: 'I4_9',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/vomiting_de',
                                version: '1.0',
                                code: '1',
                                display: 'Erbrechen'
                            }
                        }
                    ]
                },
                {
                    linkId: 'abdomPain',
                    prefix: 'I4_10',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/abdom_de',
                                version: '1.0',
                                code: '1',
                                display: 'Bauchschmerzen'
                            }
                        }
                    ]
                },
                {
                    linkId: 'diarrhea',
                    prefix: 'I4_11',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/diarrhea_de',
                                version: '1.0',
                                code: '1',
                                display: 'Durchfall'
                            }
                        }
                    ]
                },
                {
                    linkId: 'fatigue',
                    prefix: 'I4_12',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/fatigue_de',
                                version: '1.0',
                                code: '1',
                                display: 'Müdigkeit'
                            }
                        }
                    ]
                },
                {
                    linkId: 'dificultyConc',
                    prefix: 'I4_13',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system:
                                    'http://uk-erlangen.de/freeda/valueCoding/DifficutlyConcentrating_de',
                                version: '1.0',
                                code: '1',
                                display: 'Mir fällt es schwer mich zu konzentrieren'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherSymptomes_txt',
                    prefix: 'I4_14',
                    type: 'string',
                    maxLength: 1000,
                    text: 'Andere Beschwerden?',
                    required: false
                }
            ]
        },
        {
            linkId: 'pneumoniaYNU',
            prefix: 'I5',
            type: 'choice',
            text: 'Wurde bei Ihnen eine Lungenentzündung (Pneumonie) diagnostiziert?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'treatmentYNU',
            prefix: 'I6',
            type: 'choice',
            text: 'Erhalten Sie aktuell aufgrund der SARS-CoV-2-Infektion eine Behandlung?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'I7_0',
            prefix: 'I7_0',
            text: 'Welche Art von Behandlung erhalten Sie? (Mehrfachnennungen möglich)',
            type: 'group',
            enableWhen: [
                {
                    question: 'treatmentYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'oxygenYNU',
                    prefix: 'I7_1',
                    type: 'choice',
                    text: 'Bekommen Sie Sauerstoff?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'infusionYNU',
                    prefix: 'I7_2',
                    type: 'choice',
                    text: 'Haben Sie eine Infusion bekommen?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'antibioticsYNU',
                    prefix: 'I7_3',
                    type: 'choice',
                    text: 'Erhalten Sie Antibiotika?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'quensylYNU',
                    prefix: 'I7_4',
                    type: 'choice',
                    text: 'Werden Sie mit Quensyl®/Hydroxychloroquin behandelt?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'remdesivirYNU',
                    prefix: 'I7_5',
                    type: 'choice',
                    text: 'Werden Sie mit Remdesivir behandelt?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'intubationYNU',
                    prefix: 'I7_6',
                    type: 'choice',
                    text: 'Wurden Sie künstlich beatmet (Intubation)?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherTreatment_txt',
                    prefix: 'I8',
                    type: 'string',
                    maxLength: 1000,
                    text:
                        'Nehmen Sie sich bitte noch die Zeit, in folgendes Feld gegebenenfalls auch alle anderen Behandlungen aufgrund Ihrer SARS-CoV-2-Infektion einzutragen:',
                    required: false
                }
            ]
        },
        {
            linkId: 'covStudyYNU',
            prefix: 'I9',
            type: 'choice',
            text: 'Nehmen Sie an einer Studie zu einer (neuen) COVID-Therapie teil?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'I10_0',
            prefix: 'I10_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'covStudyYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'covStudyName_txt',
                    prefix: 'I10_1',
                    type: 'string',
                    text: 'Was ist der Name der Studie?',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'covStudyNameUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'covStudyNameUk',
                    prefix: 'I10_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/StudyName_de',
                                version: '1.0',
                                code: '1',
                                display: 'Der Name der Studie ist mir unbekannt.'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'I11_0',
            prefix: 'I11_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'covStudyYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'covStudyTherap_txt',
                    prefix: 'I11_1',
                    type: 'string',
                    text: 'Welche COVID-Therapie oder Medikamente erhalten Sie in dieser Studie?',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'covStudyTherapUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'covStudyTherapUk',
                    prefix: 'I11_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                            {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_de',
                                version: '1.0',
                                code: '1',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'infEndYN',
            prefix: 'I12_1',
            text: 'Wurde Ihnen inzwischen mitgeteilt, dass Sie nicht mehr mit SARS-CoV-2 infektiös sind?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                }
            ]
        },
        {
            linkId: 'dateOfInfEnd',
            prefix: 'I12_2',
            text: 'Wann wurde dies Ihnen mitgeteilt? ',
            type: 'date',
            enableWhen: [
                {
                    question: 'infEndYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                maxValue: {
                    valueDate: 'now'
                }
            }
        }
    ]
};
