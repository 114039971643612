import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import '../../Primary.css';

/**
 * This component build and return the preview of the news.
 * @param {{}} props - Properties of this view.
 * @param {string} props.preview
 * @returns {React.ReactElement} the preview of the news.
 */
export default function NewsPreview(props: {preview: string}): React.ReactElement {
    return (
        <div className="preview">
            <ReactMarkdown className="content-preview" source={props.preview} escapeHtml={false} />
        </div>
    );
}
