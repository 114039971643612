import ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import i18n from '../../i18n';
import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from 'downloadjs';
import * as dateFns from 'date-fns';
import {Person, SHA256IdHash} from '@OneCoreTypes';

/**
 * For each consent file we have specific position where the id
 * hash of the person will be added. Every number value was
 * calculated manually and if other versions of consent document will be added
 * then it should be appended here with the right number values.
 **/
const consentDocumentHeightDividers: Record<string, number[]> = {
    'translations/en/PDFiles/information_Patient_1.0.pdf': [1.51, 1.4115],
    'translations/en/PDFiles/information_Patient_2.0.pdf': [1.415, 1.328],
    'translations/de/PDFiles/information_Patient_1.0.pdf': [1.463, 1.368],
    'translations/de/PDFiles/information_Patient_2.0.pdf': [1.437, 1.345],
    'translations/en/PDFiles/information_Partner_1.0.pdf': [1.4785, 1.4085],
    'translations/en/PDFiles/information_Partner_2.0.pdf': [1.387, 1.328],
    'translations/de/PDFiles/information_Partner_1.0.pdf': [1.495, 1.395],
    'translations/de/PDFiles/information_Partner_2.0.pdf': [1.505, 1.373]
};

/**
 * This function will create the needed pdf file from an existing template
 * with the additional information and will download the file.
 * @param consentFileModel
 * @param isPartnerApp
 */
export async function downloadPdf(
    consentFileModel: ConsentFileModel,
    isPartnerApp: boolean
): Promise<void> {
    const personIdHash = await getPersonId(consentFileModel);
    const consentDocumentVersion = await getConsentFileVersion(consentFileModel);

    const pdfVersion = isPartnerApp
        ? i18n.t(`markDown:information_Partner_${consentDocumentVersion}`)
        : i18n.t(`markDown:information_Patient_${consentDocumentVersion}`);

    const pdf = isPartnerApp
        ? // eslint-disable-next-line global-require
          require('../../' + i18n.t(`markDown:information_Partner_${consentDocumentVersion}`))
        : // eslint-disable-next-line global-require
          require('../../' + i18n.t(`markDown:information_Patient_${consentDocumentVersion}`));

    const existingPdfBytes = await fetch(pdf).then(res => res.arrayBuffer());

    const pdfTitle = isPartnerApp
        ? `${i18n.t('common:infos.ParticipantPdfTitle')}${personIdHash}.pdf`
        : `${i18n.t('common:infos.PatientPdfTitle')}${personIdHash}.pdf`;

    /** Load a PDFDocument from the existing PDF bytes **/
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    /** Embed the Helvetica font
     *  The font can be changed according to the template font
     **/
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    /** Get the first page of the document **/
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    let secondTablePage = pages[5];

    if (!isPartnerApp && pdfVersion.includes('de')) {
        secondTablePage = pages[6];
    }

    /** Get the width and height of the first page **/
    const {width, height} = firstPage.getSize();

    /** depending on which file is downloaded we need specific numbers
     * to divide the height of the page for a right positioning of the
     * personIdHash in the page
     **/
    const heightDividers = consentDocumentHeightDividers[pdfVersion];

    /** Append the personIdHash to the right position in the page **/

    firstPage.drawText(personIdHash, {
        x: width / 2.8,
        y: height / heightDividers[0],
        size: 8.5,
        font: helveticaFont
    });

    secondTablePage.drawText(personIdHash, {
        x: width / 2.8,
        y: height / heightDividers[1],
        size: 8.5,
        font: helveticaFont
    });

    /** Serialize the PDF file to bytes (a Uint8Array) **/
    const pdfBytes = await pdfDoc.save();

    /** Trigger the browser to download the PDF document **/
    download(pdfBytes, pdfTitle, 'application/pdf');
}

/**
 * Creates the dropout file.
 * @param consentFileModel - the consent file model.
 * @param reason - the reason of dropping out the study.
 */
export async function createDropoutStudyFile(
    consentFileModel: ConsentFileModel,
    reason: string
): Promise<void> {
    const personIdHash = await getPersonId(consentFileModel);


    try {
        await consentFileModel.getOwnerDropoutFile();
    } catch {
        await consentFileModel.addDropoutFile({
            personId: personIdHash,
            date: dateFns.format(new Date(), 'yyyy/MM/dd'),
            reason: reason
        });
    }
}

/**
 * Build the right path for the consent file markdown that will be displayed.
 * @param consentFileModel
 * @param isPartnerApp
 */
export async function getConsentFileMarkdown(
    consentFileModel: ConsentFileModel,
    isPartnerApp: boolean
): Promise<string> {
    const userType = isPartnerApp ? 'Partner' : 'Patient';

    const consentFileVersion = await getConsentFileVersion(consentFileModel);

    return `markDown:information${userType}_${consentFileVersion}`;
}

/**
 * Get the person id hash from the consent file.
 * @param consentFileModel
 */
async function getPersonId(consentFileModel: ConsentFileModel): Promise<SHA256IdHash<Person>> {
    const consentFileObject = await consentFileModel.getOwnerConsentFile();
    return consentFileObject.data.personId;
}

/**
 * Get the version of the consent file.
 * @param consentFileModel
 */
async function getConsentFileVersion(consentFileModel: ConsentFileModel): Promise<string> {
    const consentFileObject = await consentFileModel.getOwnerConsentFile();

    let consentFileVersion = '1_0';

    if (consentFileObject.data.version) {
        consentFileVersion = consentFileObject.data.version.replace('.', '_');
    }

    return consentFileVersion;
}
