import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import React from 'react';

/**
 * @param {PropertyTree} settings
 * @param {key} key
 * @param {defaultValue} defaultValue
 * @returns {[string, (value: string) => Promise<void>]}
 */
export function useSettings(
    settings: PropertyTree,
    key: string,
    defaultValue: string
): [string, (value: string) => Promise<void>] {
    const [value, setValue] = React.useState<string>(
        settings.hasValue(key) ? settings.getValue(key) : defaultValue
    );

    React.useEffect(() => {
        function fetchSettings(): void {
            if (settings.hasValue(key)) {
                setValue(settings.getValue(key));
            } else {
                setValue(defaultValue);
                settings.setValue(key, defaultValue).catch(error => {
                    console.error('Error ' + error);
                });
            }
        }

        settings.on('update', fetchSettings);
        fetchSettings();

        return () => {
            settings.removeListener('update', fetchSettings);
        };
    }, [settings]);

    return [
        value,
        (tmpValue: string) => {
            return settings.setValue(key, tmpValue);
        }
    ];
}
