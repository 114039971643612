import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useHistory} from 'react-router-dom';
import OneInstanceModel, {LogoutMode} from 'one.models/lib/models/OneInstanceModel';
import './EraseDialog.css';
import i18n from '../../i18n';
import ExitDialog from '../exitStudy/ExitStudyDialog';
import ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import {PropertyTree} from 'one.models/lib/models';
import {useSettings} from '../modelHelper/SettingsHelper';
import {createDropoutStudyFile, downloadPdf} from '../modelHelper/ConsentFileHelper';

/**
 * returns the dialog for alerting the user what erase will do
 * @param {{}} props
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {Function} props.setNavigationDrawer
 * @param {Function} props.showEraseDialog
 * @param {ConsentFileModel} props.consentFileModel
 * @param {Function} props.setLogoutFromErase
 * @param {PropertyTree} props.settings
 * @param {boolean} props.isPartnerApp - Set to true if view should be rendered for partner app.
 * @returns {React.ReactElement}
 */
export default function EraseDialog(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawer: Function;
    showEraseDialog?: Function;
    setLogoutFromErase: Function;
    consentFileModel: ConsentFileModel;
    settings: PropertyTree;
    isPartnerApp: boolean;
}): React.ReactElement {
    const [eraseDialog, setEraseDialog] = useState(true);
    const history = useHistory();
    const [choseOne, setChoseOne] = useState('');
    const [showContent, setShowContent] = useState(false);
    const [openExitStudy, setOpenExitStudy] = useState(false);
    const [hide, setHide] = useState<boolean>();

    // exitStudy the study setting
    const [exitStudy] = useSettings(props.settings, 'homeScreen.exitStudy', 'false');

    async function exit(reason: string): Promise<void> {
        await createDropoutStudyFile(props.consentFileModel, reason);
        setOpenExitStudy(false);
    }

    async function erase(): Promise<void> {
        props.setNavigationDrawer(false);
        await props.oneInstanceModel.logout(LogoutMode.PurgeData);
        props.setLogoutFromErase(true);
        history.push('/');
        window.location.reload();
    }

    function handleClose(): void {
        setOpenExitStudy(false);
        setHide(false);

        if (props.showEraseDialog) {
            props.showEraseDialog(false);
        }
    }

    return (
        <>
            <Dialog
                className={hide ? 'hide' : ''}
                open={eraseDialog}
                onClose={() => {
                    setEraseDialog(false);

                    if (props.showEraseDialog !== undefined) {
                        props.showEraseDialog(false);
                    }
                }}
            >
                <DialogContent className="dialog-error">
                    <Alert severity="error">{i18n.t('errors:eraseDialog')}</Alert>
                    <DialogActions className="erase-action-buttons dialog-content">
                        {choseOne === 'first' && showContent ? (
                            <p>
                                {i18n.t('common:eraseDialog.leaveStudyBeforeLink')}
                                {props.isPartnerApp ? (
                                    <a href="https://partner.therapie.freeda.one/FAQ">
                                        {i18n.t('common:eraseDialog.leaveStudyLink')}
                                    </a>
                                ) : (
                                    <a href="https://therapie.freeda.one/FAQ">
                                        {i18n.t('common:eraseDialog.leaveStudyLink')}
                                    </a>
                                )}
                                {i18n.t('common:eraseDialog.leaveStudyAfterLink')}
                            </p>
                        ) : (
                            <> </>
                        )}
                        {choseOne === 'second' && showContent ? (
                            <p> {i18n.t('common:eraseDialog.leaveStudy')} </p>
                        ) : (
                            <> </>
                        )}

                        {choseOne !== 'first' && choseOne !== 'second' && showContent ? (
                            <p> {i18n.t('common:eraseDialog.oneOption')} </p>
                        ) : (
                            <> </>
                        )}
                        {showContent && (choseOne === 'first' || choseOne === 'second') ? (
                            <> </>
                        ) : (
                            <FormControl className="dialog-content" component="fieldset">
                                <RadioGroup
                                    aria-label="erase"
                                    name="erase"
                                    value={choseOne}
                                    onChange={event => {
                                        setChoseOne(event.currentTarget.value);
                                        setShowContent(false);
                                    }}
                                >
                                    <FormControlLabel
                                        className="pop-up-checkbox"
                                        value={'first'}
                                        control={<Radio />}
                                        label={i18n.t('common:eraseDialog.firstCheckbox')}
                                    />
                                    <FormControlLabel
                                        className="pop-up-checkbox"
                                        value={'second'}
                                        control={<Radio />}
                                        label={i18n.t('common:eraseDialog.secondCheckbox')}
                                        disabled={exitStudy === 'true'}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}

                        <DialogActions className="erase-pop-up-buttons">
                            <Button
                                variant="contained"
                                className="pop-up-button"
                                color="primary"
                                onClick={() => {
                                    setEraseDialog(false);

                                    if (props.showEraseDialog === undefined) {
                                        history.goBack();
                                    } else {
                                        props.showEraseDialog(false);
                                    }
                                }}
                            >
                                {i18n.t('common:settings.cancel')}
                            </Button>
                            {choseOne === 'first' && showContent ? (
                                <Button
                                    className="pop-up-button"
                                    variant="contained"
                                    color="primary"
                                    onClick={async () => {
                                        await erase().then(() => {
                                            history.push('/');
                                        });
                                    }}
                                >
                                    {i18n.t('common:buttons.delete')}
                                </Button>
                            ) : (
                                <> </>
                            )}
                            {choseOne === 'second' && showContent ? (
                                <Button
                                    className="pop-up-button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenExitStudy(true);
                                        setHide(true);
                                    }}
                                >
                                    {i18n.t('common:buttons.next')}
                                </Button>
                            ) : (
                                <> </>
                            )}
                            {showContent && (choseOne === 'first' || choseOne === 'second') ? (
                                <> </>
                            ) : (
                                <Button
                                    className="pop-up-button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setShowContent(true);
                                    }}
                                >
                                    {i18n.t('common:buttons.next')}
                                </Button>
                            )}
                        </DialogActions>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <ExitDialog
                handleExitStudy={exit}
                handleModalClose={handleClose}
                isOpen={openExitStudy}
                isFromErase={true}
                handleErase={erase}
                handleDownload={async () => downloadPdf(props.consentFileModel, props.isPartnerApp)}
                setLogoutFromErase={props.setLogoutFromErase}
                settings={props.settings}
            />
        </>
    );
}
