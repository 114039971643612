import i18n from '../../i18n';

/**
 *
 * this is used for getting the partner translation updated everytime we switch the language
 * @returns {{string,string}}
 */
export default function getPartnerStepperText(): {[index: string]: string} {
    return {
        introduction: i18n.t('steppers:partnerText.introduction'),
        firstTitle: i18n.t('steppers:partnerText.firstTitle'),
        first: i18n.t('steppers:partnerText.first'),
        secondTitle: i18n.t('steppers:partnerText.secondTitle'),
        second: i18n.t('steppers:partnerText.second'),
        thirdTitle: i18n.t('steppers:partnerText.thirdTitle'),
        third: i18n.t('steppers:partnerText.third')
    };
}
