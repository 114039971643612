import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireT: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/T_de',
    name: 'T',
    status: 'active',
    item: [
        {
            linkId: 'therapyBreakYN',
            prefix: 'T1',
            text: 'Wurde Ihre Krebstherapie seit der letzten Befragung unterbrochen? ',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                }
            ]
        },
        {
            linkId: 'T2_0',
            prefix: 'T2_0',
            text: 'Über welchen Zeitraum wurde die Therapie unterbrochen?',
            type: 'group',
            enableWhen: [
                {
                    question: 'therapyBreakYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'therapyBreakFrom',
                    prefix: 'T2_1',
                    text: 'von',
                    type: 'date'
                },
                {
                    linkId: 'therapyBreakUntil',
                    prefix: 'T2_2',
                    text: 'bis',
                    type: 'date'
                }
            ]
        },
        {
            linkId: 'ecog1YNU',
            prefix: 'T3',
            type: 'choice',
            text: 'Seit der letzten Befragung: Sind Sie gehfähig und können Sie leichte körperliche Arbeiten durchführen?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'therapyChangeNewYNU',
            prefix: 'T4',
            type: 'choice',
            text:
                'Wurde seit Ihrer Beantwortung des letzten Fragebogens eine neue Krebstherapie begonnen oder wurde die Krebstherapie gewechselt?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'T5_0',
            prefix: 'T5_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'therapyChangeNewYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'numberOfTherapy',
                    prefix: 'T5_1',
                    type: 'integer',
                    text: 'Die wievielte Krebstherapie zu Ihrer aktuellen Krebserkrankung ist dies?',
                    enableWhen: [
                        {
                            question: 'numberOfTherapyUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ],
                    answerRestriction: {
                        minValue: {
                            valueInteger: '1'
                        },
                        maxValue: {
                            valueInteger: '80'
                        }
                    }
                },
                {
                    linkId: 'numberOfTherapyUk',
                    prefix: 'T5_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_de',
                                version: '1.0',
                                code: '1',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'T6_0',
            prefix: 'T6_0',
            type: 'group',
            text:
                'Welche der folgenden Therapien bekommen Sie nun aufgrund Ihrer Krebserkrankung? (Mehrfachnennung möglich)',
            enableWhen: [
                {
                    question: 'therapyChangeNewYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'ahTherapyYNU',
                    prefix: 'T6_1',
                    text: 'Anti-Hormonelle Therapie',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'chemoYNU',
                    prefix: 'T6_2',
                    text: 'Chemotherapie',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'checkPointYNU',
                    prefix: 'T6_3',
                    text:
                        'Checkpoint-Inhibitor Therapie (Immuntherapie-Antikörper, z.B. Pembrolizumab, Atezolizumab, Iplimumab, Nivolumab, u.a.)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'AntiHerYNU',
                    prefix: 'T6_4',
                    text: 'Anti-HER2/neu-Rezeptor gerichtete Therapie (z.B. Herceptin®, Kanjin;®, Perjeta®)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherTherapyYNU',
                    prefix: 'T6_5',
                    text: 'Eine andere Antikörper-Therapie',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'cdkiYNU',
                    prefix: 'T6_6',
                    text: 'CDK4/6-Inhibitor Therapie (z.B. Ibrance®, Kisquali®, Verzenios®)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'mToriYNU',
                    prefix: 'T6_7',
                    text: 'mTOR-Inhibitor Therapie (z.B. Everolimus/Rapamycin, Temsirolimus)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'radYNU',
                    prefix: 'T6_8',
                    text: 'Strahlentherapie des Brustkorbs/Lunge (vollständig oder teilweise)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'autStemCellYNU',
                    prefix: 'T6_9',
                    text: 'autologe Stammzelltransplantationen',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'allogStemCellYNU',
                    prefix: 'T6_10',
                    text: 'allogene Stammzelltransplantationen',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'carTcellYNU',
                    prefix: 'T6_11',
                    text: 'CAR-T-Zell-Therapie',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherTargTherapYNU',
                    prefix: 'T6_12',
                    text: 'Andere Zielgerichtete Therapie (z.B. bei genetischer Mutation)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bOtherTarg_txt',
                    prefix: 'T6_13',
                    text: 'Um welche andere zielgerichtete Therapie handelt es sich?',
                    type: 'string',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'otherTargTherapYNU',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'opYNU',
                    prefix: 'T6_14',
                    text: 'Sind Sie nach der letzten Befragung operiert worden?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'opType_txt',
                    prefix: 'T6_15',
                    text: 'Um was für eine Operation handelt es sich?',
                    type: 'string',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'opYNU',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'medication_txt',
            prefix: 'T7',
            text:
                'Hier haben Sie noch die Möglichkeit Ihre genaue Krebstherapie einzutragen. (Mehrfachnennungen möglich)',
            type: 'string',
            maxLength: 1000,
            enableWhen: [
                {
                    question: 'therapyChangeNewYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            required: false
        }
    ]
};
