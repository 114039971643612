/* eslint-disable @typescript-eslint/camelcase,brace-style */
import EventEmitter from 'events';
import * as dateFns from 'date-fns';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import {
    QuestionnaireResponse,
    QuestionnaireResponses,
    QuestionnaireResponseItem
} from 'one.models/lib/models/QuestionnaireModel';
import {ObjectData} from 'one.models/lib/models/ChannelManager';
import {NewsModel, QuestionnaireModel} from 'one.models/lib/models';

/** Represents the current phase. */
export enum PatientPhase {
    Basic = 0,
    RegularWithoutInfection = 1,
    RegularWithInfection = 2,
    End = 3,
    Dropout = -2,
    PatientDied = -3
}

/**
 * Represents the partner phases
 */
export enum PartnerPhase {
    Basic = 0,
    Regular = 1,
    End = 3,
    Dropout = -2,
    PatientDied = -3
}

/**
 * Represents the visit types, for the partner and the patient.
 */
export enum VisitType {
    PatientBasicVisit = 'PatientBasicVisit',
    PatientRegularWithoutInfectionVisit = 'PatientRegularWithoutInfectionVisit',
    PatientRegularWithInfectionVisit = 'PatientRegularWithInfectionVisit',
    PatientInfectionVisit = 'PatientInfectionVisit',
    PatientEndVisit = 'PatientEndVisit',
    PartnerBasicVisit = 'PartnerBasicVisit',
    PartnerRegularVisit = 'PartnerRegularVisit',
    PartnerEndVisit = 'PartnerEndVisit'
}

/** Information for one phase. */
export type PhaseProperties = {
    lastQuestionnaireTime: Date | undefined;
    nextQuestionnaireTime: Date | undefined;
    hasIncompleteQuestionnaire: boolean;
    daysUntilNextRegularQuestionnaire: number | undefined;
};

/**
 * Additional information for infection phase.
 */
export type RegularWithInfectionProperties = PhaseProperties & {
    lastInfectionQuestionnaireTime: Date | undefined;
    nextInfectionQuestionnaireTime: Date | undefined;
    daysUntilNextInfectionQuestionnaire: number | undefined;
};

/**
 * The patient phases properties.
 */
export type PatientPhasesProperties = {
    [PatientPhase.Basic]: PhaseProperties;
    [PatientPhase.RegularWithoutInfection]: PhaseProperties;
    [PatientPhase.RegularWithInfection]: RegularWithInfectionProperties;
    [PatientPhase.End]: PhaseProperties;
};

/**
 * The partner phases properties.
 */
export type PartnerPhasesProperties = {
    [PartnerPhase.Basic]: PhaseProperties;
    [PartnerPhase.Regular]: PhaseProperties;
    [PartnerPhase.End]: PhaseProperties;
};

//TODO improve the error messages send to the replicant, so that there is enough
// information to help debugging an possible error
/**
 * This model represents a workflow for covid questionnaires
 */
export default class CovidWorkflowModel extends EventEmitter {
    private questionnaireModel: QuestionnaireModel;
    private newsModel: NewsModel;
    private currentPhaseState: PatientPhase | PartnerPhase; // The current phase the patient is in
    private phasesProperties: PatientPhasesProperties | PartnerPhasesProperties; // The properties of all phases
    private oneInstance: OneInstanceModel; // Getting the current user type
    private endOfStudy: Date | null; // The date when the study ends
    private PATIENT_INTERVAL_DAYS = 14;
    private PARTNER_INTERVAL_DAYS = 28;
    private readonly boundOnUpdatedHandler: () => void;

    /**
     * Construct a new instance
     * @param {QuestionnaireModel} questionnaireModel - The questionnaire model
     * @param {OneInstanceModel} oneInstance - The one instance model.
     * @param {NewsModel} newsModel - The news model
     */
    constructor(
        questionnaireModel: QuestionnaireModel,
        oneInstance: OneInstanceModel,
        newsModel: NewsModel
    ) {
        super();
        this.questionnaireModel = questionnaireModel;
        this.newsModel = newsModel;
        this.phasesProperties = this.resetPhasesProperties();
        this.currentPhaseState = PatientPhase.Basic;
        this.oneInstance = oneInstance;
        this.endOfStudy = null;
        this.boundOnUpdatedHandler = this.handleOnUpdated.bind(this);
    }

    /**
     * Initialize the current instance.
     */
    async init(): Promise<void> {
        if (this.getUserType().includes('partner')) {
            await this.updatePhaseAndPropertiesForPartner();
        } else {
            await this.updatePhaseAndPropertiesForPatient();
        }

        this.questionnaireModel.on('updated', this.boundOnUpdatedHandler);
    }

    /**
     * Shutdown module
     */
    public shutdown(): void {
        this.questionnaireModel.removeListener('updated', this.boundOnUpdatedHandler);
    }

    /**
     * Update current phase and phases properties for partner. The major steps are:
     * - go through QuestionnaireResponses and set properties of the already fulfilled questionnaires
     * - establish the current phase state
     * - determine the next deadlines to fulfill questionnaires based on the state
     * - set the phases properties
     *  @returns {Promise<void>}
     */
    async updatePhaseAndPropertiesForPartner(): Promise<void> {
        try {
            const allResponses = await this.questionnaireModel.responses();

            // Basic phase
            let partnerBaseVisitDate: Date | undefined = undefined;
            let patientBaseVisitDate: Date | undefined = undefined;

            // Regular phase
            let partnerLastRegularQuestionnaire: Date | undefined = undefined;
            let partnerNextRegularQuestionnaire: Date | undefined = undefined;

            // End phase
            let partnerLastEndQuestionnaire: Date | undefined = undefined;
            let partnerNextEndQuestionnaire: Date | undefined = undefined;

            let hasBasicIncompleteQuestionnaire: boolean = false;
            let hasRegularIncompleteQuestionnaire: boolean = false;
            let hasEndIncompleteQuestionnaire: boolean = false;

            let patientDied: boolean = false;

            let daysUntilNextRegularQuestionnaire: number | undefined = undefined;

            for (const responses of allResponses) {
                const questionnaireResponseArray = responses.data.response;
                const questionnaireResponseDate: Date = responses.creationTime;
                const visitType = this.getQuestionnaireResponsesType(responses);

                switch (visitType) {
                    case VisitType.PartnerBasicVisit: {
                        if (!partnerBaseVisitDate) {
                            partnerBaseVisitDate = questionnaireResponseDate;
                        }
                        break;
                    }
                    // we need the patient basic questionnaire to calculate the regular visit date
                    // for the partner
                    case VisitType.PatientBasicVisit: {
                        patientBaseVisitDate = questionnaireResponseDate;
                        this.endOfStudy = dateFns.addMonths(patientBaseVisitDate, 6);

                        break;
                    }
                    case VisitType.PartnerRegularVisit: {
                        partnerLastRegularQuestionnaire = questionnaireResponseDate;
                        patientDied = this.isPatientDead(questionnaireResponseArray);
                        break;
                    }
                    case VisitType.PartnerEndVisit: {
                        partnerLastEndQuestionnaire = questionnaireResponseDate;
                        break;
                    }
                }
            }

        // Assign the current phase
        if (patientDied) {
            this.currentPhaseState = PatientPhase.PatientDied;
        } else if (partnerBaseVisitDate) {
            // detect if infection or regular
            this.currentPhaseState = PartnerPhase.Regular;

                // end of study
                if (this.endOfStudy && this.endOfStudy <= new Date()) {
                    this.currentPhaseState = PartnerPhase.End;
                }
            } else {
                this.currentPhaseState = PartnerPhase.Basic; // no questionnaires completed
            }

            // determine the next date to fill the questionnaires based on currentPhase
            switch (this.currentPhaseState) {
                case PartnerPhase.Basic: {
                    hasBasicIncompleteQuestionnaire = await this.questionnaireModel.hasIncompleteResponse(
                        'VB'
                    );
                    break;
                }

                case PartnerPhase.Regular: {
                    hasRegularIncompleteQuestionnaire = await this.questionnaireModel.hasIncompleteResponse(
                        'VS',
                        partnerLastRegularQuestionnaire
                    );

                if (patientBaseVisitDate) {
                    partnerNextRegularQuestionnaire = this.getNextPartnerDeadline(
                        dateFns.addDays(
                            this.removeTimeFromDate(patientBaseVisitDate),
                            this.PATIENT_INTERVAL_DAYS
                        ),
                        partnerLastRegularQuestionnaire
                            ? this.removeTimeFromDate(partnerLastRegularQuestionnaire)
                            : undefined,
                        this.removeTimeFromDate(new Date())
                    );

                        daysUntilNextRegularQuestionnaire = dateFns.differenceInCalendarDays(
                            partnerNextRegularQuestionnaire,
                            new Date()
                        );
                    } else {
                        partnerNextRegularQuestionnaire = undefined;
                    }

                    break;
                }

                case PartnerPhase.End: {
                    hasEndIncompleteQuestionnaire = await this.questionnaireModel.hasIncompleteResponse(
                        'VB,VA',
                        partnerLastEndQuestionnaire
                    );

                    // set next questionnaire time for regular questionnaires
                    if (!patientBaseVisitDate) {
                        throw new Error(
                            'Partner is in EndPhase, but the patient did not do the basic visit.'
                        );
                    }

                partnerNextEndQuestionnaire = this.getNextPartnerDeadline(
                    dateFns.addDays(
                        this.removeTimeFromDate(patientBaseVisitDate),
                        this.PATIENT_INTERVAL_DAYS
                    ),
                    partnerLastRegularQuestionnaire
                        ? this.removeTimeFromDate(partnerLastRegularQuestionnaire)
                        : partnerLastRegularQuestionnaire,
                    this.removeTimeFromDate(new Date())
                );

                    daysUntilNextRegularQuestionnaire = dateFns.differenceInCalendarDays(
                        partnerNextEndQuestionnaire,
                        new Date()
                    );
                    break;
                }
            }

            // set the phase properties
            this.phasesProperties = {
                [PartnerPhase.Basic]: {
                    lastQuestionnaireTime: partnerBaseVisitDate,
                    nextQuestionnaireTime: undefined,
                    hasIncompleteQuestionnaire: hasBasicIncompleteQuestionnaire,
                    daysUntilNextRegularQuestionnaire: daysUntilNextRegularQuestionnaire
                },

                [PartnerPhase.Regular]: {
                    lastQuestionnaireTime: partnerLastRegularQuestionnaire,
                    nextQuestionnaireTime: partnerNextRegularQuestionnaire,
                    hasIncompleteQuestionnaire: hasRegularIncompleteQuestionnaire,
                    daysUntilNextRegularQuestionnaire: daysUntilNextRegularQuestionnaire
                },

                [PartnerPhase.End]: {
                    lastQuestionnaireTime: partnerLastEndQuestionnaire,
                    nextQuestionnaireTime: partnerNextEndQuestionnaire,
                    hasIncompleteQuestionnaire: hasEndIncompleteQuestionnaire,
                    daysUntilNextRegularQuestionnaire: daysUntilNextRegularQuestionnaire
                }
            };
        } catch (error) {
            if (error) {
                const feedbackString = JSON.stringify({
                    message: error.toString(),
                    stack: error.stack
                });
                this.newsModel.addFeedback(feedbackString).catch(console.error);
            }
        }
    }

    /**
     * Update current phase and phases properties for patient. The major steps are:
     * - go through QuestionnaireResponses and set properties of the already fulfilled questionnaires
     * - establish the current phase state
     * - determine the next deadlines to fulfill questionnaires based on the state
     * - set the phases properties
     * @returns {Promise<void>}
     */
    async updatePhaseAndPropertiesForPatient(): Promise<void> {
        try {
            // Load all questionnaire responses
            const allResponses = await this.questionnaireModel.responses();

            // Reset the properties and phase to default values when no
            // questionnaires have been filled out
            if (allResponses.length === 0) {
                this.phasesProperties = this.resetPhasesProperties();
                this.currentPhaseState = PatientPhase.Basic;
            }

            // Obtain some properties of questionnaires required to determine the phase
            let patientDied = false;

            // Basic Questionnaire
            let baseVisitDate: Date | undefined = undefined;

            // Regular
            let lastRegularQuestionnaire: Date | undefined = undefined;
            let nextRegularQuestionnaire: Date | undefined = undefined;
            let daysUntilNextRegularQuestionnaire: number | undefined = undefined;

            // Infection
            let nextInfectionQuestionnaire: Date | undefined = undefined;
            let lastInfectionQuestionnaire: Date | undefined = undefined;
            let infectionPhase = true;
            let daysUntilNextInfectionQuestionnaire: number | undefined = undefined;

            // End
            let nextEndQuestionnaire: Date | undefined = undefined;
            let lastEndQuestionnaire: Date | undefined = undefined;

            // flags if the are incomplete questionnaires for each phase
            let hasBasicIncompleteQuestionnaire: boolean = false;
            let hasRegularWithInfectionIncompleteQuestionnaire: boolean = false;
            let hasRegularWithoutInfectionIncompleteQuestionnaire: boolean = false;
            let hasEndIncompleteQuestionnaire: boolean = false;

            for (const responses of allResponses) {
                const questionnaireResponseArray = responses.data.response;
                const questionnaireResponseTime: Date = responses.creationTime;
                const visitType = this.getQuestionnaireResponsesType(responses);

                switch (visitType) {
                    case VisitType.PatientBasicVisit: {
                        if (!baseVisitDate) {
                            baseVisitDate = questionnaireResponseTime;
                            this.endOfStudy = dateFns.addMonths(baseVisitDate, 6);
                        }

                        infectionPhase = this.isInfected(questionnaireResponseArray);
                        lastInfectionQuestionnaire = questionnaireResponseTime;
                        break;
                    }
                    case VisitType.PatientRegularWithoutInfectionVisit: {
                        lastRegularQuestionnaire = questionnaireResponseTime;

                        infectionPhase = this.isInfected(questionnaireResponseArray);

                        break;
                    }

                    case VisitType.PatientRegularWithInfectionVisit: {
                        lastRegularQuestionnaire = questionnaireResponseTime;
                        lastInfectionQuestionnaire = questionnaireResponseTime;
                        infectionPhase = this.isStillInfection(questionnaireResponseArray);

                        break;
                    }

                    case VisitType.PatientInfectionVisit: {
                        lastInfectionQuestionnaire = questionnaireResponseTime;
                        infectionPhase = this.isStillInfection(questionnaireResponseArray);
                        break;
                    }
                    case VisitType.PatientEndVisit: {
                        lastEndQuestionnaire = questionnaireResponseTime;
                        break;
                    }

                    case VisitType.PartnerRegularVisit: {
                        patientDied = this.isPatientDead(questionnaireResponseArray);
                        break;
                    }
                }
            }

            // Assign the current phase
            if (patientDied) {
                this.currentPhaseState = PatientPhase.PatientDied;
            } else if (baseVisitDate) {
                // detect if infection or regular
                if (infectionPhase) {
                    // infection phase and regular
                    this.currentPhaseState = PatientPhase.RegularWithInfection;
                } else {
                    // just regular
                    this.currentPhaseState = PatientPhase.RegularWithoutInfection;
                }

                // end of study
                if (this.endOfStudy && this.endOfStudy <= new Date()) {
                    this.currentPhaseState = PatientPhase.End;
                }
            } else {
                this.currentPhaseState = PatientPhase.Basic; // no questionnaires completed
            }

            // determine the next date to fill the questionnaires based on currentPhase
            switch (this.currentPhaseState) {
                case PatientPhase.Basic: {
                    hasBasicIncompleteQuestionnaire = await this.questionnaireModel.hasIncompleteResponse(
                        'B,BI,BT,S,IM,I'
                    );
                    break;
                }

                case PatientPhase.RegularWithInfection: {
                    hasRegularWithInfectionIncompleteQuestionnaire = await this.questionnaireModel.hasIncompleteResponse(
                        'T,S,IM,I'
                    );

                    // set next questionnaire time for regular questionnaires
                    if (!baseVisitDate) {
                        throw new Error(
                            'Patient is in RegularWithInfection phase without doing the basic visit.'
                        );
                    }

                    nextRegularQuestionnaire = this.getNextPatientDeadline(
                        this.removeTimeFromDate(baseVisitDate),
                        this.removeTimeFromDate(new Date()),
                        lastRegularQuestionnaire
                            ? this.removeTimeFromDate(lastRegularQuestionnaire)
                            : this.removeTimeFromDate(baseVisitDate)
                    );

                    // set next questionnaire time for infection
                    if (lastInfectionQuestionnaire && dateFns.isToday(lastInfectionQuestionnaire)) {
                        nextInfectionQuestionnaire = dateFns.addDays(lastInfectionQuestionnaire, 1);
                    } else {
                        nextInfectionQuestionnaire = new Date();
                    }

                    daysUntilNextRegularQuestionnaire = dateFns.differenceInCalendarDays(
                        nextRegularQuestionnaire,
                        new Date()
                    );
                    daysUntilNextInfectionQuestionnaire = dateFns.differenceInCalendarDays(
                        nextInfectionQuestionnaire,
                        new Date()
                    );

                    break;
                }

                case PatientPhase.RegularWithoutInfection: {
                    hasRegularWithoutInfectionIncompleteQuestionnaire = await this.questionnaireModel.hasIncompleteResponse(
                        'T,S,IM,I'
                    );

                    if (!baseVisitDate) {
                        throw new Error(
                            'Patient is in RegularWithoutInfection phase without doing the basic visit.'
                        );
                    }

                    nextRegularQuestionnaire = this.getNextPatientDeadline(
                        this.removeTimeFromDate(baseVisitDate),
                        this.removeTimeFromDate(new Date()),
                        lastRegularQuestionnaire
                            ? this.removeTimeFromDate(lastRegularQuestionnaire)
                            : this.removeTimeFromDate(baseVisitDate)
                    );

                    daysUntilNextRegularQuestionnaire = dateFns.differenceInCalendarDays(
                        nextRegularQuestionnaire,
                        new Date()
                    );

                    break;
                }
                case PatientPhase.End: {
                    hasEndIncompleteQuestionnaire = await this.questionnaireModel.hasIncompleteResponse(
                        'T,S,IM,I,A',
                        lastEndQuestionnaire
                    );

                    if (!baseVisitDate) {
                        throw new Error('Patient is in End phase without doing the basic visit.');
                    }

                    nextEndQuestionnaire = this.getNextPatientDeadline(
                        this.removeTimeFromDate(baseVisitDate),
                        this.removeTimeFromDate(new Date()),
                        lastRegularQuestionnaire
                            ? this.removeTimeFromDate(lastRegularQuestionnaire)
                            : undefined
                    );

                    daysUntilNextRegularQuestionnaire = dateFns.differenceInCalendarDays(
                        nextEndQuestionnaire,
                        new Date()
                    );

                    break;
                }
            }

            this.phasesProperties = {
                [PatientPhase.Basic]: {
                    lastQuestionnaireTime: baseVisitDate,
                    nextQuestionnaireTime: undefined,
                    hasIncompleteQuestionnaire: hasBasicIncompleteQuestionnaire,
                    daysUntilNextRegularQuestionnaire: daysUntilNextRegularQuestionnaire
                },

                [PatientPhase.RegularWithoutInfection]: {
                    lastQuestionnaireTime: lastRegularQuestionnaire,
                    nextQuestionnaireTime: nextRegularQuestionnaire,
                    hasIncompleteQuestionnaire: hasRegularWithoutInfectionIncompleteQuestionnaire,
                    daysUntilNextRegularQuestionnaire: daysUntilNextRegularQuestionnaire
                },

                [PatientPhase.RegularWithInfection]: {
                    lastQuestionnaireTime: lastRegularQuestionnaire,
                    nextQuestionnaireTime: nextRegularQuestionnaire,
                    lastInfectionQuestionnaireTime: lastInfectionQuestionnaire,
                    nextInfectionQuestionnaireTime: nextInfectionQuestionnaire,
                    hasIncompleteQuestionnaire: hasRegularWithInfectionIncompleteQuestionnaire,
                    daysUntilNextRegularQuestionnaire: daysUntilNextRegularQuestionnaire,
                    daysUntilNextInfectionQuestionnaire: daysUntilNextInfectionQuestionnaire
                },

                [PatientPhase.End]: {
                    lastQuestionnaireTime: lastEndQuestionnaire,
                    nextQuestionnaireTime: nextEndQuestionnaire,
                    hasIncompleteQuestionnaire: hasEndIncompleteQuestionnaire,
                    daysUntilNextRegularQuestionnaire: daysUntilNextRegularQuestionnaire
                }
            };
        } catch (error) {
            if (error) {
                const feedbackString = JSON.stringify({
                    message: error.toString(),
                    stack: error.stack
                });
                this.newsModel.addFeedback(feedbackString).catch(console.error);
            }
        }
    }

    /**
     * Retrieve the current phase.
     * @returns {PatientPhase} - current phase of the patient.
     */
    currentPhase(): PatientPhase | PartnerPhase {
        return this.currentPhaseState;
    }

    /**
     * Retrieve properties for all phases.
     * @returns {PhaseProperties}
     */
    phaseProperties(): PatientPhasesProperties | PartnerPhasesProperties {
        return this.phasesProperties;
    }

    // forwarding the patient type
    getUserType(): string {
        return this.oneInstance.patientTypeState();
    }

    getEndOfStudyDate(): Date | null {
        return this.endOfStudy;
    }

    /** ########################################## Private ########################################## **/

    /**
     * Reset the phases properties.
     * @returns {PatientPhasesProperties}
     * @private
     */
    private resetPhasesProperties(): PatientPhasesProperties {
        return {
            [PatientPhase.Basic]: {
                lastQuestionnaireTime: undefined,
                nextQuestionnaireTime: undefined,
                hasIncompleteQuestionnaire: false,
                daysUntilNextRegularQuestionnaire: undefined
            },

            [PatientPhase.RegularWithoutInfection]: {
                lastQuestionnaireTime: undefined,
                nextQuestionnaireTime: undefined,
                hasIncompleteQuestionnaire: false,
                daysUntilNextRegularQuestionnaire: undefined
            },

            [PatientPhase.RegularWithInfection]: {
                lastQuestionnaireTime: undefined,
                nextQuestionnaireTime: undefined,
                hasIncompleteQuestionnaire: false,
                lastInfectionQuestionnaireTime: undefined,
                nextInfectionQuestionnaireTime: undefined,
                daysUntilNextRegularQuestionnaire: undefined,
                daysUntilNextInfectionQuestionnaire: undefined
            },

            [PatientPhase.End]: {
                lastQuestionnaireTime: undefined,
                nextQuestionnaireTime: undefined,
                hasIncompleteQuestionnaire: false,
                daysUntilNextRegularQuestionnaire: undefined
            }
        };
    }

    /**
     * Returns the QuestionnaireResponse visit type.
     * @param {ObjectData<QuestionnaireResponses>} questionnaireResponseObjectData
     * @returns {string}
     * @private
     */
    private getQuestionnaireResponsesType(
        questionnaireResponseObjectData: ObjectData<QuestionnaireResponses>
    ): string {
        const questionnaireResponses = questionnaireResponseObjectData.data;

        if (!questionnaireResponses.response || questionnaireResponses.response.length === 0) {
            throw new Error('QuestionnaireResponses.response is empty or undefined');
        }

        const questionnaireTypes: string[] = [];
        questionnaireResponses.response.forEach((questionnaireResponse: QuestionnaireResponse) => {
            questionnaireTypes.push(this.getQuestionnaireResponseType(questionnaireResponse));
        });

        let visitType: string;

        if (
            this.arrayEquals(['B', 'BI', 'BT', 'S', 'IM'], questionnaireTypes) ||
            this.arrayEquals(['B', 'BI', 'BT', 'S', 'IM', 'I'], questionnaireTypes)
        ) {
            visitType = VisitType.PatientBasicVisit;
        } else if (this.arrayEquals(['T', 'S', 'IM'], questionnaireTypes)) {
            visitType = VisitType.PatientRegularWithoutInfectionVisit;

            if (!this.endOfStudy) {
                throw new Error(
                    'Patient did PatientRegularWithoutInfectionVisit, but endOfStudy is undefined'
                );
            }

            if (
                this.isLastRegularVisit(
                    questionnaireResponseObjectData.creationTime,
                    this.endOfStudy
                )
            ) {
                visitType = VisitType.PatientEndVisit;
            }
        } else if (this.arrayEquals(['T', 'S', 'IM', 'I'], questionnaireTypes)) {
            visitType = VisitType.PatientRegularWithInfectionVisit;

            if (!this.endOfStudy) {
                throw new Error(
                    'Patient did PatientRegularWithInfectionVisit, but endOfStudy is undefined'
                );
            }

            if (
                this.isLastRegularVisit(
                    questionnaireResponseObjectData.creationTime,
                    this.endOfStudy
                )
            ) {
                visitType = VisitType.PatientEndVisit;
            }
        } else if (this.arrayEquals(['I'], questionnaireTypes)) {
            visitType = VisitType.PatientInfectionVisit;
        } else if (this.arrayEquals(['VB'], questionnaireTypes)) {
            visitType = VisitType.PartnerBasicVisit;
        } else if (this.arrayEquals(['VS'], questionnaireTypes)) {
            visitType = VisitType.PartnerRegularVisit;

            if (this.endOfStudy) {
                if (
                    this.isLastRegularVisit(
                        questionnaireResponseObjectData.creationTime,
                        this.endOfStudy
                    )
                ) {
                    visitType = VisitType.PartnerEndVisit;
                }
            } else {
                const feedbackString = JSON.stringify({
                    message:
                        'Partner did PartnerRegularVisit, but endOfStudy is undefined. Questionnaire response creation time is: ' +
                        questionnaireResponseObjectData.creationTime.toString()
                });
                this.newsModel.addFeedback(feedbackString).catch(console.error);
            }
        } else {
            visitType = 'UnknownVisit';
        }

        return visitType;
    }

    /**
     * Returns the type of the QuestionnaireResponse. e.g B,BT,IM
     * @param {QuestionnaireResponse} questionnaireResponse
     * @returns {string}
     * @private
     */
    private getQuestionnaireResponseType(questionnaireResponse: QuestionnaireResponse): string {
        const questionnaire = questionnaireResponse.questionnaire;

        if (!questionnaire) {
            throw new Error('The questionnaire response does not have a questionnaire prop');
        }

        return questionnaire.substring(
            questionnaire.lastIndexOf('/') + 1,
            questionnaire.length - 3
        );
    }

    /**
     * Returns the nextDeadline for the regular phase.
     * @param {Date} nextDeadline
     * @param {Date} currentDate
     * @param {Date} lastQuestionnaireDate
     * @returns {Date}
     * @private
     */
    private getNextPatientDeadline(
        nextDeadline: Date,
        currentDate: Date,
        lastQuestionnaireDate?: Date
    ): Date {
        const upperLimitAcceptanceInterval = dateFns.addDays(
            nextDeadline,
            this.PATIENT_INTERVAL_DAYS
        );

        // patient hasn't returned for a long period, calculate the first valid deadline
        if (currentDate >= upperLimitAcceptanceInterval) {
            return this.getNextPatientDeadline(
                dateFns.addDays(nextDeadline, this.PATIENT_INTERVAL_DAYS),
                currentDate,
                lastQuestionnaireDate
            );
        }

        // nextDeadline is valid, but has it already been fulfilled?
        if (
            lastQuestionnaireDate &&
            lastQuestionnaireDate >= nextDeadline &&
            lastQuestionnaireDate < upperLimitAcceptanceInterval
        ) {
            // nextDeadline already fulfilled, move to the next one
            return dateFns.addDays(nextDeadline, this.PATIENT_INTERVAL_DAYS);
        } else {
            return nextDeadline;
        }
    }

    /**
     * Returns the next deadline for the partner.
     * @param {Date} nextPartnerRegularVisit
     * @param {Date | null} partnerLastRegularQuestionnaire
     * @param {Date} currentDate
     * @returns {Date}
     * @private
     */
    private getNextPartnerDeadline(
        nextPartnerRegularVisit: Date,
        partnerLastRegularQuestionnaire: Date | undefined,
        currentDate: Date
    ): Date {
        const partnerNextRegularAcceptanceUpperLimit = dateFns.addDays(
            nextPartnerRegularVisit,
            this.PARTNER_INTERVAL_DAYS
        );

        // if the partner didn't return for a longer period, calculate the first valid deadline
        if (currentDate >= partnerNextRegularAcceptanceUpperLimit) {
            return this.getNextPartnerDeadline(
                dateFns.addDays(nextPartnerRegularVisit, this.PARTNER_INTERVAL_DAYS),
                partnerLastRegularQuestionnaire,
                currentDate
            );
        }

        // partner never fulfilled a questionnaire
        if (!partnerLastRegularQuestionnaire) {
            return nextPartnerRegularVisit;
        }

        if (
            partnerLastRegularQuestionnaire >= nextPartnerRegularVisit &&
            partnerLastRegularQuestionnaire < partnerNextRegularAcceptanceUpperLimit
        ) {
            // partner already fulfilled questionnaire this regular visit
            return dateFns.addDays(nextPartnerRegularVisit, this.PARTNER_INTERVAL_DAYS);
        } else {
            return nextPartnerRegularVisit;
        }
    }

    /**
     * Returns if the patient is still in infection phase based on the Infection questionnaire response.
     * @param {QuestionnaireResponse[]} questionnaireResponseArray
     * @returns {boolean}
     * @private
     */
    private isStillInfection(questionnaireResponseArray: QuestionnaireResponse[]): boolean {
        const questionnaireResponse = questionnaireResponseArray.find(
            q => this.getQuestionnaireResponseType(q) === 'I'
        );

        if (questionnaireResponse) {
            const infItem = questionnaireResponse.item.find(
                (item: QuestionnaireResponseItem) => item.linkId === 'infEndYN'
            );

            if (
                infItem &&
                infItem.answer.length > 0 &&
                infItem.answer[0].valueCoding &&
                infItem.answer[0].valueCoding.code === '1'
            ) {
                return false;
            }
        }

        return true;
    }

    /**
     * Returns if the patient is infected or not, based on the IM questionnaire response.
     * @param questionnaireResponseArray
     * @private
     */
    private isInfected(questionnaireResponseArray: QuestionnaireResponse[]): boolean {
        const questionnaireResponse = questionnaireResponseArray.find(
            (q: QuestionnaireResponse) => this.getQuestionnaireResponseType(q) === 'IM'
        );

        if (questionnaireResponse) {
            const infItem = questionnaireResponse.item.find(item => item.linkId === 'infYNU');

            if (
                infItem &&
                infItem.answer.length > 0 &&
                infItem.answer[0].valueCoding &&
                infItem.answer[0].valueCoding.code === '1'
            ) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns if the patient died, based on the partner VS questionnaire responses.
     * @param questionnaireResponseArray
     * @private
     */
    private isPatientDead(questionnaireResponseArray: QuestionnaireResponse[]): boolean {
        const questionnaireResponse = questionnaireResponseArray.find(
            (q: QuestionnaireResponse) => this.getQuestionnaireResponseType(q) === 'VS'
        );

        if (questionnaireResponse) {
            const patHealthItem = questionnaireResponse.item.find(
                item => item.linkId === 'patHealth'
            );

            if (
                patHealthItem &&
                patHealthItem.answer.length > 0 &&
                patHealthItem.answer[0].valueCoding &&
                patHealthItem.answer[0].valueCoding.code === '4'
            ) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns true if the members of the 2 arrays are equal.
     * @param a
     * @param b
     * @private
     */
    private arrayEquals(a: string[], b: string[]): boolean {
        return a.every(aItem => b.includes(aItem)) && b.every(bItem => a.includes(bItem));
    }

    private async handleOnUpdated(): Promise<void> {
        if (this.getUserType().includes('partner')) {
            await this.updatePhaseAndPropertiesForPartner();
        } else {
            await this.updatePhaseAndPropertiesForPatient();
        }

        this.emit('updated');
    }

    /**
     * Removes the time from the date by settings the hours, minutes, seconds and milliseconds to 0.
     * @param date
     * @private
     */
    private removeTimeFromDate(date: Date): Date {
        date.setHours(0, 0, 0, 0);
        return date;
    }

    /**
     * Returns true if the questionnaire creationTime is 2 days before endOfStudy or after.
     * @param questionnaireDate
     * @param endOfStudy
     * @private
     */
    private isLastRegularVisit(questionnaireDate: Date, endOfStudy: Date): boolean {
        endOfStudy = this.removeTimeFromDate(endOfStudy);

        const lowerEndOfStudyAcceptanceInterval = dateFns.subDays(endOfStudy, 2);

        if (questionnaireDate >= lowerEndOfStudyAcceptanceInterval) {
            return true;
        }

        return false;
    }
}
