import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import {CircularProgress} from '@material-ui/core';

/**
 * logs out the user
 * @param {{}} props
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {Function} props.setNavigationDrawer
 * @returns {React.ReactElement}
 */
export default function Reset(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawer: (value: boolean) => void;
}): React.ReactElement {
    const history = useHistory();

    useEffect(() => {
        props.setNavigationDrawer(false);
        props.oneInstanceModel
            .deleteUnopenedInstance()
            .then(() => {
                history.push('/');
                window.location.reload();
            })
            .catch(err => {
                console.error(err);
                history.push('/');
            });
    }, []);

    return (
        <div className="circularProgressContainer">
            <CircularProgress className="circularProgress" size={35} />
        </div>
    );
}
