import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireI: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://uk-erlangen.de/freeda/questionnaire/I_en',
    name: 'I',
    status: 'active',
    item: [
        {
            linkId: 'cuInfYNU',
            prefix: 'I1',
            text: 'Are you currently undergoing inpatient treatment for SARS-CoV-2 infection?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'I2_0',
            prefix: 'I2_0',
            text: 'At what kind of ward are you being treated? (multiple answers possible)',
            type: 'group',
            enableWhen: [
                {
                    question: 'cuInfYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'intensiveCUYNU',
                    prefix: 'I2_1',
                    text: 'Intensiv Care Unit (ICU)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'isolInfCUYNU',
                    prefix: 'I2_2',
                    text: 'Isolation or infection ward',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherCU_txt',
                    prefix: 'I2_3',
                    text: 'others',
                    required: false,
                    type: 'string',
                    maxLength: 1000
                }
            ]
        },
        {
            linkId: 'symptomesYNU',
            prefix: 'I3',
            text: 'Do you currently suffer from symptoms?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'I4_0',
            prefix: 'I4_0',
            text: 'Which of the following symptoms do you suffer from? (multiple answers possible)',
            type: 'group',
            enableWhen: [
                {
                    question: 'symptomesYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'fever',
                    prefix: 'I4_1',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/fever_en',
                                version: '1.0',
                                code: '1',
                                display: 'fever (above 38.5°C or 101.30°F)'
                            }
                        }
                    ]
                },
                {
                    linkId: 'headache',
                    prefix: 'I4_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/headache_en',
                                version: '1.0',
                                code: '1',
                                display: 'headaches and limb pain'
                            }
                        }
                    ]
                },
                {
                    linkId: 'musclePain',
                    prefix: 'I4_3',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/muscle_en',
                                version: '1.0',
                                code: '1',
                                display: 'muscle and joint pain'
                            }
                        }
                    ]
                },
                {
                    linkId: 'shortnessOfBreath',
                    prefix: 'I4_4',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/breath_en',
                                version: '1.0',
                                code: '1',
                                display: 'shortness of breath/breathlessness'
                            }
                        }
                    ]
                },
                {
                    linkId: 'dryCough',
                    prefix: 'I4_5',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DryCough_en',
                                version: '1.0',
                                code: '1',
                                display: 'dry cough'
                            }
                        }
                    ]
                },
                {
                    linkId: 'cough',
                    prefix: 'I4_6',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/cough_en',
                                version: '1.0',
                                code: '1',
                                display: 'cough (with sputum)'
                            }
                        }
                    ]
                },
                {
                    linkId: 'tasteDisorder',
                    prefix: 'I4_7',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/taste_en',
                                version: '1.0',
                                code: '1',
                                display: 'odour and/or taste disorders'
                            }
                        }
                    ]
                },
                {
                    linkId: 'nausea',
                    prefix: 'I4_8',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/nausea_en',
                                version: '1.0',
                                code: '1',
                                display: 'nausea'
                            }
                        }
                    ]
                },
                {
                    linkId: 'vomiting',
                    prefix: 'I4_9',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/vomiting_en',
                                version: '1.0',
                                code: '1',
                                display: 'vomiting'
                            }
                        }
                    ]
                },
                {
                    linkId: 'abdomPain',
                    prefix: 'I4_10',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/abdom_en',
                                version: '1.0',
                                code: '1',
                                display: 'abdominal pain'
                            }
                        }
                    ]
                },
                {
                    linkId: 'diarrhea',
                    prefix: 'I4_11',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/diarrhea_en',
                                version: '1.0',
                                code: '1',
                                display: 'diarrhea'
                            }
                        }
                    ]
                },
                {
                    linkId: 'fatigue',
                    prefix: 'I4_12',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/fatigue_en',
                                version: '1.0',
                                code: '1',
                                display: 'fatigue'
                            }
                        }
                    ]
                },
                {
                    linkId: 'dificultyConc',
                    prefix: 'I4_13',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system:
                                    'http://uk-erlangen.de/freeda/valueCoding/DifficutlyConcentrating_en',
                                version: '1.0',
                                code: '1',
                                display: 'I have difficulty concentrating'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherSymptomes_txt',
                    prefix: 'I4_14',
                    type: 'string',
                    maxLength: 1000,
                    text: 'other symptomes',
                    required: false
                }
            ]
        },
        {
            linkId: 'pneumoniaYNU',
            prefix: 'I5',
            type: 'choice',
            text: 'Have you been diagnosed with pneumonia?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'treatmentYNU',
            prefix: 'I6',
            type: 'choice',
            text: 'Are you currently receiving treatment for SARS-CoV-2 infection?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'I7_0',
            prefix: 'I7_0',
            text: 'What kind of treatment do you receive? (multiple answers possible)',
            type: 'group',
            enableWhen: [
                {
                    question: 'treatmentYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'oxygenYNU',
                    prefix: 'I7_1',
                    type: 'choice',
                    text: 'Are you getting additional oxygen?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'infusionYNU',
                    prefix: 'I7_2',
                    type: 'choice',
                    text: 'Do you get an infusion?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'antibioticsYNU',
                    prefix: 'I7_3',
                    type: 'choice',
                    text: 'Are you receiving antibiotics?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'quensylYNU',
                    prefix: 'I7_4',
                    type: 'choice',
                    text: 'Are you treated with hydroxychloroquine (Quensyl®)?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'remdesivirYNU',
                    prefix: 'I7_5',
                    type: 'choice',
                    text: 'Are you treated with remdesivir?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'intubationYNU',
                    prefix: 'I7_6',
                    type: 'choice',
                    text: 'Have you been given artificial respiration (intubation)?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherTreatment_txt',
                    prefix: '8_0',
                    type: 'string',
                    maxLength: 1000,
                    text:
                        'Please take the time to enter in the following text box all other treatments due to your SARS-CoV-2 infection:',
                    required: false
                }
            ]
        },
        {
            linkId: 'covStudyYNU',
            prefix: 'I9',
            type: 'choice',
            text: 'Are you taking part in a study on a (new) COVID therapy?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'I10_0',
            prefix: 'I10_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'covStudyYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'covStudyName_txt',
                    prefix: 'I10_1',
                    type: 'string',
                    text: 'What is the name of the study?',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'covStudyNameUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'covStudyNameUk',
                    prefix: 'I10_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/StudyName_en',
                                version: '1.0',
                                code: '1',
                                display: 'I do not know the name of the study.'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'I11_0',
            prefix: 'I11_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'covStudyYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'covStudyTherap_txt',
                    prefix: 'I11_1',
                    type: 'string',
                    text: 'Which COVID therapy or medication do you receive in this study?',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'covStudyTherapUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'covStudyTherapUk',
                    prefix: 'I11_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_en',
                                version: '1.0',
                                code: '1',
                                display: 'I do not know'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'infEndYN',
            prefix: 'I12_1',
            text: 'Have you been informed that you are no longer infected with SARS-CoV-2?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                }
            ]
        },
        {
            linkId: 'dateOfInfEnd',
            prefix: 'I12_2',
            text: 'When were you informed of this? ',
            type: 'date',
            enableWhen: [
                {
                    question: 'infEndYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                maxValue: {
                    valueDate: 'now'
                }
            }
        }
    ]
};
