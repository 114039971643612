import React from 'react';
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import {Link} from 'react-router-dom';
import {Icon} from '../icon/Icon';
import './CloudConnections.css';
import '../../Primary.css';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import Paper from '@material-ui/core/Paper';
import CloudConnections from './CloudConnections';
import getPartnerStepperText from './partnerStepperText';
import i18n from '../../i18n';
import MenuButton from '../menu/MenuButton';
import {usePartnerConnections} from '../modelHelper/ConnectionsHelper';

/**
 * @param {{}} props
 * @param {ConnectionsModel} props.connectionsModel
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {boolean} props.isPartnerApp - Set to true if view should be rendered for partner app.
 * @returns {React.ReactElement}
 */
export default function PartnerConnections(props: {
    connectionsModel: ConnectionsModel;
    oneInstanceModel: OneInstanceModel;
    isPartnerApp: boolean;
}): React.ReactElement {
    const connectionsMap = usePartnerConnections(props.connectionsModel);

    const userType = props.isPartnerApp ? 'partner' : 'patient';

    enum CurrentStep {
        First = 'first',
        Second = 'second',
        Third = 'third'
    }

    function stepper(): React.ReactElement {
        return (
            <>
                <Stepper activeStep={3} orientation="vertical" className="simple-stepper">
                    {Object.values(CurrentStep).map((key, idx) => {
                        return (
                            <Step className="simple-stepper-icon" key={key}>
                                <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            active:
                                                key === CurrentStep.First
                                                    ? 'phase1-color'
                                                    : key === CurrentStep.Second
                                                    ? 'phase2-color'
                                                    : 'phase3-color',
                                            completed:
                                                key === CurrentStep.First
                                                    ? 'phase1-color'
                                                    : key === CurrentStep.Second
                                                    ? 'phase2-color'
                                                    : 'phase3-color'
                                        }
                                    }}
                                >
                                    <div className="simple-stepper-title partner-devices-step-title">
                                        <div>{getPartnerStepperText()[`${key}Title`]}</div>
                                    </div>
                                    <div
                                        className={`simple-stepper-content ${
                                            idx === Object.keys(CurrentStep).length - 1
                                                ? 'last-step'
                                                : ''
                                        }`}
                                    >
                                        {key === CurrentStep.Third ? (
                                            inviteButton()
                                        ) : (
                                            <div>{getPartnerStepperText()[`${key}`]}</div>
                                        )}
                                    </div>
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </>
        );
    }

    function inviteButton(): React.ReactElement {
        return (
            <Link className="app-menu-entry" to={'/invites/invitePartner'}>
                <ListItem key="inviteButton" button>
                    <ListItemIcon>
                        <Icon name="AccountPlus" />
                    </ListItemIcon>
                    <ListItemText>
                        {i18n.t(
                            `connections:partnerDevices.connectWithA${
                                userType[0].toUpperCase() + userType.substring(1)
                            }`
                        )}
                    </ListItemText>
                </ListItem>
            </Link>
        );
    }

    return (
        <>
            <div className="page-container">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">
                        {i18n.t(`connections:partnerDevices.${userType}DevicesTitle`)}
                    </h2>
                </div>
                <Paper square elevation={3} className="page-content-box">
                    <List>{userType.includes('partner') ? inviteButton() : stepper()}</List>
                </Paper>
                <CloudConnections
                    connections={connectionsMap}
                    connectionsModel={props.connectionsModel}
                    personalCloud={false}
                    userType={userType}
                />
            </div>
        </>
    );
}
