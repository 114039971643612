export const hostnameOptions: {[index: string]: {patientType: string; partnerHostname: string}} = {
    'therapie.freeda.one': {
        patientType: 'cancer',
        partnerHostname: 'partner.therapie.freeda.one'
    },
    'partner.therapie.freeda.one': {
        patientType: 'cancer partner',
        partnerHostname: 'therapie.freeda.one'
    },
    'www.freeda.one': {
        patientType: '',
        partnerHostname: ''
    },
    'freeda.one': {
        patientType: '',
        partnerHostname: ''
    },
    'freeda.refinio.one': {
        patientType: 'cancer',
        partnerHostname: 'partner.freeda.refinio.one'
    },
    'partner.freeda.refinio.one': {
        patientType: 'cancer partner',
        partnerHostname: 'freeda.refinio.one'
    },
    'freeda.dev.refinio.one': {
        patientType: 'cancer',
        partnerHostname: 'partner.freeda.dev.refinio.one'
    },
    'partner.freeda.dev.refinio.one': {
        patientType: 'cancer partner',
        partnerHostname: 'freeda.dev.refinio.one'
    },
    'freeda.demo.refinio.one': {
        patientType: 'cancer',
        partnerHostname: 'partner.freeda.demo.refinio.one'
    },
    'partner.freeda.demo.refinio.one': {
        patientType: 'cancer partner',
        partnerHostname: 'freeda.demo.refinio.one'
    },
    localhost: {
        // patientType: 'cancer partner',
        patientType: '',
        partnerHostname: 'localhost'
    },
    '1.raluca.dev.refinio.one': {
        patientType: 'cancer',
        partnerHostname: '2.raluca.dev.refinio.one'
    },
    '2.raluca.dev.refinio.one': {
        patientType: 'cancer partner',
        partnerHostname: '1.raluca.dev.refinio.one'
    },
    '1.eduard.dev.refinio.one': {
        patientType: 'cancer',
        partnerHostname: '2.eduard.dev.refinio.one'
    },
    '2.eduard.dev.refinio.one': {
        patientType: 'cancer partner',
        partnerHostname: '1.eduard.dev.refinio.one'
    },
    '1.max.dev.refinio.one': {
        patientType: 'cancer',
        partnerHostname: '2.max.dev.refinio.one'
    },
    '2.max.dev.refinio.one': {
        patientType: 'cancer partner',
        partnerHostname: '1.max.dev.refinio.one'
    },
    '1.sebastian.dev.refinio.one': {
        patientType: 'cancer',
        partnerHostname: '2.sebastian.dev.refinio.one'
    },
    '2.sebastian.dev.refinio.one': {
        patientType: 'cancer partner',
        partnerHostname: '1.sebastian.dev.refinio.one'
    }
};
