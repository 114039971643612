import AccessModel from 'one.models/lib/models/AccessModel';
import ChannelManager from 'one.models/lib/models/ChannelManager';
import ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import {Contact, Person, SHA256IdHash, UnversionedObjectResult} from '@OneCoreTypes';
import CommunicationInitiationProtocol from 'one.models/lib/misc/CommunicationInitiationProtocol';
import {calculateIdHashOfObj} from 'one.core/lib/util/object';
import {createSingleObjectThroughPurePlan} from 'one.core/lib/plan';
import {
    createManyObjectsThroughPurePlan,
    getObject,
    getObjectByIdHash,
    SET_ACCESS_MODE,
    VERSION_UPDATES
} from 'one.core/lib/storage';
import {ContactModel} from 'one.models/lib/models';
import {ContactEvent} from 'one.models/lib/models/ContactModel';
import {serializeWithType} from 'one.core/lib/util/promise';

// import replicantContact from '../replicants/freeda-dev_public_contact.json';
// import replicantContact from '../replicants/uke-replicant_public_contact.json';
// import replicantContact from '../replicants/freeda-local_public_contact.json';
import replicantContact from '../replicants/demo-replicant_public_contact.json';

/**
 * Those are the access groups of the whole application.
 *
 * @type {{partner: string, clinic: string}}
 */
export const FreedaAccessGroups = {
    partner: 'partners', // Registered partners are part of this group. Usually one.
    clinic: 'clinic' // This group holds the clinic ids. Usually one - the replicant.
};

/**
 * This type defines how access rights for channels are specified
 */
type ChannelAccessRights = {
    owner: SHA256IdHash<Person>; // The owner of the channels
    persons: SHA256IdHash<Person>[]; // The persons who should gain access
    channels: string[]; // The channels that should gain access
};

/**
 * This class manages all access rights for freeda patients and partners.
 *
 * The replicant has its own file in its own repo.
 *
 */
export default class FreedaAccessRightsManager {
    private readonly accessModel: AccessModel;
    private readonly channelManager: ChannelManager;
    private readonly connectionsModel: ConnectionsModel;
    private readonly contactModel: ContactModel;
    private initialized: boolean;
    private readonly isPartnerApp: boolean;
    private mainId: SHA256IdHash<Person> | null;
    private anonymousId: SHA256IdHash<Person> | null;
    private transmitRightsToReplicant: boolean = false;
    private timeoutHandle: ReturnType<typeof setTimeout> | null = null;

    /**
     * Create a new instance.
     *
     * @param {AccessModel} accessModel
     * @param {ChannelManager} channelManager
     * @param {ConnectionsModel} connectionsModel
     * @param {ContactModel} contactModel
     * @param {boolean} isPartnerApp
     */
    constructor(
        accessModel: AccessModel,
        channelManager: ChannelManager,
        connectionsModel: ConnectionsModel,
        contactModel: ContactModel,
        isPartnerApp: boolean
    ) {
        this.accessModel = accessModel;
        this.channelManager = channelManager;
        this.connectionsModel = connectionsModel;
        this.contactModel = contactModel;
        this.initialized = false;
        this.isPartnerApp = isPartnerApp;
        this.mainId = null;
        this.anonymousId = null;

        // Register hook for new connections
        connectionsModel.on('chum_start', this.handleNewChum.bind(this));
        contactModel.on(
            ContactEvent.NewCommunicationEndpointArrived,
            this.updatePartnerAccessGroup.bind(this)
        );
    }

    /**
     * Set up the access rights handling for the application on the current instance.
     *
     * @param {SHA256IdHash<Person>} mainId
     * @param {SHA256IdHash<Person>} anonymousId
     * @returns {Promise<void>}
     */
    public async init(
        mainId: SHA256IdHash<Person>,
        anonymousId: SHA256IdHash<Person>
    ): Promise<void> {
        this.mainId = mainId;
        this.anonymousId = anonymousId;

        // Create the two access groups
        await this.accessModel.createAccessGroup(FreedaAccessGroups.partner);
        await this.accessModel.createAccessGroup(FreedaAccessGroups.clinic);

        // Import the replicant contact and add it to the clinic group
        const importedReplicantContact: UnversionedObjectResult<
            Contact
        >[] = await createManyObjectsThroughPurePlan(
            {
                module: '@module/explodeObject',
                versionMapPolicy: {'*': VERSION_UPDATES.NONE_IF_LATEST}
            },
            decodeURI(replicantContact.data)
        );
        await this.accessModel.addPersonToAccessGroup(
            FreedaAccessGroups.clinic,
            importedReplicantContact[0].obj.personId
        );

        // Create the 'hacky' contact channels that mitigate a bug with distributing versioned objects
        // TODO: remove this hack and replace it with proper sharing
        await this.channelManager.createChannel('contacts');
        await this.channelManager.createChannel('realContacts');
        await this.channelManager.createChannel('partnerContacts');

        // Post the latest anonymous contact object at end of contacts channel
        const anonContactObjects = await this.contactModel.getContactObjectHashes(anonymousId);

        // Post my anonymous contact object into the contacts channel that is then distributed to
        // the clinic and the partner
        await this.channelManager.postToChannelIfNotExist(
            'contacts',
            await getObject(anonContactObjects[0])
        );

        // Post the latest main and anonymous contact objects at end of realContacts channel
        const mainContactObjects = await this.contactModel.getContactObjectHashes(mainId);
        await this.channelManager.postToChannelIfNotExist(
            'realContacts',
            await getObject(mainContactObjects[0])
        );
        await this.channelManager.postToChannelIfNotExist(
            'realContacts',
            await getObject(anonContactObjects[0])
        );

        // Make sure the channels is shared with the right devices even if they aren't connected yet.
        this.isPartnerApp
            ? await this.giveAccessToChannelsPartnerApp()
            : await this.giveAccessToChannelsPatientApp();

        // Give the groups access to the following channels.
        //
        // Note that this doesn't work at the moment, because of a bug in one.core.
        // So access rights are set for specific persons when authentication was successful.
        // TODO: remove this block of documentation, when the access group issue has been
        //       fixed in one.core
        /* TODO: Update and uncomment this when group sharing works!
        await this.channelManager.giveAccessToChannelInfo(
            'consentFile',
            FreedaAccessGroups.partner
        );
        await this.channelManager.giveAccessToChannelInfo('consentFile', FreedaAccessGroups.clinic);
        await this.channelManager.giveAccessToChannelInfo(
            'feedbackChannel',
            FreedaAccessGroups.clinic
        );
        await this.channelManager.giveAccessToChannelInfo(
            'questionnaireResponse',
            FreedaAccessGroups.partner
        );
        await this.channelManager.giveAccessToChannelInfo(
            'questionnaireResponse',
            FreedaAccessGroups.clinic
        );
        */
        this.initialized = true;
    }

    /**
     * Shuts everything down.
     *
     * @returns {Promise<void>}
     */
    // We want a consistent interface
    // eslint-disable-next-line @typescript-eslint/require-await
    public async shutdown(): Promise<void> {
        this.stopSendingReplicantRights();
        this.initialized = false;
        this.mainId = null;
        this.anonymousId = null;
    }

    /**
     * Starts sending the partner ids to the replicant.
     *
     * After one successful run it is stopped again.
     *
     * @param retryTimeout
     */
    public startSendingReplicantRights(retryTimeout: number = 10000): void {
        if (this.transmitRightsToReplicant) {
            return;
        }
        this.transmitRightsToReplicant = true;

        const asyncFunction = async (): Promise<void> => {
            while (this.transmitRightsToReplicant) {
                /* eslint-disable no-await-in-loop */
                try {
                    const clinics = await this.accessModel.getAccessGroupPersons(
                        FreedaAccessGroups.clinic
                    );
                    const partners = await this.accessModel.getAccessGroupPersons(
                        FreedaAccessGroups.partner
                    );

                    if (clinics.length > 0) {
                        await this.connectionsModel.connectSettingAccessGroups(
                            clinics[0],
                            partners
                        );
                        this.transmitRightsToReplicant = false;
                    }

                    // I leave this here on purpose for the production, so that we can monitor it during testing.
                    // eslint-disable-next-line no-console
                    console.log('Successfully set access rights for data.');
                } catch (ignore) {
                    // We could log this to console, but this will fail often if we don't have internet connection
                    // So drop the message for now.
                    // console.error(e);
                } finally {
                    if (this.transmitRightsToReplicant) {
                        await new Promise(resolve => {
                            this.timeoutHandle = setTimeout(() => {
                                this.timeoutHandle = null;
                                resolve();
                            }, retryTimeout);
                        });
                    }
                }
                /* eslint-enable no-await-in-loop */
            }
        };
        asyncFunction().catch(console.error);
    }

    /**
     * Stops sending he partner ids to the replicant.
     */
    public stopSendingReplicantRights(): void {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
        }
        this.transmitRightsToReplicant = false;
    }

    /**
     * Updates the partner access group by scanning over contacts.
     *
     * This also will add the contact objects of the partner to the partnerContacts channel.
     *
     * It works by taking all contact objects that are not the replicant or from myself
     * and adds them to the partner group.
     *
     * @returns {Promise<void>}
     */
    private async updatePartnerAccessGroup(): Promise<void> {
        try {
            if (!this.initialized) {
                return;
            }

            const contacts = await this.contactModel.contacts();
            await Promise.all(
                contacts.map(async contact => {
                    const clinicContacts = await this.accessModel.getAccessGroupPersons(
                        FreedaAccessGroups.clinic
                    );
                    const isClinicContact = clinicContacts.indexOf(contact) !== -1;

                    // If it is not a clinic contact then assume it is a partner
                    if (!isClinicContact) {
                        await this.accessModel.addPersonToAccessGroup(
                            FreedaAccessGroups.partner,
                            contact
                        );
                    }

                    // Apply the correct access rights based on in which app we are
                    if (this.isPartnerApp) {
                        await this.giveAccessToChannelsPartnerApp();
                    } else {
                        await this.giveAccessToChannelsPatientApp();
                    }

                    // Push the contact objects to the partnerContacts channel
                    /* if (!isClinicContact) {
                        const contactObjects = await this.contactModel.getContactObjects(contact);
                        await Promise.all(
                            contactObjects.map(async contactObject => {
                                await this.channelManager.postToChannelIfNotExist(
                                    'partnerContacts',
                                    contactObject
                                );
                            })
                        );
                    }*/
                })
            );

            this.startSendingReplicantRights();
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Handles a new chum connection by setting access rights and so on.
     *
     * @param {SHA256IdHash<Person>} localPersonId
     * @param {SHA256IdHash<Person>} remotePersonId
     * @param {CommunicationInitiationProtocol.Protocols} protocol
     * @param {boolean} initiatedLocally
     * @returns {Promise<void>}
     */
    private async handleNewChum(
        localPersonId: SHA256IdHash<Person>,
        remotePersonId: SHA256IdHash<Person>,
        protocol: CommunicationInitiationProtocol.Protocols,
        initiatedLocally: boolean
    ): Promise<void> {
        try {
            if (!this.initialized) {
                return;
            }

            if (!this.mainId || !this.anonymousId) {
                throw new Error('Identities not initialized correctly.');
            }

            // Transmit my anonymous contact data with everybody that was able to build a chum connection.
            // This will then trigger the contact hook on the other side that sets up the rest of the
            // contact stuff.
            const channelAccessRights = [
                {
                    owner: this.anonymousId,
                    persons: [remotePersonId],
                    channels: ['contacts']
                }
            ];
            await this.applyAccessRights(channelAccessRights);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Setup access rights for the patient app.
     *
     * Note that this function is just a hack until groups are functioning properly
     * TODO: this function should be removed when the group data sharing is working
     *
     * @returns {Promise<void>}
     */
    private async giveAccessToChannelsPatientApp(): Promise<void> {
        const mainId = await this.contactModel.myMainIdentity();
        const anonIds = (await this.contactModel.myIdentities()).filter(id => id !== mainId);

        if (anonIds.length !== 1) {
            throw new Error('This model requires the number of alternate Ids to be exactly 1');
        }

        const anonId = anonIds[0];
        const clinics = await this.accessModel.getAccessGroupPersons(FreedaAccessGroups.clinic);
        const partners = await this.accessModel.getAccessGroupPersons(FreedaAccessGroups.partner);

        // Build list of access rights for our own channels
        const channelAccessRights = [
            {
                owner: anonId,
                persons: [mainId, ...partners, ...clinics],
                channels: ['questionnaireResponse', 'consentFile', 'contacts']
            },
            {
                owner: anonId,
                persons: [mainId, ...clinics],
                channels: ['feedbackChannel', 'partnerContacts']
            },
            {
                owner: anonId,
                persons: [mainId],
                channels: [
                    'bodyTemperature',
                    'diary',
                    'newsChannel',
                    'realContacts',
                    'incompleteQuestionnaireResponse'
                ]
            }
        ];

        // Build list of access rights for channels of partners
        for (const partnerIdHash of partners) {
            channelAccessRights.push({
                owner: partnerIdHash,
                persons: [mainId, partnerIdHash],
                channels: ['consentFile']
            });
        }

        await this.applyAccessRights(channelAccessRights);
    }

    /**
     * Setup access rights for the partner app.
     *
     * Note that this function is just a hack until groups are functioning properly
     * TODO: this function should be removed when the group data sharing is working
     *
     * @returns {Promise<void>}
     */
    private async giveAccessToChannelsPartnerApp(): Promise<void> {
        const mainId = await this.contactModel.myMainIdentity();
        const anonIds = (await this.contactModel.myIdentities()).filter(id => id !== mainId);

        if (anonIds.length !== 1) {
            throw new Error('This model requires the number of alternate Ids to be exactly 1');
        }

        const anonId = anonIds[0];
        const clinics = await this.accessModel.getAccessGroupPersons(FreedaAccessGroups.clinic);
        const patients = await this.accessModel.getAccessGroupPersons(FreedaAccessGroups.partner);

        // Build list of access rights for our own channels
        const channelAccessRights = [
            {
                owner: anonId,
                persons: [mainId, ...patients, ...clinics],
                channels: ['consentFile', 'contacts']
            },
            {
                owner: anonId,
                persons: [mainId, ...clinics],
                channels: ['feedbackChannel', 'partnerContacts']
            },
            {
                owner: anonId,
                persons: [mainId],
                channels: [
                    'bodyTemperature',
                    'diary',
                    'newsChannel',
                    'realContacts',
                    'incompleteQuestionnaireResponse'
                ]
            }
        ];

        const serializedCreationOfAllChannels = [];

        // Build list of access rights for channels of patients
        for (const patientIdHash of patients) {
            channelAccessRights.push({
                owner: patientIdHash,
                persons: [mainId, patientIdHash],
                channels: ['questionnaireResponse', 'consentFile']
            });
            // make sure the channel for all patients are created
            serializedCreationOfAllChannels.push(
                serializeWithType(
                    'questionnaireResponse',
                    () => this.channelManager.createChannel('questionnaireResponse', patientIdHash),
                    () => this.channelManager.createChannel('consentFile', patientIdHash)
                )
            );
        }

        // wait for the channel creation before applying access rights
        await Promise.all(serializedCreationOfAllChannels);

        await this.applyAccessRights(channelAccessRights);
    }

    /**
     * Apply the specified channel access rights by writing access objects.
     *
     * Note that the array should not have duplicate entries in regard to owner / channelname combinations.
     * Otherwise only one of them will be applied. Which one is not deterministic.
     *
     * @param {ChannelAccessRights[]} channelAccessRights
     * @returns {Promise<void>}
     */
    private async applyAccessRights(channelAccessRights: ChannelAccessRights[]): Promise<void> {
        await serializeWithType('IdAccess', async () => {
            // Apply the access rights
            await Promise.all(
                channelAccessRights.map(async accessInfo => {
                    await Promise.all(
                        accessInfo.channels.map(async channelId => {
                            try {
                                const setAccessParam = {
                                    id: await calculateIdHashOfObj({
                                        $type$: 'ChannelInfo',
                                        id: channelId,
                                        owner: accessInfo.owner
                                    }),
                                    person: accessInfo.persons,
                                    group: [],
                                    mode: SET_ACCESS_MODE.REPLACE
                                };
                                await getObjectByIdHash(setAccessParam.id); // To check whether a channel with this id exists
                                await createSingleObjectThroughPurePlan({module: '@one/access'}, [
                                    setAccessParam
                                ]);
                            } catch (error) {
                                // If the partner was not connected with this instance previously,
                                // then the calculateIdHashOfObj function will return a FileNotFoundError.
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                if (error.name !== 'FileNotFoundError') {
                                    console.error(error);
                                }
                            }
                        })
                    );
                })
            );
        });
    }
}
