import React from 'react';
import {isIOS, isMobile} from 'react-device-detect';
import {useHistory} from 'react-router';
import i18n, {effectiveLanguage} from '../../i18n';
import {IconButton} from '@material-ui/core';
// the material-ui-flags library does not contain declarations files
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import {IconFlagDE, IconFlagUS} from 'material-ui-flags';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {isStandalone} from '../utils/Utils';

/**
 * @param {{}} props
 * @param {Function} props.setFooter
 * @param {Function} props.setNavigationDrawerState
 * @returns {React.ReactElement}
 */
export default function FooterElement(props: {
    setFooter: (value: number) => void;
    setNavigationDrawerState: (value: boolean) => void;
}): React.ReactElement {
    const history = useHistory();

    /**
     * Handler which is called when the website language is changed
     * @param {string} language
     */
    async function handleChange(language: string): Promise<void> {
        await i18n.changeLanguage(language);
    }

    /**
     * Builds the flags switch
     *
     * @returns {React.ReactElement}
     */
    function footerFlags(): React.ReactElement {
        return (
            <div className="flag-list">
                <Select value={effectiveLanguage()} className="hide-arrow" disableUnderline>
                    <MenuItem className="select-item" value={'de'}>
                        <IconButton onClick={() => handleChange('de')}>
                            <IconFlagDE />
                        </IconButton>
                    </MenuItem>
                    <MenuItem className="select-item" value={'en'}>
                        <IconButton onClick={() => handleChange('en')}>
                            <IconFlagUS />
                        </IconButton>
                    </MenuItem>
                </Select>
            </div>
        );
    }

    return (
        <footer>
            <div className="footer-left">{footerFlags()}</div>
            <div className={`footer-links ${isIOS && isMobile && !isStandalone() ? 'hide' : ''}`}>
                <div className="footer-center">
                    <div
                        className="footer-privacy-page"
                        onClick={() => {
                            props.setNavigationDrawerState(false);

                            if (history.location.pathname !== '/privacyPolicy') {
                                history.push('/privacyPolicy');
                            }

                            props.setFooter(1);
                        }}
                    >
                        {i18n.t('common:settings.privacyPolicy')}
                    </div>
                    <div
                        className="footer-privacy-page"
                        onClick={() => {
                            props.setNavigationDrawerState(false);

                            if (history.location.pathname !== '/impressum') {
                                history.push('/impressum');
                            }

                            props.setFooter(1);
                        }}
                    >
                        {i18n.t('common:settings.impressum')}
                    </div>
                    <div
                        className="footer-privacy-page"
                        onClick={() => {
                            props.setNavigationDrawerState(false);

                            if (history.location.pathname !== '/FAQ') {
                                history.push('/FAQ');
                            }
                            props.setFooter(1);
                        }}
                    >
                        FAQ
                    </div>
                </div>
                <div className="footer-right">
                    <div
                        className="footer-privacy-page"
                        onClick={() => {
                            window.location.href = 'https://refinio.net/';
                            props.setFooter(1);
                        }}
                    >
                        powered by refinio
                    </div>
                </div>
            </div>
        </footer>
    );
}
