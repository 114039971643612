import React from 'react';
import * as dateFns from 'date-fns';
import './News.css';
import ReactMarkdown from 'react-markdown/with-html';
import NewsModel, {News} from 'one.models/lib/models/NewsModel';
import i18n from '../../i18n';
import '../../Primary.css';
import {Alert, AlertTitle} from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import {useSettings} from '../modelHelper/SettingsHelper';
import {ObjectData} from 'one.models/lib/models/ChannelManager';
import {CircularProgress} from '@material-ui/core';
import MenuButton from '../menu/MenuButton';
import {hideCircularProgress} from '../utils/Utils';

/**
 *
 * @param {NewsModel} newsModel
 * @param {string} channelId
 * @returns {ObjectData<News[]>}
 */
export function useNewsFromAChannelId(newsModel: NewsModel, channelId: string): ObjectData<News>[] {
    const [responses, setResponses] = React.useState<ObjectData<News>[]>([]);
    React.useEffect(() => {
        function fetchNews(): void {
            newsModel
                .entries(channelId)
                .then(value => {
                    setResponses(value);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        newsModel.on('news', fetchNews);

        fetchNews();

        return () => {
            newsModel.removeListener('news', fetchNews);
        };
    }, [newsModel]);

    return responses;
}

/**
 * This component build and return the news view.
 * @param {{}} props - Properties of this view.
 * @param {NewsModel} props.newsModel
 * @param {PropertyTree} props.settings
 * @returns {React.ReactElement} the news reader view.
 */
export default function NewsReader(props: {
    newsModel: NewsModel;
    settings: PropertyTree;
}): React.ReactElement {
    const news = useNewsFromAChannelId(props.newsModel, 'newsChannel').reverse();
    // component specific setting for info boxes
    const [hideInfoBox, setHideInfoBox] = useSettings(props.settings, 'hideInfoBox', 'false');

    React.useEffect(() => {
        hideCircularProgress();
    }, [news]);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circularProgress" size={35} />
            </div>
            <div className="page-container hide">
                {hideInfoBox === 'false' ? (
                    <Paper square elevation={3} className="stick-message-top message-font-size">
                        <Alert
                            onClose={async () => {
                                await setHideInfoBox('true');
                            }}
                            severity="info"
                            className="patient-info"
                            key={'alert'}
                        >
                            <AlertTitle className="message-title">
                                {i18n.t('errors:titleInfo')}
                            </AlertTitle>
                            {i18n.t('news:alertContent')}
                        </Alert>
                    </Paper>
                ) : (
                    <></>
                )}
                <div className="scrollable-div">
                    <div className="menu-button-header">
                        <MenuButton />
                        <h2 className="headline"> {i18n.t('common:menu.news')}</h2>
                    </div>
                    {news.length === 0 ? (
                        <Paper square elevation={3} className="page-content-box margin-box">
                            <Alert severity="info" className="patient-info" key={'alert'}>
                                {i18n.t('news:noNews')}
                            </Alert>
                        </Paper>
                    ) : (
                        <>
                            {news.map((post, index) => {
                                return (
                                    <Paper
                                        key={index}
                                        square
                                        elevation={3}
                                        className="page-content-box margin-box"
                                    >
                                        <ReactMarkdown
                                            className="paper-font-size"
                                            escapeHtml={false}
                                            source={post.data.content}
                                        />
                                        <div className="date-style paper-font-size">
                                            {dateFns.format(
                                                post.creationTime,
                                                'MMMM dd yyyy HH:mm'
                                            )}
                                        </div>
                                    </Paper>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
