import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireS: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/S_de',
    name: 'S',
    status: 'active',
    item: [
        {
            linkId: 'S0',
            prefix: 'S0',
            text:
                'Im nächsten Abschnitt bitten wir Sie Fragen zu Ihrem allgemeinen Wohlbefinden zu beantworten. Bitte beantworten Sie die folgenden Fragen, indem Sie die gewünschte Antwortposition auswählen, die Ihre Erfahrungen innerhalb der letzten 7 Tage am besten beschreibt.',
            type: 'display'
        },
        {
            linkId: 'patCareMedPractit',
            prefix: 'S1',
            text:
                'Während der letzten 7 Tage: Wie gut schätzen Sie die Versorgung durch den Hausarzt in der Pandemiezeit ein?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '0',
                        display: 'sehr schlecht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr gut'
                    }
                }
            ]
        },
        {
            linkId: 'patCareClinic',
            prefix: 'S2',
            text:
                'Während der letzten 7 Tage: Wie gut schätzen Sie die aktuelle Krankenversorgung und Verfügbarkeit in Krankenhäusern ein?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '0',
                        display: 'sehr schlecht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr gut'
                    }
                }
            ]
        },
        {
            linkId: 'cancerCareSituat',
            prefix: 'S3',
            text: 'Während der letzten 7 Tage: Wie gut schätzen Sie die aktuelle Krebsversorgungslage ein?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '0',
                        display: 'sehr schlecht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/rate_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr gut'
                    }
                }
            ]
        },
        {
            linkId: 'privateStressLoad',
            prefix: 'S4',
            text: 'Während der letzten 7 Tage: Wie hoch schätzen Sie Ihre persönliche Stressbelastung privat ein?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '0',
                        display: 'sehr gering'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr hoch'
                    }
                }
            ]
        },
        {
            linkId: 'atWorkStressLoad',
            prefix: 'S5',
            text:
                'Während der letzten 7 Tage: Wie hoch schätzen Sie Ihre persönliche Stressbelastung beruflich ein?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '0',
                        display: 'sehr gering'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/HighLow_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr hoch'
                    }
                }
            ]
        },
        {
            linkId: 'fearInfection',
            prefix: 'S6',
            text:
                'Während der letzten 7 Tage: Wie sehr haben Sie im Allgemeinen Angst vor einer Ansteckung an SARS-CoV-2 (derzeitige Coronavirus-Pandemie)?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'infectDelayTherapy',
            prefix: 'S7',
            text:
                'Während der letzten 7 Tage: Wie sehr haben Sie Angst, dass durch eine Erkrankung an COVID-19 eine Verschiebung oder Verzögerung Ihrer (Chemo-)therapie folgt?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'infAndTherapyDeath',
            prefix: 'S8',
            text:
                'Während der letzten 7 Tage: Wie sehr haben Sie Angst, dass sich durch eine Erkrankung an COVID-19 und einer zusätzlichen (Chemo-)therapie oder palliativen Therapie Ihre Gesundheit stärker verschlechtert (bis hin zum Tod)?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'infectInvestigation',
            prefix: 'S9',
            text:
                'Während der letzten 7 Tage: Wie sehr haben Sie Angst, dass durch zusätzliche Untersuchungen (Herzecho, CT, Mammographie, u.a.) die Ansteckungsgefahr an SARS-CoV-2 erhöht ist?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'insecImageMeasure',
            prefix: 'S10',
            text:
                'Während der letzten 7 Tage: Wie sehr verunsichert es Sie, dass aufgrund der Pandemie bildgebende Maßnahmen zu Ihrer Krebserkrankung zur Beurteilung Ihrer Therapie ausfallen müssen?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'insecur2ndryTherap',
            prefix: 'S11_1',
            text:
                'Während der letzten 7 Tage: In Bezug auf andere medikamentöse Therapien, wie sehr fühlen Sie sich hierin verunsichert (Beispiele: Grippe-Impfung, Pneumokokken-Impfung, oder andere)?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'insecTherap_txt',
            prefix: 'S11_2',
            text: 'Was genau verunsichert Sie daran? Bitte beschreiben Sie.',
            type: 'string',
            required: false,
            maxLength: 1000,
            enableWhen: [
                {
                    question: 'insecur2ndryTherap',
                    operator: '>',
                    answerCoding: {
                        code: '0'
                    }
                }
            ]
        },
        {
            linkId: 'insecIsolFamily',
            prefix: 'S12',
            text:
                'Während der letzten 7 Tage: Wie sehr sind Sie durch eine mögliche Quarantänemaßnahme verunsichert in Bezug auf die Isolierung von Ihrer Familie?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'worryLoneliness',
            prefix: 'S13',
            text:
                'Während der letzten 7 Tage: Wie sehr haben Sie Sorge vor Einsamkeit in Ihrer aktuellen Situation?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'vaccPriority',
            prefix: 'S14',
            text:
                'Während der letzten 7 Tage: Wie sehr sehen Sie einen Sinn und eine Möglichkeit in der COVID-Impfung und möchten sich deshalb impfen lassen?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NotAtAllVeryMuch_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr'
                    }
                }
            ]
        },
        {
            linkId: 'S00',
            prefix: 'S00',
            text:
                'Nachfolgend werden Sie zu aktuellen Beschwerden befragt. Dabei kann es sich sowohl um Nebenwirkungen einer Krebstherapie oder Symptome infolge einer Infektion, aber auch Beschwerden bedingt durch andere Auslöser handeln. Bitte beantworten Sie die folgenden Fragen, indem Sie die Antwort auswählen, die Ihre Erfahrungen innerhalb der letzten 7 Tage am besten beschreibt.',
            type: 'display'
        },
        {
            linkId: 'S15_0',
            prefix: 'S15_0',
            text: 'Geschmacksveränderungen beim Essen oder Trinken',
            type: 'group',
            item: [
                {
                    linkId: 'tasteDisorder',
                    prefix: 'S15_1',
                    text:
                        'Während der letzten 7 Tage: Wie STARK waren Ihre GESCHMACKSVERÄNDERUNGEN BEIM ESSEN ODER TRINKEN im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S16_0',
            prefix: 'S16_0',
            text: 'Kurzatmigkeit',
            type: 'group',
            item: [
                {
                    linkId: 'shortnessOfBreath',
                    prefix: 'S16_1',
                    text:
                        'Während der letzten 7 Tage: Wie STARK war Ihre KURZATMIGKEIT im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S17_0',
            prefix: 'S17_0',
            text: 'Probleme sich zu konzentrieren',
            type: 'group',
            item: [
                {
                    linkId: 'concProbl',
                    prefix: 'S17_1',
                    text:
                        'Während der letzten 7 Tage: Wie STARK waren Ihre PROBLEME SICH ZU KONZENTRIEREN im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                },
                {
                    linkId: 'concProblDailyAct',
                    prefix: 'S17_2',
                    text:
                        'Während der letzten 7 Tage: Wie sehr haben Ihre PROBLEME SICH ZU KONZENTRIEREN Sie in Ihren täglichen Aktivitäten GESTÖRT?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S18_0',
            prefix: 'S18_0',
            text: 'Muskelschmerzen',
            type: 'group',
            item: [
                {
                    linkId: 'musclePainFreq',
                    prefix: 'S18_1',
                    text: 'Während der letzten 7 Tage: Wie HÄUFIG hatten Sie MUSKELSCHMERZEN?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                },
                {
                    linkId: 'musclePain',
                    prefix: 'S18_2',
                    text:
                        'Während der letzten 7 Tage: Wie STARK hatten Sie MUSKELSCHMERZEN im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                },
                {
                    linkId: 'muscPainDailyAct',
                    prefix: 'S18_3',
                    text:
                        'Während der letzten 7 Tage: Wie sehr haben MUSKELSCHMERZEN Sie in Ihren täglichen Aktivitäten GESTÖRT?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S19_0',
            prefix: 'S19_0',
            text: 'Probleme beim Schlafen (wie z.B. Schwierigkeiten beim Einschlafen, Durchschlafen oder zu frühes Aufwachen)',
            type: 'group',
            item: [
                {
                    linkId: 'sleepProbl',
                    prefix: 'S19_1',
                    text:
                        'Während der letzten 7 Tage: Wie STARK waren Ihre PROBLEME BEIM SCHLAFEN (WIE Z.B. SCHWIERIGKEITEN BEIM EINSCHLAFEN, DURCHSCHLAFEN ODER ZU FRÜHES AUFWACHEN) im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                },
                {
                    linkId: 'sleepProbDailyAct',
                    prefix: 'S19_2',
                    text:
                        'Während der letzten 7 Tage: Wie sehr haben PROBLEME BEIM SCHLAFEN (WIE Z.B. SCHWIERIGKEITEN BEIM EINSCHLAFEN, DURCHSCHLAFEN ODER ZU FRÜHES AUFWACHEN) Sie in Ihren täglichen Aktivitäten GESTÖRT?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S20_0',
            prefix: 'S20_0',
            text: 'Müdigkeit, Erschöpfung oder fehlende Energie ',
            type: 'group',
            item: [
                {
                    linkId: 'fatigueExhaust',
                    prefix: 'S20_1',
                    text:
                        'Während der letzten 7 Tage: Wie STARK waren Ihre MÜDIGKEIT, ERSCHÖPFUNG ODER FEHLENDE ENERGIE im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                },
                {
                    linkId: 'fatigueExhDailyAct',
                    prefix: 'S20_2',
                    text:
                        'Während der letzten 7 Tage: Wie sehr haben MÜDIGKEIT, ERSCHÖPFUNG ODER FEHLENDE ENERGIE Sie in Ihren täglichen Aktivitäten GESTÖRT?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S21_0',
            prefix: 'S21_0',
            text: 'Angst',
            type: 'group',
            item: [
                {
                    linkId: 'fearFreq',
                    prefix: 'S21_1',
                    text: 'Während der letzten 7 Tage: Wie HÄUFIG hatten Sie ANGST?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '0',
                                display: 'nie'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '1',
                                display: 'selten'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '2',
                                display: 'gelegentlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '3',
                                display: 'häufig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '4',
                                display: 'fast immer'
                            }
                        }
                    ]
                },
                {
                    linkId: 'fear',
                    prefix: 'S21_2',
                    text: 'Während der letzten 7 Tage: Wie STARK war Ihre ANGST im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                },
                {
                    linkId: 'fearDailyAct',
                    prefix: 'S21_3',
                    text:
                        'Während der letzten 7 Tage: Wie sehr hat ANGST Sie in Ihren täglichen Aktivitäten GESTÖRT?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S22_0',
            prefix: 'S22_0',
            text: 'Mutlosigkeit (Gefühl, dass man sich an nichts erfreuen kann)',
            type: 'group',
            item: [
                {
                    linkId: 'notCheerUpFreq',
                    prefix: 'S22_1',
                    text:
                        'Während der letzten 7 Tage: Wie HÄUFIG hatten Sie das GEFÜHL, DASS SIE SICH AN NICHTS ERFREUEN KONNTEN?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '0',
                                display: 'nie'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '1',
                                display: 'selten'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '2',
                                display: 'gelegentlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '3',
                                display: 'häufig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '4',
                                display: 'fast immer'
                            }
                        }
                    ]
                },
                {
                    linkId: 'notCheerUp',
                    prefix: 'S22_2',
                    text:
                        'Während der letzten 7 Tage: Wie STARK hatten Sie das GEFÜHL, DASS SIE SICH AN NICHTS ERFREUEN KONNTEN, im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                },
                {
                    linkId: 'notCheerUpDailyAct',
                    prefix: 'S22_3',
                    text:
                        'Während der letzten 7 Tage: Wie sehr hat Sie das GEFÜHL, DASS SIE SICH AN NICHTS ERFREUEN KONNTEN in Ihren täglichen Aktivitäten GESTÖRT?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'S23_0',
            prefix: 'S23_0',
            text: 'Traurigkeit',
            type: 'group',
            item: [
                {
                    linkId: 'sadFreq',
                    prefix: 'S23_1',
                    text: 'Während der letzten 7 Tage: Wie HÄUFIG waren Sie TRAURIG?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '0',
                                display: 'nie'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '1',
                                display: 'selten'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '2',
                                display: 'gelegentlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '3',
                                display: 'häufig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/HowOften_de',
                                version: '1.0',
                                code: '4',
                                display: 'fast immer'
                            }
                        }
                    ]
                },
                {
                    linkId: 'sad',
                    prefix: 'S23_2',
                    text:
                        'Während der letzten 7 Tage: Wie STARK war Ihre TRAURIGKEIT im SCHLIMMSTEN FALL?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                },
                {
                    linkId: 'sadDailyAct',
                    prefix: 'S23_3',
                    text:
                        'Während der letzten 7 Tage: Wie sehr hat Ihre TRAURIGKEIT Sie in Ihren täglichen Aktivitäten GESTÖRT?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '0',
                                display: 'gar nicht'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '1',
                                display: 'ein wenig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '2',
                                display: 'mäßig'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '3',
                                display: 'ziemlich'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/severity_de',
                                version: '1.0',
                                code: '4',
                                display: 'sehr'
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
