import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import QuestionnaireModel, {QuestionnaireResponse,
    Questionnaire,
    Question,QuestionnaireResponses} from 'one.models/lib/models/QuestionnaireModel';
import './CovidQuestionnaire.css';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    List,
    Mark,
    Paper,
    Radio,
    RadioGroup,
    Slider,
    TextField
} from '@material-ui/core';
import {
    AnyObject,

    Person,
    SHA256IdHash
} from '@OneCoreTypes';
import i18n from '../../i18n';
import '../../Primary.css';
import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import {useSettings} from '../modelHelper/SettingsHelper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoMessage, {MessageType} from '../errors/InfoMessage';
import MenuButton from '../menu/MenuButton';
import StringQuestion from './StringQuestion';
import {formatDate, hideCircularProgress} from '../utils/Utils';
import QuestionnaireResponseBuilder, {
    QuestionnaireResponseBuilderItem,
    QuestionnaireResponseBuilderValidationErrorCode
} from './QuestionnaireResponseBuilder';
import {isHash} from 'one.core/lib/util/type-checks';
import {ObjectData} from 'one.models/lib/models/ChannelManager';

/**
 * Compares if two arrays are equal by checking equality of the first level of items.
 *
 * @param a - first array
 * @param b - second array
 */
function arraysEqual(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
}

/**
 * This thing gets an array and returns the same array.
 *
 * But the returned array will have be the same instance over multiple renders, if the content
 * does not change.
 *
 * @param arr
 */
function useArray<T>(arr: T[]): T[] {
    const [retArray, setRetArray] = useState(arr);
    useEffect(() => {
        if (!arraysEqual(retArray, arr)) {
            // It is okay to set the state inside of a useEffect, because effects are
            // executed after the render has finished.
            setRetArray(arr);
        }
    }, [retArray, arr]);
    return retArray;
}

/**
 * This thing gets an array and returns the same array.
 *
 * But the returned array will have be the same instance over multiple renders, if the content
 * does not change.
 *
 * @param arr
 */
function useArrayUndefined<T>(arr: T[] | undefined): T[] | undefined {
    const [retArray, setRetArray] = useState(arr);

    useEffect(() => {
        if (!arr || !retArray) {
            if (arr !== retArray) {
                setRetArray(arr);
            }
        } else if (!arraysEqual(retArray, arr)) {
            // It is okay to set the state inside of a useEffect, because effects are
            // executed after the render has finished.
            setRetArray(arr);
        }
    }, [retArray, arr]);

    return retArray;
}

/**
 * This returns a function that can be used to force a rerender.
 *
 * This is sometimes required, when registering for an update of a complex data structure
 * where only the content changes, but not the address, so updating a state would not trigger
 * a re-render.
 */
function useForceRender(): () => void {
    const [, setRedrawState] = useState(false);
    const stateRef = useRef(true);

    return useCallback(() => {
        setRedrawState(stateRef.current);
        stateRef.current = !stateRef.current;
    }, [setRedrawState]);
}

/**
 * Exactly as useQuestionnairesByName but returning QuestionnaireResponseBuilder instead
 * of the questionnaires.
 *
 * @param questionnaires
 * @param questionnaireResponses
 * @param updatedCallback
 */
function useQuestionnairesBuilders(
    questionnaires: Questionnaire[],
    questionnaireResponses: Map<string, QuestionnaireResponse>,
    updatedCallback: () => void
): Map<string, QuestionnaireResponseBuilder> {
    const [builders, setBuilders] = useState(new Map<string, QuestionnaireResponseBuilder>());

    useEffect(() => {
        // Build the questionnaire response builders
        const map = new Map<string, QuestionnaireResponseBuilder>();

        for (const questionnaire of questionnaires) {
            if (questionnaire.name) {
                map.set(
                    questionnaire.name,
                    new QuestionnaireResponseBuilder(
                        questionnaire,
                        questionnaireResponses.get(questionnaire.name)
                    )
                );
            }
        }
        setBuilders(map);

        // Register the events
        for (const value of map.values()) {
            value.on('updated', updatedCallback);
        }

        // Deregister event handlers
        return () => {
            for (const value of map.values()) {
                value.removeListener('updated', updatedCallback);
            }
        };
    }, [questionnaireResponses, questionnaires, updatedCallback]);

    return builders;
}

interface StateInterface {
    next(): void;
    prev(): void;
    set(state: string): void;
}

/**
 * This implementation manages states that can be stepped through or set.
 *
 * If the content of the states array changes, the state will be reset to the
 * initial state again.
 *
 * @param states
 * @param initialState
 */
function useStateList(states: string[], initialState: string): [string, StateInterface] {
    // Introduce a state for selecting questionnaires
    const [currentState, setState] = useState(initialState);
    const [statesInternal, setStatesInternal] = useState(states);

    function next(count: number = 1): void {
        const newIndex = states.findIndex(state => state === currentState) + count;

        if (states.length === 0) {
            set(initialState);
        } else if (newIndex < states.length) {
            set(states[newIndex]);
        } else {
            set(states[0]);
        }
    }

    function prev(count: number = 1): void {
        const newIndex = states.findIndex(state => state === currentState) - count;

        if (states.length === 0) {
            set(initialState);
        } else if (newIndex >= 0) {
            set(states[newIndex]);
        } else {
            set(states[states.length - 1]);
        }
    }

    function set(newState: string): void {
        setState(newState);
    }

    // This effect will reset the state to initial when the state array content changes.
    // Note this will cause a rerender.
    useEffect(() => {
        if (!arraysEqual(statesInternal, states) && initialState !== currentState) {
            // It is okay to set the state inside of a useEffect, because effects are
            // executed after the render has finished.
            setStatesInternal(states);
            setState(initialState);
        }
    }, [statesInternal, states, initialState, currentState]);

    // Return the state and the manipulating functions.
    return [
        currentState,
        {
            next,
            prev,
            set
        }
    ];
}

/**
 * Specifies how incomplete responses are handled.
 */
enum IncompleteMode {
    Disabled,
    LoadIncomplete,
    StartNew
}

/**
 * Load the questionnaires and the response objects.
 *
 * This function has the following modes:
 * 1) Load an old response by response hash -> This is usually used for view mode.
 * The questionnaires are loaded from the questionnaire urls that are stored in the
 * responses
 * -> responseId must be set to the hash of the response object to load
 * => questionniareNames will be ignored
 * => language will be ignored
 * 2) Load questionnaires based on parameter.
 * -> questionnaireNames must be a list of questionnaire names
 * -> (optional) language must be the selected language
 * -> responseId must be undefined - otherwise it will be mode 1)
 * 3) Load questionnaire based on parameter but also keep in sync with incomplete channel
 * -> questionnaireNames must be a list of questionnaire names
 * -> (optional) language must be the selected language
 * -> responseId must be undefined - otherwise it will be mode 1)
 * -> loadIncomplete must be true
 * @param questionnaireModel
 * @param questionnaireNames
 * @param language
 * @param responseId
 * @param incompleteMode
 * @param incompleteType
 */
async function loadQuestionnairesAndResponses(
    questionnaireModel: QuestionnaireModel,
    questionnaireNames: string[] | undefined,
    language: string | undefined,
    responseId: string | undefined,
    incompleteMode: IncompleteMode,
    incompleteType: string
): Promise<[Map<string, Questionnaire>, Map<string, QuestionnaireResponse>, string]> {
    const questionnaireUrls: string[] = [];
    const responses: QuestionnaireResponse[] = [];
    let incompleteResponseHash: string = '';

    // Load The questionnaire URLs and possibly load the response
    if (responseId) {
        // Load the responses
        const responsesObject = await questionnaireModel.responsesById(responseId);

        // Get the questionnaire urls
        for (const response of responsesObject.data.response) {
            if (response.questionnaire) {
                questionnaireUrls.push(response.questionnaire);
                responses.push(response);
            }
        }
    } else if (questionnaireNames) {
        // Load the urls from the questionnaire model by name and language
        questionnaireUrls.push(
            ...(await Promise.all(
                questionnaireNames.map(name =>
                    questionnaireModel.questionnaireUrlByName(name, language)
                )
            ))
        );

        // Handle incompletes
        if (incompleteMode === IncompleteMode.LoadIncomplete) {
            const incompleteResponse = await questionnaireModel.incompleteResponse(incompleteType);

            if (incompleteResponse) {
                incompleteResponseHash = incompleteResponse.dataHash;
                responses.push(...incompleteResponse.data.response);
            }
        }

        if (incompleteMode === IncompleteMode.StartNew) {
            await questionnaireModel.markIncompleteResponseAsComplete(incompleteType);
        }
    }

    // Load the questionnaires
    const questionnaires = await Promise.all(
        questionnaireUrls.map(url => questionnaireModel.questionnaireByUrl(url))
    );

    // Transform everything to maps
    const questionnaireMap = new Map<string, Questionnaire>();
    const responseMap = new Map<string, QuestionnaireResponse>();

    for (const questionnaire of questionnaires) {
        if (!questionnaire.name) {
            continue;
        }

        // Set the questionnaire in the questionnaire map
        questionnaireMap.set(questionnaire.name, questionnaire);

        // Set the response in the response map
        const response = responses.find(r => r.questionnaire === questionnaire.url);

        if (response) {
            responseMap.set(questionnaire.name, response);
        }
    }

    return [questionnaireMap, responseMap, incompleteResponseHash];
}

/**
 * This function loads questionnaires based on either the passed questionnaire names and language, or
 * based on the response. The quesionnaireNames take precedence.
 * @param questionnaireModel
 * @param questionnaireNames
 * @param language
 * @param responseId
 * @param incompleteMode
 * @param setError
 */
function useQuestionnairesAndResponses(
    questionnaireModel: QuestionnaireModel,
    questionnaireNames: string[] | undefined,
    language: string | undefined,
    responseId: string | undefined,
    incompleteMode: IncompleteMode,
    setError: (err: string) => void
): [Map<string, Questionnaire>, Map<string, QuestionnaireResponse>] {
    const [ret, setRet] = useState<
        [Map<string, Questionnaire>, Map<string, QuestionnaireResponse>]
    >([new Map<string, Questionnaire>(), new Map<string, QuestionnaireResponse>()]);

    // Make questionnaire names only change when the content changes.
    const stableQuestionnaireNames = useArrayUndefined(questionnaireNames);

    // Load everything
    useEffect(() => {
        const incompleteType = stableQuestionnaireNames ? stableQuestionnaireNames.join(',') : '';
        let incompleteResponseHash: string = '';

        function fetch(): void {
            loadQuestionnairesAndResponses(
                questionnaireModel,
                stableQuestionnaireNames,
                language,
                responseId,
                incompleteMode,
                incompleteType
            )
                .then(qAndR => {
                    const [qMap, rMap, iHash] = qAndR;
                    incompleteResponseHash = iHash;
                    setRet([qMap, rMap]);
                })
                .catch(err => setError(err.toString()));
        }

        function fetchForIncomplete(): void {
            questionnaireModel
                .incompleteResponse(incompleteType)
                .then((response: ObjectData<QuestionnaireResponses> | null) => {
                    // Fetch new data if
                    // - one of the old/new response is null
                    // - both are not null and the data hashes differ
                    if (incompleteResponseHash === '' && response !== null) {
                        fetch();
                    } else if (incompleteResponseHash !== '' && response === null) {
                        fetch();
                    } else if (incompleteResponseHash !== '' && response !== null) {
                        if (incompleteResponseHash !== response.dataHash) {
                            fetch();
                        }
                    }
                })
                .catch(err => setError(err.toString()));
        }

        fetch();

        // If in any incomplete mode, listen for new incomplete responses
        // How do we know that a new incomplete response of the requested type
        // has happened? We would need to compare them before doing the fetch!
        if (incompleteMode !== IncompleteMode.Disabled) {
            // Fetch the questionnaires
            questionnaireModel.on('updatedIncomplete', fetchForIncomplete);

            return () => {
                questionnaireModel.removeListener('updatedIncomplete', fetchForIncomplete);
            };
        }
    }, [
        questionnaireModel,
        stableQuestionnaireNames,
        language,
        responseId,
        setError,
        incompleteMode
    ]);

    return ret;
}

/**
 *
 * @param {{}} props - Properties of this view.
 * @param {QuestionnaireModel} props.questionnaireModel
 * @param {string} props.productType
 * @param {PropertyTree} props.settings
 * @returns {React.ReactElement} the view of the CoV2 questionnaire.
 */
export default function CovidQuestionnaire(props: {
    questionnaireModel: QuestionnaireModel;
    productType: string;
    settings: PropertyTree;
}): React.ReactElement {
    const {action} = useParams();
    let viewMode: boolean;
    let incompleteMode: IncompleteMode;

    // Evaluate the action and set variables accordingly
    if (action === 'edit') {
        incompleteMode = IncompleteMode.LoadIncomplete;
        viewMode = false;
    } else if (action === 'new') {
        incompleteMode = IncompleteMode.StartNew;
        viewMode = false;
    } else {
        // view is default
        incompleteMode = IncompleteMode.Disabled;
        viewMode = true;
    }

    // Setup a state to select the current questionnaire
    const [error, setError] = useState('');

    // Extract the parameters from the URL
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramResponse = queryParams.get('response');
    const paramLanguage = queryParams.get('language');
    const paramQuestionnaire = queryParams.get('questionnaires');
    const showPrefixStr = queryParams.get('showPrefix');
    const paramChannelOwner = queryParams.get('channelOwner');
    const showPrefix = showPrefixStr && showPrefixStr === 'true';
    const forceRender = useForceRender();

    // parse the channel owner
    const channelOwner = isHash<Person>(paramChannelOwner)
        ? (paramChannelOwner as SHA256IdHash<Person>)
        : undefined;

    // Build the questionnaire response builders
    const [questionnairesMap, responsesMap] = useQuestionnairesAndResponses(
        props.questionnaireModel,
        paramQuestionnaire ? paramQuestionnaire.split(',') : undefined,
        paramLanguage ? paramLanguage : undefined,
        paramResponse ? paramResponse : undefined,
        incompleteMode,
        setError
    );

    // Extract the names of the questionnaires and the questionnaires and build the builders
    const questionnaireNames: string[] = useArray([...questionnairesMap.keys()]);
    const questionnaires: Questionnaire[] = useArray([...questionnairesMap.values()]);
    const builders = useQuestionnairesBuilders(questionnaires, responsesMap, forceRender);

    // Introduce a state for selecting questionnaires
    const [questionnaireName, qStateApi] = useStateList(
        questionnaireNames,
        questionnaireNames.length > 0 ? questionnaireNames[0] : ''
    );

    // auto scroll flag from application settings
    const [autoScroll] = useSettings(props.settings, 'autoScroll', 'true');

    // we need something that keep a reference for each question
    const refs = {} as AnyObject;

    // setting that specifies if the progress of the questionnaire is saved or not when the component is closed
    const [saveQuestionnaireProgress] = useSettings(
        props.settings,
        'saveQuestionnaireProgress',
        'true'
    );

    // component specific setting for info boxes
    const [hideInfoBox, setHideInfoBox] = useSettings(props.settings, 'hideInfoBox', 'false');

    // state for displaying the information box for saving the progress of a questionnaire
    const [
        savingIncompleteQuestionnaireInformation,
        setSavingIncompleteQuestionnaireInformation
    ] = useState(saveQuestionnaireProgress === 'true');

    // when the info box is closed, the state is stored as a setting
    useEffect(() => {
        if (!savingIncompleteQuestionnaireInformation) {
            setHideInfoBox('true').catch(err => setError(err.toString()));
        }
    }, [saveQuestionnaireProgress, setHideInfoBox, savingIncompleteQuestionnaireInformation]);

    // Variables for currently selected questionnaire
    const builder = builders.get(questionnaireName);
    const [questionnaireValidationFailed, setQuestionnaireValidationFailed] = useState(false);
    const paperRef = React.createRef<HTMLDivElement>();

    // Calculate the paging numbers (starts from 1)
    let currentPage = questionnaireNames.findIndex(name => name === questionnaireName) + 1;
    let totalPages = questionnaireNames.length;

    if (currentPage < 1) {
        currentPage = 1;
    }

    // Check if we have to skip the 'I' questionnaire.
    // TODO: This is project specific, so we should remove it when we have a generic way for this
    let skipIQuestionnaire = false;

    {
        const imIndex = questionnaireNames.findIndex(name => name === 'IM');
        const iIndex = questionnaireNames.findIndex(name => name === 'I');

        if (imIndex >= 0 && iIndex === imIndex + 1) {
            const imBuilder = builders.get('IM');

            if (imBuilder) {
                const imAnswers = imBuilder.getAnswers('infYNU');

                // If the infYNU answer is not 'Yes' (Infected), then we don't count the I questionnaire
                // This means that we have to
                if (
                    imAnswers.length === 0 ||
                    imAnswers[0].valueCoding === undefined ||
                    imAnswers[0].valueCoding.code !== '1'
                ) {
                    --totalPages;
                    skipIQuestionnaire = true;

                    if (currentPage > iIndex) {
                        --currentPage;
                    }
                }
            }
        }
    }

    useEffect(() => {
        setError('');
    }, [builders]);

    // Clear the error if a new questionnaire is selected
    useEffect(() => {
        // Set the correct initial state based on the status of the loaded questionnaires
        //
        // The current behavior is to skip to page 1 if an incomplete questionnaire was
        // loaded that has a page filled out with incompleted answers in a previous index.
        if (incompleteMode !== IncompleteMode.Disabled) {
            let index = 0;

            for (const [name, b] of builders) {
                ++index;

                if (index < currentPage && b.status() === 'in-progress') {
                    history.push('/');
                    //qStateApi.set(questionnaireNames[0]);
                    break;
                }
            }
        }

        // Forward from edit to new
        if (action === 'new') {
            const editPath =
                history.location.pathname.slice(0, -3) + 'edit' + history.location.search;
            history.push(editPath);
        }
    }, [builders, action, history, incompleteMode, qStateApi, questionnaireNames]);

    /**
     * Submits the incomplete state of the questionnaire to the incomplete channel.
     */
    const submitIncomplete = useCallback(
        async (completed: boolean): Promise<void> => {
            const incompleteType = questionnaireNames.join(',');

            if (completed) {
                await props.questionnaireModel.markIncompleteResponseAsComplete(incompleteType);
            } else {
                const responses = [];

                // If no questionnaires are loaded, then do not write incomplete entries.
                if (incompleteType === '') {
                    return;
                }

                // Build the responses array
                for (const [currentBilderName, currentBuilder] of builders) {
                    if (skipIQuestionnaire && currentBilderName === 'I') {
                        continue;
                    }
                    responses.push(currentBuilder.buildResponse());
                }

                // Store the responses array
                // On success go to the home screen
                // On failure set the error state
                await props.questionnaireModel.postIncompleteResponseCollection(
                    responses,
                    incompleteType
                );
            }
        },
        [builders, props.questionnaireModel, questionnaireNames, skipIQuestionnaire]
    );

    // Incomplete handling - on each question answered
    // We deactivated this, because we only want to sace incompletes on submit for now
    // because of the 'too fast klick' issue that results in lost answers.
    /* useEffect(() => {
        const currBuilder = builder;

        **
         * Creates an incomplete entry with the new state
         *
        function handler() {
            submitIncomplete(false);
        }

        if (currBuilder) {
            currBuilder.on('updated', handler);

            return () => {
                currBuilder.removeListener('updated', handler);
            };
        }
    }, [builder, submitIncomplete]);*/

    /**
     * Submit function that is called when the submit button is pressed.
     *
     * This function will check the validity of the current questionnaire.
     * If not valid, it will set the error state accordingly.
     * If valid it will jump to the next questionnaire or store the
     * questionnaire if the last questionnaire was reached.
     */
    function submit(): void {
        async function submitInternal(): Promise<void> {
            if (!builder) {
                return;
            }

            // Check if questionnaire is valid => step to next or store
            if (builder.validate()) {
                builder.setStatus('completed');

                // Check if it is the last questionnaire
                if (currentPage === totalPages) {
                    const responses = [];
                    const responsesTypes = [];

                    // Build the responses array
                    for (const [currentBilderName, currentBuilder] of builders) {
                        if (skipIQuestionnaire && currentBilderName === 'I') {
                            continue;
                        }
                        responses.push(currentBuilder.buildResponse());
                        responsesTypes.push(currentBilderName);
                    }

                    await submitIncomplete(true);

                    // Store the responses array
                    // On success go to the home screen
                    // On failure set the error state
                    await props.questionnaireModel.postResponseCollection(
                        responses,
                        undefined,
                        responsesTypes.join(','),
                        channelOwner
                    );

                    history.push('/');
                } else {
                    // Scroll to the top of the questionnaire of the next questionnaire
                    scrollPaperToTop();

                    await submitIncomplete(false);

                    // Jump to the next questionnaire in line
                    setError('');
                    qStateApi.next();
                    setQuestionnaireValidationFailed(false);
                }
            } else {
                if (
                    builder.validationErrors.find(
                        validationError =>
                            validationError.code ===
                            QuestionnaireResponseBuilderValidationErrorCode.requiredNotPresent
                    )
                ) {
                    setError('errors:covidQuestionaire.answerAllQuestions');
                }
                setFinishedQuestion(builder.validationErrors[0].linkId);
                setQuestionnaireValidationFailed(true);
            }
        }

        submitInternal().catch(e => setError(e.toString()));
    }

    /**
     * @param linkId
     */
    function setFinishedQuestion(linkId: string | undefined): void {
        if (linkId) {
            scrollToCenter(linkId);
        }
    }

    /**
     * Used to iterate over a questionnaire and build the questionnaire html structure.
     */
    function buildQuestionnaire(): React.ReactElement {
        const questionnaire = [];

        const curentQuestionnaire = questionnaires[0];

        let i = 0;

        if (curentQuestionnaire) {
            if (builder !== undefined) {
                for (const questionContainer of builder.questionIterator({
                    hideDisabledValues: true
                })) {
                    // creating a ref for the question
                    refs[questionContainer.question.linkId] = React.createRef();

                    i++;

                    questionnaire.push(mapQuestion(questionContainer, undefined, i === 1));

                    if (questionContainer.subItems) {
                        questionnaire.push(buildSubQuestions(questionContainer));
                    }
                }
                hideCircularProgress();
            }
        }

        return <>{questionnaire}</>;
    }

    /**
     * Used to build up the subquestions of a group parent question.
     * @param questionResponseBuilderItem
     */
    function buildSubQuestions(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem
    ): React.ReactElement {
        const subQuestions = [];

        if (questionResponseBuilderItem.subItems === undefined) {
            return <></>;
        }

        let openChoiceQuestions: QuestionnaireResponseBuilderItem[] = [];

        for (const subItem of questionResponseBuilderItem.subItems) {
            // creating a ref for the question
            refs[subItem.question.linkId] = React.createRef();

            if (subItem.subItems) {
                subQuestions.push(buildSubQuestions(subItem));
            }

            if (subItem.question.type === 'open-choice') {
                openChoiceQuestions.push(subItem);
                continue;
            }

            if (openChoiceQuestions.length > 0) {
                subQuestions.push(
                    buildOpenChoiceSubgroup(questionResponseBuilderItem, openChoiceQuestions)
                );
                openChoiceQuestions = [];
            }
            subQuestions.push(mapQuestion(subItem, true));
        }

        if (openChoiceQuestions.length > 0) {
            subQuestions.push(
                buildOpenChoiceSubgroup(questionResponseBuilderItem, openChoiceQuestions)
            );
        }

        return (
            <div key={'buildSubquestions' + questionResponseBuilderItem.question.linkId}>
                {subQuestions}
            </div>
        );
    }

    /**
     * This is used to threat a special case for open-choice questions within a group.
     * Because they are seen as answers for a group question, we need to manage them together,
     * so we are building a container with all open-choice questions.
     *
     * @param parentQuestionResponseBuilder
     * @param openChoiceArray
     */
    function buildOpenChoiceSubgroup(
        parentQuestionResponseBuilder: QuestionnaireResponseBuilderItem,
        openChoiceArray: QuestionnaireResponseBuilderItem[]
    ): React.ReactElement {
        return (
            <div
                className={`answersBodyBasicQuestion openChoiceContainer ${
                    parentQuestionResponseBuilder.enabled ? '' : 'greyColor'
                }`}
                key={parentQuestionResponseBuilder.question.linkId}
            >
                {openChoiceArray.map(openChoiceSubquestion => (
                    <div key={openChoiceSubquestion.question.linkId}>
                        {buildAnswers(openChoiceSubquestion, true)}
                    </div>
                ))}
            </div>
        );
    }

    const [date, setDate] = useState<Date>();

    /**
     * This function scrolls to the question, given as a parameter, if the autoScroll is selected.
     * @param {string} linkId - the question linkId
     */
    function scrollToCenter(linkId: string): void {
        if (autoScroll === 'true' && linkId in refs && refs[linkId].current !== null) {
            refs[linkId].current.scrollIntoView({
                behavior: 'auto',
                block: 'center'
            });
        }
    }

    /**
     * This function builds the body of the question.
     * @param {Question} question - the content of the question
     * @param enabled
     * @param validationFailed
     * @returns {React.ReactElement} - The body of question
     */
    function buildQuestion(
        question: Question,
        enabled: boolean,
        validationFailed: boolean
    ): React.ReactElement {
        return (
            <>
                {question.text && (
                    <div
                        className={`${question.text === '' ? '' : 'questionBody'}  ${
                            enabled ? '' : 'greyColor'
                        } ${validationFailed && questionnaireValidationFailed ? 'error' : ''}
                ${question.type === 'display' ? 'display-question' : ''}`}
                    >
                        {showPrefix && question.prefix} {question.text}
                    </div>
                )}
            </>
        );
    }

    /**
     * @param start
     * @param end
     * @param order
     */
    function buildAnswersForQuestionOfTypeInteger(
        start: number,
        end: number,
        order?: string
    ): string[] {
        // since answers are used only in html elements,
        // with string type will be easier to work with them
        const answers: string[] = [];

        // building the answers in ascending order
        for (let i = start; i <= end; i++) {
            answers.push(i.toString());
        }

        if (order && order === 'descending') {
            // return them in descending order
            return answers.reverse();
        }

        return answers;
    }

    /**
     * Used for building the marks for slider question type.
     * @param {Question} question - for each answer of the question will be created a mark.
     * @returns {Mark[]} - the marks that will be displayed.
     */
    function buildMarks(question: Question): Mark[] {
        const marks: {value: number; label: string}[] = [];

        if (question.answerOption) {
            for (const answer of question.answerOption) {
                if (answer.valueCoding && !isNaN(Number(answer.valueCoding.code))) {
                    marks.push({
                        value: Number(answer.valueCoding.code),
                        label: answer.valueCoding.display || ''
                    });
                }
            }
        }

        return marks;
    }

    /**
     * Used to set properly the answer of an integer question.
     * @param questionResponseBuilderItem
     * @param answers
     * @param newAnswer
     */
    function onIntegerQuestionAnswerChanged(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem,
        answers: string[],
        newAnswer: string
    ): void {
        const foundAnswer = answers.find(answer => answer === newAnswer);

        questionResponseBuilderItem.setAnswer(
            foundAnswer
                ? [
                      {
                          valueInteger: foundAnswer
                      }
                  ]
                : []
        );
    }

    /**
     * This function builds the body of the answers of the question given as a parameter.
     * @param questionResponseBuilderItem
     * @param {boolean} isFromGroup - used to apply different style for question type open-choice
     * @returns {React.ReactElement} - The body of answers
     */
    function buildAnswers(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem,
        isFromGroup?: boolean
    ): React.ReactElement {
        const component = [];

        switch (questionResponseBuilderItem.question.type) {
            case 'group':
            case 'display': {
                break;
            }
            case 'choice': {
                const answers = questionResponseBuilderItem.question.answerOption
                    ? questionResponseBuilderItem.question.answerOption
                    : [];
                component.push(
                    <div
                        ref={refs[questionResponseBuilderItem.question.linkId]}
                        key={questionResponseBuilderItem.question.linkId}
                        className={`answersBodyBasicQuestion ${
                            questionResponseBuilderItem.enabled ? '' : 'greyColor'
                        } `}
                    >
                        {answers.map((answer, idx) => {
                            const displayedAnswer = answer.valueCoding
                                ? answer.valueCoding.display
                                : '';

                            const value =
                                questionResponseBuilderItem.value[0] &&
                                questionResponseBuilderItem.value[0].valueCoding
                                    ? questionResponseBuilderItem.value[0].valueCoding.display
                                    : '';

                            return (
                                <div
                                    key={
                                        questionResponseBuilderItem.question.linkId + idx.toString()
                                    }
                                    className="choice"
                                >
                                    <RadioGroup
                                        key={idx}
                                        value={value}
                                        onChange={() => {
                                            questionResponseBuilderItem.setAnswer([answer]);

                                            setFinishedQuestion(
                                                questionResponseBuilderItem.nextEnabledItem()
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            className={'answersSize radio-text-height'}
                                            value={displayedAnswer}
                                            control={<Radio />}
                                            label={displayedAnswer}
                                        />
                                    </RadioGroup>
                                </div>
                            );
                        })}
                    </div>
                );
                break;
            }
            case 'open-choice': {
                const value =
                    questionResponseBuilderItem.value[0] &&
                    questionResponseBuilderItem.value[0].valueCoding
                        ? questionResponseBuilderItem.value[0].valueCoding.display
                        : '';

                component.push(
                    <FormControlLabel
                        key={questionResponseBuilderItem.question.linkId}
                        className={`answersSize ${isFromGroup ? 'openChoiceWidth' : 'openChoice'} ${
                            !questionResponseBuilderItem.enabled && !isFromGroup ? 'greyColor' : ''
                        }`}
                        value={value}
                        onChange={() => {
                            if (value) {
                                questionResponseBuilderItem.setAnswer([]);
                            } else if (questionResponseBuilderItem.question.answerOption) {
                                questionResponseBuilderItem.setAnswer(
                                    questionResponseBuilderItem.question.answerOption
                                );
                            }
                        }}
                        control={<Checkbox />}
                        label={
                            questionResponseBuilderItem.question.answerOption &&
                            questionResponseBuilderItem.question.answerOption[0] &&
                            questionResponseBuilderItem.question.answerOption[0].valueCoding
                                ? questionResponseBuilderItem.question.answerOption[0].valueCoding
                                      .display
                                : ''
                        }
                        checked={
                            questionResponseBuilderItem.question.answerOption &&
                            questionResponseBuilderItem.question.answerOption[0] &&
                            questionResponseBuilderItem.question.answerOption[0].valueCoding &&
                            questionResponseBuilderItem.question.answerOption[0].valueCoding
                                .display === value
                        }
                    />
                );
                break;
            }
            case 'string':
            case 'date': {
                const value = questionResponseBuilderItem.value[0]
                    ? questionResponseBuilderItem.value[0].valueString
                        ? questionResponseBuilderItem.value[0].valueString
                        : questionResponseBuilderItem.value[0].valueDate
                        ? questionResponseBuilderItem.value[0].valueDate
                        : ''
                    : '';

                component.push(
                    <div
                        ref={refs[questionResponseBuilderItem.question.linkId]}
                        key={questionResponseBuilderItem.question.linkId}
                        className={`answersBodyBasicQuestion ${
                            questionResponseBuilderItem.enabled ? '' : 'greyColor'
                        }`}
                    >
                        <StringQuestion
                            currentValue={value}
                            question={questionResponseBuilderItem.question}
                            setQuestionAnswer={questionResponseBuilderItem.setAnswer}
                            setFinishedQuestion={setFinishedQuestion}
                            viewMode={viewMode}
                        />
                    </div>
                );
                break;
            }
            case 'integer': {
                let answers: string[] = [];

                if (
                    questionResponseBuilderItem.question.answerRestriction !== undefined &&
                    questionResponseBuilderItem.question.answerRestriction.minValue &&
                    questionResponseBuilderItem.question.answerRestriction.minValue.valueInteger &&
                    questionResponseBuilderItem.question.answerRestriction.maxValue &&
                    questionResponseBuilderItem.question.answerRestriction.maxValue.valueInteger
                ) {
                    const maxInclusive =
                        questionResponseBuilderItem.question.answerRestriction.maxInclusive ===
                            undefined ||
                        questionResponseBuilderItem.question.answerRestriction.maxInclusive;
                    const minInclusive =
                        questionResponseBuilderItem.question.answerRestriction.minInclusive ===
                            undefined ||
                        questionResponseBuilderItem.question.answerRestriction.minInclusive;

                    let maxValue = Number(
                        questionResponseBuilderItem.question.answerRestriction.maxValue.valueInteger
                    );
                    let minValue = Number(
                        questionResponseBuilderItem.question.answerRestriction.minValue.valueInteger
                    );

                    // removing the edges of the answers interval if needed
                    if (maxValue > minValue) {
                        if (!maxInclusive) {
                            maxValue--;
                        }

                        if (!minInclusive) {
                            minValue++;
                        }
                    } else {
                        if (!maxInclusive) {
                            maxValue++;
                        }

                        if (!minInclusive) {
                            minValue--;
                        }
                    }

                    // that's because for some questions we want the answers to be ordered descending
                    if (minValue > maxValue) {
                        // build answers in descending order
                        answers = buildAnswersForQuestionOfTypeInteger(
                            maxValue,
                            minValue,
                            'descending'
                        );
                    } else {
                        // build answers in ascending order
                        answers = buildAnswersForQuestionOfTypeInteger(
                            minValue,
                            maxValue,
                            'ascending'
                        );
                    }
                }

                answers.push('');

                const value =
                    questionResponseBuilderItem.value[0] &&
                    questionResponseBuilderItem.value[0].valueInteger
                        ? questionResponseBuilderItem.value[0].valueInteger
                        : null;

                component.push(
                    <div
                        ref={refs[questionResponseBuilderItem.question.linkId]}
                        key={questionResponseBuilderItem.question.linkId}
                        className={`answersBodyBasicQuestion ${
                            questionResponseBuilderItem.enabled ? '' : 'greyColor'
                        }`}
                    >
                        <div className="autocomplete autocomplete-input">
                            <Autocomplete
                                options={answers}
                                getOptionLabel={answer => answer}
                                value={value}
                                onInputChange={(_, newValue) => {
                                    if (newValue === '' && questionResponseBuilderItem.enabled) {
                                        questionResponseBuilderItem.setAnswer([]);
                                    }
                                }}
                                onChange={(_, newValue: string | null) => {
                                    if (newValue !== null) {
                                        questionResponseBuilderItem.setAnswer([
                                            {
                                                valueInteger: newValue
                                            }
                                        ]);
                                        setFinishedQuestion(
                                            questionResponseBuilderItem.nextEnabledItem()
                                        );
                                    }
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        value={value}
                                        variant="outlined"
                                        onKeyDown={event => {
                                            if (event.key === 'Enter') {
                                                const enteredAnswer = (event.target as HTMLInputElement)
                                                    .value;

                                                onIntegerQuestionAnswerChanged(
                                                    questionResponseBuilderItem,
                                                    answers,
                                                    enteredAnswer
                                                );
                                                setFinishedQuestion(
                                                    questionResponseBuilderItem.nextEnabledItem()
                                                );
                                                (event.target as HTMLInputElement).blur();
                                            }
                                        }}
                                        onChange={event => {
                                            onIntegerQuestionAnswerChanged(
                                                questionResponseBuilderItem,
                                                answers,
                                                event.target.value
                                            );
                                        }}
                                        onBlur={() => {
                                            setFinishedQuestion(
                                                questionResponseBuilderItem.nextEnabledItem()
                                            );
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </div>
                );
                break;
            }
            case 'slider': {
                const marks = buildMarks(questionResponseBuilderItem.question);

                const value =
                    questionResponseBuilderItem.value[0] &&
                    questionResponseBuilderItem.value[0].valueCoding
                        ? Number(questionResponseBuilderItem.value[0].valueCoding.code)
                        : -1;

                component.push(
                    <div
                        ref={refs[questionResponseBuilderItem.question.linkId]}
                        key={questionResponseBuilderItem.question.linkId}
                        className={`answersBodyBasicQuestion ${
                            questionResponseBuilderItem.enabled ? '' : 'greyColor'
                        }`}
                    >
                        {viewMode ? (
                            <Slider
                                value={value}
                                aria-labelledby="discrete-slider-always"
                                step={1}
                                marks={marks}
                                min={marks[0].value}
                                max={marks[marks.length - 1].value}
                            />
                        ) : (
                            <Slider
                                className={`slider ${value === -1 ? 'hide-slider-bullet' : ''}`}
                                value={value}
                                onChangeCommitted={(_, val: number | number[]) => {
                                    if (questionResponseBuilderItem.question.answerOption) {
                                        for (const answer of questionResponseBuilderItem.question
                                            .answerOption) {
                                            if (
                                                answer.valueCoding &&
                                                !isNaN(Number(answer.valueCoding.code)) &&
                                                Number(answer.valueCoding.code) === val
                                            ) {
                                                setFinishedQuestion(
                                                    questionResponseBuilderItem.nextEnabledItem()
                                                );
                                                questionResponseBuilderItem.setAnswer([answer]);
                                            }
                                        }
                                    }
                                }}
                                aria-labelledby="discrete-slider-always"
                                step={1}
                                marks={marks}
                                min={marks[0].value}
                                max={marks[marks.length - 1].value}
                            />
                        )}
                    </div>
                );
                break;
            }
            default: {
                component.push(<div>Wrong type of question</div>);
                break;
            }
        }

        return <>{component}</>;
    }

    /**
     * @param questionResponseBuilderItem
     * @param isFromGroup
     * @param isFirstQuestion
     */
    function mapQuestion(
        questionResponseBuilderItem: QuestionnaireResponseBuilderItem,
        isFromGroup?: boolean,
        isFirstQuestion?: boolean
    ): React.ReactElement {
        return (
            <List
                className={`componentContainer ${
                    isFromGroup || isFirstQuestion ? 'remove-top-border' : ''
                }`}
                key={'mapQuestions' + questionResponseBuilderItem.question.linkId}
            >
                {questionResponseBuilderItem.question.type === 'open-choice'
                    ? ''
                    : buildQuestion(
                          questionResponseBuilderItem.question,
                          questionResponseBuilderItem.enabled,
                          questionResponseBuilderItem.validationFailed
                      )}
                {buildAnswers(questionResponseBuilderItem, isFromGroup)}
            </List>
        );
    }

    /**
     * Used to scroll the paper which contains the questionnaire
     * to the top while navigating through a set of questionnaires.
     */
    function scrollPaperToTop(): void {
        if (paperRef !== null && paperRef.current !== null) {
            paperRef.current.scrollTo(0, 0);
        }
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container questionnaire-container hide">
                {error && (
                    <InfoMessage
                        displayMessage={error !== ''}
                        setDisplayMessage={setError}
                        errorMessage={error}
                        messageType={MessageType.Error}
                    />
                )}
                {!viewMode && hideInfoBox === 'false' && (
                    <InfoMessage
                        errorMessage={'errors:covidQuestionaire.savingQuestionnaireProgress'}
                        displayMessage={savingIncompleteQuestionnaireInformation}
                        setDisplayMessage={setSavingIncompleteQuestionnaireInformation}
                        messageType={MessageType.Info}
                    />
                )}
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">
                        {questionnaireNames.length
                            ? i18n.t(`common:questionnaire.${questionnaireNames.toString()}`)
                            : i18n.t('common:questionnaire')}
                    </h2>
                    <div className="questionnaire-progress-indicator">
                        {currentPage}/{totalPages}
                    </div>
                </div>
                {viewMode && <div className="title-element"> {formatDate(date)} </div>}
                <Paper
                    square
                    elevation={3}
                    className="page-content-box questionnaire-content"
                    ref={paperRef}
                >
                    <div className={viewMode ? 'cov-questions-view-mode' : 'cov-questions'}>
                        {buildQuestionnaire()}
                    </div>
                </Paper>
                {viewMode ? (
                    <div className="buttons-container">
                        <div>
                            <Button
                                color="primary"
                                disabled={currentPage === 1}
                                variant="contained"
                                className="button"
                                onClick={() => {
                                    qStateApi.prev();
                                    scrollPaperToTop();
                                }}
                            >
                                {i18n.t('common:buttons.previous')}
                            </Button>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                disabled={currentPage === totalPages}
                                variant="contained"
                                className="button button-margin-left"
                                onClick={() => {
                                    qStateApi.next();
                                    scrollPaperToTop();
                                }}
                            >
                                {i18n.t('common:buttons.next')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="buttons-container">
                        <Button
                            color="primary"
                            variant="contained"
                            className="button"
                            onClick={submit}
                        >
                            {i18n.t('common:buttons.submit')}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
}
