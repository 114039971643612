import React from 'react';
import i18n from '../../i18n';
import {Icon} from '../icon/Icon';
import {CircularProgress, ListItem, ListItemIcon, ListItemText, TextField} from '@material-ui/core';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import download from 'downloadjs';
import MenuButton from '../menu/MenuButton';
import {displayCircularProgress, hideCircularProgress} from '../utils/Utils';
import InfoMessage, {MessageType} from '../errors/InfoMessage';

/**
 * @param {{}} props
 * @param {OneInstanceModel} props.oneInstanceModel
 * @returns {React.ReactElement}
 */
export default function Backup(props: {oneInstanceModel: OneInstanceModel}): React.ReactElement {
    const [openUseDataPermission, setOpenUseDataPermission] = React.useState(false);
    const [openStoreData, setOpenStoreDataPermission] = React.useState(false);

    const [useDataPermission, setUseDataPermission] = React.useState<boolean>(false);
    const [storeDataPermission, setStoreDataPermission] = React.useState<boolean>(false);

    const fileInput = React.createRef<HTMLDivElement>();

    const [informationMessage, setInformationMessage] = React.useState('');
    const [error, isError] = React.useState(false);
    const [success, isSuccess] = React.useState(false);

    React.useEffect(() => {
        if (storeDataPermission) {
            exportData()
                .then(() => {
                    hideCircularProgress();
                })
                .catch(err => {
                    console.error('Error: ', err);
                    hideCircularProgress();
                });
        }
    }, [storeDataPermission]);

    React.useEffect(() => {
        if (useDataPermission) {
            triggerInputFile();
        }
    }, [useDataPermission]);

    function triggerInputFile(): void {
        if (fileInput.current !== undefined && fileInput.current !== null) {
            if (fileInput.current.click !== undefined) {
                fileInput.current.click();
            }
        }
    }

    function onCloseFileDialog(): void {
        setTimeout(() => {
            setUseDataPermission(false);
        }, 1000);
    }

    async function exportData(): Promise<void> {
        const file = await props.oneInstanceModel.backupInstance();
        download(file, 'one.backup.html', 'text/html');
        setStoreDataPermission(false);
    }

    async function importData(event: React.FormEvent<EventTarget>): Promise<void> {
        if (useDataPermission) {
            let file: File | undefined;

            if (event.target instanceof HTMLInputElement) {
                if (event.target.files !== null) {
                    file = event.target.files[0];
                }
            }

            if (!file) {
                setUseDataPermission(false);
                return;
            }

            // extracting the file extension from the file name
            const fileExtension = file.name
                .substring(file.name.length - 5, file.name.length)
                .toLowerCase();

            if (fileExtension === '.html') {
                await props.oneInstanceModel
                    .restoreInstance(file)
                    .then(() => {
                        hideCircularProgress();
                        isError(false);
                        setInformationMessage('common:backup.successImport');
                        isSuccess(true);
                    })
                    .catch(() => {
                        hideCircularProgress();
                        isSuccess(false);
                        setInformationMessage('errors:importDocument.corruptedFile');
                        isError(true);
                    });
            } else {
                isSuccess(false);
                setInformationMessage('errors:backup.formatNotAccepted');
                isError(true);
            }

            setUseDataPermission(false);
        }
    }

    return (
        <>
            <div className="circular-progress-container hide">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container">
                {error ? (
                    <InfoMessage
                        errorMessage={informationMessage}
                        displayMessage={error}
                        setDisplayMessage={isError}
                        messageType={MessageType.Error}
                    />
                ) : (
                    success && (
                        <InfoMessage
                            errorMessage={informationMessage}
                            displayMessage={success}
                            setDisplayMessage={isSuccess}
                            messageType={MessageType.Success}
                        />
                    )
                )}
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:settings.backup')}</h2>
                </div>
                <ListItem
                    onClick={() => {
                        setOpenStoreDataPermission(true);
                    }}
                    button
                >
                    <ListItemIcon>
                        <Icon name="Export" />
                    </ListItemIcon>
                    <ListItemText>{i18n.t('common:settings.export')}</ListItemText>
                </ListItem>
                <label
                    onClick={() => {
                        setOpenUseDataPermission(true);
                    }}
                    style={{cursor: 'pointer'}}
                >
                    <ListItem>
                        <ListItemIcon>
                            <Icon name="Import" />
                        </ListItemIcon>
                        <ListItemText>{i18n.t('common:settings.import')}</ListItemText>
                    </ListItem>
                </label>
                <label>
                    <TextField
                        ref={fileInput}
                        type="file"
                        id="file-input"
                        onClick={() => {
                            document.body.onfocus = onCloseFileDialog;
                        }}
                        onChange={async e => {
                            displayCircularProgress();
                            await importData(e).then(() => hideCircularProgress());
                        }}
                        style={{display: 'none'}}
                    />
                </label>
                <Dialog
                    open={openUseDataPermission}
                    onClose={() => {
                        setOpenUseDataPermission(false);
                    }}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {i18n.t('common:backup.usePersonalData')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenUseDataPermission(false);
                            }}
                            color="primary"
                        >
                            {i18n.t('common:backup.cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenUseDataPermission(false);
                                setUseDataPermission(true);
                            }}
                            color="primary"
                            autoFocus
                        >
                            {i18n.t('common:backup.agree')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openStoreData}
                    onClose={() => {
                        setOpenStoreDataPermission(false);
                    }}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {i18n.t('common:backup.usePersonalDevice')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenStoreDataPermission(false);
                            }}
                            color="primary"
                        >
                            {i18n.t('common:backup.cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenStoreDataPermission(false);
                                setStoreDataPermission(true);
                            }}
                            color="primary"
                            autoFocus
                        >
                            {i18n.t('common:backup.agree')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
