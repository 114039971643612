import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireT: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://uk-erlangen.de/freeda/questionnaire/T_en',
    name: 'T',
    status: 'active',
    item: [
        {
            linkId: 'therapyBreakYN',
            prefix: 'T1',
            text: 'Was your cancer therapy interrupted since the last survey?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                }
            ]
        },
        {
            linkId: 'T2_0',
            prefix: 'T2_0',
            text: 'For how long was your therapy interrupted?',
            type: 'group',
            enableWhen: [
                {
                    question: 'therapyBreakYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'therapyBreakFrom',
                    prefix: 'T2_1',
                    text: 'from',
                    type: 'date'
                },
                {
                    linkId: 'therapyBreakUntil',
                    prefix: 'T2_2',
                    text: 'to',
                    type: 'date'
                }
            ]
        },
        {
            linkId: 'ecog1YNU',
            prefix: 'T3',
            type: 'choice',
            text: 'Since the last interview, are you able to walk and do light physical work?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'therapyChangeNewYNU',
            prefix: 'T4',
            type: 'choice',
            text:
                'Has a new cancer therapy been started or has your cancer therapy been changed since you answered the last questionnaire?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'T5_0',
            prefix: 'T5_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'therapyChangeNewYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'numberOfTherapy',
                    prefix: 'T5_1',
                    type: 'integer',
                    text: 'How many cancer therapies including the new one did you receive?',
                    enableWhen: [
                        {
                            question: 'numberOfTherapyUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ],
                    answerRestriction: {
                        minValue: {
                            valueInteger: '1'
                        },
                        maxValue: {
                            valueInteger: '80'
                        }
                    }
                },
                {
                    linkId: 'numberOfTherapyUk',
                    prefix: 'T5_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_en',
                                version: '1.0',
                                code: '1',
                                display: 'I do not know'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'T6_0',
            prefix: 'T6_0',
            type: 'group',
            text:
                'Which of the following therapies do you now receive because of your cancer? (multiple answers possible)',
            enableWhen: [
                {
                    question: 'therapyChangeNewYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'ahTherapyYNU',
                    prefix: 'T6_1',
                    text: 'anti-hormonal therapy',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'chemoYNU',
                    prefix: 'T6_2',
                    text: 'chemotherapy',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'checkPointYNU',
                    prefix: 'T6_3',
                    text:
                        'checkpoint inhibitor therapy (immunotherapy antibodies, e.g. Pembrolizumab, Atezolizumab, Iplimumab, Nivolumab)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'AntiHerYNU',
                    prefix: 'T6_4',
                    text: 'anti-HER2-targeted therapy (e.g. Herceptin®, Kanjinti®, Perjeta®)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherTherapyYNU',
                    prefix: 'T6_5',
                    text: 'another antibody therapy',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'cdkiYNU',
                    prefix: 'T6_6',
                    text: 'CDK4/6 inhibitor therapy (e.g. Ibrance®, Kisquali®, Verzenios®)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'mToriYNU',
                    prefix: 'T6_7',
                    text: 'mTOR inhibitor therapy (e.g. everolimus/rapamycin, sirolimus)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'radYNU',
                    prefix: 'T6_8',
                    text: 'radiotherapy of the chest or lungs (complete or partial)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'autStemCellYNU',
                    prefix: 'T6_9',
                    text: 'autologous stem cell transplants',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'allogStemCellYNU',
                    prefix: 'T6_10',
                    text: 'allogenic stem cell transplants',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'carTcellYNU',
                    prefix: 'T6_11',
                    text: 'CAR-T cell therapy',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherTargTherapYNU',
                    prefix: 'T6_12',
                    text: 'other targeted therapy (e.g. for genetic mutation)',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bOtherTarg_txt',
                    prefix: 'T6_13',
                    text: 'What other targeted therapy is it?',
                    type: 'string',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'otherTargTherapYNU',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'opYNU',
                    prefix: 'T6_14',
                    text: 'Have you been undergone surgery after the last interview? ',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'opType_txt',
                    prefix: 'T6_15',
                    text: 'What kind of surgery was it?',
                    type: 'string',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'opYNU',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'medication_txt',
            prefix: 'T7',
            text:
                'Here you still have the possibility to enter your exact cancer therapy. (multiple answers possible)',
            type: 'string',
            maxLength: 1000,
            enableWhen: [
                {
                    question: 'therapyChangeNewYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            required: false
        }
    ]
};
