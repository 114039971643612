import React from 'react';
import {Collapse, Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Link} from 'react-router-dom';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import MenuButton from '../menu/MenuButton';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

/*
 type definition of the layout of elements of settings  menu
 */
export type SettingsItemsType = {
    name: string;
    path?: string;
    icon?: React.ReactElement;
    subItems?: SettingsItemsType[];
};

/**
 * @param {{}} props
 * @param {SettingsItemsType} props.settingsItems
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {boolean} props.isMenuOpen
 * @returns {React.ReactElement}
 */
export default function Settings(props: {
    settingsItems: SettingsItemsType[];
    oneInstanceModel: OneInstanceModel;
    isMenuOpen: boolean;
}): React.ReactElement {
    const [open, setOpen] = React.useState(false);

    function handleClick(): void {
        setOpen(!open);
    }

    function displayElement(
        name: string,
        path: string,
        icon: React.ReactElement,
        index: number,
        hasSubItems: boolean = false
    ): React.ReactElement {
        return (
            <Link className="app-menu-entry" to={path}>
                <ListItem key={index} button>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{name}</ListItemText>
                    {hasSubItems ? open ? <ExpandLess /> : <ExpandMore /> : ''}
                </ListItem>
            </Link>
        );
    }

    return (
        <>
            <MenuButton />
            <List>
                {props.settingsItems.map(({name, path, icon, subItems}, index) => (
                    <div key={index}>
                        {path && icon ? (
                            subItems ? (
                                <>
                                    <div onClick={handleClick}>
                                        {displayElement(name, path, icon, index, true)}
                                    </div>
                                    <Collapse
                                        className="navigation-drawer-sub-items"
                                        in={open}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        {subItems.map(
                                            (
                                                {name: subName, path: subPath, icon: subIcon},
                                                idx
                                            ) => (
                                                <div key={idx}>
                                                    {displayElement(
                                                        subName,
                                                        subPath ? subPath : path,
                                                        subIcon ? subIcon : icon,
                                                        idx
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </Collapse>
                                </>
                            ) : (
                                displayElement(name, path, icon, index)
                            )
                        ) : (
                            <Divider />
                        )}
                    </div>
                ))}
            </List>
        </>
    );
}
