import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireVB: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/VB_de',
    name: 'VB',
    status: 'active',
    item: [
        { linkId: 'bVpatIntensCuYNU',
            prefix: 'VB1',
            text: 'Wird die teilnehmende Person auf einer Intensivstation behandelt? ',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        { linkId: 'bVpatIntubatYNU',
            prefix: 'VB2',
            text: 'Wird die teilnehmende Person künstlich beatmet?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        { linkId: 'bVpatLoadCare',
            prefix: 'VB3',
            text:
                'Denken Sie an sich selbst. Wie sehr belastet die Coronasituation Sie bei der Betreuung der Ihnen vertrauten krebskranken Person zusätzlich?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr belastend'
                    }
                }
            ]
        },
        { linkId: 'bVpatLoadRelation',
            prefix: 'VB4',
            text:
                'Denken Sie an sich selbst. Wie sehr belastet die Coronasituation Ihre Beziehung zu der Ihnen vertrauten krebskranken Person?',
            type: 'slider',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr belastend'
                    }
                }
            ]
        }
    ]
};
