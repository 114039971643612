import React, {createRef} from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import './displayMarkdownFiles.css';
import Paper from '@material-ui/core/Paper';
import i18n from '../../i18n';
import {
    addScrollbar,
    contentAreaLargerThanDisplayArea,
    removeScrollbar,
    updateScrollbarAtWindowResize,
    verifyIfBottomWasReached,
    hideCircularProgress
} from '../utils/Utils';

function getConfirmationOfExitingTheStudy(dropoutContent: string): string {
    if (dropoutContent) {
        const content = dropoutContent.split('|');
        const titleContent = i18n.t('common:infos.patientTitle');
        const participantContent = `${i18n.t('common:infos.patientId')} ${content[0]}`;
        const dateContent = `${i18n.t('common:date.date')}: ${content[1]}`;
        const reason = `${i18n.t('common:infos.reason')}: ${content[2]}`;
        return (
            titleContent +
            '<br /> <br />' +
            participantContent +
            '<br /> <br />' +
            dateContent +
            '<br /> <br />' +
            reason
        );
    }

    return '';
}

/**
 * @param {{}} props - if the file path
 * represents a text written in markdown instead of a file path from which
 * the text should be read, the parameter isText should be set to true,
 * else the parameter can be left without a value
 * @param {string} props.filePath
 * @param {boolean} props.isText
 * @param {Function} props.setDisabled
 * @param props.withoutPaper
 * @returns {React.ReactElement}
 */
export default function DisplayMarkdownFiles(props: {
    filePath: string;
    isText?: boolean;
    setDisabled?: (value: boolean) => void;
    withoutPaper?: boolean;
}): React.ReactElement {
    const [markdownText, setMarkdownText] = React.useState({markdown: ''});
    const ref = createRef<HTMLElement>();

    React.useEffect(() => {
        if (props.isText) {
            setMarkdownText({markdown: getConfirmationOfExitingTheStudy(props.filePath)});
        } else {
            // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-assignment
            const exactPath = require('../../' + props.filePath);

            fetch(exactPath)
                .then(res => res.text())
                .then(text => {
                    setMarkdownText({markdown: text});
                })
                .catch(error => {
                    throw error;
                });
        }
    }, [props.filePath, props.isText]);

    React.useEffect(() => {
        if (markdownText.markdown !== '') {
            hideCircularProgress();
        }

        /**
         * When the page content is displayed make sure the page is scrolled to the top.
         */
        if (ref.current && ref.current.children[0]) {
            ref.current.children[0].scrollTo(0, 0);
        }
    }, [markdownText]);

    React.useEffect(() => {
        const element = ref.current;

        if (element) {
            const needScrollbar = contentAreaLargerThanDisplayArea(element);

            if (needScrollbar) {
                addScrollbar(element);

                if (props.setDisabled) {
                    props.setDisabled(!verifyIfBottomWasReached(element));
                }
            } else {
                removeScrollbar(element);

                if (props.setDisabled) {
                    props.setDisabled(false);
                }
            }
        }
    });

    React.useEffect(() => {
        if (ref.current) {
            updateScrollbarAtWindowResize(ref.current);
        }
    }, []);

    /**
     * Displays the content of a markdown file.
     */
    function displayMarkdownContent(): React.ReactElement {
        return (
            <div className="markdown-content">
                <ReactMarkdown
                    escapeHtml={false}
                    source={
                        props.isText
                            ? getConfirmationOfExitingTheStudy(props.filePath)
                            : markdownText.markdown
                    }
                />
            </div>
        );
    }

    return (
        <>
            {props.withoutPaper ? (
                displayMarkdownContent()
            ) : (
                <Paper
                    ref={ref}
                    square
                    elevation={3}
                    className="page-content-box paper-font-size"
                    onScroll={(event: any) => {
                        if (props.setDisabled) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            const isAtBottom = verifyIfBottomWasReached(event.target);

                            if (isAtBottom) {
                                return props.setDisabled(false);
                            }
                        }
                    }}
                >
                    {displayMarkdownContent()}
                </Paper>
            )}
        </>
    );
}
