import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireVS: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://uk-erlangen.de/freeda/questionnaire/VS_en',
    name: 'VS',
    status: 'active',
    item: [
        {
            linkId: 'patHealth',
            prefix: 'VS1_1',
            text: 'How well is the person participating in the study?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NoSymptomesHome_en',
                        version: '1.0',
                        code: '1',
                        display: 'symptom free at home'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/SymptomesHome_en',
                        version: '1.0',
                        code: '2',
                        display: 'symptomatic at home'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/SymptomesHospital_en',
                        version: '1.0',
                        code: '3',
                        display: 'with symptoms at the hospital'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/death_en',
                        version: '1.0',
                        code: '4',
                        display: 'passed away'
                    }
                }
            ]
        },
        {
            linkId: 'dateOfPatDeath',
            prefix: 'VS1_2',
            text: 'When did the participating person pass away?',
            type: 'date',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerRestriction: {
                maxValue: {
                    valueDate: 'now'
                }
            }
        },
        {
            linkId: 'VS2_0',
            prefix: 'VS2_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            item: [
                {
                    linkId: 'howPatDeath',
                    prefix: 'VS2_1',
                    text: 'How did the participating person die?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/deathReason_en',
                                version: '1.0',
                                code: '1',
                                display: 'COVID-19'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/deathReason_en',
                                version: '1.0',
                                code: '2',
                                display: 'cancer'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/deathReason_en',
                                version: '1.0',
                                code: '999',
                                display: 'other'
                            }
                        }
                    ],
                    required: false
                },
                {
                    linkId: 'otherDeath',
                    prefix: 'VS2_2',
                    type: 'string',
                    maxLength: 1000,
                    text: 'Please specify from what the person died of:',
                    enableWhen: [
                        {
                            question: 'howPatDeath',
                            operator: '=',
                            answerCoding: {
                                code: '999'
                            }
                        }
                    ],
                    required: true
                }
            ]
        },
        {
            linkId: 'VpatIntensCuYNU',
            prefix: 'VS3',
            text: 'Is the participating person being treated in an intensive care unit?',
            type: 'choice',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '!=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'VpatIntubatYNU',
            prefix: 'VS4',
            text:
                'Is the participating person receiving artificial respiration?',
            type: 'choice',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '!=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'VpatLoadCare',
            prefix: 'VS5',
            text:
                'Think about yourself. How much of a burden does the corona situation place on you when caring for the participating person?',
            type: 'slider',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '!=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '10',
                        display: 'very stressful'
                    }
                }
            ]
        },
        {
            linkId: 'VpatLoadRelation',
            prefix: 'VS6',
            text:
                'Think about yourself. How much does the corona situation burden your relationship with the participating person?',
            type: 'slider',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '!=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '0',
                        display: 'not at all'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_en',
                        version: '1.0',
                        code: '10',
                        display: 'very stressful'
                    }
                }
            ]
        }
    ]
};
