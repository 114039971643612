import EventEmitter from 'events';
import {
    Question,
    Questionnaire,
    QuestionnaireEnableWhenAnswer,
    QuestionnaireResponse,
    QuestionnaireResponseItem,
    QuestionnaireValue
} from 'one.models/lib/models/QuestionnaireModel';
import * as dateFns from 'date-fns';
import {formatAnswerRestrictionDate} from './QuestionnaireCommon';

/**
 * An item representing the current question and other data like the current answer ...
 */
export type QuestionnaireResponseBuilderItem = {
    /**
     * The current question
     */
    question: Question;

    /**
     * The current value.
     *
     * This will even be set if the questionnaire is disabled, but it won't be saved
     * when the final response is compiled.
     */
    value: QuestionnaireValue[];

    /**
     * Enabled state of question
     */
    enabled: boolean;

    /**
     * The setter for setting new values
     */
    setAnswer: (data: QuestionnaireValue[]) => void;

    /**
     * Set to true if the validation of the answer failed.
     */
    validationFailed: boolean;

    /**
     * The level number for nested questions. 0 = root level
     */
    level: number;

    /**
     * Stack of level names, level 0 is the url of the questionnaire
     *
     * Example: ['http://refinio.one/questionnaire1', 'group1', 'question1']
     */
    stack: string[];

    /**
     * The whole path including the questionnaire url
     *
     * Example: 'http://refinio.one/questionnaire1/group1/question1'
     */
    path: string;

    /**
     * Iterator for iterating sub items
     */
    subItems?: IterableIterator<QuestionnaireResponseBuilderItem>;

    /**
     * Get the linkId of the next question that is enabled.
     *
     * Note: This function only returns the correct result after the iteration
     *       encountered the next enabled question. That is the reason why this
     *       is a function - so that the values can be updated of past elements
     *       while the iteration is still continuing.
     *
     * @returns If there is no next item, undefined is returned.
     */
    nextEnabledItem: () => string | undefined;
};

/**
 * Those are the different validation errors that may happen during validation.
 */
export enum QuestionnaireResponseBuilderValidationErrorCode {
    requiredNotPresent = 'requiredNotPresent',
    futureDateNotAllowed = 'futureDateNotAllowed',
    pastDateNotAllowed = 'pastDateNotAllowed',
    integerNotInRange = 'integerNotInRange'
}

/**
 * This represents a validation error
 */
type QuestionnaireResponseBuilderValidationError = {
    /**
     * The kind of validation error that happened.
     */
    code: QuestionnaireResponseBuilderValidationErrorCode;

    /**
     * The linkid of the item that failed validation.
     */
    linkId: string;

    /**
     * The prefix of the item that failed validation.
     */
    prefix?: string;
};

/**
 * Options for iterating questions.
 */
type QuestionnaireResponseBuilderIteratorOptions = {
    /**
     * If true, no validation is done (default: false)
     */
    disableValidation?: boolean;
    /**
     * If true, then the iteration is flat - all children are also iterated by the top iterator
     *
     * As a consequence the subItems of the iterator value will always be undefined.
     */
    flatIteration?: boolean;

    /**
     * For disables elements, do not set the value field.
     */
    hideDisabledValues?: boolean;

    /**
     * Do not iterate disabled questions.
     */
    hideDisabledQuestions?: boolean;
};

/**
 * Internal options for iterating questions.
 */
type QuestionnaireResponseBuilderIteratorOptionsInternal = {
    disableValidation: boolean;
    flatIteration: boolean;
    hideDisabledValues: boolean;
    hideDisabledQuestions: boolean;
    forceDisable: boolean;
};

/**
 * This is a utility class that makes it easy to implement rendering questionnaires.
 *
 * It provides an iterator that you can use to simultaneously iterate over questionnaires
 * and a response object. It also calculates the enabled state the current value etc.
 */
export default class QuestionnaireResponseBuilder extends EventEmitter {
    public questionnaire: Questionnaire;
    public errors: string[] = [];
    public validationErrors: QuestionnaireResponseBuilderValidationError[] = [];
    private response: QuestionnaireResponse;
    private disableErrors: boolean = false;

    /**
     * Construct a new instance from a questionnaire and possible a response object.
     *
     * If you don't pass a response object, the status will always be initialized with in-progress.
     *
     * @param questionnaire
     * @param response
     */
    constructor(questionnaire: Questionnaire, response?: QuestionnaireResponse) {
        super();
        this.questionnaire = questionnaire;

        // Sanity checks for existing response objects
        if (response) {
            this.response = response;

            // Set the questionnaire link if non was specified.
            if (this.response.questionnaire === undefined) {
                this.response.questionnaire = this.questionnaire.url;
            }

            // Check if the response object comes from the correct questionnaire.
            if (this.questionnaire.url !== this.response.questionnaire) {
                throw new Error(
                    'The specified response object is incompatible with the supplied questionnaire object.'
                );
            }
        }

        // Create new response object if none exists
        else {
            this.response = {
                resourceType: 'QuestionnaireResponse',
                questionnaire: this.questionnaire.url,
                status: 'in-progress',
                item: []
            };
        }
    }

    /**
     * Iterates over all questions of the questionnaire.
     *
     * After this call the this.errors and this.validationErrors will contain the errors
     * that happened while iterating.
     *
     * @param options
     */
    public* questionIterator(
        options?: QuestionnaireResponseBuilderIteratorOptions
    ): IterableIterator<QuestionnaireResponseBuilderItem> {
        this.errors = [];
        this.validationErrors = [];
        this.disableErrors = false;

        const url = this.questionnaire.url ? this.questionnaire.url : '/';
        const nextMap = new Map<string, string>();
        yield* this.questionIteratorInternal(this.questionnaire.item, this.response.item, [url], {
            disableValidation:
                options && options.disableValidation ? options.disableValidation : false,
            flatIteration: options && options.flatIteration ? options.flatIteration : false,
            hideDisabledValues:
                options && options.hideDisabledValues ? options.hideDisabledValues : false,
            hideDisabledQuestions:
                options && options.hideDisabledQuestions ? options.hideDisabledQuestions : false,
            forceDisable: false
        });
    }

    /**
     * Builds the final response objects from all the answers.
     *
     * This basically iterates over everything and excludes the disabled questions.
     *
     * After this call the this.errors and this.validationErrors will contain the errors
     * that happened while iterating.
     */
    public buildResponse(): QuestionnaireResponse {
        const response = {...this.response};

        /**
         * Builds the response items recursively by iterating over all
         * response builder items and extracting the answers.
         *
         * @param iter
         */
        function buildResponseRecursive(
            iter: IterableIterator<QuestionnaireResponseBuilderItem>
        ): QuestionnaireResponseItem[] {
            const returnItems: QuestionnaireResponseItem[] = [];

            for (const item of iter) {
                returnItems.push({
                    linkId: item.question.linkId,
                    answer: item.value,
                    item: item.subItems ? buildResponseRecursive(item.subItems) : undefined
                });
            }

            return returnItems;
        }

        // Collect all the items by excluding the disabled ones.
        response.item = buildResponseRecursive(
            this.questionIterator({
                hideDisabledQuestions: true
            })
        );
        return response;
    }

    /**
     * Checks whether the current answers are valid.
     *
     * This happens by iterating over all items and checking whether they
     * produce any validation errors.
     *
     * After this call the this.errors and this.validationErrors will contain the errors
     * that happened while iterating.
     */
    public validate(): boolean {
        const it = this.questionIterator({
            flatIteration: true,
            hideDisabledValues: true,
            hideDisabledQuestions: true
        });

        for (const ignore of it) {
            // Do nothing, we just want an iteration of all alements
            // and later inspect if validation errors happened.
        }

        return this.validationErrors.length === 0;
    }

    /**
     * Get an answer from the reponse object by this linkid searching the passed response items.
     *
     * Note: The FHIR standard requires a certain search order when searching for a value based on the link id
     *       e.g. inside of enableWhen evaluations. This search order makes a difference when a questionnaire
     *       uses the same linkid multiple times. (Apparently it doesn't have to be unique)
     *       The workflow is roughly this, but have a look at the documentation:
     *       1) Search the hierarchy upwards (from the current element)
     *       2) Search the questions before
     *       3) Search the questions after
     *       At the moment we assume, that we have unique linkids, so we do not use this approach and just search from
     *       top to bottom (depth first).
     *
     * @param linkId - The link of the question / response item
     */
    public getAnswers(linkId: string): QuestionnaireValue[] {
        for (const responseItem of this.responseItemIterator(this.response.item)) {
            if (responseItem.linkId === linkId) {
                return responseItem.answer;
            }
        }

        return [];
    }

    public setStatus(status: QuestionnaireResponse['status']): void {
        this.response.status = 'completed';
    }

    public status(): QuestionnaireResponse['status'] {
        return this.response.status;
    }

    // ############ PRIVATE IMPLEMENTATION ############

    /**
     * Iterates over all questions and also recurses into children.
     *
     * @param questions - The questions to iterate.
     * @param responseItems - The response item container.
     * @param initialStack - The stack when starting the iteration. Used for building error message.
     * @param options
     */
    private* questionIteratorInternal(
        questions: Question[],
        responseItems: QuestionnaireResponseItem[],
        initialStack: string[],
        options: QuestionnaireResponseBuilderIteratorOptionsInternal
    ): IterableIterator<QuestionnaireResponseBuilderItem> {
        // Iterate over all questions and yield each element
        for (const question of questions) {
            const linkId = question.linkId;
            const stack = [...initialStack, linkId];

            // If response item does not exist, then create it.
            const responseItemChild = responseItems.find(elem => elem.linkId === linkId);
            let responseItem: QuestionnaireResponseItem;

            if (responseItemChild) {
                responseItem = responseItemChild;
            } else {
                responseItem = {
                    linkId: linkId,
                    answer: []
                };
                responseItems.push(responseItem);
            }

            // Calculate the enabled state
            const enabled = options.forceDisable ? false : this.evaluateEnableWhen(question, stack);

            // Calculate the next question id stuff
            const nextCall = (): string | undefined => {
                try {
                    this.disableErrors = true;

                    const iter = this.questionIteratorInternal(
                        this.questionnaire.item,
                        this.response.item,
                        [this.questionnaire.url ? this.questionnaire.url : ''],
                        {
                            disableValidation: true,
                            flatIteration: true,
                            hideDisabledValues: true,
                            hideDisabledQuestions: false,
                            forceDisable: false
                        }
                    );
                    let foundStart = false;

                    for (const q of iter) {
                        if (
                            foundStart &&
                            q.enabled &&
                            q.value.length === 0 &&
                            q.question.type !== 'group' &&
                            q.question.type !== 'display'
                        ) {
                            return q.question.linkId;
                        }

                        if (q.question.linkId === linkId) {
                            foundStart = true;
                        }
                    }
                } finally {
                    this.disableErrors = false;
                }

                return undefined;
            };

            // If the question has subitems als create subitems array for the response
            // construct new iterator
            let subItemsIterator: IterableIterator<QuestionnaireResponseBuilderItem> | undefined;

            if (question.item) {
                if (responseItem.item === undefined) {
                    responseItem.item = [];
                }
                subItemsIterator = this.questionIteratorInternal(
                    question.item,
                    responseItem.item,
                    stack,
                    {
                        ...options,
                        forceDisable: !enabled
                    }
                );
            }

            // Create the set answer callback
            const setAnswer = (answer: QuestionnaireValue[]) => {
                const oldAnswer = responseItem.answer;
                responseItem.answer = answer;

                if (answer !== oldAnswer) {
                    this.emit('updated');
                }
            };

            // Do validation
            let validationFailed: boolean = false;
            let validationError: QuestionnaireResponseBuilderValidationErrorCode | undefined;

            if (enabled && question.type !== 'group' && question.type !== 'display') {
                if (question.required === undefined || question.required) {
                    if (responseItem.answer.length === 0) {
                        validationError =
                            QuestionnaireResponseBuilderValidationErrorCode.requiredNotPresent;
                    }
                }

                const answerRestriction = question.answerRestriction;

                if (answerRestriction && responseItem.answer[0]) {
                    let maxInclusive: boolean = false;
                    let minInclusive: boolean = false;

                    // check date answers restriction and integer answers restrictions
                    switch (question.type) {
                        case 'date': {
                            if (responseItem.answer[0].valueDate) {
                                let isAfterMaxDate: boolean = false;
                                let isAfterOrEqualsMaxDate: boolean = false;

                                let isBeforeMinDate: boolean = false;
                                let isBeforeOrEqualsMinDate: boolean = false;

                                // get the answer given by user in a Date format
                                const currentDateAnswer = dateFns.parseISO(
                                    responseItem.answer[0].valueDate
                                );

                                if (
                                    answerRestriction.maxValue &&
                                    answerRestriction.maxValue.valueDate
                                ) {
                                    maxInclusive =
                                        answerRestriction.maxInclusive === undefined ||
                                        answerRestriction.maxInclusive;

                                    // get the max Date allowed from the questionnaire
                                    const maxAllowedDateAnswer = formatAnswerRestrictionDate(
                                        answerRestriction.maxValue.valueDate
                                    );

                                    isAfterMaxDate = dateFns.isAfter(
                                        currentDateAnswer,
                                        maxAllowedDateAnswer
                                    );

                                    if (!maxInclusive) {
                                        isAfterOrEqualsMaxDate = dateFns.isAfter(
                                            currentDateAnswer,
                                            dateFns.subDays(maxAllowedDateAnswer, 1)
                                        );
                                    }
                                }

                                if (
                                    answerRestriction.minValue &&
                                    answerRestriction.minValue.valueDate
                                ) {
                                    minInclusive =
                                        answerRestriction.minInclusive === undefined ||
                                        answerRestriction.minInclusive;

                                    // get the min Date allowed from the questionnaire
                                    const minAllowedDateAnswer = formatAnswerRestrictionDate(
                                        answerRestriction.minValue.valueDate
                                    );

                                    isBeforeMinDate = dateFns.isBefore(
                                        currentDateAnswer,
                                        minAllowedDateAnswer
                                    );

                                    if (!minInclusive) {
                                        isBeforeOrEqualsMinDate = dateFns.isBefore(
                                            dateFns.parseISO(responseItem.answer[0].valueDate),
                                            dateFns.addDays(minAllowedDateAnswer, 1)
                                        );
                                    }
                                }

                                //  case 1 - future date
                                // if the answer is after the specified date or
                                // if the answer is after the specified date or equals with the specified date then report the error.
                                if (
                                    (maxInclusive && isAfterMaxDate) ||
                                    (!maxInclusive && isAfterOrEqualsMaxDate)
                                ) {
                                    validationError =
                                        QuestionnaireResponseBuilderValidationErrorCode.futureDateNotAllowed;
                                }

                                // case 2 - past date
                                // if the answer is before the specified date or
                                // if the answer is before the specified date or equals with the specified date then report the error.
                                if (
                                    (minInclusive && isBeforeMinDate) ||
                                    (!minInclusive && isBeforeOrEqualsMinDate)
                                ) {
                                    validationError =
                                        QuestionnaireResponseBuilderValidationErrorCode.pastDateNotAllowed;
                                }
                            }
                            break;
                        }
                        case 'integer': {
                            if (responseItem.answer[0].valueInteger) {
                                let isBiggerThanMaxValue: boolean = false;
                                let isBiggerOrEqualsMaxValue: boolean = false;

                                let isSmallerThanMinValue: boolean = false;
                                let isSmallerOrEqualsMinValue: boolean = false;

                                // since we can define maxValue < minValue to have the answers in descending order,
                                // we need to know which one is the upper edge of the interval
                                let maxValue: number | undefined;
                                let minValue: number | undefined;

                                if (
                                    answerRestriction.maxValue &&
                                    answerRestriction.maxValue.valueInteger &&
                                    answerRestriction.minValue &&
                                    answerRestriction.minValue.valueInteger
                                ) {
                                    const answerRestrictionMinValue = Number(
                                        answerRestriction.minValue.valueInteger
                                    );
                                    const answerRestrictionMaxValue = Number(
                                        answerRestriction.maxValue.valueInteger
                                    );

                                    if (answerRestrictionMaxValue > answerRestrictionMinValue) {
                                        maxValue = answerRestrictionMaxValue;
                                        minValue = answerRestrictionMinValue;
                                        maxInclusive =
                                            answerRestriction.maxInclusive === undefined ||
                                            answerRestriction.maxInclusive;
                                        minInclusive =
                                            answerRestriction.minInclusive === undefined ||
                                            answerRestriction.minInclusive;
                                    } else {
                                        maxValue = answerRestrictionMinValue;
                                        minValue = answerRestrictionMaxValue;
                                        maxInclusive =
                                            answerRestriction.minInclusive === undefined ||
                                            answerRestriction.minInclusive;
                                        minInclusive =
                                            answerRestriction.maxInclusive === undefined ||
                                            answerRestriction.maxInclusive;
                                    }
                                }

                                const answer = Number(responseItem.answer[0].valueInteger);

                                if (maxValue) {
                                    isBiggerThanMaxValue = answer > maxValue;

                                    if (!maxInclusive) {
                                        isBiggerOrEqualsMaxValue = answer >= maxValue;
                                    }
                                }

                                if (minValue) {
                                    isSmallerThanMinValue = answer < minValue;

                                    if (!minInclusive) {
                                        isSmallerOrEqualsMinValue = answer <= minValue;
                                    }
                                }

                                // if the answer is bigger than the biggest allowed answer -> error
                                // if the answer is bigger or equals with the biggest allowed answer -> error
                                // if the answer is smaller than the smallest allowed answer -> error
                                // if the answer is smaller or equals with the smallest allowed answer -> error
                                if (
                                    (maxInclusive && isBiggerThanMaxValue) ||
                                    (!maxInclusive && isBiggerOrEqualsMaxValue) ||
                                    (minInclusive && isSmallerThanMinValue) ||
                                    (!minInclusive && isSmallerOrEqualsMinValue)
                                ) {
                                    validationError =
                                        QuestionnaireResponseBuilderValidationErrorCode.integerNotInRange;
                                }
                            }
                            break;
                        }
                    }
                }
            }

            if (validationError) {
                validationFailed = true;
                this.validationErrors.push({
                    code: validationError,
                    linkId: linkId,
                    prefix: question.prefix
                });
            }

            if (!options.hideDisabledQuestions || enabled) {
                // Yield the next element
                yield {
                    question: question,
                    value: !options.hideDisabledValues || enabled ? responseItem.answer : [],
                    enabled: enabled,
                    setAnswer: setAnswer,
                    validationFailed: validationFailed,
                    subItems: options.flatIteration ? undefined : subItemsIterator,
                    level: stack.length - 2,
                    stack: stack,
                    path: stack.join('/'),
                    nextEnabledItem: nextCall
                };

                // If it is a flat iteration, then yield the sub iterator after the parent object was yielded.
                if (options.flatIteration && subItemsIterator) {
                    yield* subItemsIterator;
                }
            }
        }
    }

    /**
     * Iterator that iterates over all response items and their children recursively.
     *
     * @param responseItems - The list of response items to iterate over.
     */
    private* responseItemIterator(
        responseItems: QuestionnaireResponseItem[]
    ): IterableIterator<QuestionnaireResponseItem> {
        for (const responseItem of responseItems) {
            yield responseItem;

            if (responseItem.item) {
                yield* this.responseItemIterator(responseItem.item);
            }
        }
    }

    /**
     * Evaluates the enableWhen / enableBehavior parts of the passed question object.
     *
     * Note: This function does not handle inherited disabling. This has to be done by the outside!
     *
     * @param question
     * @param stack
     */
    private evaluateEnableWhen(question: Question, stack: string[]): boolean {
        if (question.enableWhen) {
            // Check: enableBehaviour is mandatory if more than one enableWhen item exists.
            if (question.enableWhen.length > 1 && !question.enableBehavior) {
                // Push to the error stack. The question will be disabled.
                this.pushError(
                    stack,
                    'enableWhen: enableWhen clauses is larger than 1, but no enableBehavior is specified.'
                );
                return false;
            }

            // Case all must be true
            if (question.enableBehavior === undefined || question.enableBehavior === 'all') {
                for (const enableWhen of question.enableWhen) {
                    if (!this.evaluateEnableWhenItem(enableWhen, stack)) {
                        return false;
                    }
                }

                return true;
            }

            // Case only one must be true
            if (question.enableBehavior === 'any') {
                for (const enableWhen of question.enableWhen) {
                    if (this.evaluateEnableWhenItem(enableWhen, stack)) {
                        return true;
                    }
                }

                return false;
            }
        }

        return true;
    }

    /**
     * Evaluates the enableWhen clause and determines whether the current question should be disabled or not.
     *
     * @param enableWhenItem - The enableWhen clause that shall be evaluated
     * @param stack - The location stack for error message generation
     */
    private evaluateEnableWhenItem(
        enableWhenItem: Exclude<Question['enableWhen'], undefined>[0],
        stack: string[]
    ): boolean {
        const linkid = enableWhenItem.question;
        const op = enableWhenItem.operator;
        const op1s = this.getAnswers(linkid);
        const op2: QuestionnaireEnableWhenAnswer = enableWhenItem;

        if (op === 'exists') {
            // Assert that op2 is boolean when then the op is 'exists'.
            if (op2.answerBoolean === undefined) {
                this.pushError(
                    stack,
                    "enableWhen: The 'exists' operator needs a boolean argument."
                );
                return false;
            }

            // If any answer is given, then we assume that it exists
            if (op2.answerBoolean) {
                return op1s.length > 0;
            } else {
                return op1s.length === 0;
            }
        }

        // The != operator fails when one of the elements is true
        if (op === '!=') {
            for (const op1 of op1s) {
                if (!this.compare(op1, op2, op, stack)) {
                    return false;
                }
            }

            return true;
        }

        // The other operators succeed if one of the elements evaluates to true
        for (const op1 of op1s) {
            if (this.compare(op1, op2, op, stack)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Compares two values with each other based on the passed operator.
     *
     * @param op1 - The answer to a question
     * @param op2 - The enableWhen operand
     * @param op - The compare operator (exists isn't handled here)
     * @param stack - The location stack for error message generation
     * @returns comparision result
     */
    private compare(
        op1: QuestionnaireValue,
        op2: QuestionnaireEnableWhenAnswer,
        op: Exclude<Question['enableWhen'], undefined>[0]['operator'],
        stack: string[]
    ): boolean {
        let op1Value: any = null;
        let op2Value: any = null;

        if (op1.valueBoolean !== undefined && op2.answerBoolean !== undefined) {
            op1Value = op1.valueBoolean;
            op2Value = op2.answerBoolean;
        } else if (op1.valueDecimal !== undefined && op2.answerDecimal !== undefined) {
            op1Value = op1.valueDecimal;
            op2Value = op2.answerDecimal;
        } else if (op1.valueInteger !== undefined && op2.answerInteger !== undefined) {
            op1Value = op1.valueInteger;
            op2Value = op2.answerInteger;
        } else if (op1.valueDate !== undefined && op2.answerDate !== undefined) {
            op1Value = op1.valueDate;
            op2Value = op2.answerDate;
        } else if (op1.valueDateTime !== undefined && op2.answerDateTime !== undefined) {
            op1Value = op1.valueDateTime;
            op2Value = op2.answerDateTime;
        } else if (op1.valueTime !== undefined && op2.answerTime !== undefined) {
            op1Value = op1.valueTime;
            op2Value = op2.answerTime;
        } else if (op1.valueString !== undefined && op2.answerString !== undefined) {
            op1Value = op1.valueString;
            op2Value = op2.answerString;
        } else if (op1.valueCoding !== undefined && op2.answerCoding !== undefined) {
            // Assert compatible code systems
            if (
                // TODO: reenable me when we fixed the questionnaires, or when we decided that we want to have it this way
                // op1.valueCoding.system !== op2.answerCoding.system ||
                // op1.valueCoding.version !== op2.answerCoding.version ||
                op1.valueCoding.code === undefined
            ) {
                this.pushError(
                    stack,
                    'enableWhen: Operators in enableWhen have incompatible code systems'
                );
                return false;
            }

            // Codes are the items that shall be compared
            op1Value = op1.valueCoding.code;
            op2Value = op2.answerCoding.code;
        } else {
            this.pushError(stack, 'enableWhen: Operators in enableWhen have incompatible types');
            return false;
        }

        // Do the comparision
        switch (op) {
            case '=':
                return op1Value === op2Value;
            case '!=':
                return op1Value !== op2Value;
            case '>':
                return op1Value > op2Value;
            case '<':
                return op1Value < op2Value;
            case '>=':
                return op1Value >= op2Value;
            case '<=':
                return op1Value <= op2Value;
            default:
                this.pushError(
                    stack,
                    `enableWhen: Programming Error - operators '${op}' in enableWhen is not implemented`
                );
                return false;
        }
    }

    /**
     * Push an error to the error stack.
     *
     * @param {string[]} stack
     * @param {string} message
     */
    private pushError(stack: string[], message: string) {
        this.errors.push(`${stack.join('/')} ${message}`);
    }
}
