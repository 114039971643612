import React from 'react';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import {Button} from '@material-ui/core';
import i18n from '../../i18n';
import EraseDialog from '../eraseInstance/EraseDialog';
import ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import InformationPage from '../displayMarkdowns/displayMarkdownFiles';
import {PropertyTree} from 'one.models/lib/models';
import MenuButton from '../menu/MenuButton';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * @param {{}} props
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {Function} props.setNavigationDrawer
 * @param {ConsentFileModel} props.consentFileModel
 * @param {Function} props.setLogoutFromErase
 * @param {PropertyTree} props.settings
 * @param {boolean} props.isPartnerApp - Set to true if view should be rendered for partner app.
 * @returns {React.ReactElement}
 */
export default function Erase(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawer: Function;
    setLogoutFromErase: Function;
    consentFileModel: ConsentFileModel;
    settings: PropertyTree;
    isPartnerApp: boolean;
}): React.ReactElement {
    const [showEraseDialog, setShowEraseDialog] = React.useState<boolean>(false);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container hide">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:eraseView.title')}</h2>
                </div>
                <InformationPage filePath={i18n.t('markDown:eraseInstance')} />
                <div className="buttons-container">
                    <Button
                        className="button"
                        variant="contained"
                        color="secondary"
                        onClick={() => setShowEraseDialog(true)}
                    >
                        {i18n.t('common:buttons.delete')}
                    </Button>
                </div>
                {showEraseDialog ? (
                    <EraseDialog
                        oneInstanceModel={props.oneInstanceModel}
                        setNavigationDrawer={props.setNavigationDrawer}
                        showEraseDialog={setShowEraseDialog}
                        setLogoutFromErase={props.setLogoutFromErase}
                        consentFileModel={props.consentFileModel}
                        settings={props.settings}
                        isPartnerApp={props.isPartnerApp}
                    />
                ) : (
                    <> </>
                )}
            </div>
        </>
    );
}
