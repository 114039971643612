import React, {ReactElement} from 'react';
import {Avatar} from '@material-ui/core';
import {
    Book,
    Calendar,
    Certificate,
    Delete,
    Export,
    Fingerprint,
    Home,
    Import,
    InformationOutline,
    InformationVariant,
    Menu,
    NewspaperVariantOutline,
    Security,
    Devices,
    Logout,
    Information,
    BackupRestore,
    Wrench,
    AccountPlus,
    AccountArrowLeft,
    AccountArrowRight,
    Close,
    ArrowLeft,
    LockReset,
    Tag,
    WrenchOutline
} from 'mdi-material-ui';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';
import './Icon.css';
import '../../Primary.css';
import {Feedback, QuestionAnswerSharp} from '@material-ui/icons';

//  TODO for now leave any
export const icons: any = {
    AccountPlus: AccountPlus,
    ArrowLeft: ArrowLeft,
    Backup: BackupRestore,
    Devices: Devices,
    FAQ: QuestionAnswerSharp,
    Feedback: Feedback,
    Info: InformationOutline,
    Erase: Delete,
    PatientInformation: Information,
    Diary: Calendar,
    Export: Export,
    FileCopy: FileCopyIcon,
    Home: Home,
    Import: Import,
    Impressum: InformationVariant,
    Journal: Fingerprint,
    Logout: Logout,
    Menu: Menu,
    News: NewspaperVariantOutline,
    PrivacyPolicy: Security,
    Settings: Wrench,
    AppSettings: WrenchOutline,
    Information: Book,
    TermsAndConditions: Book,
    ViewLicense: Certificate,
    AccountArrowLeft: AccountArrowLeft,
    AccountArrowRight: AccountArrowRight,
    Close: Close,
    LockReset: LockReset,
    Tag: Tag,
    Refresh: RefreshIcon
};

/**
 * @param {AnyObject} props -> used as the icon name to be passes ({name: string} type)
 * @param {string} props.name
 * @returns {ReactElement}
 */
export function Icon(props: {name: string}): ReactElement {
    const IconType = icons[props.name];

    return (
        <Avatar className={`avatar-wrapper ${props.name}`}>
            <IconType className={'icon-type'} />
        </Avatar>
    );
}
