import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireB: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/B_de',
    name: 'B',
    status: 'active',
    item: [
        {
            linkId: 'yearOfBirth',
            prefix: 'B1',
            text: 'In welchem Jahr sind Sie geboren?',
            type: 'integer',
            answerRestriction: {
                minValue: {
                    valueInteger: '1920'
                },
                maxValue: {
                    valueInteger: '2020'
                }
            }
        },
        {
            linkId: 'gender',
            prefix: 'B2',
            text: 'Welches Geschlecht haben Sie?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/gender_de',
                        version: '1.0',
                        code: '1',
                        display: 'männlich'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/gender_de',
                        version: '1.0',
                        code: '2',
                        display: 'weiblich'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/gender_de',
                        version: '1.0',
                        code: '3',
                        display: 'divers'
                    }
                }
            ]
        },
        {
            linkId: 'B3_0',
            prefix: 'B3_0',
            type: 'group',
            item: [
                {
                    linkId: 'weight_N',
                    prefix: 'B3_1',
                    text: 'Wie ist Ihr Gewicht in kg?',
                    type: 'integer',
                    enableWhen: [
                        {
                            question: 'weightUk',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerRestriction: {
                        minValue: {
                            valueInteger: '20'
                        },
                        maxValue: {
                            valueInteger: '200'
                        }
                    }
                },
                {
                    linkId: 'weightUk',
                    prefix: 'B3_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_de',
                                version: '1.0',
                                code: '1',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'B4_0',
            prefix: 'B4_0',
            text: 'In welchem Organ ist bei Ihnen eine Krebserkrankung diagnostiziert worden?',
            type: 'group',
            item: [
                {
                    linkId: 'colonCancer',
                    prefix: 'B4_1',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/colon_de',
                                version: '1.0',
                                code: '1',
                                display: 'Darm'
                            }
                        }
                    ]
                },
                {
                    linkId: 'breastCancer',
                    prefix: 'B4_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/breast_de',
                                version: '1.0',
                                code: '1',
                                display: 'Brust'
                            }
                        }
                    ]
                },
                {
                    linkId: 'lungCancer',
                    prefix: 'B4_3',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/lung_de',
                                version: '1.0',
                                code: '1',
                                display: 'Lunge'
                            }
                        }
                    ]
                },
                {
                    linkId: 'prostateCancer',
                    prefix: 'B4_4',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/prostate_de',
                                version: '1.0',
                                code: '1',
                                display: 'Prostata'
                            }
                        }
                    ]
                },
                {
                    linkId: 'skinCancer',
                    prefix: 'B4_5',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/skin_de',
                                version: '1.0',
                                code: '1',
                                display: 'Haut'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bladderCancer',
                    prefix: 'B4_6',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/urinary_de',
                                version: '1.0',
                                code: '1',
                                display: 'Harnblase'
                            }
                        }
                    ]
                },
                {
                    linkId: 'lymphomaCancer',
                    prefix: 'B4_7',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/lymphoma_de',
                                version: '1.0',
                                code: '1',
                                display: 'Lymphom (Hodgkin, Non-Hodgkin, multiples Myelom)'
                            }
                        }
                    ]
                },
                {
                    linkId: 'uterineBodyCancer',
                    prefix: 'B4_8',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/uterine_de',
                                version: '1.0',
                                code: '1',
                                display: 'Gebärmutterkörper'
                            }
                        }
                    ]
                },
                {
                    linkId: 'cervicalCancer',
                    prefix: 'B4_9',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/cervical_de',
                                version: '1.0',
                                code: '1',
                                display: 'Gebärmutterhals'
                            }
                        }
                    ]
                },
                {
                    linkId: 'oropharyngealCancer',
                    prefix: 'B4_10',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/oropharyngeal_de',
                                version: '1.0',
                                code: '1',
                                display: 'Mundhöhle und Rachen'
                            }
                        }
                    ]
                },
                {
                    linkId: 'gastricCancer',
                    prefix: 'B4_11',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/stomach_de',
                                version: '1.0',
                                code: '1',
                                display: 'Magen'
                            }
                        }
                    ]
                },
                {
                    linkId: 'renalCancer',
                    prefix: 'B4_12',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/renal_de',
                                version: '1.0',
                                code: '1',
                                display: 'Niere'
                            }
                        }
                    ]
                },
                {
                    linkId: 'leukemiasCancer',
                    prefix: 'B4_13',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/leukemia_de',
                                version: '1.0',
                                code: '1',
                                display: 'Leukämie'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherCancer_txt',
                    prefix: 'B4_14',
                    type: 'string',
                    text: 'Sonstige?',
                    required: false,
                    maxLength: 1000
                }
            ]
        },
        {
            linkId: 'remoteMetYNU',
            prefix: 'B5',
            type: 'choice',
            text: 'Sind bereits Nachbarorgane oder sind entfernte Organe (Fernmetastasen) von Ihrer Krebserkrankung betroffen?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/OrganLimited_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein, noch auf das Ursprungsorgan beschränkt'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'yearOfCancerDiagn',
            prefix: 'B6',
            type: 'integer',
            text: 'In welchem Jahr ist Ihre Krebserkrankung diagnostiziert worden?',
            answerRestriction: {
                minValue: {
                    valueInteger: '1920'
                },
                maxValue: {
                    valueInteger: '2023'
                }
            }
        },
        {
            linkId: 'influenzaVaccYNU',
            prefix: 'B7',
            type: 'choice',
            text: 'Sind Sie in den letzten 5 Jahren mindestens 1 Mal gegen Influenza geimpft worden?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'pneumococVacc',
            prefix: 'B8',
            type: 'choice',
            text: 'Sind Sie gegen Pneumokokken geimpft worden',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'secDiseaseYNU',
            prefix: 'B9',
            type: 'choice',
            text:
                'Haben Sie neben der Krebserkrankung (und der SARS-CoV-2-Infektion) andere Erkrankungen oder sind Ihnen Vorerkrankungen bekannt?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'B10_0',
            prefix: 'B10_0',
            type: 'group',
            text: 'Welche der folgenden (Vor-)erkrankungen? (Mehrfachnennung möglich)',
            enableWhen: [
                {
                    question: 'secDiseaseYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'hypertensionYNU',
                    prefix: 'B10_1',
                    type: 'choice',
                    text: 'Bluthochdruck (Hypertonie)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'chroRespOrLungYNU',
                    prefix: 'B10_2',
                    type: 'choice',
                    text: 'Chronische Atemwegs-/Lungenerkrankungen',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'secDiabetes1o2YNU',
                    prefix: 'B10_3',
                    type: 'choice',
                    text: 'Diabetes Typ 1 oder Typ 2',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'secDisOtherYNU',
                    prefix: 'B10_4',
                    type: 'choice',
                    text: 'Haben Sie sonst noch weitere Krankheiten?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'secDisOther_txt',
                    prefix: 'B10_5',
                    type: 'string',
                    text: 'Welche weiteren Krankheiten haben Sie?',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'secDisOtherYNU',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'smoking',
            prefix: 'B11_1',
            type: 'choice',
            text: 'Haben Sie jemals Zigaretten geraucht?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/smoking_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nichtraucher/in'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/smoking_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/smoking_de',
                        version: '1.0',
                        code: '3',
                        display: 'ehemalige/r Raucher/in'
                    }
                }
            ]
        },
        {
            linkId: 'smokingYears_N',
            prefix: 'B11_2',
            type: 'integer',
            text: 'Wie viele Jahre?',
            enableWhen: [
                {
                    question: 'smoking',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                minValue: {
                    valueInteger: '1'
                },
                maxValue: {
                    valueInteger: '100'
                }
            }
        },
        {
            linkId: 'persHoushold_N',
            prefix: 'B12',
            type: 'integer',
            text: 'Wie viele weitere Personen leben mit Ihnen zusammen im Haushalt?',
            answerRestriction: {
                minValue: {
                    valueInteger: '0'
                },
                maxValue: {
                    valueInteger: '50'
                }
            }
        },
        {
            linkId: 'childs_N',
            prefix: 'B13',
            type: 'integer',
            text: 'Wie viele Kinder haben Sie?',
            answerRestriction: {
                minValue: {
                    valueInteger: '0'
                },
                maxValue: {
                    valueInteger: '20'
                }
            }
        },
        {
            linkId: 'petsYNU',
            prefix: 'B14',
            type: 'choice',
            text:
                'Bisher gibt es noch keine Hinweise auf einen Zusammenhang zwischen der Haltung von Haustieren im eigenen Haushalt und der Übertragung von SARS-CoV-2 auf den Menschen oder umgekehrt. Dennoch bitten wir Sie folgende Fragen zu beantworten. Haben Sie Haustiere, die mit Ihnen im Haushalt leben?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'B15_0',
            prefix: 'B15_0',
            type: 'group',
            text: 'Welche Haustiere? (Mehrfachnennung möglich)',
            enableWhen: [
                {
                    question: 'petsYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'petsCat',
                    prefix: 'B15_1',
                    type: 'open-choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/cat_de',
                                version: '1.0',
                                code: '1',
                                display: 'Katze'
                            }
                        }
                    ],
                    required: false
                },
                {
                    linkId: 'petsDog',
                    prefix: 'B15_2',
                    type: 'open-choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/dog_de',
                                version: '1.0',
                                code: '1',
                                display: 'Hund'
                            }
                        }
                    ],
                    required: false
                },
                {
                    linkId: 'petsOther_txt',
                    prefix: 'B15_3',
                    type: 'string',
                    text: 'Sonstige',
                    required: false,
                    maxLength: 1000
                }
            ]
        }
    ]
};
