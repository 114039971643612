import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireVS: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/VS_de',
    name: 'VS',
    status: 'active',
    item: [
        {
            linkId: 'patHealth',
            prefix: 'VS1_1',
            text: 'Wie geht es der an der Studie teilnehmenden Person?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NoSymptomesHome_de',
                        version: '1.0',
                        code: '1',
                        display: 'symptomfrei zu Hause'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/SymptomesHome_de',
                        version: '1.0',
                        code: '2',
                        display: 'mit Symptomen zu Hause'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/SymptomesHospital_de',
                        version: '1.0',
                        code: '3',
                        display: 'mit Symptomen im Krankenhaus'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/death_de',
                        version: '1.0',
                        code: '4',
                        display: 'verstorben'
                    }
                }
            ]
        },
        {
            linkId: 'dateOfPatDeath',
            prefix: 'VS1_2',
            text: 'Wann ist die an der Studie teilnehmende Person verstorben?',
            type: 'date',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerRestriction: {
                maxValue: {
                    valueDate: 'now'
                }
            }
        },
        {
            linkId: 'VS2_0',
            prefix: 'VS2_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            item: [
                {
                    linkId: 'howPatDeath',
                    prefix: 'VS2_1',
                    text: 'Woran ist die betroffene Person verstorben?',
                    type: 'choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/deathReason_de',
                                version: '1.0',
                                code: '1',
                                display: 'COVID-19'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/deathReason_de',
                                version: '1.0',
                                code: '2',
                                display: 'Krebs'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/deathReason_de',
                                version: '1.0',
                                code: '999',
                                display: 'Sonstige'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherDeath',
                    prefix: 'VS2_2',
                    type: 'string',
                    maxLength: 1000,
                    text: 'Bitte geben Sie an woran die betroffene Person verstorben ist:',
                    enableWhen: [
                        {
                            question: 'howPatDeath',
                            operator: '=',
                            answerCoding: {
                                code: '999'
                            }
                        }
                    ],
                    required: true
                }
            ]
        },
        {
            linkId: 'VpatIntensCuYNU',
            prefix: 'VS3',
            text: 'Wird die teilnehmende Person auf einer Intensivstation behandelt?',
            type: 'choice',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '!=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'VpatIntubatYNU',
            prefix: 'VS4',
            text: 'Wird die teilnehmende Person künstlich beatmet?',
            type: 'choice',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '!=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'VpatLoadCare',
            prefix: 'VS5',
            text:
                'Denken Sie an sich selbst. Wie sehr belastet die Coronasituation Sie bei der Betreuung der Ihnen vertrauten krebskranken Person zusätzlich?',
            type: 'slider',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '!=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr belastend'
                    }
                }
            ]
        },
        {
            linkId: 'VpatLoadRelation',
            prefix: 'VS6',
            text:
                'Denken Sie an sich selbst. Wie sehr belastet die Coronasituation Ihre Beziehung zu der Ihnen vertrauten krebskranken Person?',
            type: 'slider',
            enableWhen: [
                {
                    question: 'patHealth',
                    operator: '!=',
                    answerCoding: {
                        code: '4'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '0',
                        display: 'gar nicht'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '1'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '2'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '3'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '4'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '5'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '6'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '7'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '8'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '9'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/Burden_de',
                        version: '1.0',
                        code: '10',
                        display: 'sehr belastend'
                    }
                }
            ]
        }
    ]
};
