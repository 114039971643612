import React from 'react';
import {Fab} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import './MenuButton.css';
import '../../Primary.css';

let menuOpen: boolean = false;
let clickCallback: () => void;

/**
 * Renders the toolbar of the menu
 * @param {{}} props
 * @param {boolean} props.isMenuOpen
 * @param {boolean} props.isHidden
 * @param {Function} props.clickCallback
 * @returns {React.ReactElement}
 */
export default function MenuButton(props: {
    isMenuOpen?: boolean;
    isHidden?: boolean;
    clickCallback?: () => void;
}): React.ReactElement {
    let isPageFromFooter = false;

    if (props.isMenuOpen !== undefined) {
        menuOpen = props.isMenuOpen;
    }

    if (props.clickCallback !== undefined) {
        clickCallback = props.clickCallback;
    }

    if (
        window.location.pathname === '/impressum' ||
        window.location.pathname === '/privacyPolicy' ||
        window.location.pathname === '/FAQ'
    ) {
        isPageFromFooter = true;
    }

    return (
        <>
            {!props.isHidden && !menuOpen && !isPageFromFooter && (
                <div className="button-menu">
                    <Fab
                        size="small"
                        color="primary"
                        className="fab-wrapper"
                        onClick={() => {
                            clickCallback();
                        }}
                    >
                        <MenuIcon />
                    </Fab>
                </div>
            )}
        </>
    );
}
