import React, {ReactElement, useState} from 'react';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import ConsentFileModel, {ConsentFile} from 'one.models/lib/models/ConsentFileModel';
import {
    Button,
    Checkbox,
    FormControlLabel,
    StepLabel,
    Stepper,
    Step,
    TextField,
    FormControl
} from '@material-ui/core';
import DisplayMarkdownFiles from '../displayMarkdowns/displayMarkdownFiles';
import i18n from '../../i18n';
import './registrationProcess.css';
import '../registration/registrationProcessInformation.css';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {useHistory} from 'react-router-dom';
import {downloadRecoveryFile} from '../recovery/RecoveryHelper';
import Paper from '@material-ui/core/Paper';
import {displayCircularProgress} from '../utils/Utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import RecoveryModel from 'one.models/lib/models/RecoveryModel';

enum PageType {
    Information,
    ConsentAgreement,
    PrivacyPolicyPWA,
    TermsOfUse,
    Recovery,
    Profile
}

enum PartnerPageType {
    Information,
    ConsentAgreement,
    PrivacyPolicyPWA,
    TermsOfUse
}
// todo: to be removed when the profile page should be displayed and use PageType instead
enum PatientPageType {
    Information,
    ConsentAgreement,
    PrivacyPolicyPWA,
    TermsOfUse,
    Recovery
}

/**
 * @param {{}} props
 * @param {ConsentFileModel} props.consentFileModel
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {RecoveryModel} props.recoveryModel
 * @returns {React.ReactElement}
 */
export default function RegistrationProcessInformation(props: {
    consentFileModel: ConsentFileModel;
    oneInstanceModel: OneInstanceModel;
    recoveryModel: RecoveryModel;
}): React.ReactElement {
    const [acceptAge, setAcceptAge] = useState(false);
    const isPartner = props.oneInstanceModel.patientTypeState().includes('partner');
    const [acceptConditions, setAcceptConditions] = useState(false);
    const [showPage, setShowPage] = useState(PageType.Information);
    const [isDisabled, setIsDisabled] = useState(true);

    const history = useHistory();
    const [openStoreData, setOpenStoreDataPermission] = React.useState<boolean>(false);
    const [storeDataPermission, setStoreDataPermission] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (storeDataPermission) {
            downloadRecoveryFile(props.consentFileModel, props.recoveryModel)
                .then(() => {
                    setStoreDataPermission(false);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [storeDataPermission]);

    function addConsentFile(): void {
        const consentFileVersion = '2.0';
        const personIdHash = props.consentFileModel.getOwnerId();

        if (!personIdHash) {
            throw new Error(i18n.t('errors:recovery.emailNotFound'));
        }

        const consentFileObject: ConsentFile = {
            personId: personIdHash,
            version: consentFileVersion
        };

        props.consentFileModel.addConsentFile(consentFileObject).catch(err => {
            console.error('Error: ', err);
        });
    }

    function acceptPrivacyPolicy(): void {
        props.oneInstanceModel.unregister();
        addConsentFile();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore window.navigator.standalone is available only in safari
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const isInStandalone = window.navigator.standalone;

        if (
            (isInStandalone || window.matchMedia('(display-mode: standalone)').matches) &&
            !window.location.pathname.includes('invites')
        ) {
            history.push('/');
        }
    }

    function checkPolicies(): void {
        if (acceptAge && acceptConditions) {
            displayCircularProgress();
            setShowPage(PageType.PrivacyPolicyPWA);
        }
    }

    function renderInformation(): ReactElement {
        return (
            <>
                <div className="privacy-policy-wrapper">
                    {isPartner ? (
                        <DisplayMarkdownFiles
                            filePath={i18n.t('markDown:informationPartner_2_0')}
                            setDisabled={(value: boolean) => setIsDisabled(value)}
                        />
                    ) : (
                        <DisplayMarkdownFiles
                            filePath={i18n.t('markDown:informationPatient_2_0')}
                            setDisabled={(value: boolean) => setIsDisabled(value)}
                        />
                    )}
                </div>
                <div className="consent-information">
                    {i18n.t('steppers:consentInformation:consentInformation1')}
                    <br />
                    {i18n.t('steppers:consentInformation:consentInformation2')}
                    <br />
                    <br />
                    {i18n.t('steppers:consentInformation:consentInformation3')}
                </div>
                <div className="register-stepper-buttons-container">
                    <Button
                        className="privacy-policy-button"
                        onClick={() => {
                            setShowPage(PageType.ConsentAgreement);
                        }}
                        disabled={isDisabled}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        {i18n.t('common:buttons.next')}
                    </Button>
                </div>
            </>
        );
    }

    /**
     * Renders the information about how the user should continue the registration process.
     */
    function renderProceedRegistrationInformation(): ReactElement {
        return (
            <div className="term-of-use">
                {i18n.t('steppers:proceedRegistrationInformation.proceedRegistrationInformation1')}
                <br />
                {i18n.t('steppers:proceedRegistrationInformation.proceedRegistrationInformation2')}
                <br />
                {i18n.t('steppers:proceedRegistrationInformation.proceedRegistrationInformation3')}
            </div>
        );
    }

    function renderPrivacyPolicyPWA(): ReactElement {
        return (
            <>
                <div className="privacy-policy-wrapper">
                    <DisplayMarkdownFiles
                        filePath={i18n.t('markDown:privacyPolicyPWA')}
                        setDisabled={setIsDisabled}
                    />
                </div>
                {renderProceedRegistrationInformation()}
                <div className="register-stepper-buttons-container">
                    <Button
                        className="privacy-policy-button"
                        disabled={isDisabled}
                        onClick={() => {
                            displayCircularProgress();
                            setShowPage(PageType.TermsOfUse);
                        }}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        {i18n.t('common:buttons.accept')}
                    </Button>
                </div>
            </>
        );
    }

    function renderTermsOfUse(): ReactElement {
        return (
            <>
                <div className="privacy-policy-wrapper">
                    {isPartner ? (
                        <DisplayMarkdownFiles
                            filePath={i18n.t('markDown:termsOfUse')}
                            setDisabled={setIsDisabled}
                        />
                    ) : (
                        <DisplayMarkdownFiles
                            filePath={i18n.t('markDown:termsOfUse')}
                            setDisabled={setIsDisabled}
                        />
                    )}
                </div>
                {renderProceedRegistrationInformation()}
                <div className="register-stepper-buttons-container">
                    <Button
                        className="privacy-policy-button"
                        id="accept"
                        variant="contained"
                        color="primary"
                        disabled={isDisabled}
                        onClick={() => {
                            if (isPartner) {
                                acceptPrivacyPolicy();
                            } else {
                                displayCircularProgress();
                                setShowPage(PageType.Recovery);
                            }
                        }}
                    >
                        {i18n.t('common:buttons.accept')}
                    </Button>
                </div>
            </>
        );
    }

    function renderRecovery(): ReactElement {
        return (
            <>
                <div className="recovery-text">
                    <DisplayMarkdownFiles
                        filePath={i18n.t('markDown:recoveryRegistrationStep')}
                        withoutPaper={true}
                    />
                </div>
                <div className="recovery-button">
                    <Button
                        id="accept"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setOpenStoreDataPermission(true);
                        }}
                    >
                        {i18n.t('common:settings.downloadRecovery')}
                    </Button>
                    <Button
                        className="privacy-policy-button"
                        id="accept"
                        variant="contained"
                        color="primary"
                        onClick={acceptPrivacyPolicy}
                        // setShowPage(PageType.Profile)}
                    >
                        {i18n.t('common:buttons.newAccount')}
                    </Button>
                    <Dialog
                        open={openStoreData}
                        onClose={() => {
                            setOpenStoreDataPermission(false);
                        }}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {i18n.t('common:backup.usePersonalDevice')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setOpenStoreDataPermission(false);
                                }}
                                color="primary"
                            >
                                {i18n.t('common:backup.cancel')}
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpenStoreDataPermission(false);
                                    setStoreDataPermission(true);
                                }}
                                color="primary"
                                autoFocus
                            >
                                {i18n.t('common:backup.agree')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </>
        );
    }

    function renderProfile(): React.ReactElement {
        return (
            <div className="privacy-policy-wrapper">
                <Paper square elevation={3} className="page-content-box box-margin">
                    <div className="content-text">
                        <p>{i18n.t('common:infos.profile-stepper1')}</p>
                        <p>{i18n.t('common:infos.profile-stepper2')}</p>
                        <p>{i18n.t('common:infos.profile-stepper3')}</p>
                    </div>
                    <div className="text-box">
                        <TextField
                            fullWidth={true}
                            id="outlined-basic"
                            label={i18n.t('common:textfields.name')}
                            variant="outlined"
                            multiline
                            onChange={() => {
                                // TODO to be implemented
                            }}
                        />
                    </div>
                    <div className="text-box">
                        <TextField
                            fullWidth={true}
                            label={i18n.t('common:textfields.deviceName')}
                            id="outlined-basic"
                            variant="outlined"
                            multiline
                            onChange={() => {
                                // TODO to be implemented
                            }}
                        />
                    </div>
                </Paper>
                <Button
                    id="accept"
                    variant="contained"
                    color="primary"
                    onClick={acceptPrivacyPolicy}
                >
                    {i18n.t('common:buttons.next')}
                </Button>
            </div>
        );
    }

    function renderConsentAgreement() {
        return (
            <>
                <div className="consent-agreement">
                    {i18n.t('steppers:consentAgreement.consentAgreement1')}
                    <br />
                    {i18n.t('steppers:consentAgreement.consentAgreement2')}
                    <br />
                    <br />
                    {i18n.t('steppers:consentAgreement.consentAgreement3')}
                </div>
                <div className="checkboxes-container">
                    <FormControl required error>
                        <FormControlLabel
                            value="end"
                            checked={acceptAge}
                            control={
                                <Checkbox
                                    color="secondary"
                                    onChange={() => {
                                        setAcceptAge(!acceptAge);
                                    }}
                                />
                            }
                            label={i18n.t('common:buttons.18yearsOld')}
                            labelPlacement="end"
                        />
                    </FormControl>
                    <FormControl required error>
                        <FormControlLabel
                            value="end"
                            checked={acceptConditions}
                            control={
                                <Checkbox
                                    onChange={() => {
                                        setAcceptConditions(!acceptConditions);
                                    }}
                                />
                            }
                            label={i18n.t('common:buttons.acceptPatientInfo')}
                            labelPlacement="end"
                        />
                    </FormControl>
                </div>
                <div className="register-stepper-buttons-container">
                    <Button
                        className="privacy-policy-button"
                        onClick={checkPolicies}
                        disabled={!acceptConditions || !acceptAge}
                        color="primary"
                        variant="contained"
                        autoFocus
                    >
                        {i18n.t('common:buttons.next')}
                    </Button>
                </div>
            </>
        );
    }

    function displayPageInformation(showPageType: PageType): React.ReactElement {
        switch (showPageType) {
            case PageType.Information:
                return renderInformation();
            case PageType.ConsentAgreement:
                return renderConsentAgreement();
            case PageType.PrivacyPolicyPWA:
                return renderPrivacyPolicyPWA();
            case PageType.TermsOfUse:
                return renderTermsOfUse();
            case PageType.Recovery:
                return renderRecovery();
            case PageType.Profile:
                return renderProfile();
            default:
                return renderInformation();
        }
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>

            <div className="registration-stepper-wrapper page-content not-visible">
                <>
                    {showPage >= 0 ? (
                        <Stepper className="registration-stepper" activeStep={showPage} nonLinear>
                            {Object.keys(isPartner ? PartnerPageType : PatientPageType)
                                .filter(key => isNaN(Number(key)))
                                .map(label => (
                                    <Step key={label}>
                                        <StepLabel />
                                    </Step>
                                ))}
                        </Stepper>
                    ) : (
                        <></>
                    )}
                </>
                <>{displayPageInformation(showPage)}</>
            </div>
        </>
    );
}
