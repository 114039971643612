import React, {ChangeEvent, useState, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    DialogContentText
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import './ExitStudyDialog.css';
import i18n from '../../i18n';
import {useSettings} from '../modelHelper/SettingsHelper';
import {PropertyTree} from 'one.models/lib/models';

/**
 * returns the dialog for alerting the user what exitStudy study will do
 * @param {{}} props
 * @param {boolean} props.isOpen
 * @param {Function} props.handleModalClose
 * @param {Function} props.handleExitStudy
 * @param {boolean} props.isFromErase
 * @param {Function} props.handleErase
 * @param {Function} props.handleDownload
 * @param {Function} props.setLogoutFromErase
 * @param {PropertyTree} props.settings
 * @returns {React.ReactElement}
 */
export default function ExitStudyDialog(props: {
    isOpen: boolean;
    handleModalClose: Function;
    handleExitStudy: Function;
    isFromErase?: boolean;
    handleErase?: Function;
    handleDownload?: Function;
    setLogoutFromErase?: Function;
    settings: PropertyTree;
}): React.ReactElement {
    const [confirmation, setConfirmation] = useState(false);
    const [confirmationTextContent, setConfirmationTextContent] = useState('');
    const [openStoreData, setOpenStoreDataPermission] = useState<boolean>(false);
    const [storeDataPermission, setStoreDataPermission] = useState<boolean>(false);
    const [switchView, setSwitchView] = useState<boolean>(true);
    const maxLengthOfARawInAPDF = 93;

    const [exitStudy, setExitStudy] = useSettings(props.settings, 'homeScreen.exitStudy', 'false');

    useEffect(() => {
        if (storeDataPermission) {
            handleExitStudy();
        }
    }, [storeDataPermission]);

    function closeDialog(): void {
        setStoreDataPermission(false);
        setConfirmation(false);

        props.handleModalClose();
    }

    function proceedToConfirmationExit(): void {
        setConfirmation(true);
    }

    function handleExitStudy(): void {
        if (props.isFromErase && props.handleDownload !== undefined) {
            props.handleDownload();
            setSwitchView(true);
        } else {
            props.handleExitStudy(confirmationTextContent);
            setConfirmation(false);
            setConfirmationTextContent('');
            setStoreDataPermission(false);
        }
    }

    async function handleSettingsWhenExitStudy(): Promise<void> {
        await setExitStudy('true');
    }

    function updateConfirmationTextContent(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void {
        if (e.target.value.length < maxLengthOfARawInAPDF) {
            setConfirmationTextContent(e.target.value);
        }
    }

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={() => {
                    setStoreDataPermission(false);
                    props.handleModalClose();
                }}
            >
                {confirmation ? (
                    <DialogContent>
                        <p className="dialog-content dialog-margin">
                            {i18n.t('common:settings:exitConfirmationHeadline')}
                        </p>
                        <TextField
                            className="exit-dialog-text-field"
                            id="outlined-multiline-static"
                            onChange={updateConfirmationTextContent}
                            value={confirmationTextContent}
                            multiline
                            rows={4}
                            variant="filled"
                        />
                        <DialogActions className="exit-dialog-buttons">
                            <Button
                                className="pop-up-button"
                                variant="contained"
                                color="primary"
                                onClick={closeDialog}
                            >
                                {props.isFromErase
                                    ? i18n.t('common:backup.cancel')
                                    : i18n.t('common:buttons.closeButton')}
                            </Button>
                            <Button
                                className="pop-up-button"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenStoreDataPermission(true);

                                    if (props.isFromErase) {
                                        props.handleExitStudy(confirmationTextContent);
                                        handleSettingsWhenExitStudy().catch(err =>
                                            console.error(err)
                                        );
                                    }
                                }}
                                disabled={exitStudy === 'true'}
                            >
                                {i18n.t('common:settings.exitStudy')}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                ) : (
                    <DialogContent className="dialog-error">
                        <Alert className="error-alert" severity="error">
                            {props.isFromErase
                                ? i18n.t('errors:eraseDialog')
                                : i18n.t('errors:exitDialog')}
                        </Alert>
                        {props.isFromErase && (
                            <p className="dialog-content">{i18n.t('errors:exitDialog')}</p>
                        )}
                        <DialogActions className="exit-dialog-buttons">
                            <Button
                                className="pop-up-button"
                                variant="contained"
                                color="primary"
                                onClick={closeDialog}
                            >
                                {props.isFromErase
                                    ? i18n.t('common:backup.cancel')
                                    : i18n.t('common:buttons.closeButton')}
                            </Button>
                            <Button
                                className="pop-up-button"
                                variant="contained"
                                color="primary"
                                onClick={proceedToConfirmationExit}
                            >
                                {props.isFromErase
                                    ? i18n.t('common:buttons.next')
                                    : i18n.t('common:settings.exitStudy')}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                )}
            </Dialog>

            <Dialog
                open={openStoreData}
                onClose={() => {
                    setOpenStoreDataPermission(false);
                    props.handleModalClose();
                }}
                aria-describedby="alert-dialog-description"
            >
                {props.isFromErase && switchView ? (
                    <>
                        <DialogContent className="exit-dialog-text">
                            <DialogContentText>
                                {i18n.t('common:eraseDialog.textDialog')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="erase-pop-up-buttons">
                            <Button
                                className="pop-up-button"
                                variant="contained"
                                onClick={() => {
                                    closeDialog();
                                    setOpenStoreDataPermission(false);
                                }}
                                color="primary"
                                autoFocus
                            >
                                {i18n.t('common:backup.cancel')}
                            </Button>
                            <Button
                                className="pop-up-button"
                                variant="contained"
                                onClick={() => {
                                    setSwitchView(false);
                                }}
                                color="primary"
                                autoFocus
                            >
                                {i18n.t('common:settings.download')}
                            </Button>
                            <Button
                                className="pop-up-button"
                                variant="contained"
                                onClick={() => {
                                    if (
                                        props.handleErase !== undefined &&
                                        props.setLogoutFromErase !== undefined
                                    ) {
                                        props.handleErase();
                                        props.setLogoutFromErase(true);
                                    }
                                }}
                                color="primary"
                                autoFocus
                            >
                                {i18n.t('common:buttons.delete')}
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {i18n.t('common:infos.confirmExitStudy')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={props.isFromErase ? 'erase-pop-up-buttons' : ''}>
                            {props.isFromErase ? (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setSwitchView(true);
                                    }}
                                    color="primary"
                                    autoFocus
                                >
                                    {i18n.t('common:backup.cancel')}
                                </Button>
                            ) : (
                                <> </>
                            )}

                            <Button
                                variant="contained"
                                onClick={() => {
                                    setStoreDataPermission(true);

                                    if (!props.isFromErase) {
                                        setOpenStoreDataPermission(false);
                                    }
                                }}
                                color="primary"
                                autoFocus
                            >
                                {i18n.t('common:backup.agree')}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
}
