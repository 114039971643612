import React from 'react';
import Paper from '@material-ui/core/Paper';
import {Alert, AlertTitle} from '@material-ui/lab';
import i18n from '../../i18n';
import {Button, TextField} from '@material-ui/core';
import {OneInstanceModel} from 'one.models/lib/models';
import InfoMessage, {MessageType} from '../errors/InfoMessage';
import './ConnectionErrorViews.css';
import {useHistory} from 'react-router-dom';
import * as H from 'history';
import {isStandalone} from '../utils/Utils';

/**
 * This component implement all error views in case invitation failed
 * Cases:
 *      -> Your Devices : HomeScreen error view and web view
 *      -> Partner: HomeScreen error view and web view
 */

/**
 * Renders the error page if the connection between a partner and a patient did not succeed on the web app
 * Checks localStorage for appBrokenOnInvite to render this view
 *
 * @param {{}} props
 * @param {OneInstanceModel} props.oneInstanceModel
 * @returns {React.ReactElement}
 */
export function ConnectionClosedWebAppPartner(props: {
    oneInstanceModel: OneInstanceModel;
}): React.ReactElement {
    const history = useHistory();

    return (
        <div className="product-type-wrapper">
            <Paper
                square
                elevation={3}
                className="stick-message-top message-font-size error-color text-box"
            >
                <Alert className="patient-info" severity="error" key="alert">
                    <AlertTitle className="message-title">{i18n.t('errors:titleError')}</AlertTitle>
                    <p>{i18n.t('errors:connection.connectionClosedWebAppPartners')}</p>
                    <Button
                        className="button-margin"
                        color="primary"
                        variant="contained"
                        onClick={async () => {
                            await deleteInstanceOnErrorView(props.oneInstanceModel, history);
                        }}
                    >
                        {i18n.t('common:buttons.deleteAvailableData')}
                    </Button>
                </Alert>
            </Paper>
        </div>
    );
}

/**
 * Delete unopened instance and redirect to homepage
 *
 * @param {OneInstanceModel} oneInstanceModel
 * @param {H.History<H.History.UnknownFacade>}history
 * @returns {Promise<void>}
 */
async function deleteInstanceOnErrorView(
    oneInstanceModel: OneInstanceModel,
    history: H.History
): Promise<void> {
    await oneInstanceModel.deleteUnopenedInstance();
    history.push('/');
    window.location.reload();
}

/**
 *
 * render the error page for the HomeScreen mode if a connection between a patient and a partner fails
 * @returns {React.ReactElement}
 *
 */
export function ConnectionClosedHomeScreenPartner(): React.ReactElement {
    return (
        <div className="product-type-wrapper">
            <Paper
                square
                elevation={3}
                className="stick-message-top message-font-size error-color text-box"
            >
                <Alert className="patient-info" severity="error" key="alert">
                    <AlertTitle className="message-title">{i18n.t('errors:titleError')}</AlertTitle>
                    <p>{i18n.t('errors:connection.connectionClosedHomeScreenPartners')}</p>
                </Alert>
            </Paper>
        </div>
    );
}

/**
 * Renders the error page if the connection between your devices did
 * not succeed. Depending on whether or not it is a standalone application(a application saved on home screen),
 * setting an appropriate error message and hiding how to re-enter the password.
 *
 * @param {{}} props - properties of the view.
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {string} props.patientType
 *
 * @returns {React.ReactElement}
 */
export function ConnectionClosedIoM(props: {
    oneInstanceModel: OneInstanceModel;
    patientType: string;
}): React.ReactElement {
    const [secret, setSecret] = React.useState('');
    const [errorState, setErrorState] = React.useState('');
    const [displayError, setDisplayError] = React.useState(false);

    const history = useHistory();

    async function onLogin(): Promise<void> {
        try {
            await props.oneInstanceModel.login(secret, props.patientType, true);
            localStorage.setItem('appBrokeOnInvite', '');
        } catch (err) {
            setDisplayError(true);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (err.message.includes('SC-INIT1')) {
                setErrorState('passwordNotValid');
            } else {
                setErrorState('errors:unknownError');
            }
        }
    }

    return (
        <>
            {displayError ? (
                <InfoMessage
                    errorMessage={errorState}
                    displayMessage={displayError}
                    setDisplayMessage={setDisplayError}
                    messageType={MessageType.Warning}
                />
            ) : (
                <></>
            )}
            <div className="product-type-wrapper">
                <Paper
                    square
                    className="message-font-size error-color  error-message-box color-box connection-iom-close-alert"
                >
                    <Alert className="patient-info" severity="error" key="alert">
                        <AlertTitle className="message-title">
                            {i18n.t('errors:titleError')}
                        </AlertTitle>
                        {isStandalone() ? (
                            <p>
                                {i18n.t('errors:connection.connectionClosedYourDevicesHomeScreen')}
                            </p>
                        ) : (
                            <p>{i18n.t('errors:connection.connectionClosedYourDevicesWebApp')}</p>
                        )}
                        <TextField
                            className="password-text-field"
                            label={i18n.t('login:password')}
                            type="password"
                            value={secret}
                            onChange={e => {
                                setSecret(e.target.value);
                            }}
                            onKeyPress={async event => {
                                if (event.key === 'Enter') {
                                    await onLogin();
                                }
                            }}
                            required
                        />
                        <div className="connection-iom-closed-buttons">
                            <Button
                                onClick={async () => {
                                    await onLogin();
                                }}
                                color="primary"
                                variant="contained"
                            >
                                {i18n.t('common:buttons.reenter')}
                            </Button>
                            {isStandalone() ? (
                                <></>
                            ) : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={async () => {
                                        await deleteInstanceOnErrorView(
                                            props.oneInstanceModel,
                                            history
                                        );
                                    }}
                                >
                                    {i18n.t('common:buttons.deleteAvailableData')}
                                </Button>
                            )}
                        </div>
                    </Alert>
                </Paper>
            </div>
        </>
    );
}
