import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireIM: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://uk-erlangen.de/freeda/questionnaire/IM_en',
    name: 'IM',
    status: 'active',
    item: [
        { linkId: 'infYNU',
            prefix: 'IM1',
            text: 'Are you (re)infected with SARS-CoV-2?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'dateTestOfInf',
            prefix: 'IM2_1',
            text: 'When were you tested for SARS-CoV-2 infection?',
            type: 'date',
            enableWhen: [
                {
                    question: 'infYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                maxValue: {
                    valueDate: 'now'
                }
            }
        },
        {
            linkId: 'howTestOfInf',
            prefix: 'I2_3',
            text: 'How have you tested positive for SARS-CoV-2 infection?',
            type: 'choice',
            enableWhen: [
                {
                    question: 'infYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NasopharyngealBlood_en',
                        version: '1.0',
                        code: '1',
                        display: 'nasopharyngeal smear'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NasopharyngealBlood_en',
                        version: '1.0',
                        code: '2',
                        display: 'blood test'
                    }
                }
            ]
        },
        { linkId: 'quarantineYNU',
            prefix: 'IM3_1',
            text: 'Have you been in quarantine since the last interview? ',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        { linkId: 'quarantineTime_N',
            prefix: 'IM3_2',
            text: 'How long were you quarantined? (specify in days)',
            type: 'integer',
            enableWhen: [
                {
                    question: 'quarantineYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                minValue: {
                    valueInteger: '1'
                },
                maxValue: {
                    valueInteger: '100'
                }
            }
        },
        { linkId: 'vaccinationYNU',
            prefix: 'IM4_1',
            text: 'Have you been vaccinated against COVID-19 since the last interview?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/vaccination_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/vaccination_en',
                        version: '1.0',
                        code: '0',
                        display: 'No, I have never been vaccinated'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/vaccination_en',
                        version: '1.0',
                        code: '2',
                        display: 'No, I have already been vaccinated.'
                    }
                }
            ]
        },
        { linkId: 'vaccination_txt',
            prefix: 'IM4_2',
            text: 'What vaccine were you vaccinated with?',
            type: 'string',
            required: false,
            maxLength: 1000,
            enableWhen: [
                {
                    question: 'vaccinationYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ]
        }
    ]
};
