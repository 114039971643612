import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireBI: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/BI_de',
    name: 'BI',
    status: 'active',
    item: [
        {
            linkId: 'pastInfYN',
            prefix: 'BI1',
            text: 'Sind Sie in der Vergangenheit mit SARS-CoV-2 infiziert gewesen?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                }
            ]
        },
        {
            linkId: 'pInfDate',
            prefix: 'BI2',
            text:
                'Erinnern Sie sich bitte, wann wurden Sie damals auf eine SARS-CoV-2-Infektion positiv getestet?',
            type: 'date',
            enableWhen: [
                {
                    question: 'pastInfYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                maxValue: {
                    valueDate: 'now'
                }
            }
        },
        {
            linkId: 'pInfCuYNU',
            prefix: 'BI3',
            text:
                'Waren Sie aufgrund Ihrer zurückliegenden SARS- CoV-2-Infektion in stationärer Behandlung?',
            type: 'choice',
            enableWhen: [
                {
                    question: 'pastInfYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'BI4_0',
            prefix: 'BI4_0',
            text: 'Auf welcher Art von Station wurden Sie behandelt? (Mehrfachnennungen möglich)',
            type: 'group',
            enableWhen: [
                {
                    question: 'pInfCuYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'pInfIntensCUYNU',
                    prefix: 'BI4_1',
                    type: 'choice',
                    text: 'Intensivstation?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfIsolInfCUYNU',
                    prefix: 'BI4_2',
                    type: 'choice',
                    text: 'Isolierstation oder Infektionsstation?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfOtherCU_txt',
                    prefix: 'BI4_3',
                    type: 'string',
                    maxLength: 1000,
                    text: 'Sonstige?'
                }
            ]
        },
        {
            linkId: 'pInfSymptYNU',
            prefix: 'BI5',
            type: 'choice',
            text: 'Erinnern Sie sich bitte, haben Sie Symptome aufgrund der SARS-CoV-2-Infektion?',
            enableWhen: [
                {
                    question: 'pastInfYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'BI6_0',
            prefix: 'BI6_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'pInfSymptYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'pInfDurOfSympt_N',
                    prefix: 'BI6_1',
                    type: 'integer',
                    text: 'Wie lange (in Tagen) hatten Sie Symptome?',
                    enableWhen: [
                        {
                            question: 'pInfDurOfSymptUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ],
                    answerRestriction: {
                        minValue: {
                            valueInteger: '0'
                        },
                        maxValue: {
                            valueInteger: '100'
                        }
                    }
                },
                {
                    linkId: 'pInfDurOfSymptUk',
                    prefix: 'BI6_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_de',
                                version: '1.0',
                                code: '1',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'pInfPneumoniaYNU',
            prefix: 'BI7',
            type: 'choice',
            text: 'Wurde bei Ihnen eine Lungenentzündung (Pneumonie) diagnostiziert?',
            enableWhen: [
                {
                    question: 'pastInfYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'pInfTreatYNU',
            prefix: 'BI8',
            type: 'choice',
            text: 'Erhielten Sie aufgrund der SARS-CoV-2-Infektion eine Behandlung?',
            enableWhen: [
                {
                    question: 'pInfCuYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'BI9_0',
            prefix: 'BI9_0',
            text: 'Haben Sie eine der folgenden Behandlungen erhalten?',
            type: 'group',
            enableWhen: [
                {
                    question: 'pInfTreatYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'pInfOxygenYNU',
                    prefix: 'BI9_1',
                    type: 'choice',
                    text: 'Bekamen Sie Sauerstoff?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfInfusionYNU',
                    prefix: 'BI9_2',
                    type: 'choice',
                    text: 'Haben Sie eine Infusion bekommen?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfAntibioticYNU',
                    prefix: 'BI9_3',
                    type: 'choice',
                    text: 'Erhielten Sie Antibiotika?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfQuensylYNU',
                    prefix: 'BI9_4',
                    type: 'choice',
                    text: 'Wurden Sie mit Hydroxychloroquine (Quensyl®) behandelt?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfRemdesivirYNU',
                    prefix: 'BI9_5',
                    type: 'choice',
                    text: 'Wurden Sie mit Remdesivir behandelt?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfIntubationYNU',
                    prefix: 'BI9_6',
                    type: 'choice',
                    text: 'Wurden Sie künstlich beatmet (Intubation)?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'pInfCovStudyYNU',
            prefix: 'BI10',
            type: 'choice',
            text: 'Haben Sie an einer Studie zu einer (neuen) COVID-Therapie teilgenommen?',
            enableWhen: [
                {
                    question: 'pastInfYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'BI11_0',
            prefix: 'BI11_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'pInfCovStudyYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'pInfStudyName_txt',
                    prefix: 'BI11_1',
                    type: 'string',
                    text: 'Was ist der Name der Studie?',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'pInfStudyNameUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfStudyNameUk',
                    prefix: 'BI11_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_de',
                                version: '1.0',
                                code: '1',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'BI12_0',
            prefix: 'BI12_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'pInfCovStudyYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'pInfStTherapy_txt',
                    prefix: 'BI11_1',
                    type: 'string',
                    text: 'Welche COVID-Therapie oder Medikament erhielten Sie in dieser Studie?',
                    maxLength: 1000,
                    enableWhen: [
                        {
                            question: 'pInfStTherapyUk',
                            operator: '!=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'pInfStTherapyUk',
                    prefix: 'BI11_2',
                    type: 'open-choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_de',
                                version: '1.0',
                                code: '1',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                }
            ]
        }
    ]
};
