import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireIM: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/IM_de',
    name: 'IM',
    status: 'active',
    item: [
        { linkId: 'infYNU',
            prefix: 'IM1',
            text: 'Sind Sie (erneut) mit SARS-CoV-2 infiziert?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        {
            linkId: 'dateTestOfInf',
            prefix: 'IM2_1',
            text: 'Wann wurden Sie auf eine SARS-CoV-2-Infektion getestet?',
            type: 'date',
            enableWhen: [
                {
                    question: 'infYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                maxValue: {
                    valueDate: 'now'
                }
            }
        },
        {
            linkId: 'howTestOfInf',
            prefix: 'IM2_2',
            text: 'Wie sind Sie positiv auf eine SARS-CoV-2-Infektion getestet worden?',
            type: 'choice',
            enableWhen: [
                {
                    question: 'infYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NasopharyngealBlood_de',
                        version: '1.0',
                        code: '1',
                        display: 'Rachen- oder Nasenabstrich'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/NasopharyngealBlood_de',
                        version: '1.0',
                        code: '2',
                        display: 'Blut-Test'
                    }
                }
            ]
        },
        { linkId: 'quarantineYNU',
            prefix: 'IM3_1',
            text: 'Sind Sie seit der letzten Befragung in Quarantäne gegangen?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '999',
                        display: 'Weiß nicht'
                    }
                }
            ]
        },
        { linkId: 'quarantineTime_N',
            prefix: 'IM3_2',
            text: 'Wie lange waren Sie in Quarantäne? (Angabe in Tagen)',
            type: 'integer',
            enableWhen: [
                {
                    question: 'quarantineYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                minValue: {
                    valueInteger: '1'
                },
                maxValue: {
                    valueInteger: '100'
                }
            }
        },
        { linkId: 'vaccinationYNU',
            prefix: 'IM4_1',
            text: 'Sind Sie seit der letzten Befragung gegen COVID-19 geimpft worden?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/vaccination_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/vaccination_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein, ich bin noch nie geimpft worden.'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/vaccination_de',
                        version: '1.0',
                        code: '2',
                        display: 'Nein, ich bin schon geimpft.'
                    }
                }
            ]
        },
        { linkId: 'vaccination_txt',
            prefix: 'IM4_2',
            text: 'Mit welchem Impfstoff wurden Sie geimpft?',
            type: 'string',
            required: false,
            maxLength: 1000,
            enableWhen: [
                {
                    question: 'vaccinationYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ]
        }
    ]
};
