import React, {ReactElement, useEffect, useState} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import './HomeScreen.css';
import '../../Primary.css';
import CovidWorkflowModel, {
    PatientPhase,
    PhaseProperties,
    PatientPhasesProperties,
    PartnerPhasesProperties,
    PartnerPhase,
    RegularWithInfectionProperties
} from '../../model/CovidWorkflowModel';
import {Alert, AlertTitle} from '@material-ui/lab';
import {useHistory} from 'react-router-dom';
import i18n, {effectiveLanguage} from '../../i18n';
import {Accordion, AccordionSummary, AccordionDetails, CircularProgress} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import MenuButton from '../menu/MenuButton';
import {useSettings} from '../modelHelper/SettingsHelper';
import {AccessModel} from 'one.models/lib/models';
import {FreedaAccessGroups} from '../../model/FreedaAccessRightsManager';
import {displayCircularProgress, formatDate, hideCircularProgress} from '../utils/Utils';
import Divider from '@material-ui/core/Divider';

enum Phases {
    Initial = 0,
    Basic = 1,
    Regular = 2,
    Infection = 3,
    End = 4,
    Dropout = -2,
    PatientDied = -3
}

/**
 * This function is used to get the details for each phase.
 * @param {CovidWorkflowModel} covidWorkflowModel - the covid workflow model.
 * @returns {PatientPhasesProperties} an object with details for each phase.
 */
function usePhasesProperties(
    covidWorkflowModel: CovidWorkflowModel
): PatientPhasesProperties | PartnerPhasesProperties {
    const [phaseProperties, setPhaseProperties] = React.useState<
        PatientPhasesProperties | PartnerPhasesProperties
    >({} as PatientPhasesProperties);

    React.useEffect(() => {
        function fetchPhasesProperties(): void {
            setPhaseProperties(covidWorkflowModel.phaseProperties());
        }

        covidWorkflowModel.on('updated', fetchPhasesProperties);
        fetchPhasesProperties();

        return () => {
            covidWorkflowModel.removeListener('updated', fetchPhasesProperties);
        };
    }, [covidWorkflowModel]);

    return phaseProperties;
}

function useActivePartnerConnections(
    accessModel: AccessModel,
    setPartnerConnected: (value: string) => Promise<void>
): void {
    React.useEffect(() => {
        function fetchPartnerConnections(): void {
            accessModel
                .getAccessGroupPersons(FreedaAccessGroups.partner)
                .then(async availablePartnerConnections => {
                    if (availablePartnerConnections.length > 0) {
                        await setPartnerConnected('true');
                    }
                })
                .catch(err => console.error(err));
        }

        accessModel.on('groups_updated', fetchPartnerConnections);
        fetchPartnerConnections();

        return () => {
            accessModel.removeListener('groups_updated', fetchPartnerConnections);
        };
    }, [accessModel]);
}

/**
 * This function builds and returns the home screen stepper.
 * @param {{}} props - properties of this view.
 * @param {CovidWorkflowModel} props.covidWorkflowModel
 * @param {AccessModel} props.accessModel
 * @param {boolean} props.isPartnerApp - Set tu true if the view should be rendered for partner app, otherwise false
 * @param {PropertyTree} props.settings
 * @returns {ReactElement} - the home page view.
 */
export default function HomeScreenStepper(props: {
    covidWorkflowModel: CovidWorkflowModel;
    accessModel: AccessModel;
    isPartnerApp: boolean;
    settings: PropertyTree;
}): ReactElement {
    const phaseProperties = usePhasesProperties(props.covidWorkflowModel);
    const [activeStep, setActiveStep] = useState(Phases.Initial);
    // component specific setting for info boxes
    const [hideInfoBox, setHideInfoBox] = useSettings(props.settings, 'hideInfoBox', 'false');
    // drop out phase setting
    const [exitStudy] = useSettings(props.settings, 'exitStudy', 'false');
    // connect to partner setting
    const [partnerConnected, setPartnerConnected] = useSettings(
        props.settings,
        'partnerConnected',
        'false'
    );
    useActivePartnerConnections(props.accessModel, setPartnerConnected);

    const history = useHistory();
    const [expandedInitial, setExpandedInitial] = useState(false);
    const [expandedFirst, setExpandedFirst] = useState(false);
    const [expandedSecond, setExpandedSecond] = useState(false);
    const [expandedThird, setExpandedThird] = useState(false);
    const [patientDiedInfo, setPatientDiedInfo] = useState(false);
    const [patientId, setPatientId] = useState('');
    const endOfStudy = props.covidWorkflowModel.getEndOfStudyDate();
    const patientOrPartner = props.isPartnerApp ? 'partner' : 'patient';
    const phases = props.isPartnerApp ? [1, 2] : [1, 2, 3];
    const activeLanguage = effectiveLanguage().toLowerCase();
    let displayedDetails: PhaseProperties | RegularWithInfectionProperties;
    const sortPhases: Phases[] = [];

    // used for getting the phases details when the component is rendered for the first time.
    useEffect(() => {
        /**
         * This function is called when the component is rendered for the first time and get the current phase of the user.
         */
        function getCurrentPhase(): void {
            displayCircularProgress();

            let phase;

            if (partnerConnected === 'false' && expandedFirst) {
                setExpandedInitial(true);
            } else {
                setExpandedInitial(false);
            }

            if (exitStudy === 'true') {
                phase = Phases.Dropout;
            } else {
                phase = props.covidWorkflowModel.currentPhase();
            }

            switch (phase) {
                case PatientPhase.Basic | PartnerPhase.Basic: {
                    setActiveStep(Phases.Basic);
                    break;
                }
                case PatientPhase.RegularWithoutInfection | PartnerPhase.Regular: {
                    setActiveStep(Phases.Regular);
                    break;
                }
                case PatientPhase.RegularWithInfection: {
                    setActiveStep(Phases.Infection);
                    break;
                }
                case PatientPhase.End | PartnerPhase.End: {
                    setActiveStep(Phases.End);
                    break;
                }
                case PatientPhase.Dropout: {
                    setActiveStep(Phases.Dropout);
                    // TODO: do proper error handling
                    setHideInfoBox('true').catch(e => console.error(e));
                    break;
                }
                case PatientPhase.PatientDied: {
                    setActiveStep(Phases.PatientDied);
                    setPatientDiedInfo(true);
                    break;
                }
            }
        }

        getCurrentPhase();
        hideCircularProgress();
    }, [phaseProperties, partnerConnected]);

    // Get the id of the associated patient
    // This is used in order to build the link for the questionnaire, so that the result is
    // posted to the correct channel
    // This is not ideal and again another hack ...
    useEffect(() => {
        /**
         *
         */
        async function fetchPatientId(): Promise<void> {
            try {
                const persons = await props.accessModel.getAccessGroupPersons(
                    FreedaAccessGroups.partner
                );

                if (persons.length > 0) {
                    setPatientId(persons[0]);
                }
            } catch (_) {
                // TODO: do proper error handling ... but this should be done by the original ui implementor
                console.error('Failed to fetch partners.');
            }
        }

        if (props.isPartnerApp) {
            // Register updated events
            props.accessModel.on('updated', fetchPatientId);
            void fetchPatientId();

            return () => {
                props.accessModel.removeListener('updated', fetchPatientId);
            };
        }
    }, [props.accessModel, props.isPartnerApp]);

    // setting the current phase details that will be displayed in th UI
    if (props.isPartnerApp) {
        if (activeStep === Phases.Basic) {
            displayedDetails = (phaseProperties as PartnerPhasesProperties)[PartnerPhase.Basic];
        } else if (activeStep === Phases.Regular) {
            displayedDetails = phaseProperties[PartnerPhase.Regular];
            sortPhases.push(Phases.Regular);
        }
    } else if (activeStep === Phases.Regular) {
        displayedDetails = phaseProperties[PatientPhase.RegularWithoutInfection];
        sortPhases.push(Phases.Regular);
    } else if (activeStep === Phases.Infection) {
        displayedDetails = (phaseProperties as PatientPhasesProperties)[
            PatientPhase.RegularWithInfection
        ];
        sortPhasesDependingOnDate();
    }

    /**
     * This function sorts the phases depending on the next visit date.
     *
     * TODO: CLEAN CODE -> please improve it when we have more time for this.
     */
    function sortPhasesDependingOnDate(): void {
        let regularVal: number | boolean | Date | undefined,
            infectionVal: number | boolean | Date | undefined;
        Object.entries(displayedDetails).forEach(([name, value]) => {
            if (name === 'daysUntilNextRegularQuestionnaire') {
                regularVal = value;
            }

            if (name === 'daysUntilNextInfectionQuestionnaire') {
                infectionVal = value;
            }
        });
        sortPhases.push(Phases.Regular);

        if (regularVal !== undefined && infectionVal !== undefined) {
            if (regularVal < infectionVal) {
                sortPhases.push(Phases.Infection);
            } else {
                sortPhases.unshift(Phases.Infection);
            }
        }
    }

    /**
     * This function is called when the "Next questionnaire" button from the stepper is clicked
     * and redirect the user to the correct questionnaire based on the phase he is.
     * @param {string} phase - phase identifier.
     * @param {boolean} loadRegular - specified if all the regular questionnaire or only the infection questionnaire need to be loaded
     */
    function redirectToQuestionnaire(phase: number, loadRegular: boolean = true): void {
        let pathPart: string;

        switch (phase) {
            case Phases.Basic: {
                if (props.isPartnerApp) {
                    pathPart = (phaseProperties as PartnerPhasesProperties)[PartnerPhase.Basic]
                        .hasIncompleteQuestionnaire
                        ? 'edit'
                        : 'new';

                    history.push(
                        `covidQuestionnaire/${pathPart}?questionnaires=VB&language=${activeLanguage}&channelOwner=${patientId}`
                    );
                    break;
                }
                pathPart = phaseProperties[PatientPhase.Basic].hasIncompleteQuestionnaire
                    ? 'edit'
                    : 'new';
                history.push(
                    `covidQuestionnaire/${pathPart}?questionnaires=B,BI,BT,S,IM,I&language=${activeLanguage}`
                );
                break;
            }

            case Phases.Regular:
            case Phases.Infection: {
                if (props.isPartnerApp) {
                    pathPart = phaseProperties[PartnerPhase.Regular].hasIncompleteQuestionnaire
                        ? 'edit'
                        : 'new';
                    history.push(
                        `covidQuestionnaire/${pathPart}?questionnaires=VS&language=${activeLanguage}&channelOwner=${patientId}`
                    );
                    break;
                }

                pathPart =
                    (phaseProperties as PatientPhasesProperties)[
                        PatientPhase.RegularWithoutInfection
                    ].hasIncompleteQuestionnaire ||
                    (phaseProperties as PatientPhasesProperties)[PatientPhase.RegularWithInfection]
                        .hasIncompleteQuestionnaire
                        ? 'edit'
                        : 'new';

                if (loadRegular) {
                    history.push(
                        `covidQuestionnaire/${pathPart}?questionnaires=T,S,IM,I&language=${activeLanguage}`
                    );
                } else {
                    history.push(
                        `covidQuestionnaire/${pathPart}?questionnaires=I&language=${activeLanguage}`
                    );
                }

                break;
            }

            case Phases.End: {
                if (props.isPartnerApp) {
                    pathPart = phaseProperties[PartnerPhase.End].hasIncompleteQuestionnaire
                        ? 'edit'
                        : 'new';

                    history.push(
                        `covidQuestionnaire/${pathPart}?questionnaires=VB,VA&language=${activeLanguage}&channelOwner=${patientId}`
                    );
                    break;
                }
                pathPart = phaseProperties[PatientPhase.End].hasIncompleteQuestionnaire
                    ? 'edit'
                    : 'new';

                history.push(
                    `covidQuestionnaire/${pathPart}?questionnaires=T,S,IM,I,A&language=${activeLanguage}`
                );
                break;
            }
        }
    }

    /**
     * This function returns the proper css class, which sets the color for the stepper dot
     * @param {Phases} phaseIdentifier - the current phase
     * @returns string
     */
    function getStepDotColor(phaseIdentifier: Phases): string {
        switch (true) {
            case phaseIdentifier === Phases.Basic:
                return 'phase1-color';
            case phaseIdentifier === Phases.Regular:
                return 'phase2-color';
            case phaseIdentifier === Phases.Infection && activeStep === Phases.Infection:
                return 'phase3-active-color';
            default:
                return 'phase3-color';
        }
    }

    /**
     *  This function builds the information for the given phase as a parameter, such as:
     *  - the date of the next visit
     *  - the button that allows the user to start a new questionnaire or continue an existing one
     * @param {Phases} phase - the phase
     * @returns React.ReactElement
     */
    function buildPhaseInformation(phase: Phases): React.ReactElement {
        let nextQuestionnaire1: string;
        let nextQuestionnaire2: string;
        let nextQuestionnaireClassName: string = '';
        let buttonMessage: string;
        let buttonClassName: string = '';
        let nextQuestionnaireTime: Date | undefined = displayedDetails.nextQuestionnaireTime;
        let nextQuestionnaireDay: number | undefined =
            displayedDetails.daysUntilNextRegularQuestionnaire;
        let loadRegularQ: boolean;

        if (phase === Phases.Regular) {
            buttonMessage = 'nextRegular';
            buttonClassName = 'regular-visit-info';
        } else {
            buttonMessage = 'nextInfection';
            nextQuestionnaireTime = (displayedDetails as RegularWithInfectionProperties)
                .nextInfectionQuestionnaireTime;
            nextQuestionnaireDay = (displayedDetails as RegularWithInfectionProperties)
                .daysUntilNextInfectionQuestionnaire;
            loadRegularQ = false;
            buttonClassName = 'infection-visit-info';
        }

        // check if next questionnaire is in the future or was in the past
        if (nextQuestionnaireDay === undefined || 0 <= nextQuestionnaireDay) {
            nextQuestionnaire1 = 'nextQuestionnaireFuture1';
            nextQuestionnaire2 = 'nextQuestionnaireFuture2';
        } else {
            nextQuestionnaire1 = 'nextQuestionnairePast1';
            nextQuestionnaire2 = 'nextQuestionnairePast2';
            nextQuestionnaireClassName = 'past';
        }

        return (
            <div key={phase} className={'phase-information ' + buttonClassName}>
                {endOfStudy !== null && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            redirectToQuestionnaire(phase, loadRegularQ);
                        }}
                    >
                        {i18n.t(`common:buttons.${buttonMessage}`)}
                    </Button>
                )}
                <Typography className={'visit ' + nextQuestionnaireClassName}>
                    {displayedDetails === undefined ||
                    nextQuestionnaireDay === undefined ||
                    Object.keys(displayedDetails).length === 0 ||
                    nextQuestionnaireTime === null
                        ? i18n.t('homeScreen:dateNotCalculatedPartner')
                        : nextQuestionnaireDay === 0
                        ? formatDate(nextQuestionnaireTime) +
                          ' ' +
                          i18n.t('common:home.nextQuestionnaireToday')
                        : Math.abs(nextQuestionnaireDay) === 1
                        ? `${formatDate(nextQuestionnaireTime)} (${i18n.t(
                              'common:home.' + nextQuestionnaire1
                          )}${Math.abs(nextQuestionnaireDay)} ${i18n.t(
                              'common:home.' + nextQuestionnaire2 + 'Single'
                          )})`
                        : `${formatDate(nextQuestionnaireTime)} (${i18n.t(
                              'common:home.' + nextQuestionnaire1
                          )}${Math.abs(nextQuestionnaireDay)} ${i18n.t(
                              'common:home.' + nextQuestionnaire2
                          )})`}
                </Typography>
            </div>
        );
    }

    /**
     * This function displays the next visit date
     * @param nextQuestionnaireTime
     * @param nextQuestionnaireDay
     * @param nextQuestionnaire1
     * @param nextQuestionnaire2
     * @returns ReactElement
     */
    function displayDate(
        nextQuestionnaireTime: Date | undefined,
        nextQuestionnaireDay: number,
        nextQuestionnaire1: string,
        nextQuestionnaire2: string
    ): React.ReactElement {
        return (
            <>
                <span>{formatDate(nextQuestionnaireTime)}</span>
                <span className="date-parentheses">
                    {i18n.t('common:home.' + nextQuestionnaire1) +
                        Math.abs(nextQuestionnaireDay).toString() +
                        i18n.t('common:home.' + nextQuestionnaire2)}
                </span>
            </>
        );
    }

    /**
     * This function is called every time when the user choose to extend/collapse an accordion element in the stepper.
     * @param {Phases} phaseIdentifier - the current phase
     */
    function handleOnChange(phaseIdentifier: Phases): void {
        switch (phaseIdentifier) {
            case Phases.Basic:
                setExpandedFirst(!expandedFirst);
                break;
            case Phases.Regular:
                setExpandedSecond(!expandedSecond);
                break;
            case Phases.Infection:
                setExpandedThird(!expandedThird);
                break;
        }
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container hide">
                {hideInfoBox === 'false' && (
                    <Paper square elevation={3} className="stick-message-top">
                        <Alert
                            severity="info"
                            onClose={async () => {
                                await setHideInfoBox('true');
                            }}
                            className="patient-info"
                            key={'alert'}
                        >
                            <AlertTitle className="message-title">
                                {i18n.t('errors:titleInfo')}
                            </AlertTitle>
                            {i18n.t(`homeScreen:${patientOrPartner}Info`)}
                        </Alert>
                    </Paper>
                )}
                {props.isPartnerApp && patientDiedInfo && (
                    <Paper square elevation={3} className="stick-message-top message-font-size">
                        <Alert severity="info" className="patient-info" key={'alert'}>
                            <AlertTitle>{i18n.t('homeScreen:patientDiedInfo.title')} </AlertTitle>

                            {i18n.t('homeScreen:patientDiedInfo.message')}
                            <p className="remove-bottom-margin">
                                {i18n.t('homeScreen:patientDiedInfo.footer')}
                            </p>
                        </Alert>
                    </Paper>
                )}
                {activeStep === Phases.Dropout && (
                    <Paper square elevation={3} className="stick-message-top message-font-size">
                        <Alert severity="error">
                            <AlertTitle className="message-title">
                                {i18n.t('errors:titleInfo')}
                            </AlertTitle>
                            {i18n.t('homeScreen:dropoutPhaseDetails')} <br />
                        </Alert>
                    </Paper>
                )}
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline"> {i18n.t('homeScreen:title')}</h2>
                </div>
                <Paper elevation={3}>
                    <Stepper
                        activeStep={props.isPartnerApp ? activeStep - 1 : activeStep}
                        orientation="vertical"
                        className="home-stepper"
                    >
                        {!props.isPartnerApp && (
                            <Step active className="simple-stepper-icon">
                                <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            active: 'initial-phase-home-screen',
                                            completed: 'initial-phase-home-screen'
                                        }
                                    }}
                                >
                                    <Accordion
                                        className="home-stepper-accordion"
                                        expanded={expandedInitial}
                                        onChange={() => setExpandedInitial(!expandedInitial)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={'panel-initial-content'}
                                        >
                                            <b className="home-stepper-title">
                                                {i18n.t('homeScreen:phases.initial.phaseTitle')}
                                            </b>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="phase-description">
                                                {i18n.t(
                                                    `homeScreen:phases.initial.phaseDescription.${patientOrPartner}`
                                                )}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </StepLabel>
                                <StepContent>
                                    {partnerConnected === 'false' && activeStep !== Phases.End ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                history.push('/invites/invitePartner');
                                            }}
                                        >
                                            {i18n.t('common:buttons.connectToPartner')}
                                        </Button>
                                    ) : null}
                                </StepContent>
                            </Step>
                        )}
                        {phases.map((phaseIdentifier, index) => (
                            <Step
                                key={index}
                                className={'simple-stepper-icon phase' + phaseIdentifier.toString()}
                            >
                                <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            active: getStepDotColor(phaseIdentifier),
                                            completed: getStepDotColor(phaseIdentifier)
                                        }
                                    }}
                                >
                                    <Accordion
                                        className="home-stepper-accordion"
                                        expanded={
                                            phaseIdentifier === Phases.Basic
                                                ? expandedFirst
                                                : phaseIdentifier === Phases.Regular
                                                ? expandedSecond
                                                : expandedThird
                                        }
                                        onChange={() => handleOnChange(phaseIdentifier)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={
                                                'panel-' + phaseIdentifier.toString() + '-content'
                                            }
                                            id={'panel-' + phaseIdentifier.toString() + '-header'}
                                        >
                                            <div className="step-summary">
                                                <b className="home-stepper-title">
                                                    {i18n.t(
                                                        `homeScreen:phases.${phaseIdentifier}.phaseTitle`
                                                    )}
                                                </b>
                                                <div className="home-stepper-sub-title">
                                                    {i18n.t(
                                                        `homeScreen:phases.${phaseIdentifier}.subTitle.${patientOrPartner}`
                                                    )}
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography
                                                className={
                                                    'home-stepper-description activeStep' +
                                                    activeStep.toString()
                                                }
                                            >
                                                {i18n.t(
                                                    `homeScreen:phases.${phaseIdentifier}.phaseDescription.${patientOrPartner}`
                                                )}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </StepLabel>
                                <StepContent>
                                    {activeStep === Phases.Basic &&
                                    phaseIdentifier === Phases.Basic ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                redirectToQuestionnaire(phases[index]);
                                            }}
                                        >
                                            {i18n.t('common:buttons.nextQuestionnaireFirstPhase')}
                                        </Button>
                                    ) : null}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === Phases.Regular || activeStep === Phases.Infection ? (
                        <div className="phases-info-container paper-font-size">
                            <Divider />
                            <Typography className="end-of-study">
                                {i18n.t('homeScreen:statisticInformation')}
                            </Typography>
                            {sortPhases.map(phase => buildPhaseInformation(phase))}
                            <Typography className="end-of-study">
                                {i18n.t('homeScreen:endOfStudy')}
                                {endOfStudy === null
                                    ? i18n.t('homeScreen:dateNotCalculatedPartner')
                                    : formatDate(endOfStudy)}
                            </Typography>
                        </div>
                    ) : null}
                    {!props.isPartnerApp && activeStep === Phases.End && (
                        <div className="phases-info-container paper-font-size">
                            <Divider />
                            <div className="phase-information">
                                {i18n.t('homeScreen:fourthPhase')}
                            </div>
                        </div>
                    )}
                </Paper>
            </div>
        </>
    );
}
