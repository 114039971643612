import React, {useState} from 'react';
import DisplayMarkdownFiles from './displayMarkdownFiles';
import {Button} from '@material-ui/core';
import i18n, {effectiveLanguage} from '../../i18n';
import './displayConsentFiles.css';
import ExitStudyDialog from '../exitStudy/ExitStudyDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useHistory} from 'react-router-dom';
import {PDFDocument, StandardFonts} from 'pdf-lib';
import download from 'downloadjs';
import ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import {useSettings} from '../modelHelper/SettingsHelper';
import MenuButton from '../menu/MenuButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {formatDate} from '../utils/Utils';
import {
    createDropoutStudyFile,
    downloadPdf,
    getConsentFileMarkdown
} from '../modelHelper/ConsentFileHelper';
import InfoMessage, {MessageType} from '../errors/InfoMessage';

/**
 * React component that displays the patient information and consent
 * @param {{}} props
 * @param {boolean} props.enableDownload
 * @param {ConsentFileModel} props.consentFileModel
 * @param {boolean} props.viewDropoutFile
 * @param {PropertyTree} props.settings
 * @param {PropertyTree} props.rootPropertyTree
 * @param {boolean} props.isPartnerApp - Set to true if view should be rendered for partner app.
 * @returns {React.ReactElement}
 */
export default function DisplayConsentFiles(props: {
    enableDownload: boolean;
    consentFileModel: ConsentFileModel;
    viewDropoutFile?: boolean;
    settings: PropertyTree;
    rootPropertyTree: PropertyTree;
    isPartnerApp: boolean;
}): React.ReactElement {
    const [dialog, setDialog] = useState(false);
    const [openStoreData, setOpenStoreDataPermission] = React.useState<boolean>(false);
    const [storeDataPermission, setStoreDataPermission] = React.useState<boolean>(false);
    const [stringFormatFile, setStringFormatFile] = React.useState('');
    const [date, setDate] = React.useState<Date>();
    const [consentFileMarkdownPath, setConsentFileMarkdownPath] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [exitStudy, setExitStudy] = useSettings(
        props.rootPropertyTree,
        'homeScreen.exitStudy',
        'false'
    );

    const history = useHistory();

    React.useEffect(() => {
        if (storeDataPermission) {
            if (props.viewDropoutFile) {
                downloadDropoutFile()
                    .then(() => {
                        setStoreDataPermission(false);
                    })
                    .catch(() => {
                        setStoreDataPermission(false);
                    });
            } else {
                downloadPdf(props.consentFileModel, props.isPartnerApp)
                    .then(() => {
                        setStoreDataPermission(false);
                    })
                    .catch(_ => {
                        setStoreDataPermission(false);
                    });
            }
        }
    }, [storeDataPermission]);

    React.useEffect(() => {
        prepareDropoutFile().catch(err => {
            console.error('Error: ', err);
        });

        if (props.viewDropoutFile === undefined) {
            getConsentFileDate().catch(err => {
                console.error('Error: ', err);
            });
        }
    }, []);

    React.useEffect(() => {
        getConsentFileMarkdown(props.consentFileModel, props.isPartnerApp)
            .then((markdownPath: string) => {
                setConsentFileMarkdownPath(markdownPath);
            })
            .catch(error => setErrorMessage(error));
    }, [props.consentFileModel, props.isPartnerApp]);

    async function getConsentFileDate(): Promise<void> {
        const consentFileObj = await props.consentFileModel.getOwnerConsentFile();
        setDate(consentFileObj.creationTime);
    }

    async function prepareDropoutFile(): Promise<void> {
        if (props.viewDropoutFile) {
            try {
                const dropoutFile = await props.consentFileModel.getOwnerDropoutFile();
                setDate(dropoutFile.creationTime);

                setStringFormatFile(
                    `${dropoutFile.data.personId} | ${dropoutFile.data.date} | ${dropoutFile.data.reason}`
                );
            } catch (err) {
                setErrorMessage(err);
            }
        }
    }

    async function onExitStudy(reason: string): Promise<void> {
        await createDropoutStudyFile(props.consentFileModel, reason);
        setDialog(false);
        await setExitStudy('true');
        history.push('/journal');
    }

    function formatReason(reason: string): string[] {
        const words = reason.split(' ');
        let formattedReason = '';
        const finalReason: string[] = [];

        for (let i = 0; i < words.length; i++) {
            if (formattedReason.length + words[i].length < 75) {
                formattedReason += words[i] + ' ';
            } else {
                finalReason.push(formattedReason);
                formattedReason = words[i] + ' ';
            }
        }
        finalReason.push(formattedReason);

        return finalReason;
    }

    async function downloadDropoutFile(): Promise<void> {
        try {
            const dropoutFile = await props.consentFileModel.getOwnerDropoutFile();

            const personId = dropoutFile.data.personId;
            const exitStudyDate = dropoutFile.data.date;
            const reason = dropoutFile.data.reason;

            // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
            const pdf = require('../../' + i18n.t('markDown:dropoutStudy'));

            const existingPdfBytes = await fetch(pdf).then(res => res.arrayBuffer());

            /** Load a PDFDocument from the existing PDF bytes **/
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            /** Embed the Helvetica font
             *  The font can be changed according to the template font
             **/
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            /** Get the first page of the document **/
            const page = pdfDoc.getPage(0);

            /** Get the width and height of the first page **/
            const height = page.getHeight();

            const fontSize = 11;

            const formattedReason = formatReason(reason);

            const lang = effectiveLanguage();

            if (lang === 'en') {
                page.drawText(personId, {
                    x: 141,
                    y: height / 1.26,
                    size: fontSize,
                    font: helveticaFont
                });

                page.drawText(exitStudyDate, {
                    x: 102,
                    y: height / 1.319,
                    size: fontSize,
                    font: helveticaFont
                });

                page.drawText(formattedReason[0], {
                    x: 122,
                    y: height / 1.385,
                    size: fontSize,
                    font: helveticaFont
                });

                if (formattedReason[1]) {
                    page.drawText(formattedReason[1], {
                        x: 73,
                        y: height / 1.439,
                        size: fontSize,
                        font: helveticaFont
                    });
                }
            } else if (lang === 'de') {
                page.drawText(personId, {
                    x: 145,
                    y: height / 1.255,
                    size: fontSize,
                    font: helveticaFont
                });

                page.drawText(exitStudyDate, {
                    x: 112,
                    y: height / 1.313,
                    size: fontSize,
                    font: helveticaFont
                });

                page.drawText(formattedReason[0], {
                    x: 116,
                    y: height / 1.378,
                    size: fontSize,
                    font: helveticaFont
                });

                if (formattedReason[1]) {
                    page.drawText(formattedReason[1], {
                        x: 73,
                        y: height / 1.42,
                        size: fontSize,
                        font: helveticaFont
                    });
                }
            }

            /** Serialize the PDF file to bytes (a Uint8Array) **/
            const pdfBytes = await pdfDoc.save();

            const title = `${i18n.t('common:settings.DropoutFile')} - ${personId}`;

            /** Trigger the browser to download the PDF document **/
            download(pdfBytes, title, 'application/pdf');
        } catch (err) {
            setErrorMessage(err);
        }
    }

    function openDialog(): void {
        setDialog(true);
    }

    function closeDialog(): void {
        setDialog(false);
    }

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <div className="page-container patient-information hide">
                {errorMessage && (
                    <InfoMessage
                        displayMessage={errorMessage !== ''}
                        setDisplayMessage={setErrorMessage}
                        errorMessage={errorMessage}
                        messageType={MessageType.Error}
                    />
                )}
                {props.viewDropoutFile ? (
                    <>
                        <div className="menu-button-header">
                            <MenuButton />
                            <h2 className="headline"> {i18n.t('common:settings.DropoutFile')}</h2>
                        </div>
                        <div className="title-element"> {formatDate(date)} </div>
                        <DisplayMarkdownFiles filePath={stringFormatFile} isText={true} />
                    </>
                ) : props.isPartnerApp ? (
                    <>
                        <div className="menu-button-header">
                            <MenuButton />
                            <h2 className="headline">{i18n.t('common:settings.ConsentFile')}</h2>
                        </div>
                        {props.enableDownload ? (
                            <></>
                        ) : (
                            <div className="title-element"> {formatDate(date)} </div>
                        )}
                        {consentFileMarkdownPath && (
                            <DisplayMarkdownFiles filePath={i18n.t(consentFileMarkdownPath)} />
                        )}
                    </>
                ) : (
                    <>
                        <div className="menu-button-header">
                            <MenuButton />
                            <h2 className="headline"> {i18n.t('common:settings.ConsentFile')}</h2>
                        </div>
                        {props.enableDownload ? (
                            <></>
                        ) : (
                            <div className="title-element"> {formatDate(date)} </div>
                        )}
                        {consentFileMarkdownPath && (
                            <DisplayMarkdownFiles filePath={i18n.t(consentFileMarkdownPath)} />
                        )}
                    </>
                )}
                <div className="buttons-container">
                    <Button
                        className="button"
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenStoreDataPermission(true)}
                    >
                        {i18n.t('common:settings.download')}
                    </Button>
                    {props.enableDownload ? (
                        <>
                            <Button
                                className="button button-margin-left"
                                variant="contained"
                                color="primary"
                                onClick={openDialog}
                                disabled={exitStudy === 'true'}
                            >
                                {i18n.t('common:settings.exitStudy')}
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                    <ExitStudyDialog
                        handleExitStudy={onExitStudy}
                        handleModalClose={closeDialog}
                        isOpen={dialog}
                        settings={props.settings}
                    />
                </div>

                <Dialog
                    open={openStoreData}
                    onClose={() => {
                        setOpenStoreDataPermission(false);
                    }}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {i18n.t('common:backup.usePersonalDevice')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenStoreDataPermission(false);
                            }}
                            color="primary"
                        >
                            {i18n.t('common:backup.cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenStoreDataPermission(false);
                                setStoreDataPermission(true);
                            }}
                            color="primary"
                            autoFocus
                        >
                            {i18n.t('common:backup.agree')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
