import React from 'react';
import {Button} from '@material-ui/core';
import i18n from '../../i18n';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {downloadRecoveryFile} from './RecoveryHelper';
import ConsentFileModel from 'one.models/lib/models/ConsentFileModel';
import RegistrationSimpleStepper from '../registration/registrationSimpleStepper';
import {getRecoveryText} from './recoveryText';
import MenuButton from '../menu/MenuButton';
import InfoMessage, {MessageType} from '../errors/InfoMessage';
import RecoveryModel from 'one.models/lib/models/RecoveryModel';

/**
 * In settings menu the recovery page is displayed so the user can
 * download the recovery file and if the user forgets it's password
 * he can recreate the old instance with a new password using the
 * recovery link.
 *
 * @param {{}}props
 * @param {ConsentFileModel} props.consentFileModel
 * @param {RecoveryModel} props.recoveryModel
 * @returns {React.ReactElement}
 */
export default function RecoveryPage(props: {
    consentFileModel: ConsentFileModel;
    recoveryModel: RecoveryModel;
}): React.ReactElement {
    const [openStoreData, setOpenStoreDataPermission] = React.useState<boolean>(false);
    const [storeDataPermission, setStoreDataPermission] = React.useState<boolean>(false);
    const [errorState, setErrorState] = React.useState('');
    const [displayError, setDisplayError] = React.useState(false);

    React.useEffect(() => {
        if (storeDataPermission) {
            downloadRecoveryFile(props.consentFileModel, props.recoveryModel)
                .then(() => {
                    setStoreDataPermission(false);
                })
                .catch(err => {
                    console.error(err);
                    setErrorState(err);
                    setDisplayError(true);
                });
        }
    }, [storeDataPermission, props.consentFileModel, props.recoveryModel]);

    return (
        <>
            <div className="page-container recovery-container">
                {errorState ? (
                    <InfoMessage
                        errorMessage={errorState}
                        displayMessage={displayError}
                        setDisplayMessage={setDisplayError}
                        messageType={MessageType.Error}
                    />
                ) : (
                    <div />
                )}
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:settings.recoveryPage')}</h2>
                </div>
                <div className="privacy-policy-wrapper">
                    <RegistrationSimpleStepper textObject={getRecoveryText()} />
                </div>
                <div className="buttons-container">
                    <Button
                        color="primary"
                        variant="contained"
                        className="button"
                        onClick={() => setOpenStoreDataPermission(true)}
                    >
                        {i18n.t('common:settings.download')}
                    </Button>
                </div>
                <Dialog
                    open={openStoreData}
                    onClose={() => {
                        setOpenStoreDataPermission(false);
                    }}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {i18n.t('common:backup.usePersonalDevice')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenStoreDataPermission(false);
                            }}
                            color="primary"
                        >
                            {i18n.t('common:backup.cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenStoreDataPermission(false);
                                setStoreDataPermission(true);
                            }}
                            color="primary"
                            autoFocus
                        >
                            {i18n.t('common:backup.agree')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
