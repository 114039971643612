import React from 'react';
import JournalModel, {EventListEntry, EventType} from 'one.models/lib/models/JournalModel';
import {EventTypes} from '../journal/Journal';
import {Help} from 'mdi-material-ui';
import * as dateFns from 'date-fns';
import i18n from '../../i18n';

/**
 * This method provides a event list response based on the eventList model.
 * @param {JournalModel} journalModel - The event list model.
 * @returns {JournalModel[]} an array with event list responses.
 */
export function useEventList(journalModel: JournalModel): EventListEntry[] {
    const [responses, setResponses] = React.useState<EventListEntry[]>([]);

    React.useEffect(() => {
        function fetchEvents(): void {
            journalModel
                .events()
                .then((value: EventListEntry[]) => {
                    setResponses(value.reverse()); // TODO: sort order should be passed as sorting
                    // flag to events() call for efficiency reasons
                })
                .catch(err => {
                    // TODO: This should be replaced by something the user actually sees
                    console.error('Error fetching data: ' + err);
                });
        }

        journalModel.on('updated', fetchEvents);
        fetchEvents();

        return () => {
            journalModel.removeListener('updated', fetchEvents);
        };
    }, [journalModel]);

    return responses;
}

/**
 * This method provides a event list response based on the eventList model.
 * @param {EventListEntry} event - The event list model.
 * @param {EventType[]} listOfEventTypes - array with event list responses.
 * @returns {{hour: EventTypes[],minutes: EventTypes[],icon: EventTypes[]}} - needed information
 */
export function getCurrentEventInformation(
    event: EventListEntry,
    listOfEventTypes: EventTypes[]
): {
    hour: string;
    minutes: string;
    icon?: React.ReactElement;
    type: string;
} {
    const currentEventType = listOfEventTypes.find(element => element.type === event.type);
    let type = '';

    Object.entries(event.data).forEach(([name, value]) => {
        if (name === 'data') {
            Object.entries(value).forEach(([dataName, dataValue]) => {
                if (dataName === '$type$') {
                    type = dataValue + ' ';
                }

                // reason property is specific to the dropout file
                if (event.type === 'ConsentFileModel' && dataName === 'reason') {
                    type = i18n.t('common:settings.DropoutFile');
                }

                // version property is specific to the consent document
                if (event.type === 'ConsentFileModel' && dataName === 'version') {
                    type = i18n.t('common:settings.ConsentFile');
                }

                if (event.type === 'QuestionnaireModel' && dataName === 'type') {
                    type = i18n.t(`common:questionnaire.${dataValue}`);
                }
            });
        }
    });

    if (currentEventType && currentEventType.name !== i18n.t('common:settings.ConsentFile')) {
        if (!currentEventType.name.includes(i18n.t('common:menu.questionnaire'))) {
            // take a look here when we know what to display as event name in the journal (-questionnaire related)
            type = type + ' ' + currentEventType.name;
        }
    } else if (!currentEventType) {
        type = i18n.t('common:eventTypes.unknownEvent');
    }

    return {
        hour: dateFns.format(event.data.creationTime, 'HH'),
        minutes: dateFns.format(event.data.creationTime, 'mm'),
        icon: currentEventType ? currentEventType.icon : <Help />,
        type: type
    };
}
