import React, {ReactElement} from 'react';
import {Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Fab} from '@material-ui/core';
import './NavigationDrawer.css';
import {Link} from 'react-router-dom';
import '../../Primary.css';
import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import {useSettings} from '../modelHelper/SettingsHelper';
import i18n from '../../i18n';
import {Icon} from '../icon/Icon';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

/*
 type definition of the layout of elements properties menu
 */
export type NavigationDrawerItemsType = {
    name: string;
    path?: string;
    icon?: React.ReactElement;
    subItems?: NavigationDrawerItemsType[];
    disabled?: boolean;
};

/**
 * This function build the items from the menu.
 * @param {{}} props - Properties of this view.
 * @param {NavigationDrawerItemsType} props.itemsArray
 * @param {Function} props.setNavigationDrawerState
 * @param {PropertyTree} props.settings
 * @param {boolean} props.bottomElements - specify if the given elements
 * should be displayed at the bottom of the menu or not
 * @returns {ReactElement} - a list containing the items of the menu.
 */
export function MenuItems(props: {
    itemsArray: NavigationDrawerItemsType[];
    setNavigationDrawerState: (value: boolean) => void;
    settings: PropertyTree;
    bottomElements: boolean;
}): ReactElement {
    const [openState, setOpenState] = React.useState<{[key: string]: boolean}>({});
    const [closeMenuOnItemSelect] = useSettings(props.settings, 'closeMenuOnItemSelect', 'false');

    function menuClickHandler(): void {
        if (
            window.innerWidth < 770 ||
            (window.innerWidth >= 770 && closeMenuOnItemSelect === 'true')
        ) {
            props.setNavigationDrawerState(false);
        }
    }

    /**
     * This function extends or collapses the sub-items list.
     * @param {string} path
     */
    function handleClick(path: string): void {
        setOpenState({...openState, [path]: !openState[path]});
    }

    /**
     * This function builds the items of the menu navigation
     * @param {string} name - the name of the item
     * @param {string} path - the path of the item
     * @param {icon} icon - the icon of the item
     * @param {boolean} disabled - if the menu item should be disabled or not
     * @returns {React.ReactElement} - the item body
     */
    function buildNavigationElements(
        name: string,
        path?: string,
        icon?: React.ReactElement,
        disabled?: boolean
    ): React.ReactElement {
        let menuItem = <></>;

        if (!(icon || path)) {
            return <Divider />;
        }

        menuItem = (
            <ListItem button disabled={!!disabled} className="menu-option">
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{name}</ListItemText>
            </ListItem>
        );

        if (path) {
            return disabled ? (
                <div className="menu-option">{menuItem}</div>
            ) : (
                <Link className="menu-option app-menu-entry" to={path} onClick={menuClickHandler}>
                    {menuItem}
                </Link>
            );
        }

        return <>{menuItem}</>;
    }

    function buildSubItems(
        name: string,
        subItems: NavigationDrawerItemsType[],
        path?: string,
        disabled?: boolean
    ): React.ReactElement {
        return (
            <div className={openState[path ? path : name] ? '' : 'hide'}>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: 'navigation-drawer'
                    }}
                    open={openState[path ? path : name]}
                >
                    <List>
                        <ListItem
                            button
                            onClick={() => {
                                handleClick(path ? path : name);
                            }}
                            className="menu-option"
                        >
                            <ListItemIcon className="menu-button">
                                <Fab size="small" color="primary" className="fab-wrapper">
                                    <ArrowBackIcon />
                                </Fab>
                            </ListItemIcon>
                            <ListItemText>{name}</ListItemText>
                        </ListItem>
                    </List>

                    {subItems.map(
                        (
                            {name: subName, path: subPath, icon: subIcon, subItems: subSubItems},
                            idx
                        ) => (
                            <div key={idx}>
                                {subSubItems
                                    ? buildSubItems(subName, subSubItems, subPath, disabled)
                                    : buildNavigationElements(
                                          subName,
                                          subPath ? subPath : undefined,
                                          subIcon,
                                          disabled
                                      )}
                            </div>
                        )
                    )}
                </Drawer>
            </div>
        );
    }

    return (
        <List
            className={
                props.bottomElements
                    ? 'navigation-drawer-items bottom-items'
                    : 'navigation-drawer-items'
            }
        >
            {props.itemsArray.map(({name, path, icon, subItems, disabled}, index) => (
                <div key={index}>
                    {subItems ? (
                        <>
                            <ListItem
                                button
                                disabled={!!disabled}
                                className="menu-option"
                                onClick={() => handleClick(path ? path : name)}
                            >
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListItemText>{name}</ListItemText>
                            </ListItem>
                            {openState[path ? path : name] === undefined &&
                            '/' + window.location.pathname.split('/')[1] === path
                                ? handleClick(path ? path : name)
                                : ''}
                            {buildSubItems(name, subItems, path, disabled)}
                        </>
                    ) : (
                        <>{buildNavigationElements(name, path, icon, disabled)}</>
                    )}
                </div>
            ))}
        </List>
    );
}

/**
 * Renders the drawer of the menu navigation
 * @param {{}} props
 * @param {NavigationDrawerItemsType} props.navigationDrawerItems
 * @param {Function} props.setNavigationDrawerState
 * @param {PropertyTree} props.settings
 * @returns {React.ReactElement}
 */
export default function NavigationDrawer(props: {
    navigationDrawerItems: NavigationDrawerItemsType[];
    setNavigationDrawerState: (value: boolean) => void;
    settings: PropertyTree;
}): React.ReactElement {
    const topItems: NavigationDrawerItemsType[] = [];
    const bottomItems: NavigationDrawerItemsType[] = [];
    let order = -1;

    /**
     * This is used to creates two arrays of items, one array for the top items and one for the bottom items.
     */
    for (let i = 0; i < props.navigationDrawerItems.length; i++) {
        if (props.navigationDrawerItems[i].name === '----l') {
            order = 1;
        }

        if (order === 1) {
            bottomItems.push(props.navigationDrawerItems[i]);
        } else {
            topItems.push(props.navigationDrawerItems[i]);
        }
    }

    return (
        <div>
            <Drawer
                variant="permanent"
                classes={{
                    paper: 'navigation-drawer'
                }}
            >
                <List className="navigation-drawer-items">
                    <ListItem
                        button
                        onClick={() => {
                            props.setNavigationDrawerState(false);
                        }}
                        className="menu-option"
                    >
                        <ListItemIcon className="menu-button">
                            <Icon name="Menu" />
                        </ListItemIcon>
                        <ListItemText>{i18n.t('common:menu.name')}</ListItemText>
                    </ListItem>
                </List>

                <MenuItems
                    setNavigationDrawerState={props.setNavigationDrawerState}
                    itemsArray={topItems}
                    settings={props.settings.getChild('menuItems')}
                    bottomElements={false}
                />

                <MenuItems
                    setNavigationDrawerState={props.setNavigationDrawerState}
                    itemsArray={bottomItems}
                    settings={props.settings.getChild('menuItems')}
                    bottomElements={true}
                />
            </Drawer>
        </div>
    );
}
