import {Alert, AlertTitle} from '@material-ui/lab';
import React from 'react';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper/Paper';

export enum MessageType {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Success = 'success'
}

/**
 * @param {{}} props
 * @param {string} props.errorMessage
 * @param {boolean} props.displayMessage
 * @param {Function} props.setDisplayMessage
 * @param {MessageType} props.messageType
 * @returns {React.ReactElement}
 */
export default function InfoMessage(props: {
    errorMessage: string;
    displayMessage: boolean;
    setDisplayMessage: Function;
    messageType: MessageType;
}): React.ReactElement {
    return (
        <>
            {props.displayMessage && (
                <Paper
                    square
                    elevation={3}
                    className={`stick-message-top message-font-size ${
                        props.messageType === MessageType.Error
                            ? 'error-color'
                            : props.messageType === MessageType.Warning
                            ? 'warning-color'
                            : props.messageType === MessageType.Success
                            ? 'success-color'
                            : ''
                    }`}
                >
                    <Alert
                        severity={props.messageType}
                        onClose={() => {
                            props.setDisplayMessage(false);
                        }}
                    >
                        <AlertTitle className="message-title">
                            {i18n.t(
                                `errors:title${
                                    props.messageType[0].toUpperCase() + props.messageType.substr(1)
                                }`
                            )}
                        </AlertTitle>
                        {i18n.t(`${props.errorMessage}`)}
                    </Alert>
                </Paper>
            )}
        </>
    );
}
