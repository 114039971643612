import React, {useEffect} from 'react';
import OneInstanceModel, {LogoutMode} from 'one.models/lib/models/OneInstanceModel';
import {Button, Dialog} from '@material-ui/core';
import i18n from '../../i18n';
import {Alert, AlertTitle} from '@material-ui/lab';
import './AutoLogout.css';

/**
 * @param {{}} props
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param props.setNavigationDrawerState - used to close the menu before logging out due the inactivity
 * @returns {React.ReactElement}
 */
export default function AutoLogout(props: {
    oneInstanceModel: OneInstanceModel;
    setNavigationDrawerState: (navigationDrawerState: boolean) => void
}): React.ReactElement {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    const logoutTimeout = 900000; // ms which is 15 minutes
    const warningTime = 840000;
    let logoutTimeoutHandler: number | null;
    let warningTimeoutHandler: number | null;

    const [warningDialog, setWarningDialog] = React.useState(false);

    useEffect(() => {
        /**
         *  add listeners for the events that detect user activity
         */
        for (let i = 0; i < events.length; i++) {
            window.addEventListener(events[i], clearTimeoutLogout);
        }
        logoutTimeoutHandler = window.setTimeout(logout, logoutTimeout);
        warningTimeoutHandler = window.setTimeout(warn, warningTime);
    }, []);

    function warn(): void {
        setWarningDialog(true);
    }

    function clearTimeoutLogout(): void {
        if (logoutTimeoutHandler === null) {
            logoutTimeoutHandler = window.setTimeout(logout, logoutTimeout);
        } else {
            window.clearTimeout(logoutTimeoutHandler);
            logoutTimeoutHandler = null;
        }

        if (warningTimeoutHandler === null) {
            warningTimeoutHandler = window.setTimeout(warn, warningTime);
        } else {
            window.clearTimeout(warningTimeoutHandler);
            warningTimeoutHandler = null;
        }
    }

    async function logout(): Promise<void> {
        // close the menu => remove the css that is added to the app when the menu is open
        // in this way we are sure that no extra margin or padding is added to the login view
        props.setNavigationDrawerState(false);
        await props.oneInstanceModel.logout(LogoutMode.KeepData);
        destroy();
    }

    function destroy(): void {
        clearTimeout();

        for (let i = 0; i < events.length; i++) {
            window.removeEventListener(events[i], clearTimeoutLogout);
        }
    }

    return warningDialog ? (
        <Dialog
            onClose={() => {
                clearTimeoutLogout();
                setWarningDialog(false);
            }}
            aria-labelledby="simple-dialog-title"
            open={warningDialog}
        >
            <Alert severity="info">
                <AlertTitle>
                    <b>{i18n.t('common:infos.sessionExpired')}</b>
                </AlertTitle>
            </Alert>
            <div className="margin_text">{i18n.t('common:infos.sessionExpiredContent')}</div>
            <Button
                className="button_style"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                    clearTimeoutLogout();
                    setWarningDialog(false);
                }}
            >
                {i18n.t('common:settings.cancel')}
            </Button>
        </Dialog>
    ) : (
        <div />
    );
}
