import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireBT: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'de',
    url: 'http://uk-erlangen.de/freeda/questionnaire/BT_de',
    name: 'BT',
    status: 'active',
    item: [
        {
            linkId: 'bTherapyYN',
            prefix: 'BT1',
            text: 'Erhalten Sie aktuell eine Krebstherapie?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '0',
                        display: 'Nein'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                        version: '1.0',
                        code: '1',
                        display: 'Ja'
                    }
                }
            ]
        },
        {
            linkId: 'BT2_0',
            prefix: 'BT2_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'bTherapyYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'bNumberOfTherapy',
                    prefix: 'BT2_1',
                    type: 'integer',
                    text: 'Die wievielte Krebstherapie zu Ihrer aktuellen Krebserkrankung ist dies?',
                    enableWhen: [
                        {
                            question: 'bNumberOfTherapyUk',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerRestriction: {
                        minValue: {
                            valueInteger: '1'
                        },
                        maxValue: {
                            valueInteger: '80'
                        }
                    }
                },
                {
                    linkId: 'bNumberOfTherapyUk',
                    prefix: 'BT2_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_de',
                                version: '1.0',
                                code: '1',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'BT3_0',
            prefix: 'BT3_0',
            text:
                'Welche der folgenden Therapien erhalten Sie aufgrund Ihrer Krebserkrankung? (Mehrfachnennungen möglich)',
            type: 'group',
            enableWhen: [
                {
                    question: 'bTherapyYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'bAhTherapyYNU',
                    prefix: 'BT3_1',
                    type: 'choice',
                    text: 'Anti-Hormonelle Therapie',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bChemoYNU',
                    prefix: 'BT3_2',
                    type: 'choice',
                    text: 'Chemotherapie',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bCheckPointYNU',
                    prefix: 'BT3_3',
                    type: 'choice',
                    text:
                        'Checkpoint-Inhibitor Therapie (Immuntherapie-Antikörper, z.B. Pembrolizumab, Atezolizumab, Iplimumab, Nivolumab, u.a.)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bAntiHerYNU',
                    prefix: 'BT3_4',
                    type: 'choice',
                    text: 'Anti-HER2/neu-Rezeptor gerichtete Therapie (z.B. Herceptin®, Kanjinti®, Perjeta®)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bOtherAntibodyYNU',
                    prefix: 'BT3_5',
                    type: 'choice',
                    text: 'Eine andere Antikörper-Therapie',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bCdkiYNU',
                    prefix: 'BT3_6',
                    type: 'choice',
                    text: 'CDK4/6-Inhibitor Therapie (z.B. Ibrance®, Kisquali®, Verzenios®)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bMToriYNU',
                    prefix: 'BT3_7',
                    type: 'choice',
                    text: 'mTOR-Inhibitor Therapy (z.B. Everolimus/Rapamycin, Temsirolimus)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bRadYNU',
                    prefix: 'BT3_8',
                    type: 'choice',
                    text: 'Strahlentherapie des Brustkorbs/Lunge (vollständig oder teilweise)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bAutStemCellYNU',
                    prefix: 'BT3_9',
                    type: 'choice',
                    text: 'autologe Stammzelltransplantationen',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bAllogStemCellYNU',
                    prefix: 'BT3_10',
                    type: 'choice',
                    text: 'allogene Stammzelltransplantationen',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bCarTcellYNU',
                    prefix: 'BT3_11',
                    type: 'choice',
                    text: 'CAR-T Zell-Therapie',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bOtherTargTYNU',
                    prefix: 'BT3_12',
                    type: 'choice',
                    text: 'Andere zielgerichtete Therapie (z.B. bei genetischer Mutation)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '0',
                                display: 'Nein'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '1',
                                display: 'Ja'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_de',
                                version: '1.0',
                                code: '999',
                                display: 'Weiß nicht'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bOtherTarg_txt',
                    prefix: 'BT3_13',
                    type: 'string',
                    maxLength: 1000,
                    text: 'Um welche andere zielgerichtete Therapie handelt es sich?',
                    enableWhen: [
                        {
                            question: 'bOtherTargTYNU',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bMedication_txt',
                    prefix: 'BT3_14',
                    type: 'string',
                    maxLength: 1000,
                    text:
                        'Hier haben Sie noch die Möglichkeit Ihre genaue Krebstherapie einzutragen. (Mehrfachnennungen möglich)',
                    required: false
                }
            ]
        }
    ]
};
