import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireB: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://uk-erlangen.de/freeda/questionnaire/B_en',
    name: 'B',
    status: 'active',
    item: [
        {
            linkId: 'yearOfBirth',
            prefix: 'B1',
            text: 'In which year were you born?',
            type: 'integer',
            answerRestriction: {
                minValue: {
                    valueInteger: '1920'
                },
                maxValue: {
                    valueInteger: '2020'
                }
            }
        },
        {
            linkId: 'gender',
            prefix: 'B2',
            text: 'What is your gender?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/gender_en',
                        version: '1.0',
                        code: '1',
                        display: 'male'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/gender_en',
                        version: '1.0',
                        code: '2',
                        display: 'female'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/gender_en',
                        version: '1.0',
                        code: '3',
                        display: 'divers'
                    }
                }
            ]
        },
        {
            linkId: 'B3_0',
            prefix: 'B3_0',
            type: 'group',
            item: [
                {
                    linkId: 'weight_N',
                    prefix: 'B3_1',
                    type: 'integer',
                    text: 'What is your weight in kg?',
                    answerRestriction: {
                        minValue: {
                            valueInteger: '20'
                        },
                        maxValue: {
                            valueInteger: '200'
                        }
                    },
                    enableWhen: [
                        {
                            question: 'weightUk',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ]
                },
                {
                    linkId: 'weightUk',
                    prefix: 'B3_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_en',
                                version: '1.0',
                                code: '1',
                                display: 'I do not know'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'B4_0',
            prefix: 'B4_0',
            text: 'Which cancer has been diagnosed in you?',
            type: 'group',
            item: [
                {
                    linkId: 'colonCancer',
                    prefix: 'B4_1',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/colon_en',
                                version: '1.0',
                                code: '1',
                                display: 'colon'
                            }
                        }
                    ]
                },
                {
                    linkId: 'breastCancer',
                    prefix: 'B4_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/breast_en',
                                version: '1.0',
                                code: '1',
                                display: 'breast'
                            }
                        }
                    ]
                },
                {
                    linkId: 'lungCancer',
                    prefix: 'B4_3',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/lung_en',
                                version: '1.0',
                                code: '1',
                                display: 'lung'
                            }
                        }
                    ]
                },
                {
                    linkId: 'prostateCancer',
                    prefix: 'B4_4',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/prostate_en',
                                version: '1.0',
                                code: '1',
                                display: 'prostate'
                            }
                        }
                    ]
                },
                {
                    linkId: 'skinCancer',
                    prefix: 'B4_5',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/skin_en',
                                version: '1.0',
                                code: '1',
                                display: 'skin'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bladderCancer',
                    prefix: 'B4_6',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/urinary_en',
                                version: '1.0',
                                code: '1',
                                display: 'urinary bladder'
                            }
                        }
                    ]
                },
                {
                    linkId: 'lymphomaCancer',
                    prefix: 'B4_7',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/lymphoma_en',
                                version: '1.0',
                                code: '1',
                                display: 'lymphoma (Hodgkin, non-Hodgkin, multiple myloma)'
                            }
                        }
                    ]
                },
                {
                    linkId: 'uterineBodyCancer',
                    prefix: 'B4_8',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/uterine_en',
                                version: '1.0',
                                code: '1',
                                display: 'uterine body'
                            }
                        }
                    ]
                },
                {
                    linkId: 'cervicalCancer',
                    prefix: 'B4_9',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/cervical_en',
                                version: '1.0',
                                code: '1',
                                display: 'endometrial'
                            }
                        }
                    ]
                },
                {
                    linkId: 'oropharyngealCancer',
                    prefix: 'B4_10',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/oropharyngeal_en',
                                version: '1.0',
                                code: '1',
                                display: 'oropharyngeal'
                            }
                        }
                    ]
                },
                {
                    linkId: 'gastricCancer',
                    prefix: 'B4_11',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/stomach_en',
                                version: '1.0',
                                code: '1',
                                display: 'stomach'
                            }
                        }
                    ]
                },
                {
                    linkId: 'renalCancer',
                    prefix: 'B4_12',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/renal_en',
                                version: '1.0',
                                code: '1',
                                display: 'kidney'
                            }
                        }
                    ]
                },
                {
                    linkId: 'leukemiasCancer',
                    prefix: 'B4_13',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/leukemia_en',
                                version: '1.0',
                                code: '1',
                                display: 'leukemia'
                            }
                        }
                    ]
                },
                {
                    linkId: 'otherCancer_txt',
                    prefix: 'B4_14',
                    type: 'string',
                    maxLength: 1000,
                    text: 'others',
                    required: false
                }
            ]
        },
        {
            linkId: 'remoteMetYNU',
            prefix: 'B5',
            type: 'choice',
            text: 'Are neighbouring organs or more distant organs already affected by your cancer?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/OrganLimited_en',
                        version: '1.0',
                        code: '0',
                        display: 'No it is still limited to the organ of origin'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'yearOfCancerDiagn',
            prefix: 'B6',
            type: 'integer',
            text: 'In which year was your cancer diagnosed?',
            answerRestriction: {
                minValue: {
                    valueInteger: '1920'
                },
                maxValue: {
                    valueInteger: '2023'
                }
            }
        },
        {
            linkId: 'influenzaVaccYNU',
            prefix: 'B7',
            type: 'choice',
            text: 'Have you been vaccinated against influenza at least once in the last 5 years?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'pneumococVacc',
            prefix: 'B8',
            type: 'choice',
            text: 'Have you been vaccinated against pneumococcus?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'secDiseaseYNU',
            prefix: 'B9',
            type: 'choice',
            text:
                'Do you have other diseases besides cancer (and the SARS-CoV-2 infection) or are you aware of previous diseases?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'B10_0',
            prefix: 'B10_0',
            type: 'group',
            text: 'Which of the following previous illnesses? (multiple answers possible)',
            enableWhen: [
                {
                    question: 'secDiseaseYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'hypertensionYNU',
                    prefix: 'B10_1',
                    type: 'choice',
                    text: 'high blood pressure (hypertension)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'chroRespOrLungYNU',
                    prefix: 'B10_2',
                    type: 'choice',
                    text: 'chronic respiratory/lung diseases',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'secDiabetes1o2YNU',
                    prefix: 'B10_3',
                    type: 'choice',
                    text: 'diabetes type 1 or type 2',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'secDisOtherYNU',
                    prefix: 'B10_4',
                    type: 'choice',
                    text: 'Do you have any other diseases?',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'secDisOther_txt',
                    prefix: 'B10_5',
                    type: 'string',
                    maxLength: 1000,
                    text: 'What other diseases do you have?',
                    enableWhen: [
                        {
                            question: 'secDisOtherYNU',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'smoking',
            prefix: 'B11_1',
            type: 'choice',
            text: 'Have you ever smoked cigarettes?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/smoking_en',
                        version: '1.0',
                        code: '0',
                        display: 'non-smoker'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/smoking_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/smoking_en',
                        version: '1.0',
                        code: '3',
                        display: 'former smoker'
                    }
                }
            ]
        },
        {
            linkId: 'smokingYears_N',
            prefix: 'B11_2',
            type: 'integer',
            text: 'How many years?',
            enableWhen: [
                {
                    question: 'smoking',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            answerRestriction: {
                minValue: {
                    valueInteger: '1'
                },
                maxValue: {
                    valueInteger: '100'
                }
            }
        },
        {
            linkId: 'persHoushold_N',
            prefix: 'B12',
            type: 'integer',
            text: 'How many other people live with you in your household?',
            answerRestriction: {
                minValue: {
                    valueInteger: '0'
                },
                maxValue: {
                    valueInteger: '50'
                }
            }
        },
        {
            linkId: 'childs_N',
            prefix: 'B13',
            minLength: 0,
            maxLength: 20,
            type: 'integer',
            text: 'How many children do you have?',
            answerRestriction: {
                minValue: {
                    valueInteger: '0'
                },
                maxValue: {
                    valueInteger: '20'
                }
            }
        },
        {
            linkId: 'petsYNU',
            prefix: 'B14',
            type: 'choice',
            text:
                'So far, there is no evidence of a link between keeping pets in the home and the transmission of SARS-CoV-2 to humans or vice versa. Nevertheless, we ask you to answer the following question. Do you have pets living with you in the household?',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '999',
                        display: 'I do not know'
                    }
                }
            ]
        },
        {
            linkId: 'B15_0',
            prefix: 'B15_0',
            type: 'group',
            text: 'Which pets? (multiple answers possible)',
            enableWhen: [
                {
                    question: 'petsYNU',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'petsCat',
                    prefix: 'B15_1',
                    type: 'open-choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/cat_en',
                                version: '1.0',
                                code: '1',
                                display: 'cat'
                            }
                        }
                    ],
                    required: false
                },
                {
                    linkId: 'petsDog',
                    prefix: 'B15_2',
                    type: 'open-choice',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/dog_en',
                                version: '1.0',
                                code: '1',
                                display: 'dog'
                            }
                        }
                    ],
                    required: false
                },
                {
                    linkId: 'petsOther_txt',
                    prefix: 'B15_3',
                    type: 'string',
                    maxLength: 1000,
                    text: 'others',
                    required: false
                }
            ]
        }
    ]
};
