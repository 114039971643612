import Model from '../model/Model';
import React, {useEffect} from 'react';
import {isIOS, isSafari, isMobile} from 'react-device-detect';
import './Ui.css';
import MenuButton from './menu/MenuButton';
import NavigationDrawer, {NavigationDrawerItemsType} from './menu/NavigationDrawer';
import {BrowserRouter, Route} from 'react-router-dom';
import {Switch} from 'react-router';
import Journal, {EventTypes} from './journal/Journal';
import {AuthenticationState, LogoutMode} from 'one.models/lib/models/OneInstanceModel';
import {useAuthenticationState, useRegistrationState} from './modelHelper/OneInstanceHelper';
import Login from './login/Login';
import Loading from './loading/Loading';
import NewsCreator from './news/NewsCreator';
import NewsReader from './news/NewsReader';
import EraseDialog from './eraseInstance/EraseDialog';
import CovidQuestionnaire from './questionnaireCovid/CovidQuestionnaire';
import PersonalCloud from './cloudConnections/PersonalCloud';
import CreateAcceptInvites from './cloudConnections/CreateAcceptInvites';
import DiaryEntryPage from './diary/DiaryEntry';
import PartnerConnections from './cloudConnections/PartnerConnections';
import HomeScreenStepper from './homeScreen/HomeScreenStepper';
import SettingsPolicies from './settingsPolicies/SettingsPolicies';
import RegistrationProcessInformation from './registration/registrationProcessInformation';
import i18n from '../i18n';
import '../Primary.css';
import {freedaNavigationDrawerItems, freedaEventTypes} from './productData/freedaData';
import Backup from './backup/Backup';
import AppSettings from './appSettings/AppSettings';
import {partnerEventTypes, partnerNavigationDrawerItems} from './productData/partnerData';
import DisplayConsentFiles from './displayMarkdowns/displayConsentFiles';
import Logout from './logout/Logout';
import AddToHomeScreen from './addToHomeScreen/AddToHomeScreen';
import {ReactComponent as LandscapeMode} from './../resources/phone-rotate-portrait.svg';
import AutoLogout from './autoLogout/AutoLogout';
import {PropertyTree} from 'one.models/lib/models/SettingsModel';
import RecoveryPage from './recovery/RecoveryPage';
import Erase from './eraseInstance/Erase';
import FooterElement from './footer/Footer';
import Feedback from './feedback/Feedback';

import BackButton from './menu/BackButton';
import Connections from './cloudConnections/Connections';
import {isStandalone} from './utils/Utils';
import {
    ConnectionClosedWebAppPartner,
    ConnectionClosedHomeScreenPartner,
    ConnectionClosedIoM
} from './cloudConnections/ConnectionErrorViews';
import Reset from './logout/Reset';
import AutocompleteQuestionnaires from './autocompleteQuestionnaires/AutocompleteQuestionnaires';

/**
 * List of product types
 */
export enum ProductType {
    Freeda = 'freeda',
    Partner = 'partner'
}

enum Footer {
    NoFooter,
    FooterPage
}

/**
 * Render the ui (top level element)
 *
 * TODO: remove the patient type property again when it is handled by the models
 *
 * @param {{}} props
 * @param {Model} props.model
 * @param {PropertyTree} props.settings
 * @param {boolean} props.isPartnerApp - Set to true if you want to render the Partner Ui. False for Patient Ui
 * @param {boolean} props.patientType - Use this patient type for queries on oneInstanceModel.
 * @returns {React.ReactElement}
 */
export default function Ui(props: {
    model: Model;
    settings: PropertyTree;
    isPartnerApp: boolean;
    patientType: string;
}): React.ReactElement {
    const productType = props.isPartnerApp ? ProductType.Partner : ProductType.Freeda;
    const authenticationState = useAuthenticationState(props.model.oneInstance);
    const registrationState = useRegistrationState(props.model.oneInstance);
    const [navigationDrawerState, setNavigationDrawerState] = React.useState(false);
    const [menuContentStyle, setMenuContentStyle] = React.useState('app-left-container');
    const [navigationDrawerItemsState, updateNavigationDrawerItemsState] = React.useState<
        NavigationDrawerItemsType[]
    >([]);
    const [footerPage, setFooterPage] = React.useState(Footer.NoFooter);
    const [eventTypesState, updateEventTypesState] = React.useState<EventTypes[]>([]);
    const [logoutFromErase, setLogoutFromErase] = React.useState<boolean>(false);
    const [offlineDialog, setOfflineDialog] = React.useState(false);

    // this checks if the invitation failed
    const appBrokeOnInvite = localStorage.getItem('appBrokeOnInvite');

    function updateProductData(): void {
        // This switch is there to get rid of the ts any type error ...
        // a different solution would be nice
        switch (productType) {
            case ProductType.Freeda:
                updateNavigationDrawerItemsState(freedaNavigationDrawerItems());
                updateEventTypesState(freedaEventTypes());
                document.title = 'freeda';
                break;
            case ProductType.Partner:
                updateNavigationDrawerItemsState(partnerNavigationDrawerItems());
                updateEventTypesState(partnerEventTypes());
                document.title = 'freeda partner';
                break;
            default:
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                throw Error(`Unsupported product type: ${productType}`);
        }
    }

    /**
     * Depending on the logout model either log out the
     * user either erase all data from this device.
     */
    function renderLogoutComponent(): React.ReactElement {
        const path = window.location.href.split('=');
        const logoutMode = path[path.length - 1];

        if (logoutMode === 'true') {
            return (
                <Logout
                    setNavigationDrawer={setNavigationDrawerState}
                    oneInstanceModel={props.model.oneInstance}
                />
            );
        } else {
            return (
                <EraseDialog
                    setLogoutFromErase={setLogoutFromErase}
                    consentFileModel={props.model.consentFile}
                    oneInstanceModel={props.model.oneInstance}
                    setNavigationDrawer={setNavigationDrawerState}
                    settings={props.settings}
                    isPartnerApp={props.isPartnerApp}
                />
            );
        }
    }

    useEffect(() => {
        window.location.pathname === '/impressum' ||
        window.location.pathname === '/FAQ' ||
        window.location.pathname === '/privacyPolicy'
            ? setFooterPage(Footer.FooterPage)
            : setFooterPage(Footer.NoFooter);
        window.addEventListener('popstate', () => {
            window.location.pathname === '/impressum' ||
            window.location.pathname === '/FAQ' ||
            window.location.pathname === '/privacyPolicy'
                ? setFooterPage(Footer.FooterPage)
                : setFooterPage(Footer.NoFooter);
        });
    }, [footerPage]);

    useEffect(() => {
        updateProductData();
    }, [productType]);

    // Set a listener for the online state, when the onlineState is false means you are in offline mode.
    // So we have to let the user know about this.
    useEffect(() => {
        props.model.connections.on('onlineStateChange', handleOnlineStateChangeEvent);

        if (isStandalone()) {
            document.addEventListener('visibilitychange', handleVisibilityChangeEvent);
        }

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChangeEvent);
            props.model.connections.removeListener(
                'onlineStateChange',
                handleOnlineStateChangeEvent
            );
        };
    }, []);

    // Set the style for open / closed menu
    useEffect(() => {
        if (navigationDrawerState) {
            setMenuContentStyle('app-right-container');
        } else {
            setMenuContentStyle('app-left-container');
        }
    }, [navigationDrawerState, authenticationState, registrationState, props.isPartnerApp]);

    i18n.on('languageChanged', () => {
        updateProductData();
    });

    /**
     * Handler for the 'onlineStateChange' event -> this event is triggered when the application goes offline
     * @param {boolean} onlineState
     */
    function handleOnlineStateChangeEvent(onlineState: boolean): void {
        if (!onlineState && !offlineDialog) {
            setOfflineDialog(true);
        }
    }

    /**
     * Handler for the 'visibilityChange' event -> this event is triggered when the standalone application(saved on home screen)
     * is opened or closed from background.
     */
    function handleVisibilityChangeEvent(): void {
        if (localStorage.getItem('instance')) {
            if (document.hidden) {
                localStorage.setItem('sleepSinceTS', new Date().getTime().toString());
                return;
            }

            if (localStorage.getItem('sleepSinceTS')) {
                const sleepSinceTS = Number(localStorage.getItem('sleepSinceTS'));
                const nowTS = new Date().getTime();

                // 60000  = 1 minute
                if (nowTS - sleepSinceTS > 10 * 60000) {
                    props.model.oneInstance
                        .logout(LogoutMode.KeepData)
                        .catch(err => console.error(err));
                }
            }
        }
    }

    /**
     *
     * if there is the appBrokeOnInvite flag set this means that the invitation failed and
     * the login page will not be shown anymore and an error blocking page will appear
     * if not login is rendered normally
     */
    function renderLoginOrErrorView(): React.ReactElement {
        if (!appBrokeOnInvite) {
            return (
                <Login
                    oneInstanceModel={props.model.oneInstance}
                    fromErase={logoutFromErase}
                    setFromErase={setLogoutFromErase}
                    contactModel={props.model.contactModel}
                    isPartnerApp={props.isPartnerApp}
                    patientType={props.patientType}
                    recoveryModel={props.model.recoveryModel}
                />
            );
        }

        if (appBrokeOnInvite === 'partner' && isStandalone()) {
            return ConnectionClosedHomeScreenPartner();
        }

        if (appBrokeOnInvite === 'partner' && !isStandalone()) {
            return <ConnectionClosedWebAppPartner oneInstanceModel={props.model.oneInstance} />;
        }

        if (appBrokeOnInvite === 'IoM') {
            return (
                <ConnectionClosedIoM
                    oneInstanceModel={props.model.oneInstance}
                    patientType={props.patientType}
                />
            );
        }

        return (
            <Login
                oneInstanceModel={props.model.oneInstance}
                fromErase={logoutFromErase}
                setFromErase={setLogoutFromErase}
                contactModel={props.model.contactModel}
                isPartnerApp={props.isPartnerApp}
                patientType={props.patientType}
                recoveryModel={props.model.recoveryModel}
            />
        );
    }

    return (
        // added to prevent the double tap zoom for ios devices
        // NOTE: the click listener is mandatory together with css: "touch-action: manipulation"
        // in order to prevent the double tap zoom
        <div className="remove-double-tap-zoom">
            <BrowserRouter>
                <div className="mobile-landscape-notification">
                    <LandscapeMode />
                    <div>{i18n.t('common:landscapeWarning')}</div>
                </div>
                <div className={menuContentStyle}>
                    <div
                        className="mobile-app-overlay"
                        onClick={() => setNavigationDrawerState(false)}
                    />
                    <div className="app-content-wrapper">
                        <Route exact path="/FAQ">
                            <SettingsPolicies file={i18n.t('markDown:faq')} />
                        </Route>
                        <Route exact path="/impressum">
                            <SettingsPolicies file={i18n.t('markDown:impressum')} />
                        </Route>
                        <Route exact path="/privacyPolicy">
                            <SettingsPolicies file={i18n.t('markDown:privacyPolicyPWA')} />
                        </Route>
                        <BackButton isHidden={true} />
                        {isIOS && isMobile && isSafari && !isStandalone() ? (
                            <AddToHomeScreen />
                        ) : authenticationState === AuthenticationState.Authenticated &&
                          footerPage === Footer.NoFooter ? (
                            <>
                                {
                                    /* Until the user accept the privacy policy or synchronise
                                     * this device with another one that he owns, he can see just
                                     * the Privacy page and the Invitation one */
                                    registrationState ? (
                                        <Switch>
                                            <Route path={'/invites/personalCloud'}>
                                                <CreateAcceptInvites
                                                    connectionsModel={props.model.connections}
                                                    oneInstanceModel={props.model.oneInstance}
                                                    accessModel={props.model.accessModel}
                                                />
                                            </Route>
                                            <Route>
                                                <RegistrationProcessInformation
                                                    consentFileModel={props.model.consentFile}
                                                    oneInstanceModel={props.model.oneInstance}
                                                    recoveryModel={props.model.recoveryModel}
                                                />
                                            </Route>
                                        </Switch>
                                    ) : (
                                        <>
                                            {/** Register only partner app specific paths*/}
                                            {props.isPartnerApp ? (
                                                <Switch>
                                                    <Route path="/invites">
                                                        <CreateAcceptInvites
                                                            connectionsModel={
                                                                props.model.connections
                                                            }
                                                            oneInstanceModel={
                                                                props.model.oneInstance
                                                            }
                                                            accessModel={props.model.accessModel}
                                                        />
                                                    </Route>
                                                    <Route path="/participantInformation">
                                                        <DisplayConsentFiles
                                                            consentFileModel={
                                                                props.model.consentFile
                                                            }
                                                            enableDownload={false}
                                                            settings={props.settings.getChild(
                                                                'displayDocuments'
                                                            )}
                                                            rootPropertyTree={props.settings}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route
                                                        exact
                                                        path="/information/participantInformation/"
                                                    >
                                                        <DisplayConsentFiles
                                                            consentFileModel={
                                                                props.model.consentFile
                                                            }
                                                            enableDownload={true}
                                                            settings={props.settings.getChild(
                                                                'displayDocuments'
                                                            )}
                                                            rootPropertyTree={props.settings}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route exact path="/feedback">
                                                        <Feedback newsModel={props.model.news} />
                                                    </Route>
                                                    <Route exact path="/settings/erase">
                                                        <Erase
                                                            setLogoutFromErase={setLogoutFromErase}
                                                            consentFileModel={
                                                                props.model.consentFile
                                                            }
                                                            oneInstanceModel={
                                                                props.model.oneInstance
                                                            }
                                                            setNavigationDrawer={
                                                                setNavigationDrawerState
                                                            }
                                                            settings={props.settings}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route exact path="/settings/backup">
                                                        <Backup
                                                            oneInstanceModel={
                                                                props.model.oneInstance
                                                            }
                                                        />
                                                    </Route>
                                                    <Route exact path="/settings/app">
                                                        <AppSettings
                                                            settings={props.settings.getChild(
                                                                'appSettings'
                                                            )}
                                                            rootPropertyTree={props.settings}
                                                        />
                                                    </Route>
                                                    <Route exact path="/connections">
                                                        <Connections
                                                            connectionsModel={
                                                                props.model.connections
                                                            }
                                                            accessModel={props.model.accessModel}
                                                            isPartnerApp={props.isPartnerApp}
                                                            instancesModel={
                                                                props.model.instancesModel
                                                            }
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/termsOfUse">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:termsOfUse')}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/impressum">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:impressum')}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/FAQ">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:faq')}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/privacyPolicy">
                                                        <SettingsPolicies
                                                            file={i18n.t(
                                                                'markDown:privacyPolicyPWA'
                                                            )}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/license">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:license')}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/releaseNotes">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:releaseNotes')}
                                                        />
                                                    </Route>
                                                    <Route
                                                        path="/logout"
                                                        to="/logout?keep=true|false"
                                                        render={renderLogoutComponent}
                                                    />
                                                    <Route path="/journal">
                                                        <Journal
                                                            journalModel={props.model.journal}
                                                            eventTypes={eventTypesState}
                                                            covidWorkflowModel={
                                                                props.model.covidWorkflow
                                                            }
                                                            settings={props.settings.getChild(
                                                                'journal'
                                                            )}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route path="/diary/view/:diaryId">
                                                        <DiaryEntryPage
                                                            diaryModel={props.model.diary}
                                                        />
                                                    </Route>
                                                    <Route path="/diary">
                                                        <DiaryEntryPage
                                                            diaryModel={props.model.diary}
                                                        />
                                                    </Route>
                                                    <Route path="/news">
                                                        <NewsReader
                                                            newsModel={props.model.news}
                                                            settings={props.settings.getChild(
                                                                'news'
                                                            )}
                                                        />
                                                    </Route>
                                                    <Route path="/covidQuestionnaire/:action">
                                                        <CovidQuestionnaire
                                                            questionnaireModel={
                                                                props.model.questionnaires
                                                            }
                                                            settings={props.settings.getChild(
                                                                'covidQuestionnaire'
                                                            )}
                                                            productType={productType}
                                                        />
                                                    </Route>
                                                    <Route path="/covidQuestionnaireView/:action">
                                                        <CovidQuestionnaire
                                                            questionnaireModel={
                                                                props.model.questionnaires
                                                            }
                                                            settings={props.settings.getChild(
                                                                'covidQuestionnaire'
                                                            )}
                                                            productType={productType}
                                                        />
                                                    </Route>
                                                    <Route exact path="/">
                                                        <HomeScreenStepper
                                                            covidWorkflowModel={
                                                                props.model.covidWorkflow
                                                            }
                                                            accessModel={props.model.accessModel}
                                                            isPartnerApp={props.isPartnerApp}
                                                            settings={props.settings.getChild(
                                                                'homeScreen'
                                                            )}
                                                        />
                                                    </Route>
                                                </Switch>
                                            ) : (
                                                <Switch>
                                                    <Route path="/participantInformation">
                                                        <DisplayConsentFiles
                                                            consentFileModel={
                                                                props.model.consentFile
                                                            }
                                                            enableDownload={false}
                                                            settings={props.settings.getChild(
                                                                'displayDocuments'
                                                            )}
                                                            rootPropertyTree={props.settings}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route exact path="/settings/erase">
                                                        <Erase
                                                            setLogoutFromErase={setLogoutFromErase}
                                                            consentFileModel={
                                                                props.model.consentFile
                                                            }
                                                            oneInstanceModel={
                                                                props.model.oneInstance
                                                            }
                                                            setNavigationDrawer={
                                                                setNavigationDrawerState
                                                            }
                                                            settings={props.settings}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route exact path="/">
                                                        <HomeScreenStepper
                                                            covidWorkflowModel={
                                                                props.model.covidWorkflow
                                                            }
                                                            accessModel={props.model.accessModel}
                                                            isPartnerApp={props.isPartnerApp}
                                                            settings={props.settings.getChild(
                                                                'homeScreen'
                                                            )}
                                                        />
                                                    </Route>
                                                    <Route path="/feedback">
                                                        <Feedback newsModel={props.model.news} />
                                                    </Route>
                                                    <Route path="/journal">
                                                        <Journal
                                                            journalModel={props.model.journal}
                                                            eventTypes={eventTypesState}
                                                            covidWorkflowModel={
                                                                props.model.covidWorkflow
                                                            }
                                                            settings={props.settings.getChild(
                                                                'journal'
                                                            )}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route path="/diary/view/:diaryId">
                                                        <DiaryEntryPage
                                                            diaryModel={props.model.diary}
                                                        />
                                                    </Route>
                                                    <Route path="/diary">
                                                        <DiaryEntryPage
                                                            diaryModel={props.model.diary}
                                                        />
                                                    </Route>
                                                    <Route path="/covidQuestionnaire/:action">
                                                        <CovidQuestionnaire
                                                            questionnaireModel={
                                                                props.model.questionnaires
                                                            }
                                                            settings={props.settings.getChild(
                                                                'covidQuestionnaire'
                                                            )}
                                                            productType={productType}
                                                        />
                                                    </Route>
                                                    <Route path="/covidQuestionnaireView/:action">
                                                        <CovidQuestionnaire
                                                            questionnaireModel={
                                                                props.model.questionnaires
                                                            }
                                                            settings={props.settings.getChild(
                                                                'covidQuestionnaire'
                                                            )}
                                                            productType={productType}
                                                        />
                                                    </Route>
                                                    <Route exact path="/settings/backup">
                                                        <Backup
                                                            oneInstanceModel={
                                                                props.model.oneInstance
                                                            }
                                                        />
                                                    </Route>
                                                    <Route exact path="/personalCloud">
                                                        <PersonalCloud
                                                            connectionsModel={
                                                                props.model.connections
                                                            }
                                                        />
                                                    </Route>
                                                    <Route exact path="/connections/personalCloud">
                                                        <PersonalCloud
                                                            connectionsModel={
                                                                props.model.connections
                                                            }
                                                        />
                                                    </Route>
                                                    <Route exact path="/connections">
                                                        <Connections
                                                            connectionsModel={
                                                                props.model.connections
                                                            }
                                                            accessModel={props.model.accessModel}
                                                            isPartnerApp={props.isPartnerApp}
                                                            instancesModel={
                                                                props.model.instancesModel
                                                            }
                                                        />
                                                    </Route>
                                                    <Route path="/connections/otherConnections">
                                                        <PartnerConnections
                                                            connectionsModel={
                                                                props.model.connections
                                                            }
                                                            oneInstanceModel={
                                                                props.model.oneInstance
                                                            }
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route path="/invites">
                                                        <CreateAcceptInvites
                                                            connectionsModel={
                                                                props.model.connections
                                                            }
                                                            oneInstanceModel={
                                                                props.model.oneInstance
                                                            }
                                                            accessModel={props.model.accessModel}
                                                        />
                                                    </Route>
                                                    <Route path="/create-news">
                                                        <NewsCreator newsModel={props.model.news} />
                                                    </Route>
                                                    <Route path="/news">
                                                        <NewsReader
                                                            newsModel={props.model.news}
                                                            settings={props.settings.getChild(
                                                                'news'
                                                            )}
                                                        />
                                                    </Route>
                                                    <Route exact path="/settings/app">
                                                        <AppSettings
                                                            settings={props.settings.getChild(
                                                                'appSettings'
                                                            )}
                                                            rootPropertyTree={props.settings}
                                                        />
                                                    </Route>
                                                    <Route path="/information/participantInformation/">
                                                        <DisplayConsentFiles
                                                            consentFileModel={
                                                                props.model.consentFile
                                                            }
                                                            enableDownload={true}
                                                            settings={props.settings.getChild(
                                                                'displayDocuments'
                                                            )}
                                                            rootPropertyTree={props.settings}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route path="/settings/recovery">
                                                        <RecoveryPage
                                                            consentFileModel={
                                                                props.model.consentFile
                                                            }
                                                            recoveryModel={
                                                                props.model.recoveryModel
                                                            }
                                                        />
                                                    </Route>
                                                    <Route path="/dropoutFileView">
                                                        <DisplayConsentFiles
                                                            consentFileModel={
                                                                props.model.consentFile
                                                            }
                                                            enableDownload={false}
                                                            viewDropoutFile={true}
                                                            settings={props.settings.getChild(
                                                                'displayDocuments'
                                                            )}
                                                            rootPropertyTree={props.settings}
                                                            isPartnerApp={props.isPartnerApp}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/termsOfUse">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:termsOfUse')}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/impressum">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:impressum')}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/FAQ">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:faq')}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/privacyPolicy">
                                                        <SettingsPolicies
                                                            file={i18n.t(
                                                                'markDown:privacyPolicyPWA'
                                                            )}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/license">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:license')}
                                                        />
                                                    </Route>
                                                    <Route exact path="/information/releaseNotes">
                                                        <SettingsPolicies
                                                            file={i18n.t('markDown:releaseNotes')}
                                                        />
                                                    </Route>
                                                    <Route
                                                        path="/logout"
                                                        to="/logout?keep=true|false"
                                                        render={renderLogoutComponent}
                                                    />
                                                    <Route path="/settings/autocompleteQuestionnaires">
                                                        <AutocompleteQuestionnaires
                                                            channelManager={
                                                                props.model.channelManager
                                                            }
                                                            questionnaireModel={
                                                                props.model.questionnaires
                                                            }
                                                            accessModel={props.model.accessModel}
                                                        />
                                                    </Route>
                                                </Switch>
                                            )}
                                            {/* Display the top bar */}
                                            <AutoLogout
                                                oneInstanceModel={props.model.oneInstance}
                                                setNavigationDrawerState={setNavigationDrawerState}
                                            />
                                            <MenuButton
                                                isMenuOpen={navigationDrawerState}
                                                isHidden={true}
                                                clickCallback={() => {
                                                    setNavigationDrawerState(
                                                        !navigationDrawerState
                                                    );
                                                }}
                                            />

                                            {/* Display the menu on the left*/}
                                            <div className="app-menu">
                                                {navigationDrawerState && (
                                                    <NavigationDrawer
                                                        navigationDrawerItems={
                                                            navigationDrawerItemsState
                                                        }
                                                        setNavigationDrawerState={
                                                            setNavigationDrawerState
                                                        }
                                                        settings={props.settings.getChild(
                                                            'navigationDrawer'
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        ) : authenticationState === AuthenticationState.NotAuthenticated ? (
                            footerPage === Footer.NoFooter && (
                                <Switch>
                                    <Route exact path="/reset">
                                        <Reset
                                            oneInstanceModel={props.model.oneInstance}
                                            setNavigationDrawer={setNavigationDrawerState}
                                        />
                                    </Route>
                                    <Route path="/">{renderLoginOrErrorView()}</Route>
                                </Switch>
                            )
                        ) : (
                            footerPage === Footer.NoFooter && <Loading />
                        )}
                    </div>
                </div>
                <FooterElement
                    setNavigationDrawerState={setNavigationDrawerState}
                    setFooter={setFooterPage}
                />
            </BrowserRouter>
        </div>
    );
}
