import React from 'react';
import DisplayMarkdownFiles from '../displayMarkdowns/displayMarkdownFiles';
import './SettingsPolicies.css';
import i18n from '../../i18n';
import MenuButton from '../menu/MenuButton';
import BackButton from '../menu/BackButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {displayCircularProgress} from '../utils/Utils';

const PageTitles: {[index: string]: string} = {
    'faq.md': 'faq',
    'license.md': 'viewLicense',
    'impressum.md': 'impressum',
    'termsOfUse.md': 'termsConditions',
    'privacyPolicyPWA.md': 'privacyPolicy',
    'releaseNotes.md': 'version'
};

/**
 * This component builds and returns the view of the markdown file received as a parameter.
 * @param {{}} props - the file path
 * @param {string} props.file
 * @returns {React.ReactElement}
 */
export default function SettingsPolicies(props: {file: string}): React.ReactElement {
    const isFromFooter = !(window.location.pathname.split('/')[1] === 'information');
    const [title, setTitle] = React.useState('');

    React.useEffect(() => {
        displayCircularProgress();

        const splitPath = props.file.split('/');
        const file = splitPath[splitPath.length - 1];
        setTitle(PageTitles[file]);
    }, [props.file]);

    return (
        <>
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>

            <div className="settings-policies page-container hide">
                <div className="menu-button-header">
                    {isFromFooter ? <BackButton /> : <MenuButton />}
                    <h2 className="headline">{i18n.t(`common:settings.${title}`)}</h2>
                </div>
                <DisplayMarkdownFiles filePath={props.file} />
            </div>
        </>
    );
}
