import CovidWorkflowModel from './CovidWorkflowModel';
import {
    ContactModel,
    JournalModel,
    QuestionnaireModel,
    DocumentModel,
    ConnectionsModel,
    DiaryModel,
    NewsModel,
    OneInstanceModel,
    ChannelManager,
    ConsentFileModel,
    PropertyTreeStore,
    PropertyTree,
    AccessModel,
    RecoveryModel,
    ECGModel
} from 'one.models/lib/models';
import InstancesModel from 'one.models/lib/models/InstancesModel';
import FreedaAccessRightsManager, {FreedaAccessGroups} from './FreedaAccessRightsManager';
import {Person, SHA256IdHash} from '@OneCoreTypes';
import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';
import {EventType} from 'one.models/lib/models/JournalModel';
//import * as QuestionnaireBDE from './questionnaires/de/QuestionnaireB';

import {QuestionnaireB as QuestionnaireB_de} from './questionnaires/de/QuestionnaireB';
import {QuestionnaireBI as QuestionnaireBI_de} from './questionnaires/de/QuestionnaireBI';
import {QuestionnaireBT as QuestionnaireBT_de} from './questionnaires/de/QuestionnaireBT';
import {QuestionnaireI as QuestionnaireI_de} from './questionnaires/de/QuestionnaireI';
import {QuestionnaireIM as QuestionnaireIM_de} from './questionnaires/de/QuestionnaireIM';
import {QuestionnaireS as QuestionnaireS_de} from './questionnaires/de/QuestionnaireS';
import {QuestionnaireT as QuestionnaireT_de} from './questionnaires/de/QuestionnaireT';
import {QuestionnaireVB as QuestionnaireVB_de} from './questionnaires/de/QuestionnaireVB';
import {QuestionnaireVS as QuestionnaireVS_de} from './questionnaires/de/QuestionnaireVS';

import {QuestionnaireB as QuestionnaireB_en} from './questionnaires/en/QuestionnaireB';
import {QuestionnaireBI as QuestionnaireBI_en} from './questionnaires/en/QuestionnaireBI';
import {QuestionnaireBT as QuestionnaireBT_en} from './questionnaires/en/QuestionnaireBT';
import {QuestionnaireI as QuestionnaireI_en} from './questionnaires/en/QuestionnaireI';
import {QuestionnaireIM as QuestionnaireIM_en} from './questionnaires/en/QuestionnaireIM';
import {QuestionnaireS as QuestionnaireS_en} from './questionnaires/en/QuestionnaireS';
import {QuestionnaireT as QuestionnaireT_en} from './questionnaires/en/QuestionnaireT';
import {QuestionnaireVB as QuestionnaireVB_en} from './questionnaires/en/QuestionnaireVB';
import {QuestionnaireVS as QuestionnaireVS_en} from './questionnaires/en/QuestionnaireVS';

/* import * as logger from 'one.core/lib/logger';
logger.start({
    includeInstanceName: false,
    types: ['error', 'alert', 'log']
});*/

/**
 * List with available questionnaires
 */
const questionnaires: Questionnaire[] = [
    QuestionnaireB_de,
    QuestionnaireBI_de,
    QuestionnaireBT_de,
    QuestionnaireI_de,
    QuestionnaireIM_de,
    QuestionnaireS_de,
    QuestionnaireT_de,
    QuestionnaireVB_de,
    QuestionnaireVS_de,
    QuestionnaireB_en,
    QuestionnaireBI_en,
    QuestionnaireBT_en,
    QuestionnaireI_en,
    QuestionnaireIM_en,
    QuestionnaireS_en,
    QuestionnaireT_en,
    QuestionnaireVB_en,
    QuestionnaireVS_en
];

export default class Model {
    constructor(isPartnerApp: boolean, commServerUrl: string) {
        this.isPartnerApp = isPartnerApp;

        // Setup basic models
        this.accessModel = new AccessModel();
        this.channelManager = new ChannelManager(this.accessModel);
        this.instancesModel = new InstancesModel();
        this.contactModel = new ContactModel(
            this.instancesModel,
            commServerUrl,
            this.channelManager
        );
        this.connections = new ConnectionsModel(this.contactModel, this.instancesModel, {
            commServerUrl,
            acceptIncomingConnections: true,
            acceptUnknownInstances: true,
            acceptUnknownPersons: false,
            allowOneTimeAuth: true,
            authTokenExpirationDuration: 60000 * 15, // qr-code (invitation) timeout
            establishOutgoingConnections: true,
            connectToOthersWithAnonId: true
        });
        this.settings = new PropertyTreeStore('Settings', '.');

        // Setup freeda specific models
        this.consentFile = new ConsentFileModel(this.channelManager);
        this.oneInstance = new OneInstanceModel(
            this.channelManager,
            this.consentFile,
            this.accessModel
        );
        this.freedaAccessRightsManager = new FreedaAccessRightsManager(
            this.accessModel,
            this.channelManager,
            this.connections,
            this.contactModel,
            isPartnerApp
        );
        this.recoveryModel = new RecoveryModel(this.connections);

        // Setup data manging models
        this.questionnaires = new QuestionnaireModel(this.channelManager);
        this.documents = new DocumentModel(this.channelManager);
        this.diary = new DiaryModel(this.channelManager);
        this.news = new NewsModel(this.channelManager);
        this.ecgModel = new ECGModel(this.channelManager);
        this.journal = new JournalModel([
            {
                model: this.questionnaires,
                retrieveFn: async () => await this.questionnaires.responses(),
                eventType: EventType.QuestionnaireResponse
            },
            {
                model: this.documents,
                retrieveFn: async () => await this.documents.documents(),
                eventType: EventType.DocumentInfo
            },
            {
                model: this.diary,
                retrieveFn: async () => await this.diary.entries(),
                eventType: EventType.DiaryEntry
            },
            {
                model: this.consentFile,
                retrieveFn: async () => await this.consentFile.entries(),
                eventType: EventType.ConsentFileEvent
            }
        ]);

        // Setup additional freeda specific stuff
        this.covidWorkflow = new CovidWorkflowModel(
            this.questionnaires,
            this.oneInstance,
            this.news
        );

        // Setup event handler that initialize the models when somebody logged in
        // and shuts down the model when somebody logs out.
        this.oneInstance.loggingIn = this.init.bind(this);
        this.oneInstance.loggingOut = this.shutdown.bind(this);
    }

    /**
     * Initialize all the models.
     *
     * TODO: Note that if any of the model initization fails we would need to shutdown all
     *       the models!
     *
     * @param {boolean} registrationState
     * @param {string} anonymousEmail
     * @param {boolean} takeOver
     * @returns {Promise<void>}
     */
    public async init(
        registrationState: boolean,
        anonymousEmail?: string,
        takeOver?: boolean,
        recoveryState?: boolean
    ): Promise<void> {
        try {
            const secret = this.oneInstance.getSecret();

            /**
             * In instance take over and in recovery process the main person and
             * the anonymous person keys will be overwritten, so the first generated
             * keys can be ignored, because they will not be used after the overwrite
             * process is completed.
             *
             * This is just a temporary workaround! (only a hack!)
             */
            const ownerWillBeOverwritten = takeOver || recoveryState;

            // Initialize contact model. This is the base for identity handling and everything
            await this.contactModel.init(ownerWillBeOverwritten);
            await this.accessModel.init();
            await this.instancesModel.init(secret);

            // Setup the identities
            const {mainId, anonymousId} = await this.setupMyIds(
                anonymousEmail,
                ownerWillBeOverwritten
            );
            this.consentFile.setPersonId(anonymousId);

            // Initialize the rest of the models
            await this.channelManager.init(anonymousId);
            await this.consentFile.init();
            await this.news.init();
            await this.questionnaires.init();
            this.questionnaires.registerQuestionnaires(questionnaires);
            await this.diary.init();
            await this.covidWorkflow.init();
            await this.settings.init();
            await this.freedaAccessRightsManager.init(mainId, anonymousId); // is this the correct location?
            this.connections.setPassword(secret);
            this.recoveryModel.setPassword(secret);
            await this.ecgModel.init();
            this.journal.init();

            if (recoveryState) {
                // In the recovery process the person keys have to be overwritten because the
                // instance has to be recreated with the old informations received in the url.
                await this.recoveryModel.overwritePersonKeyWithReceivedEncryptedOnes();

                // Try to connect with the clinic in order to recover data before staring the
                // applications. This will try to establish a connection to the clinic and if
                // no connection was established the application will be initialised without
                // the recovered data.
                const clinicPersons = await this.accessModel.getAccessGroupPersons(
                    FreedaAccessGroups.clinic
                );

                try {
                    await Promise.all(
                        clinicPersons.map(async replicantPerson => {
                            await this.connections.connectOneTime(replicantPerson, 1000, 10 * 1000);
                        })
                    );
                } catch (e) {
                    if (
                        e.message ===
                        'The connection could not be established before the timeout was reached!'
                    ) {
                        // timeout exceed and data was not recovered
                    }
                    console.error(e);
                }
            }

            await this.connections.init();
            await this.documents.init();

            // Set the access rights after the 2000 ms duplicate detection window on the replicant
            setTimeout(() => {
                this.freedaAccessRightsManager.startSendingReplicantRights();
            }, 3000);
        } catch (e) {
            // Shutdown all models when initialization failed.
            // Shutdown should not throw, even if models were not initialized.
            // So this call should never throw. If it throws we should return the
            // original error, not the one from shutdown, because otherwise the original
            // problem will be obfuscated. => console.error is ok here. Perhaps later we
            // should emit it as error event when we have a proper setup how to handle those.
            await this.shutdown().catch(console.error);
            throw e;
        }
    }

    /**
     * Shutdown the models.
     *
     * @returns {Promise<void>}
     */
    public async shutdown(): Promise<void> {
        try {
            await this.freedaAccessRightsManager.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.connections.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.diary.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.questionnaires.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.news.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.consentFile.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.channelManager.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.contactModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.documents.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            await this.ecgModel.shutdown();
        } catch (e) {
            console.error(e);
        }

        try {
            this.journal.shutdown();
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Sets up my own ids.
     *
     * For this project it is just a main id and an anonymous one.
     *
     * TODO: remove the takeover flag when the hack is removed
     *
     * @param {string} anonymousEmail - If specified use this email instead of a random one for the anon id.
     * @param {boolean} takeOver - On takeover omit the public person key from the contact object (only a hack!)
     * @returns {Promise<{mainId: SHA256IdHash<Person>; anonymousId: SHA256IdHash<Person>}>}
     */
    private async setupMyIds(
        anonymousEmail?: string,
        takeOver?: boolean
    ): Promise<{mainId: SHA256IdHash<Person>; anonymousId: SHA256IdHash<Person>}> {
        // Setup identities if necessary
        let anonymousId;
        const mainId = await this.contactModel.myMainIdentity();
        const myIdentities = await this.contactModel.myIdentities();

        if (myIdentities.length === 2) {
            anonymousId = myIdentities[0] === mainId ? myIdentities[1] : myIdentities[0];
        } else if (anonymousEmail) {
            anonymousId = await this.contactModel.createNewIdentity(true, anonymousEmail, takeOver);
        } else {
            anonymousId = await this.contactModel.createNewIdentity(true);
        }

        return {
            mainId,
            anonymousId
        };
    }

    public channelManager: ChannelManager;
    public contactModel: ContactModel;
    public journal: JournalModel;
    public questionnaires: QuestionnaireModel;
    public documents: DocumentModel;
    public news: NewsModel;
    public oneInstance: OneInstanceModel;
    public connections: ConnectionsModel;
    public diary: DiaryModel;
    public covidWorkflow: CovidWorkflowModel;
    public consentFile: ConsentFileModel;
    public settings: PropertyTree;
    public accessModel: AccessModel;
    public instancesModel: InstancesModel;
    public freedaAccessRightsManager: FreedaAccessRightsManager;
    public recoveryModel: RecoveryModel;
    public ecgModel: ECGModel;
    public readonly isPartnerApp: boolean;
}
