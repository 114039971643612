import QuestionnaireModel, {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';
import React, {useEffect} from 'react';

/**
 * Use this to grab a questionnaire based on a questionnaire id from the questionnaire model
 *
 * @param {QuestionnaireModel} questionnaireModel - the questionnaire model from which to grab the
 *                                                  questionnaires
 * @param {string} questionnaireName - The name of the questionnaire to grab
 * @param {(err: Error) => void} onerror - Called if an error happens (e.g. the questionnaire does not exist)
 * @returns {Questionnaire} The selected questionnaire or an empty questionnaire object before the async get
 *                          function has finished.
 */
export default function useQuestionnaireByName(
    questionnaireModel: QuestionnaireModel,
    questionnaireName: string | undefined,
    onerror: (err: Error) => void
): Questionnaire {
    if (!questionnaireName) {
        throw Error('questionnaire name is undefined.');
    }

    const [questionnaireState, setQuestionnaire] = React.useState({
        $type$: 'Questionnaire',
        resourceType: 'Questionnaire',
        status: 'active',
        item: []
    } as Questionnaire);

    // Load the selected questionnaire from the model
    useEffect(() => {
        questionnaireModel
            .questionnaireByName(questionnaireName)
            .then(questionnaire => {
                setQuestionnaire(questionnaire);
            })
            .catch(err => {
                setQuestionnaire({
                        resourceType: 'Questionnaire',
                        status: 'active',
                        item: []
                });
                onerror(err);
            });
    }, [questionnaireName, questionnaireModel]);

    return questionnaireState;
}
