import React, {useEffect} from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    TextField
} from '@material-ui/core';
import './Login.css';
import OneInstanceModel from 'one.models/lib/models/OneInstanceModel';
import {Alert, AlertTitle} from '@material-ui/lab';
import i18n from '../../i18n';
import InformationPage from '../displayMarkdowns/displayMarkdownFiles';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import {Icon} from '../icon/Icon';
import {displayCircularProgress, hideCircularProgress, isStandalone} from '../utils/Utils';
import ContactModel from 'one.models/lib/models/ContactModel';
import RecoveryModel from 'one.models/lib/models/RecoveryModel';

enum UserMode {
    NoUser,
    NewUser,
    UserHasAccountOnOtherDevice,
    ExistingUser,
    Recovery,
    PartnerWithoutInvitation,
    ChooseInstanceOrInvitation
}

/**
 * Displays Login page
 *
 * TODO: Remove the patientType from props again after restructuring of the oneInstanceModel
 *       It just does not make sense to pass it from the ui.
 *
 * @param {{}} props
 * @param {OneInstanceModel} props.oneInstanceModel
 * @param {boolean} props.fromErase
 * @param {Function} props.setFromErase
 * @param {ContactModel} props.contactModel
 * @param {boolean} props.isPartnerApp - Set to true if view should be rendered for partner app.
 * @param {boolean} props.patientType - Use this patient type for queries on oneInstanceModel.
 * @param {RecoveryModel} props.recoveryModel
 * @returns {React.ReactElement}
 */
export default function Login(props: {
    oneInstanceModel: OneInstanceModel;
    fromErase: boolean;
    setFromErase: (setLogoutFromErase: boolean) => void;
    contactModel: ContactModel;
    isPartnerApp: boolean;
    patientType: string;
    recoveryModel: RecoveryModel;
}): React.ReactElement {
    const [userMode, setUserMode] = React.useState<UserMode>(UserMode.NoUser);
    const [showEraseDialog, setShowEraseDialog] = React.useState(false);
    const [messageForLoginError, setMessageForLoginError] = React.useState(false);

    const [secretLogin, setSecretLogin] = React.useState('');

    const [secretRegister, setSecretRegister] = React.useState('');
    const [confirmSecretRegister, setConfirmSecretRegister] = React.useState('');

    const [secretRecovery, setSecretRecovery] = React.useState('');
    const [confirmSecretRecovery, setConfirmSecretRecovery] = React.useState('');
    const [recoveryKey, setRecoveryKey] = React.useState('');

    const [errorState, setErrorState] = React.useState('');
    const [displayError, setDisplayError] = React.useState(false);

    const [isPersonalCloudInvite, setIsPersonalCouldInvite] = React.useState(false);
    const [isInviteFromCache, setIsInviteFromCache] = React.useState(false);

    const [savedToken, setSavedToken] = React.useState('');

    const landingPageTextPath = props.isPartnerApp
        ? i18n.t('markDown:landingPagePartner')
        : i18n.t('markDown:landingPagePatient');

    const [disableLogin, setDisableLogin] = React.useState(false);

    // consider just the case for instance recover, and not the recovery page
    // recovery is available only for patient, not for partner app
    const isRecoveryMode =
        window.location.pathname.split('/')[1] === 'recovery' && !props.isPartnerApp;
    const history = useHistory();

    useEffect(() => {
        if (
            window.location.pathname === '/invites/personalCloud/' &&
            window.location.search.includes('invited=true')
        ) {
            setIsPersonalCouldInvite(true);
        }

        const rootHMTMLElement = document.getElementById('root');

        if (rootHMTMLElement) {
            rootHMTMLElement.classList.add('root-for-login');
        }

        return () => {
            if (rootHMTMLElement) {
                rootHMTMLElement.classList.remove('root-for-login');
            }
        };
    }, []);

    /**
     * This useEffect has the role of setting the type of a user, which then depending on this userMode will be loaded
     * the correct view on the page. There are several types of users:
     *  - UserMode.ChooseInstanceOrInvitation: is a user, who must choose between keeping the current instance or accepting the received invitation.
     *  - UserMode.PartnerWithoutInvitation: is a partner user, who does not have an instance already created in localStorage
     *    and no invitation (neither in path nor in CacheStorage).
     *  - UserMode.Recovery: is a user, who wants to recover his password.
     *  - UserMode.ExistingUser: is an existing user, because there is already an instance in localStorage.
     *  - UserMode.NoUser: is a new user within the application, who will have to register.
     */
    useEffect(() => {
        if (
            ((window.location.pathname.includes('invites') &&
                window.location.search.includes('invited=true')) ||
                savedToken) &&
            localStorage.getItem('instance') &&
            !isStandalone()
        ) {
            setUserMode(UserMode.ChooseInstanceOrInvitation);
        } else if (
            ((window.location.pathname.includes('invites') &&
                window.location.search.includes('invited=true')) ||
                savedToken) &&
            localStorage.getItem('instance') &&
            isStandalone()
        ) {
            handleContinueWithInvitation();
        } else if (
            props.isPartnerApp &&
            !localStorage.getItem('instance') &&
            !window.location.pathname.includes('invites') &&
            !savedToken
        ) {
            setUserMode(UserMode.PartnerWithoutInvitation);
        } else if (
            (isRecoveryMode || savedToken.includes('recovery')) &&
            isStandalone() &&
            localStorage.getItem('instance')
        ) {
            // hack for STH: because the dynamic manifest is used, the start_url it's always the original one
            handleContinueWithInvitation();
        } else if (isRecoveryMode || savedToken.includes('recovery')) {
            setUserMode(UserMode.Recovery);
        } else if (localStorage.getItem('instance') || isPersonalCloudInvite) {
            setUserMode(UserMode.ExistingUser);
        } else {
            setUserMode(UserMode.NoUser);
        }
    }, [showEraseDialog, isPersonalCloudInvite, isRecoveryMode, savedToken]);

    function listenForAuthentication(): void {
        props.oneInstanceModel.addListener('authstate_changed', () => {
            removeAuthenticationListener();
        });
    }

    function removeAuthenticationListener(): void {
        props.oneInstanceModel.removeListener('authstate_changed', () => {
            hideCircularProgress();
        });
    }

    async function onLogin(): Promise<void> {
        setMessageForLoginError(false);
        setDisableLogin(true);

        try {
            if (secretLogin === '') {
                setErrorState(i18n.t('errors:oneInstanceModel.fillAllFields'));
                setDisplayError(true);
                setDisableLogin(false);
                return;
            }
            displayCircularProgress();
            await props.oneInstanceModel
                .login(secretLogin, props.patientType, isPersonalCloudInvite)
                .then(() => {
                    // Only for home screen apps -> adds the hash saved in CacheStorage to the path
                    if (isInviteFromCache && savedToken) {
                        history.push(savedToken);
                    }
                });
            listenForAuthentication();
        } catch (err) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            if (err.message.includes('SC-INIT1')) {
                setMessageForLoginError(true);
                setErrorState('passwordNotValid');
            } else {
                console.error(err);
                setErrorState('unknownError');
            }
            setDisplayError(true);
            setDisableLogin(false);
            hideCircularProgress();
        }
    }

    async function onRegister(): Promise<void> {
        if (secretRegister === '') {
            setErrorState(i18n.t('errors:oneInstanceModel.fillAllFields'));
            setDisplayError(true);
            return;
        }

        if (confirmSecretRegister === '') {
            setErrorState(i18n.t('errors:oneInstanceModel.fillAllFields'));
            setDisplayError(true);
            return;
        }

        if (secretRegister !== confirmSecretRegister) {
            setErrorState('samePassword');
            setDisplayError(true);
            return;
        }

        try {
            displayCircularProgress();
            await props.oneInstanceModel.register(secretRegister, props.patientType).then(() => {
                // Only for home screen apps -> adds the hash saved in CacheStorage to the path
                if (isInviteFromCache && savedToken) {
                    history.push(savedToken);
                }
            });
            listenForAuthentication();
        } catch (err) {
            if (err instanceof EvalError) {
                setErrorState(err.message);
            } else {
                setErrorState(i18n.t('errors:unknownError'));
            }
            setDisplayError(true);
            hideCircularProgress();
        }
    }

    async function onRecovery(): Promise<void> {
        if (secretRecovery !== confirmSecretRecovery) {
            setErrorState('samePassword');
            setDisplayError(true);
            return;
        }

        history.push(window.location.hash.replace('%23', '#'));

        // the path for the recovery case is
        // #recoveryNonce#encryptedPersonInformation
        const recoveryNonce = window.location.hash.split('#')[1];
        const encryptedPersonInformation = window.location.hash.split('#')[2];

        try {
            displayCircularProgress();

            // Decrypt the person information from the qr code using the recovery nonce from the url
            // and the recovery key that the user has entered. From the decrypted information extract
            // user email and anonymous user email.
            const personEmails = await props.recoveryModel.decryptReceivedRecoveryInformation(
                recoveryKey,
                recoveryNonce,
                encryptedPersonInformation
            );
            await props.oneInstanceModel.recoverInstance(
                personEmails.personEmail,
                secretRecovery,
                props.patientType,
                personEmails.anonPersonEmail
            );
            listenForAuthentication();
        } catch (_) {
            setErrorState(i18n.t('errors:unknownError'));
            setDisplayError(true);
            hideCircularProgress();
        }
        history.push('/');
    }

    async function _handleKeyDownLogin(e: React.KeyboardEvent<HTMLDivElement>): Promise<void> {
        if (e.key === 'Enter') {
            setMessageForLoginError(false);
            await onLogin();
        }
    }

    async function _handleKeyDownRegister(e: React.KeyboardEvent<HTMLDivElement>): Promise<void> {
        if (e.key === 'Enter') {
            await onRegister();
        }
    }

    async function _handleKeyDownRecovery(e: React.KeyboardEvent<HTMLDivElement>): Promise<void> {
        if (e.key === 'Enter') {
            await onRecovery();
        }
    }

    async function handleDeleteInstance(): Promise<void> {
        // todo figure out why this is an unsafe call
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await props.oneInstanceModel.deleteUnopenedInstance();
        window.location.reload();
    }

    function handleContinueWithInvitation(): void {
        history.push('/');

        setSavedToken('');
        setIsInviteFromCache(false);
        setIsPersonalCouldInvite(false);

        setUserMode(UserMode.ExistingUser);
    }

    function erasePaper(): React.ReactElement {
        return (
            <>
                {showEraseDialog && (
                    <Paper
                        square
                        elevation={3}
                        className="stick-message-top message-font-size error-color"
                    >
                        <Alert
                            severity="error"
                            onClose={() => {
                                setShowEraseDialog(false);
                            }}
                        >
                            <AlertTitle className="message-title">
                                {' '}
                                {i18n.t('errors:titleError')}
                            </AlertTitle>
                            {i18n.t('errors:login.deleteAvailableData')}
                            <br />
                            <Button
                                className="button-erase-dialog"
                                color="primary"
                                variant="contained"
                                onClick={async () => {
                                    // todo figure out why this is an unsafe call
                                    await props.oneInstanceModel.eraseWhileLoggedOut();
                                    window.location.reload();
                                }}
                            >
                                {i18n.t('common:buttons.deleteAvailableData')}
                            </Button>
                        </Alert>
                    </Paper>
                )}
            </>
        );
    }

    /**
     * This function renders the initial view, when there is no instance and no invitation
     * @returns {React.ReactElement}
     */
    function renderStartView(): React.ReactElement {
        return (
            <>
                <div className="login-text">
                    {i18n.t('login:loginContentPart1')}
                    <br />
                    {!props.isPartnerApp && (
                        <>
                            {i18n.t('login:loginContentPart2')}
                            <br />
                        </>
                    )}
                    {i18n.t('login:loginContentPart3')}
                </div>

                <div className="login-button">
                    <Button
                        onClick={() => setUserMode(UserMode.NewUser)}
                        color="primary"
                        variant="contained"
                    >
                        {i18n.t('common:buttons.newAccount')}
                    </Button>
                </div>
                <div className="login-text">
                    {i18n.t('login:loginContentPart4')}
                    <br />
                    {i18n.t('login:loginContentPart5')}
                </div>
                <div className="login-button">
                    <Button
                        onClick={() => setUserMode(UserMode.UserHasAccountOnOtherDevice)}
                        color="primary"
                        variant="contained"
                    >
                        {i18n.t('common:buttons.alreadyExistingAccount')}
                    </Button>
                </div>
                <Dialog
                    onClose={() => {
                        // eslint-disable-next-line react/prop-types
                        props.setFromErase(false);
                    }}
                    // eslint-disable-next-line react/prop-types
                    open={props.fromErase}
                >
                    <DialogContent>
                        <DialogContentText> {i18n.t('common:loginAfterErase')} </DialogContentText>
                        {/* eslint-disable-next-line react/prop-types */}
                        <Button className="ok-button" onClick={() => props.setFromErase(false)}>
                            {i18n.t('common:buttons.okButton')}
                        </Button>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    /**
     * This function renders the registration view
     * @returns {React.ReactElement}
     */
    function renderNewUser(): React.ReactElement {
        return (
            <>
                <div className="title-register">{i18n.t('login:registerTitle')}</div>
                <div className="login-text-fields">
                    {i18n.t('login:registerTitle2')}
                    <TextField
                        fullWidth
                        label={i18n.t('login:password')}
                        type="password"
                        onKeyDown={async e => {
                            await _handleKeyDownRegister(e);
                        }}
                        value={secretRegister}
                        onChange={e => {
                            setSecretRegister(e.target.value);
                        }}
                        required
                    />
                </div>
                <div className="login-text-fields">
                    <TextField
                        fullWidth
                        label={i18n.t('login:confirmPassword')}
                        type="password"
                        value={confirmSecretRegister}
                        onKeyDown={async e => {
                            await _handleKeyDownRegister(e);
                        }}
                        onChange={e => setConfirmSecretRegister(e.target.value)}
                        required
                    />
                </div>
                <div className="login-button">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={secretRegister === '' || confirmSecretRegister === ''}
                        onClick={async () => onRegister()}
                    >
                        {i18n.t('login:registerButton')}
                    </Button>
                </div>
            </>
        );
    }

    /**
     * This function renders the recovery view
     * @returns {React.ReactElement}
     */
    function renderRecovery(): React.ReactElement {
        return (
            <div className="existing-user-space">
                <label className="title-register">{i18n.t('login:recovery')}</label>
                <div className="login-text-fields">
                    <TextField
                        fullWidth
                        label={i18n.t('login:password')}
                        type="password"
                        onKeyDown={async e => {
                            await _handleKeyDownRecovery(e);
                        }}
                        value={secretRecovery}
                        onChange={e => setSecretRecovery(e.target.value)}
                        required
                    />
                </div>
                <div className="login-text-fields">
                    <TextField
                        fullWidth
                        label={i18n.t('login:confirmPassword')}
                        type="password"
                        value={confirmSecretRecovery}
                        onKeyDown={async e => {
                            await _handleKeyDownRecovery(e);
                        }}
                        onChange={e => setConfirmSecretRecovery(e.target.value)}
                        required
                    />
                </div>
                <div className="login-text-fields">
                    <TextField
                        fullWidth
                        label={i18n.t('login:recoveryKey')}
                        value={recoveryKey}
                        onKeyDown={async e => {
                            await _handleKeyDownRecovery(e);
                        }}
                        onChange={e => setRecoveryKey(e.target.value)}
                        required
                    />
                </div>
                <div className="login-button">
                    <Button variant="contained" color="primary" onClick={async () => onRecovery()}>
                        {i18n.t('login:recovery')}
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * This function renders the view when the user has an account on another device
     * @returns {React.ReactElement}
     */
    function renderUserHasAccountOnADevice(): React.ReactElement {
        return (
            <>
                <div className="title-register">{i18n.t('login:connect')}</div>
                <div className="login-text-fields">{i18n.t('login:existingUser')}</div>
                <div className="login-text-fields">{i18n.t('login:existingUser2')}</div>
            </>
        );
    }

    /**
     * This function renders the login view
     * @returns {React.ReactElement}
     */
    function renderExistingUser(): React.ReactElement {
        return (
            <div className="existing-user-space">
                <label className="title-register">{i18n.t('login:loginTitle')}</label>
                <div className="login-text-fields">
                    <TextField
                        fullWidth
                        disabled={disableLogin}
                        label={i18n.t('login:password')}
                        type="password"
                        onKeyDown={async e => {
                            await _handleKeyDownLogin(e);
                        }}
                        value={secretLogin}
                        onChange={e => {
                            setSecretLogin(e.target.value);
                        }}
                        required
                    />
                </div>
                <div className="login-button">
                    <Button
                        disabled={secretLogin === '' || disableLogin}
                        variant="contained"
                        color="primary"
                        onClick={async () => onLogin()}
                    >
                        {i18n.t('login:loginButton')}
                    </Button>
                </div>
            </div>
        );
    }

    /**
     * This function renders the view when it is a partner application but without an invitation
     * Rule: A partner cannot create an instance if he does not have an invitation
     * @returns {React.ReactElement}
     */
    function renderPartnerWithoutInvitation(): React.ReactElement {
        return (
            <div className="login-no-invitation">{i18n.t('login:partnerWithoutInvitation')}</div>
        );
    }

    /**
     * This function renders the view where the user needs to choose between the current instance or the invitation.
     * This view is loaded when there is already an instance on the current page/application, but the user receives a new invitation.
     *
     * Rule: If there is already an instance, then a new invitation cannot be accepted.
     * The user must choose between deleting or continuing with the current instance.
     * @returns {React.ReactElement}
     */
    function renderChooseInstanceOrInvitationView(): React.ReactElement {
        return (
            <>
                <p className="login-description-text">
                    {isPersonalCloudInvite
                        ? i18n.t('login:disallowIoMInvitation')
                        : i18n.t('login:disallowPartnerInvitation')}
                </p>

                <div className="login-button">
                    <Button
                        onClick={handleDeleteInstance}
                        color="primary"
                        variant="contained"
                        className="margin-bottom"
                    >
                        {i18n.t('common:buttons.deleteAvailableData')}
                    </Button>
                    <Button
                        onClick={handleContinueWithInvitation}
                        color="primary"
                        variant="contained"
                    >
                        {i18n.t('login:continueButton')}
                    </Button>
                </div>
            </>
        );
    }

    /**
     * This function renders the right view, depending on the userMode
     * @returns {React.ReactElement}
     */
    function renderWhichTypeOfUserView(): React.ReactElement {
        switch (userMode) {
            case UserMode.NoUser:
                return renderStartView();
            case UserMode.ExistingUser:
                return renderExistingUser();
            case UserMode.UserHasAccountOnOtherDevice:
                return (
                    <>
                        <IconButton
                            className="login-back-button"
                            onClick={() => setUserMode(UserMode.NoUser)}
                        >
                            <Icon name="ArrowLeft" />
                        </IconButton>
                        <>{renderUserHasAccountOnADevice()}</>
                    </>
                );
            case UserMode.NewUser:
                return (
                    <>
                        <IconButton
                            className="login-back-button"
                            onClick={() => setUserMode(UserMode.NoUser)}
                        >
                            <Icon name="ArrowLeft" />
                        </IconButton>
                        <>{renderNewUser()}</>
                    </>
                );
            case UserMode.Recovery:
                return renderRecovery();
            case UserMode.PartnerWithoutInvitation:
                return renderPartnerWithoutInvitation();
            case UserMode.ChooseInstanceOrInvitation:
                return renderChooseInstanceOrInvitationView();
            default:
                return renderStartView();
        }
    }

    function errorMessage(): React.ReactElement {
        return (
            <>
                {displayError && (
                    <Paper
                        square
                        elevation={3}
                        className="stick-message-top message-font-size error-color"
                    >
                        <Alert
                            severity="error"
                            onClose={() => {
                                setDisplayError(false);
                            }}
                        >
                            <AlertTitle className="message-title">
                                {i18n.t('errors:titleError')}
                            </AlertTitle>
                            <>
                                {errorState === 'unknownError'
                                    ? i18n.t('errors:unknownError')
                                    : errorState === 'passwordNotValid'
                                    ? i18n.t(`errors:login:${errorState}`)
                                    : errorState === 'samePassword'
                                    ? i18n.t(`errors:login:${errorState}`)
                                    : errorState}
                                {messageForLoginError ? (
                                    <>
                                        <label
                                            onClick={() => {
                                                setShowEraseDialog(true);
                                                setDisplayError(false);
                                            }}
                                            className="text-alert-login"
                                        >
                                            <strong>
                                                {i18n.t('errors:login:passwordNotValid1')}
                                            </strong>
                                        </label>
                                        {i18n.t('errors:login:passwordNotValid2')}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        </Alert>
                    </Paper>
                )}
            </>
        );
    }

    return (
        <div className="login-page-wrapper">
            {erasePaper()}
            {errorState ? errorMessage() : <></>}
            <div className="circular-progress-container">
                <CircularProgress className="circular-progress" size={35} />
            </div>
            <img
                alt="logo"
                className="app-logo"
                src={
                    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                    require('../DEFENSE_COVID-19_Logo_RGB.svg')
                }
            />
            <div className="login-content page-container hide">
                <div className="login-introduction-box">
                    <InformationPage
                        filePath={
                            isRecoveryMode ? i18n.t('markDown:recoveryPage') : landingPageTextPath
                        }
                    />
                </div>
                <div className="login-box">
                    <Paper square elevation={3} className="paper-login paper-font-size">
                        {renderWhichTypeOfUserView()}
                    </Paper>
                </div>
            </div>
        </div>
    );
}
