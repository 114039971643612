import ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import React from 'react';
import i18n from '../../i18n';
import {
    TableCell,
    TableRow,
    Table,
    TableBody,
    List,
    ListItem,
    ListItemText,
    Avatar,
    ListItemAvatar,
    TableContainer,
    withStyles,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {Person, SHA256IdHash} from '@OneCoreTypes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ConnectionInfo} from 'one.models/lib/misc/CommunicationModule';

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

/**
 * @param {{}} props
 * @param {ConnectionsModel} props.connectionsModel
 * @param {boolean} props.personalCloud
 * @param {string} props.userType
 * @param {Map<SHA256IdHash<Person>, ConnectionInfo[]>} props.connections
 * @returns {React.ReactElement}
 */
export default function CloudConnections(props: {
    connections: Map<SHA256IdHash<Person>, ConnectionInfo[]>;
    connectionsModel: ConnectionsModel;
    personalCloud: boolean;
    userType?: string;
}): React.ReactElement {
    // eslint-disable-next-line @typescript-eslint/ban-types,func-style
    const getKeyValue = <T extends object, U extends keyof T>(key: U) => (obj: T) => obj[key];

    function buildNewDevicesList(
        connectionsForPersonId: ConnectionInfo[] | undefined
    ): React.ReactElement {
        const devicesList: JSX.Element[] = [];

        if (connectionsForPersonId === undefined) {
            return <>{devicesList}</>;
        }
        // eslint-disable-next-line react/prop-types
        connectionsForPersonId.forEach(connection => {
            const {targetInstanceId, isConnected, url} = connection;
            devicesList.push(
                <Accordion key={targetInstanceId}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ListItem button key={targetInstanceId}>
                            <ListItemAvatar
                                className={
                                    isConnected
                                        ? 'no-background-avatar open-connection'
                                        : 'no-background-avatar close-connection'
                                }
                            >
                                <Avatar />
                            </ListItemAvatar>
                            <ListItemText className="my-info-summary">
                                <div>{connection.targetInstanceId}</div>
                                <div className="myContactType">{url}</div>
                            </ListItemText>
                        </ListItem>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer>
                            <Table size="small" aria-label="simple table">
                                <TableBody>
                                    {Object.keys(connection).map((key: any) => (
                                        <StyledTableRow key={key as string}>
                                            <StyledTableCell component="th" scope="row">
                                                <Box fontWeight="fontWeightBold" m={1}>
                                                    {key}
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {(getKeyValue(key as never)(
                                                    connection
                                                ) as string).toString()}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            );
        });

        return <>{devicesList}</>;
    }

    function noAvailableConnections(): boolean {
        return Array.from(props.connections.keys()).length === 0;
    }

    return (
        <Paper square elevation={3} className="page-content-box">
            <div className="types-list paper-font-size">
                {noAvailableConnections() ? (
                    <p>
                        {props.personalCloud
                            ? i18n.t('connections:personalDevices.noConnections')
                            : props.userType
                            ? i18n.t(`connections:partnerDevices.${props.userType}NoConnections`)
                            : ''}
                    </p>
                ) : (
                    <div>
                        <b>{i18n.t('connections:personalDevices.remoteInstanceKey')}</b>
                        {Array.from(props.connections.keys()).map(
                            (personId: SHA256IdHash<Person>) => (
                                <div key={personId}>
                                    <Box fontWeight="fontWeightBold" m={1}>
                                        {personId}
                                    </Box>
                                    <List>
                                        {buildNewDevicesList(
                                            props.connections.has(personId)
                                                ? props.connections.get(personId)
                                                : []
                                        )}
                                    </List>
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>
        </Paper>
    );
}
