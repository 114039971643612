import React from 'react';
import {
    Accordion,
    AccordionSummary,
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    AccordionDetails,
    Button
} from '@material-ui/core';
import ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import './CloudConnections.css';
import '../../Primary.css';
import i18n from '../../i18n';
import Paper from '@material-ui/core/Paper';
import MenuButton from '../menu/MenuButton';
import {ConnectionInfo} from 'one.models/lib/misc/CommunicationModule';
import {Person, SHA256IdHash} from '@OneCoreTypes';
import {
    downloadPairingInformationFile,
    useOtherConnections,
    usePersonalConnections
} from '../modelHelper/ConnectionsHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {AccessModel} from 'one.models/lib/models';
import {useHistory} from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import InfoMessage, {MessageType} from '../errors/InfoMessage';
import {FreedaAccessGroups} from '../../model/FreedaAccessRightsManager';
import InstancesModel from 'one.models/lib/models/InstancesModel';
import {useCurrentAnonInstanceId} from '../modelHelper/InstancesHelper';

/**
 * used in ConnectionsHelper to add to panels type
 */
export enum ConnectionsType {
    PersonalCloud,
    PartnerConnection,
    ClinicServer,
    RaspberryPi
}

/**
 * @param {{}} props
 * @param {ConnectionsModel} props.connectionsModel
 * @param {AccessModel} props.accessModel
 * @param {boolean} props.isPartnerApp - Set to true if view should be rendered for partner app.
 * @param {InstancesModel} props.instancesModel
 * @returns {React.ReactElement}
 */
export default function Connections(props: {
    connectionsModel: ConnectionsModel;
    accessModel: AccessModel;
    instancesModel: InstancesModel;
    isPartnerApp: boolean;
}): React.ReactElement {
    const history = useHistory();
    const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
    const [errorState, setErrorState] = React.useState<string>();
    const [storeDataPermission, setStoreDataPermission] = React.useState<boolean>(false);

    /** used to open the panels that contain connections */
    const [panels, setPanels] = React.useState([ConnectionsType.RaspberryPi]);

    /** personal cloud connections */
    const personalConnectionsMap = usePersonalConnections(
        props.connectionsModel,
        panels,
        setPanels
    );

    /** partner connections */
    const partnerConnectionsMap = useOtherConnections(
        props.connectionsModel,
        props.accessModel,
        FreedaAccessGroups.partner,
        setErrorState,
        panels,
        setPanels
    );

    const userType = props.isPartnerApp ? 'partner' : 'patient';

    /** clinic connections */
    const clinicConnectionsMap = useOtherConnections(
        props.connectionsModel,
        props.accessModel,
        FreedaAccessGroups.clinic,
        setErrorState,
        panels,
        setPanels
    );

    /** local anonymous instance id hash */
    const currentInstanceIdHash = useCurrentAnonInstanceId(props.instancesModel, setErrorState);

    /**
     * When a new connection is added to the list open the corresponded panel.
     *
     * @param {ConnectionsType} connectionsType
     */
    function handleAccordionChange(connectionsType: ConnectionsType): void {
        if (panels.includes(connectionsType)) {
            const indexOfPanel = panels.indexOf(connectionsType);
            panels.splice(indexOfPanel, 1);
        } else {
            panels.push(connectionsType);
        }
        setPanels([...panels]);
    }

    React.useEffect(() => {
        if (storeDataPermission) {
            downloadPairingInformationFile(props.connectionsModel, true)
                .then(() => {
                    setStoreDataPermission(false);
                })
                .catch(err => {
                    console.error(err);
                    setErrorState(err);
                });
        }
    }, [storeDataPermission, props.connectionsModel]);

    /**
     * Display the connection with the corresponded status.
     *
     * @param {ConnectionInfo[] | undefined} connectionInfo
     * @returns {React.ReactElement}
     */
    function buildConnectionsList(
        connectionInfo: ConnectionInfo[] | undefined
    ): React.ReactElement {
        const devicesList: JSX.Element[] = [];

        if (connectionInfo === undefined) {
            return <>{devicesList}</>;
        }
        connectionInfo.forEach(connection => {
            const {targetInstanceId, isConnected} = connection;
            devicesList.push(
                <ListItem button key={targetInstanceId}>
                    <ListItemAvatar
                        className={
                            isConnected
                                ? 'no-background-avatar open-connection'
                                : 'no-background-avatar close-connection'
                        }
                    >
                        <Avatar />
                    </ListItemAvatar>
                    <ListItemText className="my-info-summary">
                        <div>{connection.targetInstanceId}</div>
                    </ListItemText>
                </ListItem>
            );
        });

        return <>{devicesList}</>;
    }

    /**
     * Render the dialog for deleting an existing connection.
     *
     * @param {boolean} personalCloud
     * @returns {React.ReactElement}
     */
    function removeDialog(personalCloud: boolean): React.ReactElement {
        return (
            <Dialog
                open={openRemoveDialog}
                onClose={() => {
                    setOpenRemoveDialog(false);
                }}
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {i18n.t('connections:deleteConnection')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenRemoveDialog(false);
                        }}
                        color="primary"
                    >
                        {i18n.t('common:settings.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            setOpenRemoveDialog(false);
                            personalCloud ? removePersonalDevice() : removePartner();
                        }}
                        color="primary"
                        autoFocus
                    >
                        {i18n.t('common:buttons.okButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    // TODO: to be implemented
    function removePersonalDevice(): void {
        // eslint-disable-next-line no-console
        console.log('removePersonalDevice');
    }

    function removePartner(): void {
        Array.from(partnerConnectionsMap.values()).forEach(connectionInfo => {
            return connectionInfo.forEach(async partnerInfo => {
                await props.accessModel.removePersonFromAccessGroup(
                    FreedaAccessGroups.partner,
                    partnerInfo.targetPersonId
                );
            });
        });
    }

    function invitePartner(): void {
        history.push('/invites/invitePartner');
    }

    function inviteNewDevice(): void {
        history.push('/invites/personalCloud');
    }

    /**
     * Build connection panel.
     *
     * @param {Map<SHA256IdHash<Person>, ConnectionInfo[]>} connectionsMap - existing connections which will be displayed in this panel
     * @param {ConnectionsType} connectionsType - type of the displayed connections
     * @param {string} listTitle - title of the panel
     * @param {string} noConnectionsMessage - the message that will be displayed when the connectionsMap is empty
     * @param {string} buttonMessage - message for the invite button
     * @param {(() => void) | undefined} buttonOnClickAction - action for the invite button
     * @returns {React.ReactElement}
     */
    function buildConnectionsComponent(
        connectionsMap: Map<SHA256IdHash<Person>, ConnectionInfo[]>,
        connectionsType: ConnectionsType,
        listTitle: string,
        noConnectionsMessage: string,
        buttonMessage?: string,
        buttonOnClickAction?: (() => void) | undefined
    ): React.ReactElement {
        return (
            <Accordion
                key={listTitle}
                expanded={panels.includes(connectionsType)}
                onChange={() => {
                    handleAccordionChange(connectionsType);
                }}
                className="connections-list"
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="list-title">{listTitle}</div>
                </AccordionSummary>
                <AccordionDetails className="connections-list">
                    {connectionsType === ConnectionsType.PersonalCloud ? (
                        // display the id used for personal cloud connections
                        displayListItemWithoutIcon(
                            i18n.t('connections:mainInstanceId'),
                            currentInstanceIdHash.mainInstanceId
                        )
                    ) : (
                        <></>
                    )}
                    {connectionsType === ConnectionsType.PartnerConnection ? (
                        // display the id used for partner connections
                        displayListItemWithoutIcon(
                            i18n.t('connections:anonInstanceId'),
                            currentInstanceIdHash.anonymousInstanceId
                        )
                    ) : (
                        <></>
                    )}
                    {connectionsMap.size === 0 ? (
                        // display the message for the case when no connection is available
                        displayListItemWithoutIcon(
                            i18n.t('connections:otherDevices'),
                            noConnectionsMessage
                        )
                    ) : (
                        <div className="types-list paper-font-size">
                            {connectionsType === ConnectionsType.PartnerConnection ||
                            connectionsType === ConnectionsType.PersonalCloud ? (
                                <b>{i18n.t('connections:otherDevices')}</b>
                            ) : (
                                <></>
                            )}
                            {Array.from(connectionsMap.keys()).map(
                                // display all existing connections with the corresponding status
                                (personId: SHA256IdHash<Person>) => (
                                    <div key={personId}>
                                        <List>
                                            {buildConnectionsList(
                                                connectionsMap.has(personId)
                                                    ? connectionsMap.get(personId)
                                                    : []
                                            )}
                                        </List>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    {buttonMessage ? (
                        <div className="button-container">
                            {buttonOnClickAction === undefined ? removeDialog(false) : <></>}
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    buttonOnClickAction
                                        ? buttonOnClickAction()
                                        : setOpenRemoveDialog(true);
                                }}
                            >
                                {buttonMessage}
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                </AccordionDetails>
            </Accordion>
        );
    }

    /**
     * Display received arguments as list items with invisible
     * icon in order to preserve the page layout.
     *
     * @param {string} title -> will be displayed as simple bold text (as a list title)
     * @param {string} value -> list item text value
     * @returns {React.ReactElement}
     */
    function displayListItemWithoutIcon(title: string, value?: string): React.ReactElement {
        return (
            <div className="paper-font-size">
                <b>{title}</b>
                <ListItem key={'InstanceId'}>
                    <ListItemAvatar className={'no-background-avatar my-information'}>
                        <Avatar />
                    </ListItemAvatar>
                    <ListItemText className="my-info-summary">
                        <div>{value}</div>
                    </ListItemText>
                </ListItem>
            </div>
        );
    }

    return (
        <>
            {errorState ? (
                <InfoMessage
                    errorMessage={errorState}
                    displayMessage={!!errorState}
                    setDisplayMessage={setErrorState}
                    messageType={MessageType.Error}
                />
            ) : (
                <div />
            )}
            <div className="page-container personal-devices-container">
                <div className="menu-button-header">
                    <MenuButton />
                    <h2 className="headline">{i18n.t('common:settings.connections')}</h2>
                </div>
                <Paper square elevation={3} className="page-content-box">
                    {buildConnectionsComponent(
                        personalConnectionsMap,
                        ConnectionsType.PersonalCloud,
                        i18n.t('connections:personalDevices.personalDevicesTitle'),
                        i18n.t('connections:personalDevices.noConnections'),
                        i18n.t('connections:personalDevices.addNewDevice'),
                        inviteNewDevice
                    )}
                    <Divider />
                    {buildConnectionsComponent(
                        partnerConnectionsMap,
                        ConnectionsType.PartnerConnection,
                        i18n.t(`connections:partnerDevices.${userType}DevicesTitle`),
                        i18n.t(`connections:partnerDevices.${userType}NoConnections`),
                        props.isPartnerApp
                            ? undefined
                            : i18n.t(
                                  `connections:partnerDevices.connectWithA${
                                      userType[0].toUpperCase() + userType.substring(1)
                                  }`
                              ),
                        props.isPartnerApp ? undefined : invitePartner
                    )}
                    <Divider />
                    {buildConnectionsComponent(
                        clinicConnectionsMap,
                        ConnectionsType.ClinicServer,
                        i18n.t('connections:clinicDevices'),
                        i18n.t('connections:personalDevices.noConnections')
                    )}
                </Paper>
            </div>
        </>
    );
}
