import ConnectionsModel from 'one.models/lib/models/ConnectionsModel';
import {ConnectionInfo} from 'one.models/lib/misc/CommunicationModule';
import React from 'react';
import {Person, SHA256IdHash} from '@OneCoreTypes';
import {AccessModel} from 'one.models/lib/models';
import {ConnectionsType} from '../cloudConnections/Connections';
import i18n from '../../i18n';
import {hostnameOptions} from '../../hostnameOptions';
import {FreedaAccessGroups} from '../../model/FreedaAccessRightsManager';

/* TODO this part adding the optional parameters (setPanels, panels) is a temporary solution for release
 *  they are used to set the panels in the first case when the connections list is rendered for the panels
 *  that contain the connections to be opened when going to connections page. They are used in the
 *  2 functions (useOtherConnections and usePersonal) ,set as optional parameters
 */

/**
 * Identify all personal cloud connections and return the connections information.
 *
 * @param {ConnectionsModel} connectionsModel
 * @param  {ConnectionsType[]} panels
 * @param {(value: ConnectionsType[]) => void} setPanels
 * @returns {ConnectionInfo[]}
 */
export function usePersonalConnections(
    connectionsModel: ConnectionsModel,
    panels?: ConnectionsType[],
    setPanels?: (value: ConnectionsType[]) => void
): Map<SHA256IdHash<Person>, ConnectionInfo[]> {
    const [personsToConnectionsMap, setPersonsToConnectionsMap] = React.useState<
        Map<SHA256IdHash<Person>, ConnectionInfo[]>
    >(new Map());

    React.useEffect(() => {
        function fetchConnections(): void {
            const cloudConnections = connectionsModel
                .connectionsInfo()
                .filter((connection: ConnectionInfo) => connection.isInternetOfMe);
            setPersonsToConnectionsMap(createPersonToConnectionsMap(cloudConnections));

            // if there is a cloudConnection in the list set the panel too to be opened
            if (cloudConnections.length > 0) {
                if (panels && !panels.includes(ConnectionsType.PersonalCloud)) {
                    panels.push(ConnectionsType.PersonalCloud);

                    if (setPanels) {
                        setPanels(panels);
                    }
                }
            }
        }

        connectionsModel.on('connectionsChange', fetchConnections);
        fetchConnections();

        return () => {
            connectionsModel.removeListener('connectionsChange', fetchConnections);
        };
    }, [connectionsModel]);

    return personsToConnectionsMap;
}

/**
 * Identify all partner connections which are included in a specific access group and return the connections information.
 * @param {ConnectionsModel} connectionsModel
 * @param {AccessModel} accessModel
 * @param {string} groupName
 * @param {(value: string) => void} setErrorCallback
 * @param {ConnectionInfo[]} panels
 * @param {(value: ConnectionsType[]) => void} setPanels
 * @returns {ConnectionInfo[]}
 */
export function useOtherConnections(
    connectionsModel: ConnectionsModel,
    accessModel: AccessModel,
    groupName: string,
    setErrorCallback: (value: string) => void,
    panels?: ConnectionsType[],
    setPanels?: (value: ConnectionsType[]) => void
): Map<SHA256IdHash<Person>, ConnectionInfo[]> {
    const [personsToConnectionsMap, setPersonsToConnectionsMap] = React.useState<
        Map<SHA256IdHash<Person>, ConnectionInfo[]>
    >(new Map());

    React.useEffect(() => {
        async function fetchConnections(): Promise<void> {
            try {
                // check if the group exists before accessing it's members
                await accessModel.getAccessGroupByName(groupName);

                const availablePartnerConnections = await accessModel.getAccessGroupPersons(
                    groupName
                );

                const partnerConnections = connectionsModel
                    .connectionsInfo()
                    .filter(
                        (connection: ConnectionInfo) =>
                            !connection.isInternetOfMe &&
                            availablePartnerConnections.includes(connection.targetPersonId)
                    );
                setPersonsToConnectionsMap(createPersonToConnectionsMap(partnerConnections));

                // if a partner connection is received check group and set specific panel to open
                if (
                    partnerConnections.length > 0 &&
                    panels &&
                    (!panels.includes(ConnectionsType.ClinicServer) ||
                        !panels.includes(ConnectionsType.PartnerConnection))
                ) {
                    groupName === FreedaAccessGroups.clinic
                        ? panels.push(ConnectionsType.ClinicServer)
                        : panels.push(ConnectionsType.PartnerConnection);

                    if (setPanels) {
                        setPanels([...panels]);
                    }
                }
            } catch (error) {
                // the groups_updated event is emitted also when the groups are created
                // and because the application is not yet initialised or the group that
                // we want to access is not created yet we expect to receive an error
                // when trying to access the group
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (error.name !== 'FileNotFoundError' || error.name !== 'Error') {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    setErrorCallback(error.name);
                }
            }
        }

        connectionsModel.on('connectionsChange', fetchConnections);
        accessModel.on('groups_updated', fetchConnections);
        fetchConnections().catch((err: Error) => {
            setErrorCallback(err.name);
        });

        return () => {
            connectionsModel.removeListener('connectionsChange', fetchConnections);
            accessModel.removeListener('groups_updated', fetchConnections);
        };
    }, [connectionsModel, accessModel, groupName, setErrorCallback]);

    return personsToConnectionsMap;
}

/**
 * Identify all partner connections and return the connections information.
 *
 * @param {ConnectionsModel} connectionsModel
 * @returns {Map<SHA256IdHash<Person>, ConnectionInfo[]>}
 */
export function usePartnerConnections(
    connectionsModel: ConnectionsModel
): Map<SHA256IdHash<Person>, ConnectionInfo[]> {
    const [personsToConnectionsMap, setPersonsToConnectionsMap] = React.useState<
        Map<SHA256IdHash<Person>, ConnectionInfo[]>
    >(new Map());

    React.useEffect(() => {
        function fetchConnections(): void {
            const partnerConnections = connectionsModel
                .connectionsInfo()
                .filter((connection: ConnectionInfo) => !connection.isInternetOfMe);

            setPersonsToConnectionsMap(createPersonToConnectionsMap(partnerConnections));
        }

        connectionsModel.on('connectionsChange', fetchConnections);
        fetchConnections();

        return () => {
            connectionsModel.removeListener('connectionsChange', fetchConnections);
        };
    }, [connectionsModel]);

    return personsToConnectionsMap;
}

/**
 * @param {ConnectionInfo[]} connections
 * @returns {Map<SHA256IdHash<Person>, ConnectionInfo[]>}
 */
export function createPersonToConnectionsMap(
    connections: ConnectionInfo[]
): Map<SHA256IdHash<Person>, ConnectionInfo[]> {
    const connectionsMap = new Map<SHA256IdHash<Person>, ConnectionInfo[]>();

    for (const conn of connections) {
        if (connectionsMap.has(conn.targetPersonId)) {
            const connectionsOfPerson: ConnectionInfo[] | undefined = connectionsMap.get(
                conn.targetPersonId
            );

            if (connectionsOfPerson !== undefined) {
                connectionsOfPerson.push(conn);
            }

            connectionsMap.set(
                conn.targetPersonId,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                connectionsOfPerson
            );
        } else {
            connectionsMap.set(conn.targetPersonId, [conn]);
        }
    }

    return connectionsMap;
}

/**
 * Choose the correct URL for the connection invite.
 *
 * @param {boolean} takeOver
 * @returns {string}
 */
export function urlBase(takeOver: boolean): string {
    const {hostname, protocol, port} = window.location;
    const partnerHostname = hostnameOptions[hostname].partnerHostname;

    if (port === '') {
        return takeOver ? `${protocol}//${hostname}` : `${protocol}//${partnerHostname}`;
    }

    return takeOver
        ? `${protocol}//${hostname}:${port}`
        : `${protocol}//${partnerHostname}:${port}`;
}

/**
 * Create the URL for the invitation.
 *
 * @param {string} code
 * @param {boolean} takeOver
 * @returns {string}
 */
export function acceptUrl(code: string, takeOver: boolean): string {
    return `${urlBase(takeOver)}/invites/${
        takeOver ? 'personalCloud' : 'invitePartner'
    }/?invited=true/#${code}`;
}

/**
 * The generated pairing information will be transmitted in the QR-code and
 * will be used for verifying the identity of the receiver instance.
 *
 * @param {ConnectionsModel} connectionsModel
 * @param {boolean} takeOver
 * @returns {Promise<void>}
 */
export async function downloadPairingInformationFile(
    connectionsModel: ConnectionsModel,
    takeOver: boolean
): Promise<void> {
    const currentInvitationDuration = connectionsModel.authTokenExpirationDuration;
    // make the invitation valid for 24 hours
    connectionsModel.authTokenExpirationDuration = 60000 * 60 * 24;

    const pairingInformation = await connectionsModel.generatePairingInformation(takeOver);
    // restore invitation availability
    connectionsModel.authTokenExpirationDuration = currentInvitationDuration;

    const encryptedInformationString = JSON.stringify(pairingInformation);
    const encodedInformation = encodeURIComponent(encryptedInformationString);
    const filename = i18n.t('connections:rpiFileTitle') + '.json';
    const contentType = 'application/json;charset=utf-8;';
    /**
     * Create fake element to download json file.
     */
    const fakeElement = document.createElement('a');
    fakeElement.download = filename;
    fakeElement.href = 'data:' + contentType + ',' + encodedInformation;
    fakeElement.target = '_blank';
    document.body.appendChild(fakeElement);
    fakeElement.click();
    document.body.removeChild(fakeElement);
}
