import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nModelsInstance from 'one.models/lib/i18n';
import common_en from './translations/en/common.json';
import errors_en from './translations/en/errors.json';
import login_en from './translations/en/login.json';
import news_en from './translations/en/news.json';
import homeScreen_en from './translations/en/homeScreen.json';
import connections_en from './translations/en/connections.json';
import markDown_en from './translations/en/markdownPdfPaths.json';
import steppers_en from './translations/en/steppers.json';
import recovery_en from './translations/en/recovery.json';
import addToHomeScreen_en from './translations/en/addToHomeScreen.json';

import common_de from './translations/de/common.json';
import errors_de from './translations/de/errors.json';
import login_de from './translations/de/login.json';
import news_de from './translations/de/news.json';
import homeScreen_de from './translations/de/homeScreen.json';
import connections_de from './translations/de/connections.json';
import markDown_de from './translations/de/markdownPdfPaths.json';
import steppers_de from './translations/de/steppers.json';
import recovery_de from './translations/de/recovery.json';
import addToHomeScreen_de from './translations/de/addToHomeScreen.json';

const resources = {
    en: {
        common: common_en,
        errors: errors_en,
        login: login_en,
        news: news_en,
        homeScreen: homeScreen_en,
        connections: connections_en,
        markDown: markDown_en,
        steppers: steppers_en,
        recovery: recovery_en,
        addToHomeScreen: addToHomeScreen_en
    },
    de: {
        common: common_de,
        errors: errors_de,
        login: login_de,
        news: news_de,
        homeScreen: homeScreen_de,
        connections: connections_de,
        markDown: markDown_de,
        steppers: steppers_de,
        recovery: recovery_de,
        addToHomeScreen: addToHomeScreen_de
    }
};

const supportedLanguages = new Set(Object.keys(resources));
const fallbackLng = 'de';

i18nModelsInstance
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng
    })
    .then(() => {
        return;
    })
    .catch(ignored => {});

/**
 * Get effective language, i.e. the same language i18next loads the
 * resources from, which is the first language in the languages
 * field that has a resource.
 * @returns {string} - effective language
 */
export function effectiveLanguage(): string {
    return i18nModelsInstance.languages.find(lng => supportedLanguages.has(lng)) || fallbackLng;
}

export default i18nModelsInstance;
