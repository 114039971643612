import {NavigationDrawerItemsType} from '../menu/NavigationDrawer';
import {Icon} from '../icon/Icon';
import React from 'react';
import i18n from '../../i18n';
import {SettingsItemsType} from '../settings/Settings';
import {EventTypes} from '../journal/Journal';
import {EventType} from 'one.models/lib/models/JournalModel';
import {Book, Note, Information} from 'mdi-material-ui';

/**
 *  This function returns the items that will be displayed in the left menu, for the Freeda product.
 *  @returns {NavigationDrawerItemsType[]} - items for the left menu
 */
export function freedaNavigationDrawerItems(): NavigationDrawerItemsType[] {
    return [
        {name: '----'},
        {name: i18n.t('common:menu.home'), path: '/', icon: <Icon name="Home" />},
        {name: i18n.t('common:menu.journal'), path: '/journal', icon: <Icon name="Journal" />},
        {
            name: i18n.t('common:menu.addDiaryEntry'),
            icon: <Icon name="Diary" />,
            path: '/diary'
        },
        {name: i18n.t('common:menu.news'), path: '/news', icon: <Icon name="News" />},
        {name: '----l'},
        {
            name: i18n.t('common:settings.connections'),
            path: '/connections',
            icon: <Icon name="Devices" />
        },
        {
            name: i18n.t('common:settings.information'),
            icon: <Icon name="Information" />,
            path: '/information',
            subItems: freedaSettingsPolciesMenuItems()
        },
        {
            name: i18n.t('common:settings.feedback'),
            path: '/feedback',
            icon: <Icon name="Feedback" />
        },
        {
            name: i18n.t('common:menu.settings'),
            icon: <Icon name="Settings" />,
            path: '/settings',
            subItems: freedaSettingsMenuItems()
        },
        {
            name: i18n.t('common:menu.logout'),
            path: '/logout?keep=true',
            icon: <Icon name="Logout" />
        }
    ];
}

/**
 *  This function returns the list of the menu items provided to the settings page, for the Freeda product.
 *  @returns {SettingsItemsType[]} - items for the settings page
 */
export function freedaSettingsMenuItems(): SettingsItemsType[] {
    return [
        {name: '----'},
        {
            name: i18n.t('common:settings.backup'),
            path: '/settings/backup',
            icon: <Icon name="Backup" />
        },
        {
            name: i18n.t('common:settings.recoveryPage'),
            path: '/settings/recovery',
            icon: <Icon name="LockReset" />
        },
        {
            name: i18n.t('common:settings.appSettings'),
            path: '/settings/app',
            icon: <Icon name="AppSettings" />
        },
        {name: '----'},
        {
            name: i18n.t('common:settings.erase'),
            path: '/settings/erase',
            icon: <Icon name="Erase" />
        }
    ];
}

/**
 *
 * @returns {SettingsItemsType[]}
 */
export function freedaSettingsPolciesMenuItems(): SettingsItemsType[] {
    return [
        {name: '----'},
        {
            name: i18n.t('common:settings.faq'),
            path: '/information/FAQ',
            icon: <Icon name="FAQ" />
        },
        {
            name: i18n.t('common:settings.viewLicense'),
            path: '/information/license',
            icon: <Icon name="ViewLicense" />
        },
        {
            name: i18n.t('common:settings.impressum'),
            path: '/information/impressum',
            icon: <Icon name="Impressum" />
        },
        {
            name: i18n.t('common:settings.termsConditions'),
            path: '/information/termsOfUse',
            icon: <Icon name="TermsAndConditions" />
        },
        {
            name: i18n.t('common:settings.privacyPolicy'),
            path: '/information/privacyPolicy',
            icon: <Icon name="PrivacyPolicy" />
        },
        {
            name: i18n.t('common:settings.ConsentFile'),
            path: '/information/participantInformation',
            icon: <Icon name="PatientInformation" />
        },
        {
            name: i18n.t('common:settings.version'),
            path: '/information/releaseNotes',
            icon: <Icon name="Tag" />
        }
    ];
}

/**
 *  This function returns the list of event types, for the Freeda product.
 *  @returns {EventTypes[]} - list of event types
 */
export function freedaEventTypes(): EventTypes[] {
    return [
        {
            type: EventType.QuestionnaireResponse,
            name: i18n.t('common:menu.questionnaire'),
            icon: <Note className="note" />
        },
        {
            type: EventType.DiaryEntry,
            name: i18n.t('common:eventTypes.diaryEntry'),
            icon: <Book />
        },
        {
            type: EventType.ConsentFileEvent,
            name: i18n.t('common:settings.ConsentFile'),
            icon: <Information />
        }
    ];
}
