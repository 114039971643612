import i18n from '../../i18n';

/**
 *
 * @returns {{[p: string]: string}}
 */
export function getRecoveryText(): {[index: string]: string} {
    return {
        title: i18n.t('steppers:recoveryText.title'),
        introduction: i18n.t('steppers:recoveryText.introduction'),
        firstTitle: i18n.t('steppers:recoveryText.firstTitle'),
        first: i18n.t('steppers:recoveryText.first'),
        secondTitle: i18n.t('steppers:recoveryText.secondTitle'),
        second: i18n.t('steppers:recoveryText.second'),
        thirdTitle: i18n.t('steppers:recoveryText.thirdTitle'),
        third: i18n.t('steppers:recoveryText.third'),
        forth: i18n.t('steppers:recoveryText.forth')
    };
}
