import {Questionnaire} from 'one.models/lib/models/QuestionnaireModel';

export const QuestionnaireBT: Questionnaire = {
    resourceType: 'Questionnaire',
    language: 'en',
    url: 'http://uk-erlangen.de/freeda/questionnaire/BT_en',
    name: 'BT',
    status: 'active',
    item: [
        {
            linkId: 'bTherapyYN',
            prefix: 'BT1',
            text: 'Are you currently receiving cancer therapy?',
            type: 'choice',
            answerOption: [
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '0',
                        display: 'No'
                    }
                },
                {
                    valueCoding: {
                        system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                        version: '1.0',
                        code: '1',
                        display: 'Yes'
                    }
                }
            ]
        },
        {
            linkId: 'BT2_0',
            prefix: 'BT2_0',
            type: 'group',
            enableWhen: [
                {
                    question: 'bTherapyYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'bNumberOfTherapy',
                    prefix: 'BT2_1',
                    type: 'integer',
                    text: 'How many cancer therapies including the current one did you receive?',
                    enableWhen: [
                        {
                            question: 'bNumberOfTherapyUk',
                            operator: 'exists',
                            answerBoolean: false
                        }
                    ],
                    answerRestriction: {
                        minValue: {
                            valueInteger: '1'
                        },
                        maxValue: {
                            valueInteger: '80'
                        }
                    }
                },
                {
                    linkId: 'bNumberOfTherapyUk',
                    prefix: 'BT2_2',
                    type: 'open-choice',
                    required: false,
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/DoNotKnow_en',
                                version: '1.0',
                                code: '1',
                                display: 'I do not know'
                            }
                        }
                    ]
                }
            ]
        },
        {
            linkId: 'BT3_0',
            prefix: 'BT3_0',
            text:
                'Which of the following therapies do you receive because of your cancer? (multiple answers possible)',
            type: 'group',
            enableWhen: [
                {
                    question: 'bTherapyYN',
                    operator: '=',
                    answerCoding: {
                        code: '1'
                    }
                }
            ],
            item: [
                {
                    linkId: 'bAhTherapyYNU',
                    prefix: 'BT3_1',
                    type: 'choice',
                    text: 'anti-hormonal therapy',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bChemoYNU',
                    prefix: 'BT3_2',
                    type: 'choice',
                    text: 'chemotherapy',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bCheckPointYNU',
                    prefix: 'BT3_3',
                    type: 'choice',
                    text:
                        'checkpoint inhibitor therapy (immunotherapy antibodies, e.g. Pembrolizumab, Atezolizumab, Iplimumab, Nivolumab)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bAntiHerYNU',
                    prefix: 'BT3_4',
                    type: 'choice',
                    text: 'anti-HER2-targeted therapy (e.g. Herceptin®, Kanjinti®, Perjeta®)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bOtherAntibodyYNU',
                    prefix: 'BT3_5',
                    type: 'choice',
                    text: 'another antibody therapy',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bCdkiYNU',
                    prefix: 'BT3_6',
                    type: 'choice',
                    text: 'CDK4/6 inhibitor therapy (e.g. Ibrance®, Kisquali®, Verzenios®)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bMToriYNU',
                    prefix: 'BT3_7',
                    type: 'choice',
                    text: 'mTOR inhibitor therapy (e.g. everolimus/rapamycin, sirolimus)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bRadYNU',
                    prefix: 'BT3_8',
                    type: 'choice',
                    text: 'radiotherapy of the chest or lungs (complete or partial)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bAutStemCellYNU',
                    prefix: 'BT3_9',
                    type: 'choice',
                    text: 'autologous stem cell transplants',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bAllogStemCellYNU',
                    prefix: 'BT3_10',
                    type: 'choice',
                    text: 'allogenic stem cell transplants',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bCarTcellYNU',
                    prefix: 'BT3_11',
                    type: 'choice',
                    text: 'CAR-T cell therapy',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bOtherTargTYNU',
                    prefix: 'BT3_12',
                    type: 'choice',
                    text: 'other targeted therapy (e.g. for genetic mutation)',
                    answerOption: [
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '0',
                                display: 'No'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '1',
                                display: 'Yes'
                            }
                        },
                        {
                            valueCoding: {
                                system: 'http://uk-erlangen.de/freeda/valueCoding/YesNo_en',
                                version: '1.0',
                                code: '999',
                                display: 'I do not know'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bOtherTarg_txt',
                    prefix: 'BT3_13',
                    type: 'string',
                    maxLength: 1000,
                    text: 'What other targeted therapy is it?',
                    enableWhen: [
                        {
                            question: 'bOtherTargTYNU',
                            operator: '=',
                            answerCoding: {
                                code: '1'
                            }
                        }
                    ]
                },
                {
                    linkId: 'bMedication_txt',
                    prefix: 'BT3_14',
                    type: 'string',
                    maxLength: 1000,
                    text:
                        'Here you still have the possibility to enter your exact cancer therapy. (multiple answers possible)',
                    required: false
                }
            ]
        }
    ]
};
